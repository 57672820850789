import { call, put, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { notification } from 'antd';
import { DeleteFileAction, setFileList, setSelectedFile, setState } from '../actions';
import api from '../../../../../../services/api';
import { getCompanyLibrary } from '../selectors';
import { CompanyLibraryState } from '../reducers';
import { updateRelations } from './uploadFileWorker';

export function* deleteFileWorker(action: DeleteFileAction) {
  const { fileID } = action.payload;

  yield put(setState({ isLoadingList: true }));

  const { fileList, loadMoreAvailable } = (yield select(getCompanyLibrary)) as CompanyLibraryState;

  try {
    const response = yield call(api.delete, `/v1/company-library/${fileID}`);

    if (response.status === 200) {
      yield put(setSelectedFile(null));

      const newFileList = fileList.filter(file => file.ID !== fileID);
      yield put(setFileList(newFileList, loadMoreAvailable));
    }
    yield call(updateRelations)
  } catch (err) {
    Sentry.captureMessage(`Error deleting file: ${err}`);
    notification.error({
      message: 'Error',
      description: 'There was an error deleting the file. Please try again or contact support.',
    });
  } finally {
    yield put(setState({ isLoadingList: false }));
  }
}
