import { AircraftTimelineEntryType } from '../../../../../common/types/timeline/AircraftTimelineEntryType';
import { CrewTimelineEntryType } from '../../../../../common/types/timeline/CrewTimelineEntryType';
import { ContractTimelineEntryType } from '../../../../../common/types/timeline/ContractTimelineEntryType';

export const entryTypeInStateMap = {
  [AircraftTimelineEntryType.AircraftContract]: 'aircraftContractTimelineEntries',
  [AircraftTimelineEntryType.AircraftSchedule]: 'aircraftScheduleTimelineEntries',
  [CrewTimelineEntryType.CrewSchedule]: 'crewScheduleTimelineEntries',
  [CrewTimelineEntryType.DutySchedule]: 'dutyScheduleTimelineEntries',
  [CrewTimelineEntryType.DutyTimes]: 'dutyTimeTimelineEntries',
  [CrewTimelineEntryType.PilotContract]: 'pilotContractTimelineEntries',
  [ContractTimelineEntryType.ClientContractPeriod]: 'clientContractPeriodTimelineEntries',
};
