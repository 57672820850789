import { AddNewAircraftType, setEntry } from '../actions';
import { put, select } from 'redux-saga/effects';
import { getCSMobile } from '../selectors';
import { CombinedEntry } from '../../../../../../common/types/CombinedEntry';

export function* watchOnDeleteAircraftType(action: AddNewAircraftType) {
  const { entry } = yield select(getCSMobile);
  const { aircraftType } = action.payload;

  // todo: use editValues, but not entry
  const updatedEntry: CombinedEntry = { ...entry };
  updatedEntry.AircraftInformation.FlightInformation = updatedEntry.AircraftInformation.FlightInformation.filter(
    fi => fi.AircraftType !== aircraftType,
  );

  yield put(setEntry(updatedEntry));
}
