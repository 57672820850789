import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { fetchEntry } from '../../timelineEntries/fetchEntry';
import { CrewTimelineEntryType } from '../../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { scheduleActions } from '../../../actions';
import { put, select } from 'redux-saga/effects';
import { selectDutyTimeTimelineEntriesSaga } from '../../../selectors-saga';
import { DutyTimelineEntry } from '../../../../../../../../common/types/timeline/DutyTimelineEntry';
import { NEW_ITEM_ID } from '../../../../components/Timeline/constants';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';

export function* handleDutyTimeEntryCreate(
  action: PusherReduxAction<PusherActionType.DUTY_TIME_ENTRY_CREATED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  const { payload } = action;

  try {
    const entry = (yield fetchEntry(
      CrewTimelineEntryType.DutyTimes,
      payload.ID,
      true,
    )) as DutyTimelineEntry;

    const existedEntries = yield* selectDutyTimeTimelineEntriesSaga();
    const updatedEntries = [...existedEntries.filter(e => e.ID !== NEW_ITEM_ID), entry];

    yield put(scheduleActions.setState({ dutyTimeTimelineEntries: updatedEntries }));
  } finally {
  }
}
