import {
  selectSelectedTimelinePeriodSaga,
  selectVisibleTimelinePeriodSaga,
} from '../../selectors-saga';
import { call, put } from 'redux-saga/effects';
import { TimelinePeriodExtension } from '../../../types/timeline';
import { extendAllEntries, PeriodExtension } from './extendAllEntries';
import { scheduleActions } from '../../actions';
import { TimelinePeriod } from '../../../types/TimelinePeriod';

export function* loadMore(extension: TimelinePeriodExtension) {
  if (!extension.isRequired) {
    return;
  }

  if (extension.left && extension.right) {
    yield call(loadMoreBoth);
  } else {
    yield call(loadMoreSide, extension.left);
  }
}

function* loadMoreBoth() {
  const { extensionLeftPeriod, extensionRightPeriod } = yield* getExtensionPeriods();

  const extensions: PeriodExtension[] = [
    { period: extensionLeftPeriod, isLeft: true },
    { period: extensionRightPeriod, isLeft: false },
  ];
  yield call(extendAllEntries, extensions);

  const updatedCurrentPeriod = new TimelinePeriod(
    extensionLeftPeriod.getStart(),
    extensionRightPeriod.getEnd(),
  );
  yield put(scheduleActions.setTimelinePeriod(updatedCurrentPeriod));
}

function* loadMoreSide(isLeft: boolean) {
  const { currentPeriod, extensionPeriod } = yield* getExtensionPeriod(isLeft);

  yield call(extendAllEntries, [{ period: extensionPeriod, isLeft }]);

  const updatedCurrentPeriod = new TimelinePeriod(
    isLeft ? extensionPeriod.getStart() : currentPeriod.getStart(),
    isLeft ? currentPeriod.getEnd() : extensionPeriod.getEnd(),
  );
  yield put(scheduleActions.setTimelinePeriod(updatedCurrentPeriod));
}

function* getExtensionPeriods() {
  const currentPeriod = yield* selectSelectedTimelinePeriodSaga();
  const visiblePeriod = yield* selectVisibleTimelinePeriodSaga();

  const extensionLeftPeriod = new TimelinePeriod(
    visiblePeriod.getStart(),
    currentPeriod.getStart(),
  );
  const extensionRightPeriod = new TimelinePeriod(currentPeriod.getEnd(), visiblePeriod.getEnd());

  return { currentPeriod, extensionLeftPeriod, extensionRightPeriod };
}

function* getExtensionPeriod(isLeft: boolean) {
  const currentPeriod = yield* selectSelectedTimelinePeriodSaga();
  const visiblePeriod = yield* selectVisibleTimelinePeriodSaga();

  const extensionPeriod = isLeft
    ? new TimelinePeriod(visiblePeriod.getStart(), currentPeriod.getStart())
    : new TimelinePeriod(currentPeriod.getEnd(), visiblePeriod.getEnd());

  return { currentPeriod, extensionPeriod };
}
