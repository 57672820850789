import { CustomCurrencyItemTemplate } from '../types/customCurrencyItem';

export const getCustomCurrencyItems = record => {
  return (record.CustomCurrencyItems || []).reduce((acc, curr) => {
    let value = curr.Value;
    if (curr.Type === 'bool') {
      value = value === 'true';
    }
    return {
      ...acc,
      [curr.Name]: value,
    };
  }, {});
};

export const serializeCustomCurrencyItems = (
  customCurrencyItemTemplates: CustomCurrencyItemTemplate[],
  fi: any,
) => {
  if (!fi) {
    return [];
  }
  return customCurrencyItemTemplates.map(template => {
    const val = fi[template.Name] || '';

    return {
      Name: template.Name,
      Value: String(val),
      Type: template.ItemConfig.Type,
    };
  });
};
