import { AllowAction, Permissions } from '../types/permissions';

export enum DefinedPermissions {
  ALLOW_COMPLIANCE_OVERRIDE = 'AllowComplianceOverride',
}

export const initPermissions = (): Permissions => {
  return {
    crewScheduleV2: {
      filters: {
        aircraftType: false,
        aircraftTail: false,
        crewMember: false,
        crewCertification: false,
        crewMemberBase: false,
        aircraftBase: false,
        displayOption: false,
        companyClient: false,
        companyTag: false,
        clientContract: false,
      },
      timelines: {
        aircraftTimeline: false,
        viewAircraftContracts: false,
        viewAircraftEntries: false,
        crewTimeline: false,
      },
      actions: {
        [AllowAction.allowPrioritizeIFRHours]: false,
        [AllowAction.addAircraftContract]: false,
        [AllowAction.displayDateRangeCalculator]: false,
        [AllowAction.displayReserveOptionsInCrewSchedule]: false,
        [AllowAction.displayFDPOptionsInCrewSchedule]: false,
        [AllowAction.allowPilotDutyManagement]: false,
        [AllowAction.allowPilotsToEditHistoricalDutyTimes]: false,
        [AllowAction.addPilotContract]: false,
        [AllowAction.addScheduleEntry]: false,
        [AllowAction.addDutyEntry]: false,
        [AllowAction.addDutyTime]: false,
        [AllowAction.updateScheduleEntry]: false,
        [AllowAction.updateDutyEntry]: false,
        [AllowAction.updateDutyTime]: false,
        [AllowAction.deleteScheduleEntry]: false,
        [AllowAction.deleteDutyEntry]: false,
        [AllowAction.deleteDutyTime]: false,
        [AllowAction.copyAndPasteEntry]: false,
        [AllowAction.overrideEntryCompliance]: false,
        [AllowAction.allowEditDutyScheduleVFRInput]: false,
        [AllowAction.allowEditDutyScheduleIFRInput]: false,
        [AllowAction.allowEditSubParts]: false,
        [AllowAction.displaySinglePilot]: false,
        [AllowAction.displayAllDayVFR]: false,
        [AllowAction.allowEditFlightDetails]: false,
        [AllowAction.createDutyTimeBeforeMostRecent]: false,
        [AllowAction.allowPilotDutyScheduleManagement]: false,
        [AllowAction.allowEditDutyTimeVFRInput]: false,
        [AllowAction.allowEditDutyTimeIFRInput]: false,
        [AllowAction.allowDutyScheduleEditPilot]: false,
        [AllowAction.tracksOnCall]: false,
      },
    },
  };
};
