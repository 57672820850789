import { call, put, select } from 'redux-saga/effects';
import {
  LoadFileListAction,
  setDetailsFolderList,
  setFileList,
  setFolderList,
  setState,
} from '../actions';
import { getCompanyLibrary } from '../selectors';
import api from '../../../../../../services/api';
import { TablePaginationConfig } from 'antd';
import { CompanyLibraryState } from '../reducers';
import { updateRelations } from './uploadFileWorker';

export function* loadFileListWorker(action: LoadFileListAction) {
  const { isInitialRender } = action.payload;
  try {
    const {
      pagination,
      fileList,
      folderList,
      filters,
      currentFolderID,
      currentDetailsFolderID,
      isDetailsModalOpen,
    } = (yield select(getCompanyLibrary)) as CompanyLibraryState;

    yield put(
      setState(isDetailsModalOpen ? { detailsFolderListLoader: true } : { isLoadingList: true }),
    );

    const queryParams = {
      limit: pagination.pageSize || 50,
      page: pagination.current || 1,
    };
    let updatedFilters = filters;
    if (
      filters.relatedEntities.length > 0 &&
      filters.relatedEntityTypes.length > 0 &&
      filters.relatedEntities[0].Type !== filters.relatedEntityTypes[0]
    ) {
      updatedFilters.relatedEntities = [];
    }
    const folderId = isDetailsModalOpen ? currentDetailsFolderID : currentFolderID;

    const folderQueryParams = { ...queryParams, name: updatedFilters.fileName };

    queryParams['file_name'] = updatedFilters.fileName;
    queryParams['related_entity_type_list'] = updatedFilters.relatedEntityTypes;
    queryParams['related_entity_id_list'] = updatedFilters.relatedEntities.map(e => e.ID);
    if (!isDetailsModalOpen) {
      folderQueryParams['folder_name'] = updatedFilters.fileName;
    }

    if (!isInitialRender && folderId) {
      queryParams['current_folder_id'] = folderId;
      folderQueryParams['current_folder_id'] = folderId;
    }

    let fileTotal = 0;
    if (!isDetailsModalOpen) {
      const {
        data: { Data: FileData, Total: FileTotal },
      } = yield call(api.get, `/v1/company-library/list`, {
        params: queryParams,
      });
      const loadMoreAvailable = FileTotal != 0 && fileList.length + FileData.length < FileTotal;
      if (queryParams.page === 1) {
        const updatedFileList = [...FileData];
        yield put(setFileList(updatedFileList, loadMoreAvailable));
      } else {
        const updatedFileList = [...fileList, ...FileData];
        yield put(setFileList(updatedFileList, loadMoreAvailable));
      }
      fileTotal = FileTotal;
    }

    const {
      data: { Data: FolderData, Total: FolderTotal },
    } = yield call(api.get, `/v1/company-library/folders/list`, {
      params: folderQueryParams,
    });

    const folderLoadMoreAvailable =
      FolderTotal != 0 && folderList.length + FolderData.length < FolderTotal;
    if (queryParams.page === 1) {
      const updatedFolderList = [...FolderData];
      if (currentDetailsFolderID || isDetailsModalOpen) {
        yield put(setDetailsFolderList(updatedFolderList));
      }
      if (!currentDetailsFolderID && !isDetailsModalOpen) {
        yield put(setFolderList(updatedFolderList, folderLoadMoreAvailable));
      }
    } else {
      const updatedFolderList = [...folderList, ...FolderData];
      if (currentDetailsFolderID || isDetailsModalOpen) {
        yield put(setDetailsFolderList(updatedFolderList));
      }
      if (!currentDetailsFolderID && !isDetailsModalOpen) {
        yield put(setFolderList(updatedFolderList, folderLoadMoreAvailable));
      }
    }

    const newPagination: TablePaginationConfig = {
      total: fileTotal + FolderTotal,
      current: pagination.current,
      pageSize: pagination.pageSize,
    };
    yield updateRelations();
    yield put(
      setState({ pagination: newPagination, isLoadingList: false, detailsFolderListLoader: false }),
    );
  } catch (err) {
    console.log('Failed to load company library file list', err);
  }
}
