import moment from 'moment-timezone';

import { getCustomCurrencyItems } from '../../../../../common/helpers/customCurrencyItems';

export const serializeEntry = (
  dutyInfoFields,
  flightInfoFields,
  isSplitDuty,
  isOnReserveDuty,
  IsPositioningAfterMaxFDP,
  IsUnforeseenExtension,
  companyID: string,
  IntegrateLoggingWithCrewScheduleV2: boolean,
  AllowLoggingCustomerEditFlightInfo: boolean,
  onCallDuty: boolean,
  isFlyingDuty: boolean,
  managementOverride: boolean,
) => {
  const {
    DutyRange,
    RestRange,
    FlightDutyRange,
    RAPRange,
    RDPRange,
    SubParts,
    ...otherDutyFields
  } = dutyInfoFields;
  flightInfoFields.SubParts = SubParts;

  let StartTime = null;
  let EndTime = null;

  let RestStartTime = null;
  let RestEndTime = null;

  let FlightDutyEndTime = null;

  let RAPStartTime = null;
  let RAPEndTime = null;

  if (DutyRange?.[0]) {
    StartTime = moment(DutyRange[0])
      .seconds(0)
      .format();
  }

  if (DutyRange?.[1]) {
    EndTime = moment(DutyRange[1])
      .seconds(0)
      .format();
  }

  if (RestRange?.[0]) {
    RestStartTime = moment(RestRange[0])
      .seconds(0)
      .format();
  }

  if (RestRange?.[1]) {
    RestEndTime = moment(RestRange[1])
      .seconds(0)
      .format();
  }

  if (FlightDutyRange?.[1]) {
    FlightDutyEndTime = moment(FlightDutyRange[1])
      .seconds(0)
      .format();
  } else if (EndTime) {
    FlightDutyEndTime = EndTime;
  }

  if (RAPRange?.[0] && isOnReserveDuty) {
    RAPStartTime = moment(RAPRange[0])
      .seconds(0)
      .format();
  }

  if (RAPRange?.[1] && isOnReserveDuty) {
    RAPEndTime = moment(RAPRange[1])
      .seconds(0)
      .format();
  }

  const { IsSinglePilot, AllDayVFR, ...otherFlightFields } = flightInfoFields;

  const payload = {
    CompanyID: companyID,
    ...otherDutyFields,
    StartTime,
    EndTime: !dutyInfoFields.ongoing ? EndTime : null,
    RestStart: isSplitDuty ? RestStartTime : null,
    RestEnd: isSplitDuty ? RestEndTime : null,
    FlightDutyPeriodEndTime: isFlyingDuty ? FlightDutyEndTime : null,
    NonFlyingDuty: !isFlyingDuty,
    OnCallDuty: onCallDuty,
    ManagementOverride: managementOverride,
    RAPStartTime,
    RAPEndTime,
    PositioningAfterMaxFDP: IsPositioningAfterMaxFDP,
    UnforeseenExtension: IsUnforeseenExtension,
    Subparts: SubParts,
  };

  if (!IntegrateLoggingWithCrewScheduleV2 || AllowLoggingCustomerEditFlightInfo) {
    payload.AircraftInformation = { ...otherFlightFields };

    if (payload.AircraftInformation?.FlightInformation) {
      payload.AircraftInformation.FlightInformation = payload.AircraftInformation?.FlightInformation.filter(
        el => el?.AircraftType,
      );

      payload.AircraftInformation?.FlightInformation.forEach(el => {
        el.FlightTime = el.TotalFlightHours;
        el.SinglePilot = IsSinglePilot === undefined ? el.IsSinglePilot : IsSinglePilot;
      });
    }
  }

  return payload;
};

export const deserializeDutyInfo = (data, companySettings) => {
  const startTime = data?.StartTime ? moment(data?.StartTime) : moment();
  const futureTimeAllowed = moment().add(
    companySettings?.AllowFutureDutyTimeCreationMins,
    'minutes',
  );
  const endTime = data?.EndTime ? moment(data?.EndTime) : futureTimeAllowed;
  let dutyPeriod;
  if (startTime && endTime) {
    dutyPeriod = [startTime, endTime];
  } else if (startTime && !endTime) {
    dutyPeriod = [startTime, null];
  } else if (!startTime && !endTime) {
    dutyPeriod = [];
  }

  const restStart = data?.RestStart ? moment(data?.RestStart) : null;
  const restEnd = data?.RestEnd ? moment(data?.RestEnd) : null;
  let restAirportID = data?.RestAirportID;

  if (!restStart || !restEnd) {
    restAirportID = null;
  }

  // setting dutyinfo firstTime
  let FlightDutyPeriodEndTime = data.FlightDutyPeriodEndTime;
  if (
    companySettings?.IntegrateLoggingWithCrewScheduleV2 &&
    !companySettings.AllowLoggingCustomerEditFlightInfo
  ) {
    if (data?.AircraftInformation?.FlightInformation) {
      console.log(
        'data?.AircraftInformation?.FlightInformation',
        data?.AircraftInformation?.FlightInformation,
      );
      FlightDutyPeriodEndTime = getLastFlightTimeIn(data);
    }
  }

  return {
    ...data,
    FlightDutyPeriodEndTime: FlightDutyPeriodEndTime,
    StartAirportID: data?.StartAirportID,
    EndAirportID: data?.EndAirportID,
    RestAirportID: restAirportID,
    DutyRange: dutyPeriod,
    RestRange: restStart && restEnd ? [restStart, restEnd] : [],
    FlightDutyRange: [
      data?.StartTime ? moment(data?.StartTime) : null, // same as start time of duty range
      data?.FlightDutyPeriodEndTime ? moment(data?.FlightDutyPeriodEndTime) : null,
    ],
    RAPRange: [
      data?.RAPStartTime
        ? moment(data?.RAPStartTime)
        : data?.StartTime
        ? moment(data?.StartTime)
        : null,
      data?.RAPEndTime ? moment(data?.RAPEndTime) : data?.EndTime ? moment(data?.EndTime) : null,
    ],
    RDPRange: [
      data?.RAPStartTime ? moment(data?.RAPStartTime) : null,
      data?.FlightDutyPeriodEndTime ? moment(data?.FlightDutyPeriodEndTime) : null,
    ],
    Subpart: Array.from(
      new Set(data?.AircraftInformation?.FlightInformation?.map(el => el.Subparts?.[0]) || []),
    ),
  };
};

export const getLastFlightTimeIn = entry => {
  let result = null;
  if (entry?.AircraftInformation?.FlightInformation?.length > 0) {
    const flightInfo = entry.AircraftInformation?.FlightInformation;
    flightInfo.sort(
      (a, b) => moment(a.FirstFlightStart).valueOf() - moment(b.FirstFlightStart).valueOf(),
    );
    const lastFlightTimeIn = flightInfo[flightInfo.length - 1]?.LastFlightEnd;
    result = lastFlightTimeIn ? moment(lastFlightTimeIn) : entry.FlightDutyPeriodEndTime;
  }
  return result;
};

export const deserializeAircraftInfo = currentEntry => {
  const payload = {
    IsSinglePilot: false,
    AllDayVFR: false,
    SubParts: [],
    FlightInformation: [],
  };

  // populate subpart
  if (currentEntry?.AircraftInformation?.FlightInformation?.length > 0) {
    const subparts = [];
    currentEntry.AircraftInformation.FlightInformation.forEach(flightInfo => {
      // eslint-disable-next-line no-unused-expressions
      flightInfo?.Subparts?.forEach(subpart => subparts.push(subpart));
    });
    payload.SubParts = [...new Set(subparts)];
  }

  if (currentEntry?.AircraftInformation?.FlightInformation?.length > 0) {
    // this will run for companies without logging integration
    const flightInfo = currentEntry?.AircraftInformation?.FlightInformation?.map(fi => {
      return {
        ...fi,
        AircraftType: fi?.AircraftType || '',
        DayLandings: fi?.DayLandings || 0,
        DayTakeoffs: fi?.DayTakeoffs || 0,
        NightLandings: fi?.NightLandings || 0,
        NightTakeoffs: fi?.NightTakeoffs || 0,
        TotalFlightHours: (fi?.IFRHours || 0) + (fi?.VFRHours || 0) || 0,
        IFRHours: fi?.IFRHours || 0,
        VFRHours: fi?.VFRHours || 0,
        NumberOfFlights: fi.NumberOfFlights || 1,
        IFRApproaches: fi.IFRApproaches || 0,
        IsSinglePilot: fi.SinglePilot,
        AllDayVFR: fi.AllDayVFR || false,
        DetailedFlightTimes: fi.DetailedFlightTimes,
        ...getCustomCurrencyItems(fi),
      };
    });

    payload.FlightInformation = combineByAircraftType(flightInfo);

    const isSinglePilot = (flightInfo || []).every(fi => fi.IsSinglePilot);
    const isAllDayVFR = (flightInfo || []).every(fi => fi.AllDayVFR);

    payload.IsSinglePilot = isSinglePilot;
    payload.AllDayVFR = isAllDayVFR;
  }

  return payload;
};

export const combineByAircraftType = flightInfo => {
  const result = [];
  for (let i = 0; i < flightInfo.length; i += 1) {
    const { AircraftType: aircraftType } = flightInfo[i];
    const aircraftTypeExists = result.filter(el => el.AircraftType === aircraftType).length > 0;

    if (!aircraftTypeExists) {
      const flightInfoOfAircraftType = flightInfo.filter(el => el.AircraftType === aircraftType);
      const currentAircraftInfo = { ...flightInfoOfAircraftType[0] };

      for (let j = 1; j < flightInfoOfAircraftType.length; j += 1) {
        currentAircraftInfo.DayTakeoffs += flightInfo[j]?.DayTakeoffs || 0;
        currentAircraftInfo.NightTakeoffs += flightInfo[j]?.NightTakeoffs || 0;
        currentAircraftInfo.DayLandings += flightInfo[j]?.DayLandings || 0;
        currentAircraftInfo.NightLandings += flightInfo[j]?.NightLandings || 0;
        currentAircraftInfo.TotalFlightHours +=
          (flightInfo[j]?.IFRHours || 0) + (flightInfo[j]?.VFRHours || 0) || 0;
        currentAircraftInfo.IFRHours += flightInfo[j]?.IFRHours || 0;
        currentAircraftInfo.VFRHours += flightInfo[j]?.VFRHours || 0;
        currentAircraftInfo.NumberOfFlights += flightInfo[j].NumberOfFlights || 1;
        currentAircraftInfo.IFRApproaches += flightInfo[j].IFRApproaches;
        currentAircraftInfo.IsSinglePilot =
          currentAircraftInfo.IsSinglePilot && flightInfo[j].IsSinglePilot;
        currentAircraftInfo.AllDayVFR =
          currentAircraftInfo.AllDayVFR &&
          (flightInfo[j].AllDayVFR || !flightInfo[j]?.IFRHours || flightInfo[j]?.IFRHours === 0);
      }

      result.push(currentAircraftInfo);
    }
  }
  return result;
};
