export enum MenuItemType {
  View = 'view',
  Copy = 'copy',
  Cut = 'cut',
  Edit = 'edit',
  Delete = 'delete',
  GenerateSchedule = 'generate-schedule',
  ShowAssociatedCrew = 'show-associated-crew',
  ShowAssociatedAircraft = 'show-associated-aircraft',
  UnassignContract = 'unassign-contract',
  UnassignAircraft = 'unassign-aircraft',
}
