import crewScheduleV2PageActions, {
  LoadAircraftContractsAction,
  setIsUpdatingAircraftContracts,
} from '../actions';
import api from '../../../../services/api';
import {call, put, select} from 'redux-saga/effects';

export function* loadAircraftContractsWorker(action: LoadAircraftContractsAction) {
  const { config } = action.payload;
  const { selectedAircraft } = yield select(({ pages: { crewSchedulingV2 } }) => crewSchedulingV2);
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);
  const companyID = yield select(({ user: { Company } }) => Company.ID);

  try {
    if (companySettings?.ScheduleByBase) {
      return;
    }

    yield put(setIsUpdatingAircraftContracts(true));
    if (selectedAircraft.length > 0 && companySettings && !companySettings?.ScheduleByBase) {
      const { startTime, endTime } = config;
      const {
        data: { Data },
      } = yield call(api.get, `/v1/companies/${companyID}/aircraftcontract`, {
        params: {
          end_time__gt: startTime.format(),
          start_time__lt: endTime.format(),
        },
      });

      yield put({
        type: crewScheduleV2PageActions.SET_STATE,
        payload: {
          aircraftContracts: Data,
        },
      });
    }
  } catch (e) {
    console.log(e);
  } finally {
    yield put(setIsUpdatingAircraftContracts(false));
  }
}
