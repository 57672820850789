import actions from './actions';

const initialState = {
  tableData: [],
  tablePagination: {
    current: 1,
    hideOnSinglePage: true,
    showSizeChanger: true,
    defaultPageSize: 20,
    pageSize: 20,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: total => `${total} Total items`,
  },
  tableSorter: {
    column: {},
    field: 'StartedAt',
    order: 'descend',
  },
  tableFilters: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
