import { CrewTimelineEntryType } from './timeline/CrewTimelineEntryType';
import { Issue } from '../../redux/pages/crew-scheduling-v3/scheduleEntries/types/DutyScheduleEntry';

export type EntryType =
  | CrewTimelineEntryType.CrewSchedule
  | CrewTimelineEntryType.DutySchedule
  | CrewTimelineEntryType.DutyTimes
  | CrewTimelineEntryType.AircraftSchedule;

export const validTypes: EntryType[] = [
  CrewTimelineEntryType.CrewSchedule,
  CrewTimelineEntryType.DutySchedule,
  CrewTimelineEntryType.DutyTimes,
  CrewTimelineEntryType.AircraftSchedule,
];

export type DutyValidationUpdateDto = {
  ID: string,
  IsChanged: boolean,
  Issues: Issue[]
}
