import { DutyFiCommon } from '../../types/entries';
import { put } from 'redux-saga/effects';
import { editFields, setEditValue } from '../../actions';
import { composeKey } from '../../../helpers';
import { EntryType } from '../../../../../../../common/types/entryTypes';
import { apiEntryTypeMap } from '../../../../../../../common/types/apiEntryTypes';

export function* afterTotalHoursChange(
  fi: DutyFiCommon,
  idx: number,
  allowPrioritizeIFRHours: boolean,
  entryType: EntryType,
) {
  // todo: merge me into one whole
  if (
    entryType === apiEntryTypeMap.DutyTimeEntries ||
    entryType === apiEntryTypeMap.DutyScheduleEntries
  ) {
    if (allowPrioritizeIFRHours && fi.FlightTime >= fi.VFRHours) {
      yield put(
        setEditValue(composeKey(String(idx), editFields.IFRHours), fi.FlightTime - fi.VFRHours),
      );
    } else if (!allowPrioritizeIFRHours && fi.FlightTime >= fi.IFRHours) {
      yield put(
        setEditValue(composeKey(String(idx), editFields.VFRHours), fi.FlightTime - fi.IFRHours),
      );
    }
  }
}
