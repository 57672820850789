import { ApiTypes } from 'models';

export enum Types {
  SET_PILOT_FILTER = 'pages/crew-compliance/SET_PILOT_FILTER',

  GET_PILOTS_REQUEST = 'pages/crew-compliance/GET_PILOTS_REQUEST',
  GET_PILOTS_SUCCESS = 'pages/crew-compliance/GET_PILOTS_SUCCESS',
  GET_PILOTS_ERROR = 'pages/crew-compliance/GET_PILOTS_ERROR',
}

const setPilotFilter = (payload: ApiTypes.Pilot[]) => ({
  type: Types.SET_PILOT_FILTER,
  payload,
});

const getPilotsRequest = (payload: ApiTypes.CrewCompliance.PilotFilter) => ({
  type: Types.GET_PILOTS_REQUEST,
  payload,
});

const getPilotsSuccess = (payload?: ApiTypes.Pilot[]) => ({
  type: Types.GET_PILOTS_REQUEST,
  payload,
});

export default {
  setPilotFilter,
  getPilotsRequest,
  getPilotsSuccess,
};
