import { CustomFormsState } from './reducers';
import { CustomFormTemplate, Question } from '../types/template';
import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { PusherCustomFormSubmissionUpdate } from '../types/pusher';

const actions = {
  SET_STATE: 'pages/custom-forms/SET_STATE',
  OPEN_TEMPLATE_MODAL: 'pages/custom-forms/OPEN_TEMPLATE_MODAL',
  CLOSE_TEMPLATE_MODAL: 'pages/custom-forms/CLOSE_TEMPLATE_MODAL',
  OPEN_SUBMISSION_MODAL: 'pages/custom-forms/OPEN_SUBMISSION_MODAL',
  CLOSE_SUBMISSION_MODAL: 'pages/custom-forms/CLOSE_SUBMISSION_MODAL',
  SET_EDITING_TEMPLATE: 'pages/custom-forms/SET_EDITING_TEMPLATE',
  SAVE_TEMPLATE: 'pages/custom-forms/SAVE_TEMPLATE',
  LOAD_TEMPLATES: 'pages/custom-forms/LOAD_TEMPLATES',
  ON_EDIT_TEMPLATE: 'pages/custom-forms/ON_EDIT_TEMPLATE',
  ON_DELETE_TEMPLATE: 'pages/custom-forms/ON_DELETE_TEMPLATE',
  LOAD_SUBMISSIONS: 'pages/custom-forms/LOAD_SUBMISSIONS',
  OPEN_SUBMISSION_PREVIEW: 'pages/custom-forms/OPEN_SUBMISSION_PREVIEW',
  HANDLE_TABLE_CHANGE: 'pages/custom-forms/HANDLE_TABLE_CHANGE',
  PUSHER_SUBMISSION_CREATED_OR_UPDATED: 'pages/custom-forms/PUSHER_SUBMISSION_CREATED_OR_UPDATED',
};

export default actions;

export const setState = (payload: Partial<CustomFormsState>) => {
  return {
    type: actions.SET_STATE,
    payload,
  };
};

export type OnEditTemplateAction = {
  type: typeof actions.ON_EDIT_TEMPLATE;
  payload: {
    templateID: string;
  };
};

export type OnTableChange = {
  type: typeof actions.HANDLE_TABLE_CHANGE;
  payload: {
    pagination: TablePaginationConfig;
    sorter: SorterResult<any>;
  };
};

export type OnDeleteTemplateAction = {
  type: typeof actions.ON_DELETE_TEMPLATE;
  payload: {
    templateID: string;
  };
};

export type OpenSubmissionPreviewAction = {
  type: typeof actions.OPEN_SUBMISSION_PREVIEW;
  payload: {
    submissionID: string;
  };
};

export type PusherSubmissionCreatedOrUpdatedAction = {
  type: typeof actions.PUSHER_SUBMISSION_CREATED_OR_UPDATED;
  payload: {
    update: PusherCustomFormSubmissionUpdate;
  };
};

export const openSubmissionPreview = (submissionID: string): OpenSubmissionPreviewAction => {
  return {
    type: actions.OPEN_SUBMISSION_PREVIEW,
    payload: {
      submissionID: submissionID,
    },
  };
};

export const pusherSubmissionUpdate = (
  update: PusherCustomFormSubmissionUpdate,
): PusherSubmissionCreatedOrUpdatedAction => {
  return {
    type: actions.PUSHER_SUBMISSION_CREATED_OR_UPDATED,
    payload: {
      update: update,
    },
  };
};

export const onEditTemplate = (templateID: string): OnEditTemplateAction => {
  return {
    type: actions.ON_EDIT_TEMPLATE,
    payload: {
      templateID: templateID,
    },
  };
};

export const onDeleteTemplate = (templateID: string): OnDeleteTemplateAction => {
  return {
    type: actions.ON_DELETE_TEMPLATE,
    payload: {
      templateID: templateID,
    },
  };
};

export const openTemplateModal = () => {
  return {
    type: actions.OPEN_TEMPLATE_MODAL,
    payload: {
      isTemplateModalOpen: true,
    },
  };
};

export const closeTemplateModal = () => {
  return {
    type: actions.CLOSE_TEMPLATE_MODAL,
    payload: {
      isTemplateModalOpen: false,
    },
  };
};

export const openSubmissionModal = () => {
  return {
    type: actions.OPEN_SUBMISSION_MODAL,
    payload: {
      isSubmissionModalOpen: true,
    },
  };
};

export const closeSubmissionModal = () => {
  return {
    type: actions.CLOSE_SUBMISSION_MODAL,
    payload: {
      isSubmissionModalOpen: false,
    },
  };
};

export const setEditingTemplate = (template: CustomFormTemplate) => {
  return {
    type: actions.SET_EDITING_TEMPLATE,
    payload: {
      editingTemplate: template,
    },
  };
};

export const saveTemplate = () => {
  return {
    type: actions.SAVE_TEMPLATE,
  };
};

export const loadTemplates = () => {
  return {
    type: actions.LOAD_TEMPLATES,
  };
};

export const loadSubmissions = () => {
  return {
    type: actions.LOAD_SUBMISSIONS,
  };
};

export const onSubmissionsTableChange = (
  pagination: TablePaginationConfig,
  sorter: SorterResult<any>,
): OnTableChange => {
  return {
    type: actions.HANDLE_TABLE_CHANGE,
    payload: {
      pagination,
      sorter,
    },
  };
};
