import { all, call, debounce, put, select, takeEvery } from 'redux-saga/effects';
import {
  actions,
  openCrewScheduleModalAction,
  OpenCrewScheduleModalAction,
  setAcclimatizedTimezoneTagAction,
  setIsValidationRunningAction,
  setStateAction,
  setValidationSuggestionsAction,
} from './actions';
import {
  getCompanyID,
  getCrewSchedulingV2Page,
  getScheduleEntryModal,
} from '../../../../selectors';
import api from '../../../../../services/api';
import { serializeEntry } from './utils';
import { initialState } from './reducers';
import { VALIDATION_DEB_MS } from '../../constants';
import { setValidationStateAction } from '../DutyTimeEntryModal/actions';
import MODAL_MODE from '../../../../../pages/data/common/constants';

function* runValidationSaga({ payload: { dataFields }, type: string }) {
  const companyID = yield select(getCompanyID);
  const { customSectionValues } = yield select(getScheduleEntryModal);
  const { currentScheduleEntry } = yield select(getCrewSchedulingV2Page);
  const data = currentScheduleEntry;
  const [startDate, endDate] = dataFields.SchedulePeriod?.filter(m => m) ?? [null, null];

  const isRangeValid = startDate && endDate && startDate.isValid() && endDate.isValid();

  if (dataFields.UserID && isRangeValid && !data.IsPrivateEntry) {
    try {
      yield put(setIsValidationRunningAction(true));
      const response = yield call(
        api.post,
        `v1/companies/${companyID}/crewschedule/validate`,
        serializeEntry(data.ID, companyID, dataFields, data.Version, customSectionValues),
      );

      const {
        data: {
          Data: {
            ComplianceSummary: { Issues, ValidationState },
          },
        },
      } = response;

      if (ValidationState?.length > 0) {
        const validationState = JSON.parse(ValidationState);

        yield put(setValidationStateAction(validationState));

        yield put(setValidationSuggestionsAction(Issues || []));

        yield put(setAcclimatizedTimezoneTagAction(validationState.AcclimatizedTimezone));
      }
    } catch (err) {
      console.log('err', err);
      yield put(
        setValidationSuggestionsAction([
          {
            Severity: 'error',
            Message: 'Schedule entry invalid. Please correct all errors and try again.',
          },
        ]),
      );
    } finally {
      yield put(setIsValidationRunningAction(false));
    }
  }
}

function* resetStateWorker() {
  yield put(setStateAction(initialState));
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.RUN_VALIDATION, runValidationSaga),
    debounce(VALIDATION_DEB_MS, actions.RUN_VALIDATION_DEB, runValidationSaga),
    takeEvery(actions.RESET_STATE, resetStateWorker),
  ]);
}
