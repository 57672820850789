import { getAircraftSelectorType, getFeatureFlags } from './selectors';
import { select } from 'redux-saga/effects';

export function* selectAircraftSelectorTypeSaga(): Generator<
  any,
  ReturnType<typeof getAircraftSelectorType>,
  ReturnType<typeof getAircraftSelectorType>
> {
  return yield select(getAircraftSelectorType);
}

export function* selectFeatureFlagsSaga(): Generator<
  any,
  ReturnType<typeof getFeatureFlags>,
  ReturnType<typeof getFeatureFlags>
> {
  return yield select(getFeatureFlags);
}
