import { UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal, Upload, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/selectors';
import api from 'services/api';

const UserSignatureUpload = ({ setSignatureFeedBackStatus, setProfileSignatureUploadedURL }) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const user = useSelector(getUser);
  const [signatureUrl, setSignatureUrl] = useState(
    user?.Profile.SignatureURL ? user?.Profile.SignatureURL : null,
  );

  const handleUpload = async () => {
    const formData = new FormData();

    formData.append('signature', fileList[0]);

    setUploading(true);
    api
      .post(`/v1/users/${user?.id}/signature`, formData)
      .then(res => {
        const {
          data: { Data },
        } = res;
        setSignatureUrl(Data);
        setFileList([]);
        message.success('upload successfully.');
        setSignatureFeedBackStatus(true);
        setProfileSignatureUploadedURL(Data);
      })
      .catch(() => {
        message.error('upload failed.');
        setFileList([]);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  useEffect(() => {
    if (fileList.length > 0) {
      handleUpload();
    }
  }, [fileList]);

  return (
    <div style={{ marginBottom: '50px' }}>
      {signatureUrl && (
        <img src={signatureUrl} alt="signature" style={{ width: '300px', marginBottom: '20px' }} />
      )}
      {/* <Title level={5}>Upload Signature </Title> */}
      <div>
        <Upload
          action=""
          listType="picture"
          fileList={fileList}
          className="upload-list-inline"
          multiple={false}
          accept=".png,.jpg,.jpeg"
          beforeUpload={file => {
            setFileList([file]);
            return false;
          }}
        >
          <Button size="middle" loading={uploading} icon={<UploadOutlined />}>
            Upload Signature
          </Button>
        </Upload>
      </div>
    </div>
  );
};

export default UserSignatureUpload;
