import { ReFetchAircraftScheduleEntries, scheduleActions } from '../../actions';
import {
  selectAircraftScheduleTimelineEntriesSaga,
  selectVisibleTimelinePeriodSaga,
} from '../../selectors-saga';
import { call, put } from 'redux-saga/effects';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { selectCompanyIDSaga } from '../../../../../../../common/saga/selectors';
import api from '../../../../../../../services/api';
import { AircraftTimelineEntry } from '../../../../../../../common/types/timeline/AircraftTimelineEntry';
import _ from 'lodash';
import { NEW_ITEM_ID } from '../../../components/Timeline/constants';

export function* reFetchAircraftScheduleEntries(action: ReFetchAircraftScheduleEntries) {
  // TODO: re-fetch only by aircraftIDs
  const { aircraftIDs } = action.payload;

  const visiblePeriod = yield* selectVisibleTimelinePeriodSaga();

  const companyID = yield* selectCompanyIDSaga();

  const baseApiParams = {
    end_time__gt: visiblePeriod.getStart().format(),
    start_time__lt: visiblePeriod.getEnd().format(),
    limit: -1,
    user_ids: [],
  };

  try {
    const {
      data: { Data },
    } = yield call(
      api.get,
      `/v1/companies/${companyID}/timeline/${AircraftTimelineEntryType.AircraftSchedule}`,
      {
        params: baseApiParams,
      },
    );

    const entriesArr = Data as AircraftTimelineEntry[];
    const existedEntries = yield* selectAircraftScheduleTimelineEntriesSaga();
    const newEntries = _.unionBy(
      existedEntries.filter(e => {
        if (e.VirtualID === NEW_ITEM_ID) {
          return false;
        }
        if (!aircraftIDs?.length) {
          return false;
        }
        return !aircraftIDs?.includes(e.AssignedAircraftIDs?.[0]);
      }),
      entriesArr,
      'VirtualID',
    );

    yield put(
      scheduleActions.setState({
        aircraftScheduleTimelineEntries: newEntries,
      }),
    );
  } catch (err) {
    console.log(`Error fetching ${AircraftTimelineEntryType.AircraftSchedule} entries: `, err);
  }
}
