import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { setCompanyScheduleTypes, setDefaultScheduleType } from '../actions';

export function* loadCompanyScheduleTypesWorker(companyID: string) {
  if (!companyID) {
    throw new Error('Failed to load company schedule types. There are missed params.');
  }
  const {
    data: { Data },
  } = yield call(api.get, `/v1/companies/${companyID}/company-schedule-type`);
  yield put(setCompanyScheduleTypes(Data));
  if (Data.length > 0) {
    yield put(setDefaultScheduleType(Data[0]));
  }
}
