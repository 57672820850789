import MODAL_MODE from '../../../pages/data/common/constants';
import { ModalMode } from '../crew-scheduling-v3/modals/interfaces';
import actions from './actions';

export interface TrainingItemModalInitialState {
  modalMode: ModalMode;
}

const initialState: TrainingItemModalInitialState = {
  modalMode: { open: false, mode: MODAL_MODE.create },
};

export default function reducers(state: TrainingItemModalInitialState = initialState, action) {
  switch (action.type) {
    case actions.OPEN_MODAL:
      return { ...state, modalMode: { open: true, mode: action.payload.mode } };
    case actions.CLOSE_MODAL:
      return { ...state, modalMode: { open: false, mode: MODAL_MODE.create } };
    default:
      return state;
  }
}
