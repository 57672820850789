import { call, put, select } from 'redux-saga/effects';
import { setFlightWatchList, setState } from '../actions';
import { getFlightWatch } from '../selectors';
import api from '../../../../../../services/api';
import { TablePaginationConfig } from 'antd';

export function* loadFlightWatchListWorker() {
  try {
    yield put(setState({ isLoadingList: true }));
    const { pagination, sorter } = yield select(getFlightWatch);
    const queryParams = {
      limit: pagination.pageSize || 50,
      page: pagination.current || 1,
      order: 'default',
    };
    if (sorter.field && sorter.order) {
      queryParams['order'] = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }
    const {
      data: { Data, Total, Page },
    } = yield call(api.get, `/v1/flight-watch/list`, {
      params: queryParams,
    });
    yield put(setFlightWatchList(Data));
    const newPagination: TablePaginationConfig = {
      total: Total,
      current: Page,
      pageSize: pagination.pageSize,
    };
    yield put(setState({ pagination: newPagination, isLoadingList: false }));
  } catch (err) {
    console.log('Failed to load flight watch list', err);
  }
}
