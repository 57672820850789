import CampConfig from 'types/CampConfig';
import { integrationsApi } from './MicroserviceApi';

interface GetCampConfigOptions {
  companyId: string;
}

export const getCampConfig = async (
  { companyId }: GetCampConfigOptions,
): Promise<CampConfig> => {
  const url = `/camp/configs/${companyId}`;
  const response = await integrationsApi.request('GET', url);
  return response.data as CampConfig;
};
