import { AnyAction } from 'redux';
import { actionTypes } from './actions';
import { ModalMode } from '../../../../../../../../redux/pages/crew-scheduling-v3/modals/interfaces';
import MODAL_MODE from '../../../../../../common/constants';
import { ClientContractPeriod } from '../../../../../../../../common/types/ClientContractPeriod';

export interface ClientContractPeriodState {
  clientContractPeriodModalMode: ModalMode;
  editedClientContractPeriod: ClientContractPeriod | null;
  editedClientContractID: string | null;
}

const initialState: ClientContractPeriodState = {
  clientContractPeriodModalMode: { mode: MODAL_MODE.create, open: false },
  editedClientContractPeriod: null,
  editedClientContractID: null,
};

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
