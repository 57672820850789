import { CrewTimelineItem } from '../../../components/Timeline/CrewTimeline/generator';
import api from '../../../../../../../services/api';
import { CrewTimelineEntryType } from '../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { call, put } from 'redux-saga/effects';
import { selectCompanyIDSaga } from '../../../../../../../common/saga/selectors';
import {
  selectAircraftScheduleTimelineEntriesSaga,
  selectCrewScheduleTimelineEntriesSaga,
  selectDutyScheduleTimelineEntriesSaga,
  selectDutyTimeTimelineEntriesSaga,
  selectPilotContractTimelineEntriesSaga,
} from '../../selectors-saga';
import { scheduleActions } from '../../actions';
import { TimelineType } from '../../../types/timeline';
import { AircraftTimelineItem } from '../../../components/Timeline/AircraftTimeline/generator';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { fetchAircraftScheduleEntry } from '../timelineEntries/fetchEntry';
import { AircraftSchedule } from '../../../../../../../common/types/aircraftSchedule';
import { showErrorModal } from '../../../legacy/utils/errHandling';
import { ContractTimelineItem } from '../../../components/Timeline/ContractTimeline/generator';
import { ContractTimelineEntryType } from '../../../../../../../common/types/timeline/ContractTimelineEntryType';
import { deleteRelatedAircraftContracts } from './bulkDelete/deleteRelatedAircraftContracts';

export function* handleDeleteEntry(
  item: CrewTimelineItem | AircraftTimelineItem | ContractTimelineItem,
  timelineType: TimelineType,
) {
  const companyID = yield* selectCompanyIDSaga();

  yield put(scheduleActions.setState({ isShowTimelineLoaded: true }));

  try {
    if (!item.isNewEntry) {
      if (item.entryType === AircraftTimelineEntryType.AircraftSchedule) {
        let fullASEntry = (yield fetchAircraftScheduleEntry(
          item.entryType,
          item,
          false,
        )) as AircraftSchedule;
        const payload = {
          CrewScheduleEntryIds: fullASEntry.RelatedCrewScheduleEntries.map(e => e.ID),
        };
        yield call(api.post, getDeleteUrl(item, companyID, item.id), payload);
      } else if (item.entryType === ContractTimelineEntryType.AircraftContract) {
        yield call(
          deleteRelatedAircraftContracts,
          item.aircraftContractIDs?.length > 0
            ? item.aircraftContractIDs
            : [item.aircraftContractID],
        );
      } else {
        yield call(api.delete, getDeleteUrl(item, companyID, item.id));
      }
    }
    yield* removeTimelineEntryFromState(item);

    if (item.entryType === CrewTimelineEntryType.CrewSchedule) {
      yield put(
        scheduleActions.reFetchAircraftScheduleEntries(item.crewEntry?.AssignedAircraftIDs),
      );
    }
  } catch (err) {
    showErrorModal('Failed to delete entry', err);
  } finally {
    yield put(scheduleActions.setState({ isShowTimelineLoaded: false }));
  }
}

function* removeTimelineEntryFromState(
  item: CrewTimelineItem | AircraftTimelineItem | ContractTimelineItem,
) {
  if (item.entryType === CrewTimelineEntryType.DutyTimes) {
    const dutyTimelineEntries = yield* selectDutyTimeTimelineEntriesSaga();
    const updatedDutyTimelineEntries = dutyTimelineEntries.filter(entry => entry.ID !== item.id);
    yield put(scheduleActions.setState({ dutyTimeTimelineEntries: updatedDutyTimelineEntries }));
  } else if (item.entryType === CrewTimelineEntryType.DutySchedule) {
    const dutyScheduleTimelineEntries = yield* selectDutyScheduleTimelineEntriesSaga();
    const updatedDutyScheduleTimelineEntries = dutyScheduleTimelineEntries.filter(
      entry => entry.ID !== item.id,
    );
    yield put(
      scheduleActions.setState({ dutyScheduleTimelineEntries: updatedDutyScheduleTimelineEntries }),
    );
  } else if (item.entryType === CrewTimelineEntryType.CrewSchedule) {
    const crewScheduleTimelineEntries = yield* selectCrewScheduleTimelineEntriesSaga();
    const updatedCrewScheduleTimelineEntries = crewScheduleTimelineEntries.filter(
      entry => entry.ID !== item.id,
    );
    yield put(
      scheduleActions.setState({ crewScheduleTimelineEntries: updatedCrewScheduleTimelineEntries }),
    );
  } else if (item.entryType === CrewTimelineEntryType.PilotContract) {
    const pilotContractTimelineEntries = yield* selectPilotContractTimelineEntriesSaga();
    const updatedPilotContractTimelineEntries = pilotContractTimelineEntries.filter(
      entry => entry.ID !== item.id,
    );
    yield put(
      scheduleActions.setState({
        pilotContractTimelineEntries: updatedPilotContractTimelineEntries,
      }),
    );
  } else if (item.entryType === AircraftTimelineEntryType.AircraftContract) {
  } else if (item.entryType === AircraftTimelineEntryType.AircraftSchedule) {
    const existedEntries = yield* selectAircraftScheduleTimelineEntriesSaga();
    const updatedEntries = existedEntries.filter(entry => entry.VirtualID !== item.id);
    yield put(scheduleActions.setState({ aircraftScheduleTimelineEntries: updatedEntries }));
  }
}

export const getDeleteUrl = (
  item: CrewTimelineItem | AircraftTimelineItem | ContractTimelineItem,
  companyID: string,
  entryID: string,
): string => {
  switch (item.entryType) {
    case CrewTimelineEntryType.DutyTimes:
      return `/v1/users/${item.dutyEntry.UserID}/duty/delete/${entryID}`;
    case CrewTimelineEntryType.DutySchedule:
      return `/v1/users/${item.dutyEntry.UserID}/duty-schedule/${entryID}/v2`;
    case CrewTimelineEntryType.CrewSchedule:
      return `/v1/companies/${companyID}/crewschedule/delete/${entryID}`;
    case CrewTimelineEntryType.PilotContract:
      return `/v1/companies/${companyID}/pilotcontract/delete/${entryID}`;
    case AircraftTimelineEntryType.AircraftContract:
      return `/v1/companies/${companyID}/aircraftcontract/delete/${entryID}`;
    case AircraftTimelineEntryType.AircraftSchedule:
      return `/v1/companies/${companyID}/crewschedule/bulk-delete`;
  }

  return 'not-implemented';
};
