import { CombinedEntry } from '../../../../../../../common/types/CombinedEntry';
import { editFields, resetValidationMsg, setValidationMsg } from '../../actions';
import { put } from 'redux-saga/effects';
import { Actions, AllowAction } from '../../../../../../../common/types/permissions';
import { composeKey } from '../../../helpers';
import moment from 'moment-timezone';

type FlightTime = {
  TimeOff: moment.Moment;
  TimeOn: moment.Moment;
  tabIndex: number;
  detailIndex: number;
};

export function* checkFlightRequiredFields(
  idx: number,
  entry: CombinedEntry,
  editValues: any,
  allowedActions: Actions,
) {
  const key = composeKey(String(idx), editFields.DetailedFlightTimes);

  const mapDetailedFlightTimes = (editValues, idx: number) =>
    editValues[key]?.map((flightTime: FlightTime[], detailIdx: number) => ({
      tabIndex: idx,
      detailIndex: detailIdx,
      ...flightTime,
    })) || [];

  const mappedFlightTimes = mapDetailedFlightTimes(editValues, idx);
  const validationMsg: { [key: string]: string } = {};

  const isNonFlyingDuty =
    editValues[editFields.NonFlyingDuty] !== undefined
      ? editValues[editFields.NonFlyingDuty]
      : entry.NonFlyingDuty;

  const generateValidationKey = (tabIndex: number, detailIndex: number, field: string) =>
    `${tabIndex}_${detailIndex}_${field}`;

  const validateFlightTime = (
    flightTime: {
      TimeOff: moment.Moment;
      TimeOn: moment.Moment;
      tabIndex: number;
      detailIndex: number;
    },
    validationMsg: { [key: string]: string },
  ) => {
    const { TimeOff, TimeOn, tabIndex, detailIndex } = flightTime;
    const allowEdit = allowedActions[AllowAction.allowEditFlightDetails];

    if (!isNonFlyingDuty) {
      if (!TimeOff && allowEdit) {
        validationMsg[generateValidationKey(tabIndex, detailIndex, 'TimeOff')] =
          'Time Off must be defined';
      }
      if (!TimeOn && allowEdit) {
        validationMsg[generateValidationKey(tabIndex, detailIndex, 'TimeOn')] =
          'Time On must be defined';
      }
      if (TimeOn && TimeOff && allowEdit && TimeOn.isSame(TimeOff)) {
        validationMsg[generateValidationKey(tabIndex, detailIndex, 'TimeOn_TimeOff')] =
          'Time On cannot be the same as Time Off';
      }
    }
  };

  mappedFlightTimes.forEach((flightTime: FlightTime) =>
    validateFlightTime(flightTime, validationMsg),
  );

  const keysToCheck = mappedFlightTimes.flatMap(({ tabIndex, detailIndex }) => [
    generateValidationKey(tabIndex, detailIndex, 'TimeOff'),
    generateValidationKey(tabIndex, detailIndex, 'TimeOn'),
    generateValidationKey(tabIndex, detailIndex, 'TimeOn_TimeOff'),
  ]);

  for (const validationKey of keysToCheck) {
    if (!validationMsg[validationKey]) {
      yield put(resetValidationMsg(validationKey));
    } else {
      yield put(setValidationMsg(validationKey, validationMsg[validationKey]));
    }
  }

  if (isNonFlyingDuty) {
    yield put(resetValidationMsg(key));
  }
}
