import { call, put } from 'redux-saga/effects';
import { AddFlightWatch, setState } from '../actions';
import api from '../../../../../../services/api';
import { loadFlightWatchListWorker } from './loadFlightWatchListWorker';

export function* addFlightWatchWorker(action: AddFlightWatch) {
  const { payload } = action;
  try {
    yield call(api.post, `/v1/flight-watch`, payload);
    yield call(loadFlightWatchListWorker);
    yield put(
      setState({
        isAddUpdateModalOpen: false,
      }),
    );
  } catch (err) {
    console.log('Failed to add flight watch', err);
  }
}
