import {
  CompanyScheduleType,
  CrewScheduleRequiredFields,
} from '../../../../../../../common/types/CombinedEntry';
import { editFields, resetValidationMsg, setValidationMsg } from '../../actions';
import { put, select } from 'redux-saga/effects';
import { getCommon, getCSMobile } from '../../selectors';

export function* checkCrewScheduleRequiredFields() {
  const { editValues, entry } = yield select(getCSMobile);
  const { companyScheduleTypes } = yield select(getCommon);

  const scheduleEntryTypeID =
    editValues[editFields.CompanyScheduleTypeID] !== undefined
      ? editValues[editFields.CompanyScheduleTypeID]
      : entry.CompanyScheduleType?.ID;

  if (!scheduleEntryTypeID) {
    return;
  }

  const scheduleType: CompanyScheduleType = companyScheduleTypes.find(
    type => type.ID === scheduleEntryTypeID,
  );
  if (!scheduleType) {
    return;
  }

  const startAirportId =
    editValues[editFields.StartAirportID] !== undefined
      ? editValues[editFields.StartAirportID]
      : entry.StartAirportID;

  const endAirportId =
    editValues[editFields.EndAirportID] !== undefined
      ? editValues[editFields.EndAirportID]
      : entry.EndAirportID;

  const tag = editValues[editFields.Tag] !== undefined ? editValues[editFields.Tag] : entry.Tag;

  const assignedAircraft =
    editValues[editFields.AssignedAircraft] !== undefined
      ? editValues[editFields.AssignedAircraft]
      : entry.AssignedAircraft;

  const assignedClients =
    editValues[editFields.AssignedClient] !== undefined
      ? editValues[editFields.AssignedClient]
      : entry.AssignedClients;

  const subparts =
    editValues[editFields.SubParts] !== undefined
      ? editValues[editFields.SubParts]
      : entry.Subparts ||
        entry.FlightInformation?.SubParts ||
        (entry.AircraftInformation?.FlightInformation?.length > 0 &&
          entry.AircraftInformation?.FlightInformation[0]?.Subparts) ||
        [];

  const validationMsgs: { [key: string]: string } = {};
  for (const field of Object.values(CrewScheduleRequiredFields)) {
    const isRequired = scheduleType.RequiredFields?.[field];
    switch (field) {
      case CrewScheduleRequiredFields.StartAirport:
        if (isRequired && !startAirportId) {
          validationMsgs[editFields.StartAirportID] = 'Select the airport';
        }
        break;
      case CrewScheduleRequiredFields.EndAirport:
        if (isRequired && !endAirportId) {
          validationMsgs[editFields.EndAirportID] = 'Select the airport';
        }
        break;
      case CrewScheduleRequiredFields.Tag:
        if (isRequired && !tag) {
          validationMsgs[editFields.Tag] = 'Select the tag';
        }
        break;
      case CrewScheduleRequiredFields.AssignedAircraft:
        if (isRequired && !assignedAircraft) {
          validationMsgs[editFields.AssignedAircraft] = 'Select the aircraft';
        }
        break;
      case CrewScheduleRequiredFields.AssignedClients:
        if (isRequired && !assignedClients) {
          validationMsgs[editFields.AssignedClient] = 'Select the client';
        }
        break;
      case CrewScheduleRequiredFields.Subpart:
        if (isRequired && !subparts?.length) {
          validationMsgs[editFields.SubParts] = 'Select the regulation(s)';
        }
        break;
      default:
        break;
    }
  }

  const allKeys = [
    editFields.StartAirportID,
    editFields.EndAirportID,
    editFields.Tag,
    editFields.AssignedAircraft,
    editFields.AssignedClient,
    editFields.SubParts,
  ];

  for (const key of allKeys) {
    if (!validationMsgs[key]) {
      yield put(resetValidationMsg(key));
    } else {
      yield put(setValidationMsg(key, validationMsgs[key]));
    }
  }
}
