import { DutyFields } from '../../../../../pages/data/organization/crew-scheduling-v3/legacy/types/duty-fields';
import { ACFlightInformationRemote } from '../../../../../pages/data/organization/crew-scheduling-v3/legacy/types/flight-fields';
import moment from 'moment-timezone';
import { CustomCurrencyItemTemplate } from '../../../../../common/types/customCurrencyItem';
import { serializeCustomCurrencyItems } from '../../../../../common/helpers/customCurrencyItems';

export const serializeEntry = (
  entryID: string,
  companyID: string,
  dutyFields: Partial<DutyFields>,
  flightFields: Partial<ACFlightInformationRemote>,
  isFlyingDuty: boolean,
  onCallDuty: boolean,
  onReserveDuty: boolean,
  positioningAfterMaxFDP: boolean,
  managementOverride: boolean,
  version: number,
  customCurrencyItemTemplates: CustomCurrencyItemTemplate[] = [],
) => {
  const {
    DutyRange,
    RestRange,
    FlightDutyRange,
    RAPRange,
    RDPRange,
    SubParts,
    ComplianceOverrideReason,
    ...otherDutyFields
  } = dutyFields;

  let StartTime = null;
  let EndTime = null;

  let RestStartTime = null;
  let RestEndTime = null;

  let FlightDutyEndTime = null;

  let RAPStartTime = null;
  let RAPEndTime = null;

  if (DutyRange?.[0]) {
    StartTime = moment(DutyRange[0])
      .seconds(0)
      .format();
  }

  if (DutyRange?.[1]) {
    EndTime = moment(DutyRange[1])
      .seconds(0)
      .format();
  }

  if (RestRange?.[0]) {
    RestStartTime = moment(RestRange[0])
      .seconds(0)
      .format();
  }

  if (RestRange?.[1]) {
    RestEndTime = moment(RestRange[1])
      .seconds(0)
      .format();
  }

  if (FlightDutyRange?.[1]) {
    FlightDutyEndTime = moment(FlightDutyRange[1])
      .seconds(0)
      .format();
  }

  if (RAPRange?.[0] && onReserveDuty) {
    RAPStartTime = moment(RAPRange[0])
      .seconds(0)
      .format();
  }

  if (RAPRange?.[1] && onReserveDuty) {
    RAPEndTime = moment(RAPRange[1])
      .seconds(0)
      .format();
  }

  const { ...otherFlightFields } = flightFields;

  const aircraftInformation = {
    AllDayVFR: otherFlightFields.AllDayVFR,
    aircraftInformation: otherFlightFields.IsSinglePilot,
    FlightInformation: (otherFlightFields.FlightInformation || []).map(fi => ({
      ...fi,
      SinglePilot: otherFlightFields.IsSinglePilot,
      AllDayVFR: otherFlightFields.AllDayVFR,
      Subparts: SubParts,
      DetailedFlightTimes: (fi.DetailedFlightTimes || []).map(dft => {
        if (!moment.isMoment(dft.TimeOff) || !moment.isMoment(dft.TimeOn)) {
          return {
            ...dft,
            TimeOff: moment(dft.TimeOff).utc(),
            TimeOn: moment(dft.TimeOn).utc(),
          };
        }

        return dft;
      }),
      CustomCurrencyItems: serializeCustomCurrencyItems(customCurrencyItemTemplates, fi),
    })),
  };

  if (!aircraftInformation.FlightInformation?.length) {
    aircraftInformation.FlightInformation = [
      {
        Subparts: SubParts,
        AircraftType: '',
        DetailedFlightTimes: [],
        SinglePilot: otherFlightFields.IsSinglePilot,
        AllDayVFR: otherFlightFields.AllDayVFR,
        CustomCurrencyItems: [],
      },
    ];
  }

  const payload = {
    ID: entryID,
    CompanyID: companyID,
    ...otherDutyFields,
    StartTime,
    EndTime,
    RestStartTime,
    RestEndTime,
    FlightDutyPeriodEndTime: FlightDutyEndTime,
    NonFlyingDuty: !isFlyingDuty, // since this isn't managed by the form
    OnCallDuty: onCallDuty, // since this isn't managed by the form
    ManagementOverride: managementOverride, // since this isn't managed by the form
    AircraftInformation: aircraftInformation,
    RAPStartTime,
    RAPEndTime,
    PositioningAfterMaxFDP: positioningAfterMaxFDP,
    Version: version,
    ComplianceOverrideReason: managementOverride ? ComplianceOverrideReason : ' ',
  };

  if (payload.AircraftInformation?.FlightInformation) {
    payload.AircraftInformation.FlightInformation = payload.AircraftInformation?.FlightInformation.filter(
      el => el?.AircraftType !== null,
    );
  }

  return payload;
};
