import { all, takeEvery, put, call } from 'redux-saga/effects';
import api from '../../../services/api';
import { Types } from './actions';
import { AnyAction } from 'redux';
import { ApiTypes } from 'models/index';
import { removeEmptyParams } from 'utils/removeEmptyParams';

export function* GET_PILOTS(action: AnyAction) {
  const params: Record<string, any> = {
    search: action.payload?.search,
    is_pilot: true,
    active: action.payload?.active,
  };
  const paramsCleared = removeEmptyParams(params);

  try {
    const response: ApiTypes.ResponseGenerator = yield call(
      api.get,
      `/v1/companies/${action.payload.companyID}/users`,
      { params: paramsCleared },
    );

    if (response.status === 200) {
      yield put({
        type: Types.GET_PILOTS_SUCCESS,
        payload: response.data?.Data,
      });
    }
  } catch (error) {
    yield put({ type: Types.GET_PILOTS_ERROR });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.GET_PILOTS_REQUEST, GET_PILOTS)]);
}
