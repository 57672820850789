import actions from './actions';
import { AircraftLogMinimal } from '../../../../common/types/aircraftLog';

export interface AircraftLogSelectorState {
  logsByAircraftID: { [aircraftID: string]: AircraftLogMinimal[] };
  isLoading: boolean;
}

export const initialState: AircraftLogSelectorState = {
  logsByAircraftID: {},
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
