import actions from './actions';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        ...{
          isDocumentAdded: action.payload.confirmSuccess,
          aircraftDocuments: action.payload.newDocument
            ? [...state.aircraftDocuments, action.payload.newDocument.Data]
            : state.aircraftDocuments,
        },
      };
    case actions.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        ...{
          isDocumentDeleted: action.payload.isDocumentDeleted,
          aircraftDocuments: state.aircraftDocuments.filter(
            document => document.ID !== action.payload.documentId,
          ),
        },
      };
    case actions.EDIT_DOCUMENT_SUCCESS:
      return {
        ...state,
        ...{
          isDocumentEdited: action.payload.isDocumentEdited,
          aircraftDocuments: action.payload.updatedDocument
            ? state.aircraftDocuments.map(document =>
                document.ID === action.payload.updatedDocument.ID
                  ? action.payload.updatedDocument
                  : document,
              )
            : state.aircraftDocuments,
        },
      };
    default:
      return state;
  }
}

const initialState = {
  aircraftLoading: false,
  aircraft: {},
  nextServiceability: {},
  mostRecentLog: {},
  logsTableData: [],
  logsTablePagination: { current: 1, pageSize: 10, showSizeChanger: true },
  logsTableLoading: false,
  logsTableSorter: {},
  logsTableFilters: {},
  workOrdersTableData: [],
  workOrdersTablePagination: {},
  workOrdersTableLoading: {},
  workOrdersTableSorter: {
    field: 'AerochexID',
    order: 'descend',
  },
  workOrdersTableFilters: {},
  activeTabKey: '2',
  aircraftDocuments: [],
  isDocumentAdded: false,
};
