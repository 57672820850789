import { call, put, select } from 'redux-saga/effects';
import { AddFlightWatch, setFlightWatchList, setState } from '../actions';
import api from '../../../../../../services/api';
import { FlightWatch } from '../../types/flightWatch';
import { loadFlightWatchListWorker } from './loadFlightWatchListWorker';

type UpdateFlightWatchPayload =
  | Pick<
      FlightWatch,
      | 'Notes'
      | 'StartTime'
      | 'ETATime'
      | 'EndTime'
      | 'RouteICAOCodes'
      | 'PilotInCommandID'
      | 'SecondInCommandID'
      | 'AircraftID'
      | 'AircraftLogID'
      | 'PassengerCount'
    >
  | { IsManual: boolean };

export function* updateFlightWatchWorker(action: AddFlightWatch) {
  const { payload } = action;

  if (!payload.FlightWatchID) {
    return;
  }

  try {
    const updatePayload: UpdateFlightWatchPayload = {
      ETATime: payload.ETATime && payload.ETATime.toDate(),
      PilotInCommandID: payload.PilotInCommandID,
      StartTime: payload.StartTime && payload.StartTime.toDate(),
      Notes: payload.Notes,
      EndTime: payload.EndTime && payload.EndTime.toDate(),
      RouteICAOCodes: payload.RouteICAOCodes,
      SecondInCommandID: payload.SecondInCommandID,
      AircraftID: payload.AircraftID,
      AircraftLogID: payload.AircraftLogID,
      PassengerCount: payload.PassengerCount,
      IsManual: true,
    };
    yield call(api.patch, `/v1/flight-watch/${payload.FlightWatchID}`, updatePayload);
    yield call(loadFlightWatchListWorker);

    yield put(
      setState({
        isAddUpdateModalOpen: false,
      }),
    );
  } catch (err) {
    console.log('Failed to add flight watch', err);
  }
}
