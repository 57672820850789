import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions, { OnTableChange, setState } from './actions';
import { loadCombinedMaintenanceItemsWorker } from './workers/loadCombinedMaintenanceItemsWorker';

function* onTableChangeWorker(action: OnTableChange) {
  const { pagination, sorter } = action.payload;
  yield put(
    setState({
      pagination,
      sorter,
    }),
  );
  yield call(loadCombinedMaintenanceItemsWorker);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.HANDLE_TABLE_CHANGE, onTableChangeWorker),
    takeEvery(actions.LOAD_COMBINED_MAINTENANCE, loadCombinedMaintenanceItemsWorker),
  ]);
}
