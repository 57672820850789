import { ClientContractPeriodState } from './reducers';
import { ClientContractPeriodForm } from './types';
import { GenericAction } from '../../../../../../../../common/types/redux/actions';
import { ModalMode } from '../../../../../../../../redux/pages/crew-scheduling-v3/modals/interfaces';
import { ClientContract } from '../../../../../../../../common/types/ClientContract';
import { ClientContractPeriod } from '../../../../../../../../common/types/ClientContractPeriod';

export const actionTypes = {
  SET_STATE: 'client-contract-period/SET_STATE',
  SAVE_CLIENT_CONTRACT_PERIOD: 'client-contract-period/SAVE_CLIENT_CONTRACT',
  DELETE_CLIENT_CONTRACT_PERIOD: 'client-contract-period/DELETE_CLIENT_CONTRACT',
};

export interface SetStateAction extends GenericAction {
  payload: Partial<ClientContractPeriodState>;
}

export interface SaveClientContractPeriodAction extends GenericAction {
  payload: {
    clientContractID: string;
    form: ClientContractPeriodForm;
    existedEntryID: string | null;
  };
}

export interface DeleteClientContractAction extends GenericAction {
  payload: { clientContractPeriodID: string };
}

export interface SetModalModeAction extends GenericAction {
  payload: { clientContractPeriodModalMode: ModalMode };
}

export interface SetEditedClientContractAction extends GenericAction {
  payload: { editedClientContractPeriod: ClientContractPeriod };
}

export interface SetEditedClientIDAction extends GenericAction {
  payload: { editedClientContractID: string | null };
}

export const clientContractModalActions = {
  setState: (payload: Partial<ClientContractPeriodState>): SetStateAction => ({
    type: actionTypes.SET_STATE,
    payload,
  }),
  saveClientContractPeriod: (
    clientID: string,
    form: ClientContractPeriodForm,
    existedEntryID: string | null,
  ): SaveClientContractPeriodAction => ({
    type: actionTypes.SAVE_CLIENT_CONTRACT_PERIOD,
    payload: { clientContractID: clientID, form, existedEntryID },
  }),
  setClientContractPeriodModalMode: (
    clientContractPeriodModalMode: ModalMode,
  ): SetModalModeAction => ({
    type: actionTypes.SET_STATE,
    payload: { clientContractPeriodModalMode },
  }),
  setEditedClientContractPeriod: (
    editedClientContractPeriod: ClientContractPeriod,
  ): SetEditedClientContractAction => ({
    type: actionTypes.SET_STATE,
    payload: { editedClientContractPeriod },
  }),
  setEditedClientContractID: (editedClientContractID: string | null): SetEditedClientIDAction => ({
    type: actionTypes.SET_STATE,
    payload: { editedClientContractID },
  }),
  deleteClientContractPeriod: (clientContractPeriodID: string): DeleteClientContractAction => ({
    type: actionTypes.DELETE_CLIENT_CONTRACT_PERIOD,
    payload: { clientContractPeriodID },
  }),
};
