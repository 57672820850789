import { CombinedEntry } from '../../../../../../../common/types/CombinedEntry';
import { editFields, resetValidationMsg, setValidationMsg } from '../../actions';
import { put } from 'redux-saga/effects';
import { Actions, AllowAction } from '../../../../../../../common/types/permissions';
import moment from 'moment-timezone';

export function* checkDutyRequiredFields(
  entry: CombinedEntry,
  editValues: any,
  allowedActions: Actions,
) {
  const startAirportId =
    editValues[editFields.StartAirportID] !== undefined
      ? editValues[editFields.StartAirportID]
      : entry.StartAirportID;
  const endAirportId =
    editValues[editFields.EndAirportID] !== undefined
      ? editValues[editFields.EndAirportID]
      : entry.EndAirportID;
  const subparts =
    editValues[editFields.SubParts] !== undefined
      ? editValues[editFields.SubParts]
      : entry.Subparts ||
        (entry.AircraftInformation?.FlightInformation?.length > 0 &&
          entry.AircraftInformation?.FlightInformation[0]?.Subparts) ||
        (entry?.User && entry.User.Profile.Metadata?.MostRecentSubPart) ||
        [];

  const validationMsgs: { [key: string]: string } = {};
  if (!startAirportId) {
    validationMsgs[editFields.StartAirportID] = 'Select the airport';
  }
  if (!endAirportId) {
    validationMsgs[editFields.EndAirportID] = 'Select the airport';
  }
  if (subparts.length === 0 && allowedActions[AllowAction.allowEditSubParts]) {
    validationMsgs[editFields.SubParts] = 'Select Regulation(s)';
  }

  const allKeys = [editFields.StartAirportID, editFields.EndAirportID, editFields.SubParts];

  for (const key of allKeys) {
    if (!validationMsgs[key]) {
      yield put(resetValidationMsg(key));
    } else {
      yield put(setValidationMsg(key, validationMsgs[key]));
    }
  }
}
