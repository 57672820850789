import { NumInputState } from './reducers';

const actions = {
  SET_STATE: 'components/num-input/SET_STATE',
  CLOSE_NUM_KEYBOARD: 'components/num-input/CLOSE_NUM_KEYBOARD',
  SET_EDITING_FIELD: 'components/num-input/SET_EDITING_FIELD',
  SET_EDITING_VALUE: 'components/num-input/SET_EDITING_VALUE',
  SET_EDITING_INPUT_PROPS: 'components/num-input/SET_EDITING_INPUT_PROPS',
};

export default actions;

export const setState = payload => ({
  type: actions.SET_STATE,
  payload,
});

export const closeNumKeyboard = () => ({
  type: actions.CLOSE_NUM_KEYBOARD,
});

export const setEditingField = (field: string | null) => ({
  type: actions.SET_EDITING_FIELD,
  payload: {
    editingField: field,
  },
});

export const setEditingValue = (value: string | null) => ({
  type: actions.SET_EDITING_VALUE,
  payload: {
    editingValue: value,
  },
});

type EditingInputPropsType = NumInputState['editingInputProps'];

export const setEditingInputProps = (props: EditingInputPropsType) => ({
  type: actions.SET_EDITING_INPUT_PROPS,
  payload: {
    editingInputProps: props,
  },
});
