import moment from 'moment-timezone';

export const composeKey = (...keys: string[]) => {
  return keys.join('_');
};

export const DIVIDER_TYPE = 'month-divider';

export function populateMonthDividers(entries: { StartTime: Date }[]) {
  const result = [];
  let prevMonth: moment.Moment | null = null;

  for (const entry of entries) {
    const entryMonth = moment(entry.StartTime).startOf('month');

    if (!prevMonth || !entryMonth.isSame(prevMonth, 'month')) {
      const formattedMonth = entryMonth.format('MMMM YYYY');
      result.push({
        type: DIVIDER_TYPE,
        month: formattedMonth,
      });
      prevMonth = entryMonth;
    }

    result.push(entry);
  }

  return result;
}
