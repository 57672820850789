import React from 'react';
import { Row, Col } from 'antd';
import styles from './style.module.scss';
import { ENVIRONMENT } from '../../../config';

const currentYear = new Date().getFullYear();
const copyrightYears = `© 2017 - ${currentYear}`;

const Footer = () => (
  <div className={styles.footer} style={{ padding: '1.07rem 2.3rem 2.3rem' }}>
    <Row>
      <Col span={24}>
        <div className={styles.copyright}>
          <img src="resources/images/airtrail.png" alt="Underdog Software" />
          <span style={{ marginTop: '9px' }}>
            {`Environment: ${ENVIRONMENT}`}
            {copyrightYears}{' '}
            <a href="http://airtrail.ca/" target="_blank" rel="noopener noreferrer">
              Air Trail
            </a>
            All rights reserved
          </span>
        </div>
      </Col>
    </Row>
  </div>
);

export default Footer;
