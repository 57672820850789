import actions from './actions';
import { Moment } from 'moment-timezone';
import { Aircraft } from '../../../../../common/types/aircraft';
import { EntryType } from '../../../../../common/types/entryTypes';
import { apiEntryTypeMap } from '../../../../../common/types/apiEntryTypes';
import { Pilot } from '../../../../../common/types/pilot';
import {
  AircraftEntry,
  CombinedEntry,
  FlightInformation,
} from '../../../../../common/types/CombinedEntry';
import { CompanyRegulation } from '../../../../../common/types/companyRegulation';
import { CrewScheduleV2Permissions } from '../../../../../common/types/permissions';
import { initPermissions } from '../../../../../common/helpers/permissions';
import { CompanyTag } from './types/tag';
import { CompanyClient } from '../../../../../common/types/client';

export interface CrewScheduleMobileState {
  data: CombinedEntry[];
  aircraftData: AircraftEntry[];
  entry: CombinedEntry | null;
  aircraftEntry: (AircraftEntry & { type?: string; month?: string }) | null;
  hasMore: boolean;
  loading: boolean;
  isLoadingPast: boolean;
  isLoadingFuture: boolean;
  isFutureVisibleLimitExceeded: boolean;
  companyScheduleVersion: null;
  entryPeriod: [Moment, Moment];
  entryType: EntryType;
  preferredVersion: string;
  publishedVersions: any[]; // Replace with your specific type if possible
  selectedPilotId: string | null;
  selectedAircraftId: string | null;
  selectedPilotName: string | null;
  selectedAircraftTailNumber: string | null;
  selectedAircraftCommonDesignator: string | null;
  numPilotsRequiredToFly: number;
  companyAircraft: Aircraft[];
  pilots: Pilot[];
  companyClients: CompanyClient[];
  companyTags: CompanyTag[];
  isPilotsLoaded: boolean;
  isAircraftLoaded: boolean;
  isAllowToViewAllPilots: boolean;
  isAllowedToViewAircraftSchedule: boolean;
  isEditing: boolean;
  companyRegulations: CompanyRegulation[];
  editValues: any; // Replace with your specific type if possible
  originalFlightInformation: FlightInformation[];
  saving: boolean;
  isNewEntry: boolean;
  isShowDetails: boolean;
  isShowAircraftDetails: boolean;
  validations: { [key: string]: string };
  formValidationIssuesAmount: number;
  bannerMsgs: { message: string }[];
  additionalInfo: { [key: string]: string };
  isDetailedFlightTimesFormValid: boolean;
  detailedFlightFlightsValidStateByType: { [detailedFlightTimesKey: string]: boolean };
  permissionList: CrewScheduleV2Permissions;
  defaultStartAirportID: string;
  defaultEndAirportID: string;
  freshIssues: { Code: string; Message: string }[];
  mostRecentDutyEntry: CombinedEntry | null;
  relatedPilotNames: string[];
  activeAircraftShiftKey: string | null;
  morePastEntries: boolean;
  moreFutureEntries: boolean;
}

export const initialState: CrewScheduleMobileState = {
  data: [],
  aircraftData: [],
  entry: null,
  aircraftEntry: null,
  hasMore: true,
  loading: true,
  isLoadingPast: false,
  isLoadingFuture: false,
  isFutureVisibleLimitExceeded: false,
  companyScheduleVersion: null,
  entryPeriod: [null, null],
  entryType: apiEntryTypeMap.DutyScheduleEntries,
  preferredVersion: null,
  publishedVersions: [],
  selectedPilotId: null,
  selectedAircraftId: null,
  selectedPilotName: null,
  selectedAircraftTailNumber: null,
  selectedAircraftCommonDesignator: null,
  numPilotsRequiredToFly: null,
  companyAircraft: [],
  pilots: [],
  companyClients: [],
  companyTags: [],
  isPilotsLoaded: false,
  isAircraftLoaded: false,
  isAllowToViewAllPilots: false,
  isAllowedToViewAircraftSchedule: false,
  isEditing: false,
  companyRegulations: [],
  editValues: {},
  saving: false,
  isNewEntry: false,
  isShowDetails: false,
  isShowAircraftDetails: false,
  validations: {},
  additionalInfo: {},
  formValidationIssuesAmount: 0,

  isDetailedFlightTimesFormValid: true,
  detailedFlightFlightsValidStateByType: {},
  permissionList: initPermissions().crewScheduleV2,
  defaultStartAirportID: null,
  defaultEndAirportID: null,
  freshIssues: null,
  mostRecentDutyEntry: null,
  relatedPilotNames: [],
  bannerMsgs: null,
  originalFlightInformation: [],
  activeAircraftShiftKey: null,
  morePastEntries: true,
  moreFutureEntries: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_ENTRY_TYPE:
    case actions.SET_PILOTS:
    case actions.SET_SELECTED_PILOT:
    case actions.SET_SELECTED_AIRCRAFT:
    case actions.SET_IS_EDITING:
    case actions.SET_ENTRY:
    case actions.SET_AIRCRAFT_ENTRY:
    case actions.SET_COMPANY_REGULATIONS:
    case actions.CREATE_NEW_ENTRY:
    case actions.SET_IS_SHOW_DETAILS:
    case actions.SET_IS_SHOW_AIRCRAFT_DETAILS:
    case actions.SET_PERMISSIONS:
    case actions.SET_MOST_RECENT_DUTY_ENTRY:
    case actions.SET_FRESH_ISSUES:
    case actions.SET_SELECTED_PILOT_ID:
    case actions.SET_SELECTED_AIRCRAFT_ID:
    case actions.SET_COMPANY_CLIENTS:
    case actions.SET_COMPANY_TAGS:
    case actions.SET_FI_NUM_FIELD:
      return { ...state, ...action.payload };
    case actions.SET_ENTRIES:
      return { ...state, ...{ data: action.payload } };
    case actions.SET_AIRCRAFT_ENTRIES:
      return { ...state, ...{ aircraftData: action.payload } };
    case actions.SET_COMPANY_SCHEDULE_VERSION:
      return { ...state, companyScheduleVersion: action.payload };
    case actions.SET_PREFERRED_VERSION:
      return { ...state, preferredVersion: action.payload };
    case actions.SET_PUBLISHED_VERSIONS:
      return { ...state, publishedVersions: action.payload };
    case actions.SET_COMPANY_AIRCRAFTS:
      return { ...state, companyAircraft: action.payload };
    case actions.SET_EDIT_VALUE:
      return {
        ...state,
        editValues: { ...state.editValues, ...{ [action.payload.key]: action.payload.value } },
      };
    case actions.CLEAR_EDIT_VALUE:
      return { ...state, editValues: { ...state.editValues, ...{ [action.payload.key]: null } } };
    case actions.SET_IS_FLIGHT_INFO_FORM_VALID:
      const updatedDetailedFlightValidation = { ...state.detailedFlightFlightsValidStateByType };
      if (action.payload.isFormValid) {
        delete updatedDetailedFlightValidation[action.payload.key];
      } else {
        updatedDetailedFlightValidation[action.payload.key] = false;
      }
      return {
        ...state,
        isDetailedFlightTimesFormValid: Object.keys(updatedDetailedFlightValidation).length === 0,
      };
    case actions.STORE_ORIGINAL_FLIGHT_INFORMATION:
      return {
        ...state,
        originalFlightInformation: action.payload,
      };
    case actions.RESTORE_ORIGINAL_FLIGHT_INFORMATION:
      return {
        ...state,
        entry: {
          ...state.entry,
          AircraftInformation: {
            ...state.entry.AircraftInformation,
            FlightInformation: state.originalFlightInformation,
          },
        },
      };
    case actions.CLEAR_FLIGHT_INFO_EDIT_VALUES_BY_PREFIX:
      const newEditValues = { ...state.editValues };
      Object.keys(newEditValues).forEach(key => {
        if (key.startsWith(action.payload.prefix)) {
          delete newEditValues[key];
        }
      });
      return {
        ...state,
        editValues: newEditValues,
      };
    case actions.SET_FLIGHT_INFO_EDIT_VALUE:
      if (action.payload.key) {
        const updatedDetailedFlightValidation = { ...state.detailedFlightFlightsValidStateByType };
        if (action.payload.isFormValid || action.payload.isFormValid === undefined) {
          delete updatedDetailedFlightValidation[action.payload.key];
        } else {
          updatedDetailedFlightValidation[action.payload.key] = false;
        }

        return {
          ...state,
          isDetailedFlightTimesFormValid: Object.keys(updatedDetailedFlightValidation).length === 0,
          detailedFlightFlightsValidStateByType: updatedDetailedFlightValidation,
          editValues: { ...state.editValues, ...{ [action.payload.key]: action.payload.value } },
        };
      }

      return {
        ...state,
        editValues: { ...state.editValues, ...{ [action.payload.key]: action.payload.value } },
      };
    case actions.SET_EDIT_VALUES:
      return { ...state, editValues: { ...state.editValues, ...action.payload } };
    case actions.RESET_VALIDATION:
      if (action.payload.key) {
        let newValidations = { ...state.validations };
        delete newValidations[action.payload.key];
        return { ...state, validations: newValidations };
      } else {
        return { ...state, validations: {} };
      }
    case actions.SET_VALIDATION_MSG:
      return {
        ...state,
        validations: { ...state.validations, ...{ [action.payload.key]: action.payload.msg } },
      };
    case actions.SET_BANNER_MSGS:
      return {
        ...state,
        bannerMsgs: action.payload.bannerMsgs,
      };
    case actions.RESET_VALIDATION_MSG:
      if (action.payload.key) {
        let newValidations = { ...state.validations };
        delete newValidations[action.payload.key];
        return { ...state, validations: newValidations };
      } else {
        return { ...state, validations: {} };
      }
    case actions.RESET_VALIDATION_MSGS:
      if (action.payload.keys) {
        let newValidations = { ...state.validations };
        for (let key of action.payload.keys) {
          delete newValidations[key];
        }
        return { ...state, validations: newValidations };
      } else {
        return { ...state, validations: {} };
      }
    case actions.SET_ADDITIONAL_INFO_VALUES:
      return { ...state, additionalInfo: { ...state.additionalInfo, ...action.payload } };
    case actions.RESET_ENTRY_DETAILS_STATE:
      return {
        ...state,
        entry: null,
        isEditing: initialState.isEditing,
        editValues: initialState.editValues,
        isNewEntry: initialState.isNewEntry,
        validations: initialState.validations,
        additionalInfo: initialState.additionalInfo,
        isDetailedFlightTimesFormValid: initialState.isDetailedFlightTimesFormValid,
        detailedFlightFlightsValidStateByType: initialState.detailedFlightFlightsValidStateByType,
        freshIssues: initialState.freshIssues,
      };
    case actions.SET_MORE_FUTURE_ENTRIES:
      return {
        ...state,
        moreFutureEntries: action.payload,
      };
    case actions.SET_MORE_PAST_ENTRIES:
      return {
        ...state,
        morePastEntries: action.payload,
      };
    default:
      return state;
  }
}
