import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getCampConfig } from 'services/integrations';
import actions, { IntegrationsAction } from './actions';

export function* INIT({ companyId }: IntegrationsAction.Init) {
  if (!companyId) return;
  try {
    const campConfig = yield call(getCampConfig, { companyId });
    const action: IntegrationsAction.SetCampConfig = {
      type: actions.SET_CAMP_CONFIG,
      campConfig: campConfig,
    };
    yield put(action);
  } catch (e) {
    const action: IntegrationsAction.SetCampConfig = {
      type: actions.SET_CAMP_CONFIG,
      campConfig: null,
    };
    yield put(action);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.INIT, INIT),
  ]);
}
