import moment from 'moment-timezone';
import { TimelinePeriodSwitch, TimelineView } from './timeline';

export class TimelinePeriod {
  constructor(private start: moment.Moment, private end: moment.Moment) {}

  getStart(): moment.Moment {
    return this.start;
  }

  getEnd(): moment.Moment {
    return this.end;
  }

  getStartMs(): number {
    return this.start.valueOf();
  }

  getEndMs(): number {
    return this.end.valueOf();
  }

  clone() {
    return new TimelinePeriod(this.start.clone(), this.end.clone());
  }

  toString() {
    return `${this.start.format('YYYY-MM-DD HH:mm')} - ${this.end.format('YYYY-MM-DD HH:mm')}`;
  }

  switchPeriod(view: TimelineView, periodSwitch: TimelinePeriodSwitch): TimelinePeriod {
    let newStart = this.start.clone();
    let newEnd = this.end.clone();

    switch (view) {
      case TimelineView.Day:
        newStart.add(periodSwitch === TimelinePeriodSwitch.Next ? 1 : -1, 'day').startOf('day');
        newEnd = newStart.clone().endOf('day');
        break;
      case TimelineView.Week:
        newStart.add(periodSwitch === TimelinePeriodSwitch.Next ? 1 : -1, 'week').startOf('week');
        newEnd = newStart.clone().endOf('week');
        break;
      case TimelineView.Month:
        newStart.add(periodSwitch === TimelinePeriodSwitch.Next ? 1 : -1, 'month').startOf('month');
        newEnd = newStart.clone().endOf('month');
        break;
      case TimelineView.ThreeMonths:
        newStart
          .add(periodSwitch === TimelinePeriodSwitch.Next ? 3 : -3, 'months')
          .startOf('month');
        newEnd = newStart
          .clone()
          .add(2, 'months')
          .endOf('month'); // Add 2 more months to complete the 3 months period
        break;
      case TimelineView.Year:
        newStart.add(periodSwitch === TimelinePeriodSwitch.Next ? 1 : -1, 'year').startOf('year');
        newEnd = newStart.clone().endOf('year');
        break;
      default:
        break;
    }

    return new TimelinePeriod(newStart, newEnd);
  }
}
