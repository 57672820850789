import { all, call, debounce, put, select, take, takeEvery, takeLeading, throttle } from 'redux-saga/effects';
import api from '../../../services/api';
import actions, {
  loadAircraftContracts,
  loadAircraftScheduleEntries,
  loadCrewScheduleEntries,
  loadDutyScheduleEntries,
  loadDutyTimesEntries,
  setCompanyTags,
  setPreferredVersion,
  setSelectedCrewMemberIds,
  setSelectedTagsAction,
  setState,
  setValidatingEntries,
  validationFinished,
  ValidationFinishedAction,
  validationStarted,
  ValidationStartedAction,
} from './actions';
import userActions from '../../user/actions';
import { loadPilots, setCrewSortOptionFromCache } from './loadPilots';
import { Config } from '../../../pages/data/organization/crew-scheduling-v3/legacy/utils/Config';
import { watchTagsWorker } from './workers/watchTagsWorker';
import LocalCache from '../../../pages/local-storage/local-storage';
import { apiEntryTypeMap } from '../../../common/types/apiEntryTypes';
import { actionTypes as commonActionTypes } from '../../../redux/common/actions';
import { getCommon } from '../../selectors';
import { CommonInitState } from '../../common/reducers';
import { watchSelectedPilots } from './workers/watchSelectedPilots';
import { setSelectedAircraftIdentifiersFromCache } from './workers/setSelectedAircraftIdentifiersFromCache';
import { watchSetSelectedTimelines } from './workers/watchSetSelectedTimelines';
import { SelectedTimeline, Tag } from './types/types';
import { loadAircraftScheduleWorker } from './workers/loadAircraftScheduleWorker';
import { loadAircraftContractsWorker } from './workers/loadAircraftContractsWorker';
import { checkOverlapsWorker } from './workers/checkOverlapsWorker';
import { watchCrewSortOptionChanged } from './workers/watchCrewSortOptionChanged';
import { watchTimelinePeriodChange } from './workers/watchTimelinePeriodChange';
import { AircraftSelectorType } from '../../common/types';
import { message } from 'antd';
import { aircraftLogsFromFlightsWorker } from './workers/aircraftLogsFromFlightsWorker';
import { versioningActions } from '../../../pages/data/organization/crew-scheduling-v3/components/Versioning/redux-saga/actions';
import { PusherActionType, PusherReduxAction } from '../../pusher/PusherActions';
import {
  selectDutyScheduleTimelineEntriesSaga, selectDutyTimeTimelineEntriesSaga,
} from '../../../pages/data/organization/crew-scheduling-v3/redux-saga/selectors-saga';
import { scheduleActions } from '../../../pages/data/organization/crew-scheduling-v3/redux-saga/actions';
import { DutyTimelineEntry } from '../../../common/types/timeline/DutyTimelineEntry';

function* fetchCompanyScheduleVersionWorker() {
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  const {
    data: { Data },
  } = yield call(api.get, `/v1/companies/${companyID}/schedule-version`);
  yield put({
    type: actions.SET_COMPANY_SCHEDULE_VERSION,
    payload: Data,
  });
}

function* fetchCompanyTags() {
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  const {
    data: { Data },
  } = yield call(api.get, `/v1/companies/${companyID}/company-tag`);

  const companyTags: Tag[] = Data;
  const { selectedTags } = yield select(({ pages: { crewSchedulingV2 } }) => crewSchedulingV2);

  if (selectedTags.length > 0 && Data.length > 0) {
    const companyTagsIds = companyTags.map(t => t.ID);
    const filteredTags = selectedTags.filter(tag => companyTagsIds.includes(tag));
    yield put(setSelectedTagsAction(filteredTags));

    const user = yield select(({ user }) => user);
    const localCache = new LocalCache('crewSchedulingV2', user);
    localCache.setCached('selectedTags', filteredTags);
  }

  yield put(setCompanyTags(companyTags));
}

function* loadPublishedVersionWorker(action) {
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  let range = [];
  let pagination = { limit: 10, page: 0 };
  if (action?.payload) {
    range = action.payload.range ?? range;
    pagination = action.payload.pagination ?? pagination;
  }
  const requestPayload = {};

  if (range?.length > 0) {
    requestPayload['published_at__gte'] = range[0];
    requestPayload['published_at__lte'] = range[1];
  }
  requestPayload['company_id'] = companyID;
  requestPayload['limit'] = pagination.limit;
  requestPayload['page'] = pagination.page;

  const {
    data: { Data, Count, Total, Page },
  } = yield call(api.get, `/v1/companies/${companyID}/publish/list`, { params: requestPayload });

  yield put(
    versioningActions.setState({
      publishedVersions: Data,
      publishedVersionsRaw: { Data, Count, Total, Page },
    }),
  );
}

function* watchComponentAndPilotsLoaded() {
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);

  yield all([
    take(actions.LOAD_SELECTED_PILOTS_SUCCESS),
    take(actions.UPDATE_ENTRY_PERIOD),
    take(actions.SET_COMPANY_SCHEDULE_VERSION),
  ]);

  const entryPeriod = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2.entryPeriod,
  );

  const [startTime, endTime] = entryPeriod;

  const config = { startTime, endTime, isShowLoading: true };
  yield put(loadCrewScheduleEntries(config));
  yield put(loadDutyScheduleEntries(config));
  yield put(loadDutyTimesEntries(config));
  if (!companySettings?.ScheduleByBase) {
    yield put(loadAircraftScheduleEntries(config, []));
    yield put(loadAircraftContracts(config));
  }
}

function* setPreferredVersionWorker() {
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);
  const companyUsers = yield select(({ user: { CompanyUsers } }) => CompanyUsers);

  if (companySettings.Versioning) {
    const version = companyUsers[0]?.PreferredVersion;
    yield put(setPreferredVersion(version));
  } else {
    yield put(setPreferredVersion('current'));
  }
}

function* watchEntryPeriodChange() {
  const { crewScheduleEntries, dutyScheduleEntries, dutyTimeEntries } = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2,
  );
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);

  // check that it's not the first time the entry period is loaded
  if (
    crewScheduleEntries.length == 0 &&
    dutyScheduleEntries.length == 0 &&
    dutyTimeEntries.length == 0
  ) {
    return;
  }

  const entryPeriod = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2.entryPeriod,
  );
  const [startTime, endTime] = entryPeriod;

  const config: Config = {
    endTime: endTime,
    isShowLoading: true,
    startTime: startTime,
  };

  yield put(loadDutyTimesEntries(config));
  yield put(loadDutyScheduleEntries(config));
  yield put(loadCrewScheduleEntries(config));
  if (!companySettings?.ScheduleByBase) {
    yield put(loadAircraftScheduleEntries(config, []));
    yield put(loadAircraftContracts(config));
  }
}

function* setSelectedTagsFromCache() {
  const user = yield select(({ user }) => user);
  const localCache = new LocalCache('crewSchedulingV2', user);

  yield put(
    setState({
      selectedTags: localCache.getCached('selectedTags', []),
    }),
  );
}

function* setSelectedCrewMemberIdsFromCache() {
  const user = yield select(({ user }) => user);
  const localCache = new LocalCache('crewSchedulingV2', user);
  yield put(setSelectedCrewMemberIds(localCache.getCached('selectedCrewMemberIds', [])));
}

function* setSelectedTimelinesFromCache() {
  const user = yield select(({ user }) => user);
  const localCache = new LocalCache('crewSchedulingV2', user);
  yield put(
    setState({
      selectedTimelines: localCache.getCached('selectedTimelines', [
        SelectedTimeline.AircraftGroup,
        SelectedTimeline.Aircraft,
        SelectedTimeline.Crew,
      ]),
    }),
  );
}

function* watchLoadCrewScheduleComponent() {
  const { isCompanyLoaded, isUserLoaded } = yield select(({ user }) => user);

  const awaitActions = [];
  if (!isCompanyLoaded) {
    awaitActions.push(take(userActions.SET_COMPANY));
  } else if (!isUserLoaded) {
    awaitActions.push(take(userActions.SET_USER));
  }
  // await for company or user to be loaded
  yield all(awaitActions);

  yield call(setSelectedTagsFromCache);
  yield call(setSelectedCrewMemberIdsFromCache);
  yield call(setSelectedTimelinesFromCache);
  yield call(setCrewSortOptionFromCache);
  yield all([
    call(loadPilots),
    call(fetchCompanyScheduleVersionWorker),
    call(loadPublishedVersionWorker, { payload: { pagination: { limit: 10, page: 0 } } }),
    call(setPreferredVersionWorker),
  ]);

  yield call(fetchCompanyTags);
}

function* watchValidationStarted(action: ValidationStartedAction) {
  const { entryType, entryIDs } = action.payload;
  const validatingEntries = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2.validatingEntries,
  );

  // update validatingEntries with the new entryIDs
  const updatedValidationEntries = entryIDs.reduce(
    (acc, id) => ({ ...acc, [id]: { isValidating: true, entryType } }),
    validatingEntries,
  );

  yield put(setValidatingEntries(updatedValidationEntries));
}

function* watchValidationFinished(action: ValidationFinishedAction) {
  const { entryType, updates } = action.payload;

  const validationEntries = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2.validatingEntries,
  );

  const updatedEntries = Object.keys(validationEntries).reduce((acc, id) => {
    if (acc[id] && acc[id].entryType === entryType) {
      acc[id].isValidating = false;
    }
    return acc;
  }, validationEntries);

  let exitedEntries: DutyTimelineEntry[]
  if (entryType === apiEntryTypeMap.DutyScheduleEntries) {
     exitedEntries = yield* selectDutyScheduleTimelineEntriesSaga();
  } else {
    exitedEntries = yield* selectDutyTimeTimelineEntriesSaga();
  }
  const updatedTimelineEntries: DutyTimelineEntry[] = []

  if (updates !== null) {
  const updatesMap = updates.reduce((acc, update) => {
    acc[update.ID] = update.Issues;
    return acc;
  }, {});

  for (let i = 0; i < exitedEntries.length; i++) {
    let entry = { ...exitedEntries[i] };

    if (updatesMap.hasOwnProperty(entry.ID)) {
      entry.Issues = updatesMap[entry.ID];
    }

    updatedTimelineEntries.push(entry);
  }

  if (entryType === apiEntryTypeMap.DutyScheduleEntries) {
    yield put(
      scheduleActions.setState({ dutyScheduleTimelineEntries: updatedTimelineEntries }),
    );
  } else {
    yield put(
      scheduleActions.setState({ dutyTimeTimelineEntries: updatedTimelineEntries }),
    );
  }
}
  yield put(setValidatingEntries(updatedEntries));
}

export function* watchAircraftTailChange() {
  const { aircraftList, aircraftIdentifiers, aircraftSelectorType } = (yield select(
    getCommon,
  )) as CommonInitState;
  const selectedAircraftIdentifiers = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2.selectedAircraftIdentifiers,
  );

  const allAllowedAircraft = aircraftList.filter(aircraft => {
    if (aircraftSelectorType === AircraftSelectorType.Registration) {
      return aircraftIdentifiers.includes(aircraft.Registration);
    }
    return aircraftIdentifiers.includes(aircraft.TailNumber);
  });

  if (selectedAircraftIdentifiers.length > 0) {
    const selectedAircraft = allAllowedAircraft.filter(aircraft => {
      if (aircraftSelectorType === AircraftSelectorType.Registration) {
        return selectedAircraftIdentifiers.includes(aircraft.Registration);
      }
      return selectedAircraftIdentifiers.includes(aircraft.TailNumber);
    });
    yield put(
      setState({
        selectedAircraft,
      }),
    );
  } else {
    yield put(
      setState({
        selectedAircraft: allAllowedAircraft,
      }),
    );
  }
}

function* triggerRealTimeUpdateWorker() {
  message.info('Real Time Update Triggered');
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ALL_PUBLISHED_VERSIONS, loadPublishedVersionWorker),
    takeEvery(actions.LOAD_PUBLISHED_VERSIONS, loadPublishedVersionWorker),
    takeLeading(actions.LOAD_CREW_SCHEDULE_COMPONENT_DATA, watchLoadCrewScheduleComponent),
    takeLeading(actions.LOAD_CREW_SCHEDULE_COMPONENT_DATA, watchComponentAndPilotsLoaded),
    takeLeading(actions.LOAD_CREW_SCHEDULE_COMPONENT_DATA, watchAircraftTailChange),
    takeEvery(actions.SET_SELECTED_AIRCRAFT_IDENTIFIERS, watchAircraftTailChange),
    takeEvery(actions.UPDATE_ENTRY_PERIOD, watchEntryPeriodChange),
    takeEvery(actions.SET_SELECTED_TAGS, watchTagsWorker),
    takeEvery(actions.VALIDATION_STARTED, watchValidationStarted),
    takeEvery(actions.VALIDATION_FINISHED, watchValidationFinished),
    takeLeading(
      commonActionTypes.SET_AIRCRAFT_TAIL_NUMBERS,
      setSelectedAircraftIdentifiersFromCache,
    ),
    takeEvery(actions.SET_SELECTED_CREW_MEMBER_IDS, watchSelectedPilots),
    takeEvery(actions.SET_SELECTED_TIMELINES, watchSetSelectedTimelines),
    takeEvery(actions.LOAD_AIRCRAFT_SCHEDULE_ENTRIES, loadAircraftScheduleWorker),
    takeEvery(actions.LOAD_AIRCRAFT_CONTRACTS, loadAircraftContractsWorker),
    debounce(1500, actions.CHECK_OVERLAPS, checkOverlapsWorker),
    debounce(1500, actions.GET_LOGS_FROM_FLIGHTS, aircraftLogsFromFlightsWorker),
    takeEvery(actions.SET_CREW_SORT_OPTION, watchCrewSortOptionChanged),
    debounce(1000, actions.ON_VISIBLE_TIMELINE_PERIOD_CHANGE, watchTimelinePeriodChange),
    throttle(30000, actions.TRIGGER_REAL_TIME_UPDATE_MGS, triggerRealTimeUpdateWorker),

    takeEvery(PusherActionType.DUTY_TIME_ENTRIES_VALIDATION_STARTED, function*(
      action: PusherReduxAction<PusherActionType.DUTY_TIME_ENTRIES_VALIDATION_STARTED>,
    ) {
      yield put(validationStarted(apiEntryTypeMap.DutyTimeEntries, action.payload));
    }),
    takeEvery(PusherActionType.DUTY_SCHEDULE_ENTRIES_VALIDATION_STARTED, function*(
      action: PusherReduxAction<PusherActionType.DUTY_SCHEDULE_ENTRIES_VALIDATION_STARTED>,
    ) {
      yield put(validationStarted(apiEntryTypeMap.DutyScheduleEntries, action.payload));
    }),
    takeEvery(PusherActionType.DUTY_TIME_ENTRIES_REVALIDATED, function*(
      action: PusherReduxAction<PusherActionType.DUTY_TIME_ENTRIES_REVALIDATED>
    ) {
      yield put(validationFinished(apiEntryTypeMap.DutyTimeEntries, action.payload));
    }),
    takeEvery(PusherActionType.DUTY_SCHEDULE_ENTRIES_REVALIDATED, function*(
      action: PusherReduxAction<PusherActionType.DUTY_SCHEDULE_ENTRIES_REVALIDATED>
    ) {
      yield put(validationFinished(apiEntryTypeMap.DutyScheduleEntries, action.payload));
    }),
  ]);
}
