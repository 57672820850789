import actions from './actions';
import { AnyAction } from 'redux';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { CombinedMaintenanceItem } from '../types/combinedMaintenance';

export interface CombinedMaintenanceState {
  itemsList: CombinedMaintenanceItem[];
  isLoading: boolean;
  pagination: TablePaginationConfig;
  filter: Record<string, FilterValue | null>;
  itemsListLoaded: boolean;
  sorter: SorterResult<any>;
  aircraftID: string;
  viewOnlyWorkOrderModal: boolean;
  workOrderModalIsOpen: boolean;
  viewMaintenanceItemModalStatus: boolean;
  loadedWorkOrderId: string;
  loadedMaintenanceItemId: string;
}

export const initialState: CombinedMaintenanceState = {
  itemsList: [],
  isLoading: false,
  pagination: {
    total: 0,
    current: 1,
    pageSize: 10,
  },
  filter: {},
  sorter: {},
  itemsListLoaded: false,
  aircraftID: '',
  viewOnlyWorkOrderModal: false,
  workOrderModalIsOpen: false,
  viewMaintenanceItemModalStatus: false,
  loadedWorkOrderId: null,
  loadedMaintenanceItemId: null,
};

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.LOAD_COMBINED_MAINTENANCE:
    case actions.SET_VIEW_MAINTENANCE_ITEM_MODAL_STATUS:
    case actions.SET_WORK_ORDER_MODAL_IS_OPEN:
    case actions.SET_LOADED_WORK_ORDER_ID:
    case actions.SET_LOADED_MAINTENANCE_ITEM_ID:
    case actions.SET_VIEW_ONLY_WORK_ORDER_MODAL:
    case actions.START_SHOW_COMBINED_MAINTENANCE:
    case actions.SET_FILTER:
      return { ...state, ...action.payload };
    case actions.SET_COMBINED_MAINTENANCE:
      return { ...state, itemsList: action.payload.list, itemsListLoaded: true };
    default:
      return state;
  }
}
