import { ValidationError } from '../interfaces';
import MODAL_MODE from '../../../../../pages/data/common/constants';

export const actions = {
  SET_STATE: 'crew-scheduling-v3/ScheduleEntryModal/SET_STATE',
  SET_VALIDATION_SUGGESTIONS: 'crew-scheduling-v3/ScheduleEntryModal/SET_VALIDATION_SUGGESTIONS',
  SET_ACCLIMATIZED_TIMEZONE_TAG:
    'crew-scheduling-v3/ScheduleEntryModal/SET_ACCLIMATIZED_TIMEZONE_TAG',
  RUN_VALIDATION: 'crew-scheduling-v3/ScheduleEntryModal/RUN_VALIDATION',
  RUN_VALIDATION_DEB: 'crew-scheduling-v3/ScheduleEntryModal/RUN_VALIDATION_DEB',
  SET_IS_VALIDATION_RUNNING: 'crew-scheduling-v3/ScheduleEntryModal/SET_IS_VALIDATION_RUNNING',
  SET_VALIDATION_STATE: 'crew-scheduling-v3/ScheduleEntryModal/SET_VALIDATION_STATE',
  SET_CUSTOM_SECTION_VALUES: 'crew-scheduling-v3/ScheduleEntryModal/SET_CUSTOM_SECTION_VALUES',
  OPEN_MODAL: 'crew-scheduling-v3/ScheduleEntryModal/OPEN_MODAL',
  CLOSE_MODAL: 'crew-scheduling-v3/ScheduleEntryModal/CLOSE_MODAL',
  RESET_STATE: 'crew-scheduling-v3/ScheduleEntryModal/RESET_STATE',
};

export const setIsValidationRunningAction = (isValidationRunning: boolean) => ({
  type: actions.SET_IS_VALIDATION_RUNNING,
  payload: { isValidationRunning },
});

export const setValidationStateAction = (validationState: any) => ({
  type: actions.SET_VALIDATION_STATE,
  payload: { validationState },
});

export const setValidationSuggestionsAction = (suggestions: ValidationError[]) => ({
  type: actions.SET_VALIDATION_SUGGESTIONS,
  payload: { validationSuggestions: suggestions },
});

export const setAcclimatizedTimezoneTagAction = (acclimatizedTimezoneTag: string) => ({
  type: actions.SET_ACCLIMATIZED_TIMEZONE_TAG,
  payload: { acclimatizedTimezoneTag },
});

export const runValidationAction = dataFields => ({
  type: actions.RUN_VALIDATION,
  payload: { dataFields },
});

export const runValidationDebounce = dataFields => ({
  type: actions.RUN_VALIDATION_DEB,
  payload: { dataFields },
});

export const setCustomSectionValuesAction = (customSectionValues: any[]) => {
  return {
    type: actions.SET_CUSTOM_SECTION_VALUES,
    payload: { customSectionValues },
  };
};

export interface OpenCrewScheduleModalAction {
  type: typeof actions.OPEN_MODAL;
  payload: { mode: MODAL_MODE };
}

export const openCrewScheduleModalAction = (mode: MODAL_MODE): OpenCrewScheduleModalAction => ({
  type: actions.OPEN_MODAL,
  payload: { mode },
});

export const closeCrewScheduleModalAction = () => {
  return {
    type: actions.CLOSE_MODAL,
  };
};

export const setStateAction = payload => ({
  type: actions.SET_STATE,
  payload,
});

export const resetScheduleEntryModalStateAction = () => ({
  type: actions.RESET_STATE,
});
