import { AircraftGroup, GroupSchedule, AircraftGroupSelectorForm } from '../../GroupSchedule/types';
import { AircraftGroupTimelineGroup, AircraftGroupTimelineItem } from './types';
import moment from 'moment-timezone';

export const buildItems = (list: GroupSchedule[]): AircraftGroupTimelineItem[] => {
  const items: AircraftGroupTimelineItem[] = [];
  for (const item of list) {
    items.push({
      ID: item.ID,
      id: item.ID,
      group: item.GroupID,
      title: item.CompanyScheduleType.Name,
      start: moment(item.StartTime).valueOf(),
      end: moment(item.EndTime).valueOf(),
      color: undefined,
      selectedBgColor: undefined,
      bgColor: '#407c85',
    });
  }
  return items;
};

export const buildGroups = (list: AircraftGroup[]): AircraftGroupTimelineGroup[] => {
  const groups: AircraftGroupTimelineGroup[] = [];
  for (const aircraftGroup of list) {
    groups.push({
      id: aircraftGroup.ID,
      group: aircraftGroup.ID,
      title: aircraftGroup.Name,
      Name: aircraftGroup.Name,
    });
  }

  return groups;
};
