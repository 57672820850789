import { GenericAction } from '../../../../../../../common/types/redux/actions';
import { VersioningState } from './reducers';
import { PreferredVersion } from '../../../../../../../common/types/preferredVersoon';
import { PublishedVersion } from '../../../../../../../common/types/publishedVersion';

const actions = {
  SET_STATE: 'pages/company/schedule-versioning/SET_STATE',
  SWITCH_TO_PREFERRED_VERSION: 'pages/company/schedule-versioning/SWITCH_TO_PREFERRED_VERSION',
  PUBLISH_VERSION: 'pages/company/schedule-versioning/PUBLISH_VERSION',
  ADD_PUBLISHED_VERSION: 'pages/company/schedule-versioning/ADD_PUBLISHED_VERSION',
  SET_RECENTLY_PUBLISHED_ENTRIES:
    'pages/company/schedule-versioning/SET_RECENTLY_PUBLISHED_ENTRIES',
  SET_COMPANY_SCHEDULE_VERSION: 'pages/company/schedule-versioning/SET_COMPANY_SCHEDULE_VERSION',
  LOAD_PUBLISHED_VERSIONS: 'pages/company/schedule-versioning/LOAD_PUBLISHED_VERSIONS',
  SET_UNPUBLISHED_ENTRIES: 'pages/company/schedule-versioning/SET_UNPUBLISHED_ENTRIES',
};

export default actions;

export interface SetStateAction extends GenericAction {
  payload: Partial<VersioningState>;
}

export const setState = (payload: Partial<VersioningState>): SetStateAction => ({
  type: actions.SET_STATE,
  payload,
});

export interface SwitchToPreferredVersionAction extends GenericAction {
  payload: {
    version: PreferredVersion;
  };
}

export interface PublishVersionAction extends GenericAction {
  payload: {
    version: Partial<PublishedVersion>;
    notifyCrewMembers?: string[];
  };
}

export const publishVersion = (
  version: PublishVersionAction['payload']['version'],
  notifyCrewMembers: PublishVersionAction['payload']['notifyCrewMembers'],
): PublishVersionAction => ({
  type: actions.PUBLISH_VERSION,
  payload: {
    version,
    notifyCrewMembers,
  },
});

export interface AddPublishedVersionAction extends GenericAction {
  payload: { publishedVersions: VersioningState['publishedVersions'] };
}

const addPublishedVersion = (
  publishedVersions: VersioningState['publishedVersions'],
): AddPublishedVersionAction => ({
  type: actions.ADD_PUBLISHED_VERSION,
  payload: { publishedVersions },
});

export interface SetRecentlyPublishedEntriesAction extends GenericAction {
  payload: {
    entries: VersioningState['recentlyPublishedEntries'];
  };
}

const setRecentlyPublishedEntries = (
  entries: VersioningState['recentlyPublishedEntries'],
): SetRecentlyPublishedEntriesAction => ({
  type: actions.SET_RECENTLY_PUBLISHED_ENTRIES,
  payload: { entries },
});

export interface SetCompanyScheduleVersionAction extends GenericAction {
  payload: {
    companyScheduleVersion: VersioningState['companyScheduleVersion'];
  };
}

const setCompanyScheduleVersion = (
  companyScheduleVersion: VersioningState['companyScheduleVersion'],
): SetCompanyScheduleVersionAction => {
  return {
    type: actions.SET_COMPANY_SCHEDULE_VERSION,
    payload: { companyScheduleVersion },
  };
};

export interface LoadPublishedVersionsAction extends GenericAction {
  payload?: {
    pagination?: {
      limit: number;
      page: number;
    };
    range?: [string, string];
  };
}

const loadPublishedVersions = (
  payload: LoadPublishedVersionsAction['payload'],
): LoadPublishedVersionsAction => ({
  type: actions.LOAD_PUBLISHED_VERSIONS,
  payload,
});

export interface SetUnpublishedEntriesAction extends GenericAction {
  payload: {
    entries: VersioningState['unpublishedEntries'];
  };
}

const setUnpublishedEntries = (
  entries: VersioningState['unpublishedEntries'],
): SetUnpublishedEntriesAction => ({
  type: actions.SET_UNPUBLISHED_ENTRIES,
  payload: { entries },
});

export interface SwitchToPreferredVersionAction extends GenericAction {
  payload: {
    version: PreferredVersion;
  };
}

const switchToPreferredVersion = (
  version: SwitchToPreferredVersionAction['payload']['version'],
) => ({
  type: actions.SWITCH_TO_PREFERRED_VERSION,
  payload: {
    version,
  },
});

export const versioningActions = {
  setState,
  publishVersion,
  addPublishedVersion,
  setRecentlyPublishedEntries,
  setCompanyScheduleVersion,
  loadPublishedVersions,
  setUnpublishedEntries,
  switchToPreferredVersion,
};
