import {
  DutyInfoFormFields,
  FlightInfoFormFields,
  FlightTimeEntry,
  ModalMode,
  ValidationError,
} from '../interfaces';
import { DutyTimeEntry } from '../../scheduleEntries/types/DutyTimeEntry';
import MODAL_MODE from '../../../../../pages/data/common/constants';

export const actions = {
  SET_STATE: 'crew-scheduling-v3/DutyTimeEntryModal/SET_STATE',
  SET_FORM_FIELDS_DEFAULT_VALUES:
    'crew-scheduling-v3/DutyTimeEntryModal/SET_FORM_FIELDS_DEFAULT_VALUES',

  OPEN_MODAL: 'crew-scheduling-v3/DutyTimeEntryModal/OPEN_MODAL',
  CLOSE_MODAL: 'crew-scheduling-v3/DutyTimeEntryModal/CLOSE_MODAL',

  RUN_VALIDATION: 'crew-scheduling-v3/DutyTimeEntryModal/RUN_VALIDATION',
  RUN_VALIDATION_DEB: 'crew-scheduling-v3/DutyTimeEntryModal/RUN_VALIDATION_DEB',

  SET_IS_SPLIT_DUTY: 'crew-scheduling-v3/DutyTimeEntryModal/SET_IS_SPLIT_DUTY',
  SET_IS_ON_CALL_DUTY: 'crew-scheduling-v3/DutyTimeEntryModal/SET_IS_ON_CALL_DUTY',
  SET_MANAGEMENT_OVERRIDE: 'crew-scheduling-v3/DutyTimeEntryModal/SET_MANAGEMENT_OVERRIDE',
  SET_IS_FLYING_DUTY: 'crew-scheduling-v3/DutyTimeEntryModal/SET_IS_FLYING_DUTY',
  SET_IS_ON_RESERVE_DUTY: 'crew-scheduling-v3/DutyTimeEntryModal/SET_IS_ON_RESERVE_DUTY',
  SET_IS_POSITIONING_AFTER_MAX_FDP:
    'crew-scheduling-v3/DutyTimeEntryModal/SET_IS_POSITIONING_AFTER_MAX_FDP',
  SET_IS_UNFORESEEN_EXTENSION: 'crew-scheduling-v3/DutyTimeEntryModal/SET_IS_UNFORESEEN_EXTENSION',

  SET_ACCLIMATIZED_TIMEZONE_TAG:
    'crew-scheduling-v3/DutyTimeEntryModal/SET_ACCLIMATIZED_TIMEZONE_TAG',
  SET_IS_VALIDATION_RUNNING: 'crew-scheduling-v3/DutyTimeEntryModal/SET_IS_VALIDATION_RUNNING',
  SET_VALIDATION_STATE: 'crew-scheduling-v3/DutyTimeEntryModal/SET_VALIDATION_STATE',
  SET_MAX_DUTY_TIME: 'crew-scheduling-v3/DutyTimeEntryModal/SET_MAX_DUTY_TIME',
  SET_MAX_FDP: 'crew-scheduling-v3/DutyTimeEntryModal/SET_MAX_FDP',
  SET_REST_AFTER: 'crew-scheduling-v3/DutyTimeEntryModal/SET_REST_AFTER',
  SET_REST_BEFORE: 'crew-scheduling-v3/DutyTimeEntryModal/SET_REST_BEFORE',
  SET_VALIDATION_SUGGESTIONS: 'crew-scheduling-v3/DutyTimeEntryModal/SET_VALIDATION_SUGGESTIONS',

  SET_DUTY_INFO_FORM_FIELDS: 'crew-scheduling-v3/DutyTimeEntryModal/SET_DUTY_INFO_FORM_FIELDS',
  UPDATE_DUTY_INFO_FORM_FIELDS:
    'crew-scheduling-v3/DutyTimeEntryModal/UPDATE_DUTY_INFO_FORM_FIELDS',
  SET_FLIGHT_INFO_FORM_FIELDS: 'crew-scheduling-v3/DutyTimeEntryModal/SET_FLIGHT_INFO_FORM_FIELDS',
  UPDATE_FLIGHT_INFO_FORM_FIELDS:
    'crew-scheduling-v3/DutyTimeEntryModal/UPDATE_FLIGHT_INFO_FORM_FIELDS',

  SET_CURRENT_DUTY_TIME_ENTRY: 'crew-scheduling-v3/DutyTimeEntryModal/SET_CURRENT_DUTY_TIME_ENTRY',
  SET_FLIGHT_TIME_ENTRIES: 'crew-scheduling-v3/DutyTimeEntryModal/SET_FLIGHT_TIME_ENTRIES',
  RESET_FLIGHT_TIME_ENTRIES: 'crew-scheduling-v3/DutyTimeEntryModal/RESET_FLIGHT_TIME_ENTRIES',
  SET_IS_DETAILED_FLIGHT_TIMES_FORM_VALID:
    'crew-scheduling-v3/DutyTimeEntryModal/SET_IS_DETAILED_FLIGHT_TIMES_FORM_VALID',
  RESET_DETAILED_FLIGHT_VALIDATION_STATUS:
    'crew-scheduling-v3/DutyTimeEntryModal/RESET_DETAILED_FLIGHT_VALIDATION_STATUS',
};

export const runValidationAction = () => ({
  type: actions.RUN_VALIDATION,
});

export const runValidationDebounce = () => ({
  type: actions.RUN_VALIDATION_DEB,
});

export const setIsSplitDutyAction = (isSplitDuty: boolean) => ({
  type: actions.SET_IS_SPLIT_DUTY,
  payload: { isSplitDuty },
});

export const setIsOnCallDutyAction = (isOnCallDuty: boolean) => ({
  type: actions.SET_IS_ON_CALL_DUTY,
  payload: { isOnCallDuty },
});

export const setManagementOverrideAction = (managementOverride: boolean) => ({
  type: actions.SET_MANAGEMENT_OVERRIDE,
  payload: { managementOverride },
});

export const setIsFlyingDutyAction = (isFlyingDuty: boolean) => ({
  type: actions.SET_IS_FLYING_DUTY,
  payload: { isFlyingDuty },
});

export const setIsOnReserveDutyAction = (isOnReserveDuty: boolean) => ({
  type: actions.SET_IS_ON_RESERVE_DUTY,
  payload: { isOnReserveDuty },
});

export const setIsPositioningAfterMaxFDPAction = (isPositioningAfterMaxFDP: boolean) => ({
  type: actions.SET_IS_POSITIONING_AFTER_MAX_FDP,
  payload: { isPositioningAfterMaxFDP },
});

export const setIsUnforeseenExtensionAction = (isUnforeseenExtension: boolean) => ({
  type: actions.SET_IS_UNFORESEEN_EXTENSION,
  payload: { isUnforeseenExtension },
});

export const setAcclimatizedTimezoneTagAction = (acclimatizedTimezoneTag: string) => {
  return {
    type: actions.SET_ACCLIMATIZED_TIMEZONE_TAG,
    payload: { acclimatizedTimezoneTag },
  };
};

export const setIsValidationRunningAction = (isRunning: boolean) => ({
  type: actions.SET_IS_VALIDATION_RUNNING,
  payload: isRunning,
});

export const setValidationStateAction = (validationState: any) => ({
  type: actions.SET_VALIDATION_STATE,
  payload: validationState,
});

export const setMaxDutyTimeAction = (maxDutyTime: string) => ({
  type: actions.SET_MAX_DUTY_TIME,
  payload: { maxDutyTime },
});

export const setMaxFDPAction = (maxFDP: string) => ({
  type: actions.SET_MAX_FDP,
  payload: { maxFDP },
});

export const setRestAfterAction = (restAfter: string) => ({
  type: actions.SET_REST_AFTER,
  payload: { restAfter },
});

export const setRestBeforeAction = (restBefore: string) => ({
  type: actions.SET_REST_BEFORE,
  payload: { restBefore },
});

export const setValidationSuggestionsAction = (suggestions: ValidationError[]) => ({
  type: actions.SET_VALIDATION_SUGGESTIONS,
  payload: { validationSuggestions: suggestions },
});

export const openModalAction = (mode: MODAL_MODE) => ({
  type: actions.OPEN_MODAL,
  payload: { mode },
});

export const closeModalAction = () => ({
  type: actions.CLOSE_MODAL,
});

type FormFieldsDefaults = {
  isSplitDuty: boolean;
  isOnCallDuty: boolean;
  isFlyingDuty: boolean;
  isOnReserveDuty: boolean;
  isPositioningAfterMaxFDP: boolean;
  isUnforeseenExtension: boolean;
};

export const setFormFieldsDefaults = (defaults: FormFieldsDefaults) => {
  return {
    type: actions.SET_FORM_FIELDS_DEFAULT_VALUES,
    payload: defaults,
  };
};

export const setDutyInfoFormFieldsAction = (dutyInfoFormFields: DutyInfoFormFields) => {
  return {
    type: actions.SET_DUTY_INFO_FORM_FIELDS,
    payload: dutyInfoFormFields,
  };
};

export const updateDutyInfoFormFieldsAction = (dutyInfoFormFields: DutyInfoFormFields) => {
  return {
    type: actions.UPDATE_DUTY_INFO_FORM_FIELDS,
    payload: dutyInfoFormFields,
  };
};

export const setFlightInfoFormFieldsAction = (flightInfoFormFields: FlightInfoFormFields) => {
  return {
    type: actions.SET_FLIGHT_INFO_FORM_FIELDS,
    payload: flightInfoFormFields,
  };
};

export const updateFlightInfoFormFieldsAction = (flightInfoFormFields: FlightInfoFormFields) => {
  return {
    type: actions.UPDATE_FLIGHT_INFO_FORM_FIELDS,
    payload: flightInfoFormFields,
  };
};

export const setCurrentDutyTimeEntry = (dutyTimeEntry: DutyTimeEntry) => ({
  type: actions.SET_CURRENT_DUTY_TIME_ENTRY,
  payload: dutyTimeEntry,
});

export const setFlightTimeByAircraftType = (
  aircraftType: string,
  flightTimeEntries: FlightTimeEntry[],
) => {
  return {
    type: actions.SET_FLIGHT_TIME_ENTRIES,
    payload: { aircraftType, flightTimeEntries },
  };
};

export const setIsDetailedFlightTimesFormValid = (aircraftType, isValid: boolean) => {
  return {
    type: actions.SET_IS_DETAILED_FLIGHT_TIMES_FORM_VALID,
    payload: { aircraftType, isValid },
  };
};

export const resetDetailedFlightValidationStatus = () => ({
  type: actions.RESET_DETAILED_FLIGHT_VALIDATION_STATUS,
});

export const resetFlightTimeEntries = () => ({
  type: actions.RESET_FLIGHT_TIME_ENTRIES,
});

const actionCreators = {
  openModalAction,
  closeModalAction,
  setCurrentDutyTimeEntry,
};

export default actionCreators;
