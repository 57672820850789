import { selectCompanyID, selectCompanySettings, selectUser } from '../../redux/user/selectors';
import { select } from 'redux-saga/effects';
import { getUserRole } from '../../redux/selectors';

export function* selectCompanySettingsSaga(): Generator<
  any,
  ReturnType<typeof selectCompanySettings>,
  ReturnType<typeof selectCompanySettings>
> {
  return yield select(selectCompanySettings);
}

export function* selectCompanyIDSaga(): Generator<
  any,
  ReturnType<typeof selectCompanyID>,
  ReturnType<typeof selectCompanyID>
> {
  return yield select(selectCompanyID);
}

export function* selectUserSaga(): Generator<
  any,
  ReturnType<typeof selectUser>,
  ReturnType<typeof selectUser>
> {
  return yield select(selectUser);
}

export function* selectUserRoleSaga(): Generator<
  any,
  ReturnType<typeof getUserRole>,
  ReturnType<typeof getUserRole>
> {
  return yield select(getUserRole);
}
