import React from 'react';
import { Modal } from 'antd';
import * as Sentry from '@sentry/react';

const { error } = Modal;

export const showErrorModal = (title: string, errMsg: string | Error) => {
  Sentry.captureMessage(`${title} ${errMsg}`);
  const errorMessage = errMsg instanceof Error ? errMsg.message : (errMsg as string);
  error({
    title: title || 'Action error',
    content: <>{errorMessage || 'If this issue persists, contact support.'}</>,
    okText: 'Reload page',
    onOk: async () => {
      window.location.reload();
    },
  });
};
