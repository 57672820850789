import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { fetchEntry } from '../../timelineEntries/fetchEntry';
import { CrewTimelineEntryType } from '../../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { DutyTimelineEntry } from '../../../../../../../../common/types/timeline/DutyTimelineEntry';
import { put, select } from 'redux-saga/effects';
import { scheduleActions } from '../../../actions';
import { selectDutyTimeTimelineEntriesSaga } from '../../../selectors-saga';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';

export function* handleDutyTimeEntryUpdate(
  action: PusherReduxAction<PusherActionType.DUTY_TIME_ENTRY_UPDATED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  try {
    const { payload } = action;

    const dutyTimeEntry = (yield fetchEntry(
      CrewTimelineEntryType.DutyTimes,
      payload.ID,
      true,
    )) as DutyTimelineEntry;

    const exitedEntries = yield* selectDutyTimeTimelineEntriesSaga();

    const updatedEntries = exitedEntries.map(dutyScheduleTimelineEntry => {
      if (dutyScheduleTimelineEntry.ID === dutyTimeEntry.ID) {
        return dutyTimeEntry;
      }
      return dutyScheduleTimelineEntry;
    });

    yield put(scheduleActions.setState({ dutyTimeTimelineEntries: updatedEntries }));
  } finally {
  }
}
