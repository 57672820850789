import { CombinedEntry } from '../../../../../../../common/types/CombinedEntry';
import { composeKey } from '../../../helpers';
import { editFields, resetValidationMsg, setValidationMsg } from '../../actions';
import moment from 'moment-timezone';
import { put } from 'redux-saga/effects';
import { Actions } from '../../../../../../../common/types/permissions';
import * as dateMath from 'date-arithmetic';

export function* checkFDP(
  entry: CombinedEntry,
  editValues: any,
  mostRecentDutyEntry: CombinedEntry | null,
  allowedActions: Actions,
) {
  const composedKey = composeKey(editFields.DutyFrom, editFields.FDPTo);

  const dutyFrom =
    editValues[editFields.DutyFrom] !== undefined
      ? moment(editValues[editFields.DutyFrom])
      : entry.StartTime && moment(entry.StartTime);

  const dutyTo =
    editValues[editFields.DutyTo] !== undefined
      ? moment(editValues[editFields.DutyTo])
      : entry.EndTime && moment(entry.EndTime);

  const FDPEndTime =
    editValues[editFields.FDPTo] !== undefined
      ? editValues[editFields.FDPTo]
      : entry.FlightDutyPeriodEndTime && moment(entry.FlightDutyPeriodEndTime);

  const isNonFlyingDuty =
    editValues[editFields.NonFlyingDuty] !== undefined
      ? editValues[editFields.NonFlyingDuty]
      : entry.NonFlyingDuty;
  if (!isNonFlyingDuty) {
    if (dutyTo && FDPEndTime) {
      if (dateMath.gt(FDPEndTime.toDate(), dutyTo.toDate(), 'minutes')) {
        yield put(
          setValidationMsg(composeKey(composedKey), 'Flight duty end must be before duty end'),
        );
      } else if (dateMath.lte(FDPEndTime.toDate(), dutyFrom.toDate(), 'minutes')) {
        yield put(
          setValidationMsg(composeKey(composedKey), 'Flight duty end must be after duty start'),
        );
      } else {
        yield put(resetValidationMsg(composeKey(composedKey)));
      }
    }
  } else {
    yield put(resetValidationMsg(composeKey(composedKey)));
  }
}
