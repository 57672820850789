import { call, put, select } from 'redux-saga/effects';
import api from '../../../services/api';
import actions from '../actions';
import { Aircraft, AircraftMinimal } from '../../../common/types/aircraft';
import { getCompanyUser } from '../../selectors';
import { CompanyUser } from '../../../common/types/pilot';
import { AircraftSelectorType } from '../types';

export function* loadAircraftWorker(companyID: string) {
  if (!companyID) {
    throw new Error('Failed to load aircrafts. There are missed params.');
  }

  try {
    const queryParams = { limit: -1, order: 'registration asc nulls first' };
    const {
      data: { Data },
    } = yield call(api.get, `/v1/companies/${companyID}/aircraft`, {
      params: queryParams,
    });

    const aircraftListMin: AircraftMinimal[] = Data.map((aircraft: Aircraft) => {
      return {
        ID: aircraft.ID,
        CommonName: aircraft.CommonName,
        Registration: aircraft.Registration,
        CommonDesignator: aircraft.CommonDesignator,
        CertificationType: aircraft.CertificationType,
        TailNumber: aircraft.TailNumber,
        NumberPilotsRequiredToFly: aircraft.NumberPilotsRequiredToFly,
        BaseAirportCode: aircraft.BaseAirportCode,
      };
    });

    yield put(actions.setCompanyAircraftList(aircraftListMin));

    const aircraftSelectorType = aircraftListMin.some(a => !a.TailNumber)
      ? AircraftSelectorType.Registration
      : AircraftSelectorType.TailNumber;

    yield put(actions.setAircraftSelectorType(aircraftSelectorType));

    const companyUser = (yield select(getCompanyUser)) as CompanyUser;
    const userRoles = companyUser.Roles;
    const isAircraftViewer =
      userRoles.includes('subContractor') || userRoles.includes('taggedScheduleViewer');

    if (isAircraftViewer) {
      const allowedAircraft = userRoles.includes('taggedScheduleViewer')
        ? aircraftListMin
        : aircraftListMin.filter(aircraft =>
            companyUser.AircraftTails?.includes(aircraft.TailNumber),
          );

      const allowedIdentifiers = allowedAircraft.map(aircraft => {
        if (aircraftSelectorType == AircraftSelectorType.Registration) {
          return aircraft.Registration;
        }
        return aircraft.TailNumber;
      });
      yield put(actions.setCompanyAircraftIdentifiers(allowedIdentifiers));

      const aircraftBasesData = Array.from(
        new Set(allowedAircraft.map((a: Aircraft) => a.BaseAirportCode)),
      )
        .filter(Boolean)
        .sort((a: string, b: string) => a.localeCompare(b));

      yield put(actions.setAircraftBases(aircraftBasesData));
    } else {
      yield put(
        actions.setCompanyAircraftIdentifiers(
          aircraftListMin.map(aircraft => {
            if (aircraftSelectorType === AircraftSelectorType.Registration) {
              return aircraft.Registration;
            }
            return aircraft.TailNumber;
          }),
        ),
      );
      const aircraftBasesData = Array.from(new Set(Data.map(a => a.BaseAirportCode)))
        .filter(Boolean)
        .sort((a: string, b: string) => a.localeCompare(b)) as string[];
      yield put(actions.setAircraftBases(aircraftBasesData));
    }
  } catch (err) {
    console.log('Failed to load pilots: ', err);
  }
}
