import { Issue } from '../scheduleEntries/types/DutyScheduleEntry';
import { EntryType } from '../../../../common/types/entryTypes';

export type PreferredVersion = 'current' | 'latest';

export interface Tag {
  CreatedAt: Date;
  UpdatedAt: Date;
  UpdatedBy: string;
  DeletedAt: null;
  ID: string;
  CompanyID: string;
  Name: string;
}

export interface ValidationStatus {
  isValidating: boolean;
  entryType: EntryType;
}

export interface ValidationStatusPusherUpdate {
  ID: string;
  IsChanged: boolean;
  Issues: Issue[];
}

export enum SelectedTimeline {
  Crew = 'crew',
  Aircraft = 'aircraft',
  AircraftGroup = 'aircraft_group',
}

export interface AircraftLogByFlight {
  AircraftLogID: string;
  SerialID: number;
  AircraftRegistration: string;
  UpdatedAt: Date;
}
