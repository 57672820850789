import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import moment from 'moment-timezone';
import api from '../../../../../../../../services/api';
import actions, { fileImportActions } from './actions';
import { getFileImport } from './selectors';
import { FileImportSourceVersion, FileImportState, FileImportStatus } from './reducers';
import { getCompanyID } from '../../../../../../../../redux/selectors';
import {
  loadDutyScheduleEntries,
  loadDutyTimesEntries,
} from 'redux/pages/crew-scheduling-v3/actions';

export interface FlightsWithoutDuty {
  Pilot: { FirstName: string; LastName: string };
  TimeOut: moment.Moment;
  TimeIn: moment.Moment;
}

export interface FileImportResponse {
  TotalCreatedUpdatedActualDutiesCount: number;
  TotalCreatedUpdatedPlannedDutiesCount: number;
  SuccessImportPlannedDutyAmount: number;
  UpdateActualDutyAmount: number;
  UpdatePlannedDutyAmount: number;
  FailedImportAmount: number;
  FlightsWithoutDuty: FlightsWithoutDuty[];
  NotImportedPilotsNames: string[];
}

function* uploadWorker() {
  const formData = new FormData();
  const companyID = yield select(getCompanyID);
  const { dutyTimeFile, flightInfoFile, version, plannedDutiesRefMoment } = (yield select(
    getFileImport,
  )) as FileImportState;

  if (version === null) {
    return;
  }

  yield put(fileImportActions.setIsLoading(true));

  if (version === FileImportSourceVersion.v1) {
    formData.append('crewAndDutyTimeFile', dutyTimeFile);
    formData.append('dutyInfoFile', flightInfoFile);
    formData.append('plannedDutiesRefMoment', plannedDutiesRefMoment.toISOString());
  }

  if (version === FileImportSourceVersion.v2) {
    formData.append('dutyInfoFile', dutyTimeFile);
  }

  try {
    const res = yield call(
      api.post,
      `/v1/companies/${companyID}/file/${version}/import`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    const fileImportResponse: FileImportResponse = res.data;
    const flightsWithoutDuty =
      fileImportResponse.FlightsWithoutDuty?.map(f => {
        return {
          ...f,
          TimeIn: moment(f.TimeIn),
          TimeOut: moment(f.TimeOut),
        };
      }) || [];
    if (
      fileImportResponse.TotalCreatedUpdatedActualDutiesCount > 0 ||
      fileImportResponse.TotalCreatedUpdatedPlannedDutiesCount > 0
    ) {
      yield put(
        fileImportActions.setUploadResult({
          status: FileImportStatus.VALIDATION,
          notImportedPilotsNames: fileImportResponse.NotImportedPilotsNames,
          importedActualDutiesAmount: fileImportResponse.TotalCreatedUpdatedActualDutiesCount,
          importedPlannedDutiesAmount: fileImportResponse.TotalCreatedUpdatedPlannedDutiesCount,
          flightsWithoutDuty: flightsWithoutDuty,
          successUpdateActualDutyAmount: fileImportResponse.UpdateActualDutyAmount,
          successUpdatePlannedDutyAmount: fileImportResponse.UpdatePlannedDutyAmount,
        }),
      );

      yield call(checkValidationCompletionSaga);
    }

    yield put(
      fileImportActions.setUploadResult({
        status: FileImportStatus.DONE,
        notImportedPilotsNames: fileImportResponse.NotImportedPilotsNames,
        importedActualDutiesAmount: fileImportResponse.TotalCreatedUpdatedActualDutiesCount,
        importedPlannedDutiesAmount: fileImportResponse.TotalCreatedUpdatedPlannedDutiesCount,
        flightsWithoutDuty: flightsWithoutDuty,
        successUpdateActualDutyAmount: fileImportResponse.UpdateActualDutyAmount,
        successUpdatePlannedDutyAmount: fileImportResponse.UpdatePlannedDutyAmount,
      }),
    );
  } catch (err) {
    yield put(
      fileImportActions.setUploadResult({
        status: FileImportStatus.ERROR,
        notImportedPilotsNames: [],
        importedActualDutiesAmount: 0,
      }),
    );
  }

  yield put(fileImportActions.setIsLoading(false));
}

function* checkValidationCompletionSaga() {
  while (true) {
    try {
      const companyID = yield select(getCompanyID);
      const response = yield call(api.get, `/v1/companies/${companyID}/fos/import/status`);

      let completed = response.data?.Status === 'completed';

      if (completed) {
        const entryPeriod = yield select(
          ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2.entryPeriod,
        );

        const [startTime, endTime] = entryPeriod;

        const config = { startTime, endTime, isShowLoading: true };
        yield put(loadDutyScheduleEntries(config));
        yield put(loadDutyTimesEntries(config));
        break;
      }
    } catch (error) {
      console.error('Error fetching validation status:', error);
    }
    yield delay(10000); // Wait for 10 seconds before the next poll
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.UPLOAD, uploadWorker)]);
}
