import moment from 'moment-timezone';
import api from '../../../../services/api';
import crewScheduleV2PageActions, {
  LoadAircraftScheduleEntriesAction,
  setIsAircraftScheduleUpdating,
} from '../actions';
import {call, put, select} from 'redux-saga/effects';

export function* loadAircraftScheduleWorker(action: LoadAircraftScheduleEntriesAction) {
  const { config, aircraftIDs } = action.payload;
  try {
    const {
      publishedVersions,
      preferredVersion,
      companyScheduleVersion,
      aircraftScheduleEntries,
    } = yield select(({ pages: { crewSchedulingV2 } }) => crewSchedulingV2);
    const companySettings = yield select(({ user: { Company } }) => Company.Settings);
    const companyID = yield select(({ user: { Company } }) => Company.ID);

    const { startTime, endTime } = config;
    const params: any = {};

    if (startTime) {
      params.end_time__gt = moment(startTime).format();
    }

    if (endTime) {
      params.start_time__lt = moment(endTime).format();
    }

    if (
      companySettings?.Versioning &&
      publishedVersions?.length > 0 &&
      preferredVersion &&
      preferredVersion !== 'latest' &&
      companyScheduleVersion
    ) {
      let selectedVersion = preferredVersion;
      if (preferredVersion === 'current') {
        selectedVersion = companyScheduleVersion.CurrentVersion;
      }
      const selectedPublishedVersion = publishedVersions?.find(
        pv => pv.Version === parseInt(selectedVersion, 10),
      );

      if (
        selectedPublishedVersion?.VisibleStart &&
        moment(selectedPublishedVersion?.VisibleStart).valueOf() > moment(startTime).valueOf()
      ) {
        params.end_time__gt = moment(selectedPublishedVersion?.VisibleStart).format();
      }

      if (
        selectedPublishedVersion?.VisibleEnd &&
        moment(selectedPublishedVersion?.VisibleEnd).valueOf() < moment(endTime).valueOf()
      ) {
        params.start_time__lt = moment(selectedPublishedVersion?.VisibleEnd).format();
      }
    }

    if (aircraftIDs?.length > 0) {
      params.aircraft_ids = aircraftIDs;
    }

    if (config.isShowLoading) {
      yield put(setIsAircraftScheduleUpdating(true));
    }

    const response = yield call(api.get, `/v1/companies/${companyID}/aircraft-schedule`, { params });

    // the response is a map of { aircraft id -> array of aircraft schedule entries }
    const aircraftEntriesMap = response.data.Data || {};

    const aircraftEntries = [];
    Object.keys(aircraftEntriesMap).forEach(aircraftID => {
      aircraftEntries.push(...aircraftEntriesMap[aircraftID]);
    });

    if (aircraftIDs?.length > 0) {
      // push all existing non-updated aircraft schedule entries in the array
      aircraftEntries.push(
        ...(aircraftScheduleEntries || []).filter(
          entry => !aircraftIDs?.includes(entry.AssignedAircraft?.[0]),
        ),
      );
    }

    yield put({
      type: crewScheduleV2PageActions.SET_STATE,
      payload: {
        aircraftScheduleEntries: aircraftEntries,
      },
    });
  } catch (e) {
    console.log(e);
  } finally {
    yield put(setIsAircraftScheduleUpdating(false));
  }
}
