import { select } from 'redux-saga/effects';
import { editFields } from '../../actions';
import { composeKey } from '../../../helpers';
import { getAllowedActions, getCommon, getCSMobile } from '../../selectors';
import { FeatureFlag } from '../../../../../../../utils/feature-flags/FeatureFlagsProvider';
import { buildDutyFI } from '../common';
import { AllowAction } from '../../../../../../../common/types/permissions';
import { afterTotalHoursChange } from './afterTotalHoursChange';
import { runDetailedFlightTimesCalc } from './detailedFlightTimesCalc';
import { runIsSplitDutyCalc } from './splitDuty';
import { runOnReserveDutyCalc } from './onReserveDuty';
import { runFPDCalc } from './runFPDCalc';
import { apiEntryTypeMap } from '../../../../../../../common/types/apiEntryTypes';
import { afterIFRChange } from './afterIFRChange';
import { afterCrewScheduleEntyTypeChange } from './afterCrewScheduleEntyTypeChange';

export function* runFieldsCalculationWorker(editedField: string) {
  const { editValues, entry, entryType } = yield select(getCSMobile);
  const { featureFlags, customCurrencyItemTemplates } = yield select(getCommon);

  if (
    entryType !== apiEntryTypeMap.CrewScheduleEntries &&
    entryType !== apiEntryTypeMap.AircraftScheduleEntries
  ) {
    if (featureFlags[FeatureFlag.DetailedFlightTimes]) {
      const flightInformation = buildDutyFI(
        entry,
        editValues,
        !featureFlags[FeatureFlag.DetailedFlightTimes],
        false,
        entryType,
        customCurrencyItemTemplates,
      );

      if (editedField.includes(editFields.DetailedFlightTimes)) {
        const aircraftTypeIdx = Number(editedField.split('_')[0]);
        yield runDetailedFlightTimesCalc(flightInformation, aircraftTypeIdx);
      }
    }

    if (!featureFlags[FeatureFlag.DetailedFlightTimes]) {
      const flightInformation = buildDutyFI(
        entry,
        editValues,
        !featureFlags[FeatureFlag.DetailedFlightTimes],
        false,
        entryType,
        customCurrencyItemTemplates,
      );
      const allowedActions = yield select(getAllowedActions);

      for (let idx = 0; idx < flightInformation.length; idx++) {
        const fi = flightInformation[idx];
        // editedField === composeKey(String(idx), editFields.VFRHours) || editedField === composeKey(String(idx), editFields.IFRHours)
        if (editedField === composeKey(String(idx), editFields.totalFlightHours)) {
          yield afterTotalHoursChange(
            fi,
            idx,
            allowedActions[AllowAction.allowPrioritizeIFRHours],
            entryType,
          );
        }

        if (editedField === composeKey(String(idx), editFields.IFRHours)) {
          yield afterIFRChange(fi, idx);
        }
      }
    }

    yield runIsSplitDutyCalc(editedField, editValues, entry);
    yield runOnReserveDutyCalc(editedField, editValues, entry);

    if (editedField === editFields.DutyFrom || editedField === editFields.DutyTo) {
      yield runFPDCalc(editedField, editValues, entry);
    }
  }

  if (entryType == apiEntryTypeMap.CrewScheduleEntries) {
    if (editedField === editFields.CompanyScheduleTypeID) {
      yield afterCrewScheduleEntyTypeChange(editValues, entry);
    }
  }
}
