import React from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Avatar, Button, Upload, Row, Col } from 'antd';
import Icon, { UserOutlined, UploadOutlined } from '@ant-design/icons';
import TimezoneSetting from 'pages/settings/timezone/timezoneSetting';
import styles from './ProfileMenu.module.scss';
import UserSignatureUpload from './UserSignatureUpload';
import { IDs } from '../../../constants';

@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  state = {
    currentSignatureURL: '',
  };

  logout = () => {
    const { dispatch } = this.props;
    dispatch({
      type: 'user/LOGOUT',
    });
  };

  render() {
    const { user } = this.props;
    const { currentSignatureURL } = this.state;

    const defaultFileList = [];
    if (currentSignatureURL) {
      defaultFileList.push({
        uid: '-1',
        name: '',
        status: 'done',
        url: currentSignatureURL,
        thumbUrl: currentSignatureURL,
      });
    }
    // console.log("from profile menu :", user);
    const menu = (
      <Menu selectable={false}>
        <div style={{ width: '300px', padding: '15px 20px' }}>
          <strong>Hello, {user.name || 'Anonymous'}</strong>
          <br />
          {user.email}
        </div>
        <Menu.Divider />
        <div style={{ width: '100%', padding: '15px 20px' }}>
          <UserSignatureUpload />

          <hr />
          <TimezoneSetting
            config={{
              editable: true,
              labelOnly: false,
            }}
          />
        </div>
        <Menu.Divider />
        <Menu.Item>
          <div id={IDs.logoutBtn} role="button" aria-hidden="true" onClick={this.logout}>
            <i className={`${styles.menuIcon} icon-exit`} />
            Logout
          </div>
        </Menu.Item>
      </Menu>
    );
    return (
      <Row justify="end" style={{ width: '100%' }}>
        <Col span={12}>
          <TimezoneSetting
            config={{
              editable: false,
              labelOnly: true,
            }}
          />
        </Col>

        <Col>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className={styles.dropdown}>
              <Avatar
                id={IDs.userIconBtn}
                className={styles.avatar}
                shape="square"
                size="large"
                icon={<UserOutlined />}
              />
            </div>
          </Dropdown>
        </Col>
      </Row>
    );
  }
}

export default ProfileMenu;
