import { ApiTypes } from 'models';

export enum Types {
  GET_FLIGHT_TIMES_REQUEST = 'pages/flight-times/GET_FLIGHT_TIMES_REQUEST',
  GET_FLIGHT_TIMES_SUCCESS = 'pages/flight-times/GET_FLIGHT_TIMES_SUCCESS',
  GET_FLIGHT_TIMES_ERROR = 'pages/flight-times/GET_FLIGHT_TIMES_ERROR',

  SET_CURRENT_PAGE_NUMBER = 'pages/flight-times/SET_CURRENT_PAGE_NUMBER',
  SET_CURRENT_PAGE_SIZE = 'pages/flight-times/SET_CURRENT_PAGE_SIZE',
  SET_TOTAL_COUNT = 'pages/flight-times/SET_TOTAL_COUNT',
}

const getFlightTimesRequest = (payload: ApiTypes.FlightTimes.TableDataRequest) => ({
  type: Types.GET_FLIGHT_TIMES_REQUEST,
  payload,
});

const getFlightTimesSuccess = (payload: ApiTypes.FlightTimes.Data[]) => ({
  type: Types.GET_FLIGHT_TIMES_SUCCESS,
  payload,
});

const setCurrentPageNumber = (payload: number) => ({
  type: Types.SET_CURRENT_PAGE_NUMBER,
  payload,
});

const setCurrentPageSize = (payload: number) => ({
  type: Types.SET_CURRENT_PAGE_SIZE,
  payload,
});

const setTotalCount = (payload: number) => ({
  type: Types.SET_TOTAL_COUNT,
  payload,
});

export default {
  getFlightTimesRequest,
  getFlightTimesSuccess,
  setCurrentPageNumber,
  setCurrentPageSize,
  setTotalCount,
};
