import moment from 'moment';
import actions from './actions';

const initialState = {
  aircraftReportLoading: false,
  crewAircraftTypeReportLoading: false,
  pilotFlightTimeReportLoading: false,
  crewFlightAndDutyReportLoading: false,
  crewDutyAndRestLoading: false,
  updateReportMetadata: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
