import { Moment } from 'moment-timezone';

function validateReserveOverlap(
  onReserveDuty: boolean,
  dutyStartTime: Moment | null,
  dutyEndTime: Moment | null,
  startTime: Moment | null,
  endTime: Moment | null,
  overlapErrorMessage: string,
  coveringErrorMessage: string,
): Promise<void> {
  if (onReserveDuty && dutyStartTime && dutyEndTime && startTime && endTime) {
    const isOverlap = !(endTime.isBefore(dutyStartTime) || startTime.isAfter(dutyEndTime));
    const isDutyCompletelyCoveringReserve =
      (dutyStartTime.isBefore(startTime) || dutyStartTime.isSame(startTime)) &&
      (dutyEndTime.isAfter(endTime) || dutyEndTime.isSame(endTime));
    if (!isOverlap) {
      return Promise.reject(new Error(overlapErrorMessage));
    }
    if (isDutyCompletelyCoveringReserve) {
      return Promise.reject(new Error(coveringErrorMessage));
    }
  }
  return Promise.resolve();
}

export default validateReserveOverlap;
