import { put } from 'redux-saga/effects';
import { JumpToDateAction, scheduleActions } from '../../actions';
import { TimelineView } from '../../../types/timeline';
import moment from 'moment-timezone';
import { MomentStartOf } from '../../../../../../../common/types/moment';
import { selectCurrentViewSaga } from '../../selectors-saga';
import { TimelinePeriod } from '../../../types/TimelinePeriod';

export function* handleJumpToDate(action: JumpToDateAction) {
  const { jumpToDate } = action.payload;
  const currentView = yield* selectCurrentViewSaga();
  const from = jumpToDate.clone().startOf('day');

  const endOfMap: Record<TimelineView, MomentStartOf> = {
    [TimelineView.Day]: 'day',
    [TimelineView.Week]: 'week',
    [TimelineView.Month]: 'month',
    [TimelineView.ThreeMonths]: 'month', // Default for three months, handled separately
    [TimelineView.Year]: 'year',
  };

  let to: moment.Moment;
  if (currentView === TimelineView.ThreeMonths) {
    to = from
      .clone()
      .add(3, 'months')
      .endOf('month');
  } else {
    to = from.clone().add(1, endOfMap[currentView] || 'month');
  }

  const newTimePeriod = new TimelinePeriod(from, to);
  yield put(scheduleActions.setSwitchedTimelinePeriod(newTimePeriod));
}
