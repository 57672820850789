import actions from './actions';
import { AircraftGroup, GroupSchedule } from '../types';
import { AircraftGroupTimelineItem } from '../../Timeline/GroupScheduleTimeline/types';

export interface AircraftGroupScheduleState {
  isModalOpen: boolean;
  isLoading: boolean;
  aircraftGroupScheduleList: GroupSchedule[];
  editedEntry: GroupSchedule | null;
  aircraftGroupList: AircraftGroup[];
  isAircraftGroupLoading: boolean;
  isCrewUpdateEntryRequest: boolean;
  timelineItems: AircraftGroupTimelineItem[];
}

export const initialState: AircraftGroupScheduleState = {
  isModalOpen: false,
  isLoading: false,
  aircraftGroupScheduleList: [],
  editedEntry: null,
  aircraftGroupList: [],
  isAircraftGroupLoading: false,
  isCrewUpdateEntryRequest: false,
  timelineItems: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_MODAL_OPEN:
    case actions.SET_GROUP_SCHEDULE_LIST:
    case actions.SET_GROUP:
    case actions.SET_TIMELINE_ITEMS:
      return {
        ...state,
        ...action.payload,
      };
    case actions.OPEN_MODAL_FOR_NEW_ENTRY:
      return {
        ...state,
        isModalOpen: true,
        editedEntry: {
          StartTime: action.payload.start,
          EndTime: action.payload.end,
          GroupID: action.payload.groupID,
        },
      };
    case actions.SET_EDITED_ENTRY:
      const entryID = action.payload.editedEntryID;
      if (!entryID) {
        return {
          ...state,
          editedEntry: null,
          selectedAircraftGroup: null,
          isModalOpen: false,
        };
      }
      const entry = state.aircraftGroupScheduleList.find(item => item.ID === entryID);
      return {
        ...state,
        editedEntry: entry,
        selectedAircraftGroup: entry.GroupID,
        isModalOpen: true,
      };
    default:
      return state;
  }
}
