import { put, select } from 'redux-saga/effects';
import LocalCache from '../../../../../../local-storage/local-storage';
import { getCommon } from '../../../../../../../redux/selectors';
import { CommonInitState } from '../../../../../../../redux/common/reducers';
import { AircraftSelectorType } from '../../../../../../../redux/common/types';
import { scheduleActions } from '../../actions';
import { selectUserSaga } from '../../../../../../../common/saga/selectors';

export function* setSelectedAircraftIdentifiersFromCache() {
  const user = yield* selectUserSaga();
  const localCache = new LocalCache('crewSchedulingV2', user);
  const { aircraftIdentifiers, aircraftList, aircraftSelectorType } = (yield select(
    getCommon,
  )) as CommonInitState;
  const allAllowedAircraft = aircraftList.filter(aircraft => {
    if (aircraftSelectorType === AircraftSelectorType.Registration) {
      return aircraftIdentifiers.includes(aircraft.Registration);
    }
    return aircraftIdentifiers.includes(aircraft.TailNumber);
  });

  const cachedIdentifiers = localCache.getCached('selectedAircraftIdentifiers', []);

  const selectedAircraftIdentifiers = cachedIdentifiers.filter(identifier => {
    if (aircraftSelectorType === AircraftSelectorType.Registration) {
      return allAllowedAircraft.some(aircraft => aircraft.Registration === identifier);
    }
    return allAllowedAircraft.some(aircraft => aircraft.TailNumber === identifier);
  });

  if (selectedAircraftIdentifiers.length === 0) {
    if (cachedIdentifiers.length > 0) {
      yield localCache.setCached('selectedAircraftIdentifiers', []);
      yield localCache.setCached('selectedAircraftTypes', []);
      yield localCache.setCached('selectedAircraftBases', []);
    }
    yield put(
      scheduleActions.setState({
        selectedAircraftIdentifiers: [],
        // selectedAircraft: allAllowedAircraft,
      }),
    );
  } else {
    yield put(
      scheduleActions.setState({
        selectedAircraftIdentifiers: selectedAircraftIdentifiers,
        // selectedAircraft: allAllowedAircraft.filter(aircraft => {
        //   if (aircraftSelectorType === AircraftSelectorType.Registration) {
        //     return selectedAircraftIdentifiers.includes(aircraft.Registration);
        //   }
        //   return selectedAircraftIdentifiers.includes(aircraft.TailNumber);
        // }),
      }),
    );
  }
}
