import { call, put, select } from 'redux-saga/effects';
import api from '../../../../../../services/api';
import { LoadAircraftLogsForSelector, setState } from '../actions';

export function* loadAircraftLogs(action: LoadAircraftLogsForSelector) {
  const { logIDs } = action.payload;
  if (logIDs.length === 0) {
    return;
  }

  try {
    const companyID = yield select(({ user: { Company } }) => Company.ID);

    const {
      data: { Data },
    } = yield call(api.get, `/v1/companies/${companyID}/logs/minimal`, {
      params: {
        log_id_list: logIDs,
      },
    });

    yield put(setState({ relatedLogs: Data || [] }));
  } catch (err) {
    console.log('Failed to load logs by IDs', err);
  }
}
