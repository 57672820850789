import { CrewTimelineItem } from '../../../components/Timeline/CrewTimeline/generator';
import { AircraftTimelineItem } from '../../../components/Timeline/AircraftTimeline/generator';
import { ContractTimelineItem } from '../../../components/Timeline/ContractTimeline/generator';
import { TimelineType } from '../../../types/timeline';
import { ContractTimelineEntryType } from '../../../../../../../common/types/timeline/ContractTimelineEntryType';
import { put } from 'redux-saga/effects';
import { scheduleActions } from '../../actions';
import { VirtualAircraftContractModalForm } from '../../../components/types';
import moment from 'moment-timezone';
import MODAL_MODE from '../../../../../common/constants';
import { deserializeAircraftScheduleData } from '../../../components/Modals/BulkScheduleModal/Base/helpers';
import {
  fetchAircraftScheduleEntry,
  fetchGroupedCrewScheduleEntry,
} from '../timelineEntries/fetchEntry';
import { AircraftSchedule } from '../../../../../../../common/types/aircraftSchedule';
import { bulkModalEntryActions } from '../../../components/Modals/BulkScheduleModal/redux-saga/actions';
import { BulkScheduleGroupedByField } from '../../../components/Modals/BulkScheduleModal/Base/types';
import { MenuItemType } from '../../../types/MenuItemTypes';

export function* handleUnassignEntry(
  item: CrewTimelineItem | AircraftTimelineItem | ContractTimelineItem,
  menuItemType: MenuItemType,
) {
  if (item.entryType === ContractTimelineEntryType.AircraftContract) {
    let aircraftIDs = [];
    if (item?.aircraftIDs) {
      aircraftIDs = item.aircraftIDs;
    } else if (item?.aircraftID) {
      aircraftIDs = [item.aircraftID];
    }
    let aircraftContractIDs = [];
    if (item?.aircraftContractIDs) {
      aircraftContractIDs = item.aircraftContractIDs;
    } else if (item?.aircraftContractID) {
      aircraftContractIDs = [item.aircraftContractID];
    }

    const form: VirtualAircraftContractModalForm = {
      aircraftContractIDs,
      aircraftIDs,
      clientContractID: null,
      startDate: moment(item.start_time),
      endDate: moment(item.end_time),
    };
    yield put(scheduleActions.saveVirtualAircraftContractEntry(form, MODAL_MODE.update));
  } else if (item.entryType === ContractTimelineEntryType.PilotSchedule) {
    const entry = (yield fetchGroupedCrewScheduleEntry(
      item.entryType,
      item,
      false,
    )) as AircraftSchedule;

    const cleanData = deserializeAircraftScheduleData(entry, null);
    if (menuItemType === MenuItemType.UnassignAircraft) {
      cleanData.Shifts.forEach(shift => {
        shift.AssignedAircraftID = null;
      });
    } else if (menuItemType === MenuItemType.UnassignContract) {
      cleanData.Shifts.forEach(shift => {
        shift.ClientContractID = null;
      });
    }

    yield put(
      bulkModalEntryActions.saveVirtualCrewScheduleContractEntry(
        cleanData,
        MODAL_MODE.update,
        BulkScheduleGroupedByField.Aircraft,
      ),
    );
  }
}
