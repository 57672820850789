import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { PilotStatusState } from './reducers';
import { PilotStatus } from '../types/pilotStatus';

const actions = {
  SET_STATE: 'pages/company/pilot-status/SET_STATE',
  LOAD_PILOT_STATUS_LIST: 'pages/company/pilot-status/LOAD_PILOT_STATUS_LIST',
  SET_PILOT_STATUS_LIST: 'pages/company/pilot-status/SET_PILOT_STATUS_LIST',
  PUSHER_PILOT_STATUS_LIST_UPDATED: 'pages/company/pilot-status/PUSHER_PILOT_STATUS_LIST_UPDATED',
  HANDLE_TABLE_CHANGE: 'pages/company/pilot-status/HANDLE_TABLE_CHANGE',
  UPDATE_PILOT_STATUS_NOTE: 'pages/company/pilot-status/UPDATE_PILOT_STATUS_NOTE',
};

export default actions;

export type OnTableChange = {
  type: typeof actions.HANDLE_TABLE_CHANGE;
  payload: {
    pagination: TablePaginationConfig;
    sorter: SorterResult<any>;
  };
};

export type SetPilotStatusList = {
  type: typeof actions.SET_PILOT_STATUS_LIST;
  payload: {
    list: PilotStatus[];
  };
};

export type UpdatePilotStatusNote = {
  type: typeof actions.UPDATE_PILOT_STATUS_NOTE;
  payload: {
    UserID: string;
    Note: string;
  };
};

export const setPilotStatusList = (list: PilotStatus[]): SetPilotStatusList => {
  return {
    type: actions.SET_PILOT_STATUS_LIST,
    payload: {
      list,
    },
  };
};

export const loadPilotStatusList = () => {
  return {
    type: actions.LOAD_PILOT_STATUS_LIST,
  };
};

export const onTableChange = (
  pagination: TablePaginationConfig,
  sorter: SorterResult<any>,
): OnTableChange => {
  return {
    type: actions.HANDLE_TABLE_CHANGE,
    payload: {
      pagination,
      sorter,
    },
  };
};

export const setState = (payload: Partial<PilotStatusState>) => {
  return {
    type: actions.SET_STATE,
    payload,
  };
};

export const pusherPilotStatusListUpdated = () => {
  return {
    type: actions.PUSHER_PILOT_STATUS_LIST_UPDATED,
  };
};

export const updatePilotStatusNote = (ID: string, Note: string): UpdatePilotStatusNote => {
  return {
    type: actions.UPDATE_PILOT_STATUS_NOTE,
    payload: {
      UserID: ID,
      Note,
    },
  };
};
