import { PreferredVersion } from '../../../../../../../common/types/preferredVersoon';

export function isIgnoreUpdate(
  currentVersion: PreferredVersion,
  updateSandboxID: string | null,
): boolean {
  const isSandbox = currentVersion.startsWith('sandbox');
  if (isSandbox && !currentVersion.includes(updateSandboxID)) {
    return true;
  }
  if (!isSandbox && updateSandboxID) {
    return true;
  }
  return false;
}
