import { all, put, takeEvery, call, debounce } from 'redux-saga/effects';
import actions, { OnTableChange, setState } from './actions';
import { loadPilotStatusListWorker } from './workers/loadPilotStatusListWorker';
import { updatePilotStatusNoteWorker } from './workers/updatePilotStatusNoteWorker';

function* onTableChangeWorker(action: OnTableChange) {
  const { pagination, sorter } = action.payload;
  yield put(
    setState({
      pagination,
      sorter,
    }),
  );
  yield call(loadPilotStatusListWorker);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PILOT_STATUS_LIST, loadPilotStatusListWorker),
    takeEvery(actions.HANDLE_TABLE_CHANGE, onTableChangeWorker),
    takeEvery(actions.PUSHER_PILOT_STATUS_LIST_UPDATED, loadPilotStatusListWorker),
    debounce(1500, actions.UPDATE_PILOT_STATUS_NOTE, updatePilotStatusNoteWorker),
  ]);
}
