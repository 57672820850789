import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'redux/common/actions';
import { getNotifire } from '../../redux/selectors';
import { CommonTypes } from 'models';

const PublicLayout = withRouter(props => {
  const dispatch = useDispatch();
  const notifire = useSelector(getNotifire);

  const renderNotifire = (data: CommonTypes.Notifire) => {
    notification[data.type]({
      message: data.message,
      description: data?.description,
      onClose: () => dispatch(Actions.clearNotifire()),
    });
  };

  useEffect(() => {
    if (notifire) {
      renderNotifire(notifire);
    }
  }, [notifire]);

  return (
    <>
      {/* @ts-ignore */}
      {props.children}
    </>
  );
});

export default PublicLayout;
