import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions, { LoadAircraftLogsForAircraftAction, aircraftLogSelectorAction } from './actions';
import api from '../../../../services/api';
import { getAircraftLogSelector } from './selectors';

function* loadAircraftLogsForAircraft(action: LoadAircraftLogsForAircraftAction) {
  const { aircraftID } = action.payload;
  const { logsByAircraftID } = yield select(getAircraftLogSelector);

  try {
    if (!!logsByAircraftID[aircraftID]) {
      return;
    }
    yield put(
      aircraftLogSelectorAction.setState({
        isLoading: true,
      }),
    );

    const {
      data: { Data },
    } = yield call(api.get, `/v1/aircraft/${aircraftID}/logs/minimal?limit=1`, {
      params: {
        limit: 1,
      },
    });
    const updatedLogsByAircraftID = {
      ...logsByAircraftID,
      [aircraftID]: Data,
    };

    yield put(
      aircraftLogSelectorAction.setState({
        logsByAircraftID: updatedLogsByAircraftID,
      }),
    );
  } catch (err) {
    console.log('Failed to load aircraft logs for aircraft', err);
  } finally {
    yield put(
      aircraftLogSelectorAction.setState({
        isLoading: false,
      }),
    );
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_AIRCRAFT_LOGS_FOR_AIRCRAFT, loadAircraftLogsForAircraft)]);
}
