import { put, select } from 'redux-saga/effects';
import { scheduleActions } from '../../actions';

export function* setPreferredVersionWorker() {
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);
  const companyUsers = yield select(({ user: { CompanyUsers } }) => CompanyUsers);

  if (companySettings.Versioning) {
    const version = companyUsers[0]?.PreferredVersion;
    yield put(scheduleActions.setPreferredVersion(version));
  } else {
    yield put(scheduleActions.setPreferredVersion('current'));
  }
}
