import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { FlightWatch } from '../types/flightWatch';
import actions from './actions';

export interface FlightWatchState {
  isLoadingList: boolean;
  flightWatchList: FlightWatch[];
  pagination: TablePaginationConfig;
  sorter: SorterResult<any>;
  flightWatchListLoaded: boolean;
  isAddUpdateModalOpen: boolean;
  isDetailsModalOpen: boolean;
  editableFlightWatch: FlightWatch;
  flightWatchDetails: FlightWatch;
}

export const initialState: FlightWatchState = {
  isLoadingList: false,
  flightWatchList: [],
  pagination: {
    total: 0,
    current: 1,
    pageSize: 50,
  },
  sorter: {},
  flightWatchListLoaded: false,
  isAddUpdateModalOpen: false,
  isDetailsModalOpen: false,
  editableFlightWatch: null,
  flightWatchDetails: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.ADD_FLIGHT_WATCH:
    case actions.UPDATE_FLIGHT_WATCH:
    case actions.LOAD_FLIGHT_WATCH_LIST:
      return { ...state, ...action.payload };
    case actions.SET_FLIGHT_WATCH_LIST:
      return { ...state, flightWatchList: action.payload.list, flightWatchListLoaded: true };
    default:
      return state;
  }
}
