import _ from 'lodash';
import { put, select } from 'redux-saga/effects';
import { AircraftMinimal } from '../../../common/types/aircraft';
import actions, { SetCompanyAircraftList } from '../actions';
import { getCompanyUser } from '../../selectors';
import { CompanyUser } from '../../../common/types/pilot';

export function* initCertTypesWorker(action: SetCompanyAircraftList) {
  const { aircraftList } = action.payload;
  const companyUser = (yield select(getCompanyUser)) as CompanyUser;

  const filteredByPilotAircraftTail = aircraftList.filter(aircraft => {
    if (companyUser.AircraftTails != null) {
      return companyUser.AircraftTails.includes(aircraft.TailNumber);
    }
    return true;
  });

  const certificationTypeByCommonDesignator = getCertificationTypeByCommonDesignator(
    filteredByPilotAircraftTail,
  );
  const commonDesignatorsByCertificationType = getCommonDesignatorsByCertificationType(
    filteredByPilotAircraftTail,
  );

  let sortedCommonDesignatorsByCertificationType = _(commonDesignatorsByCertificationType)
    .toPairs()
    .sortBy(0)
    .map(pair => [pair[0], _.sortBy(pair[1])])
    .fromPairs()
    .value();

  yield put(
    actions.setState({
      isAircraftCertInitialized: true,
      certificationTypeByCommonDesignator,
      commonDesignatorsByCertificationType: sortedCommonDesignatorsByCertificationType,
    }),
  );
}

const getCertificationTypeByCommonDesignator = (aircraftList: AircraftMinimal[]) => {
  const certificationTypeByCommonDesignator: { [key: string]: string } = {};
  aircraftList.forEach(aircraft => {
    certificationTypeByCommonDesignator[aircraft.CommonDesignator] = aircraft.CertificationType;
  });
  return certificationTypeByCommonDesignator;
};

const getCommonDesignatorsByCertificationType = (aircraftList: AircraftMinimal[]) => {
  const commonDesignatorsByCertType: { [key: string]: string[] } = {};
  aircraftList.forEach(aircraft => {
    if (!commonDesignatorsByCertType[aircraft.CertificationType]) {
      commonDesignatorsByCertType[aircraft.CertificationType] = [];
    }
    const isExist = commonDesignatorsByCertType[aircraft.CertificationType].some(
      commonDesignator => commonDesignator === aircraft.CommonDesignator,
    );
    if (!isExist) {
      commonDesignatorsByCertType[aircraft.CertificationType].push(aircraft.CommonDesignator);
    }
  });
  return commonDesignatorsByCertType;
};
