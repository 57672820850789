import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { fetchEntry } from '../../timelineEntries/fetchEntry';
import { scheduleActions } from '../../../actions';
import { put, select } from 'redux-saga/effects';
import { AircraftContractTimelineEntry } from '../../../../../../../../common/types/timeline/AircraftContractTimelineEntry';
import { selectAircraftContractTimelineEntriesSaga } from '../../../selectors-saga';
import { AircraftTimelineEntryType } from '../../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
export function* handleAircraftContractEntryCreate(
  action: PusherReduxAction<PusherActionType.PILOT_CONTRACT_ENTRY_CREATED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  const { payload } = action;

  try {
    const entry = (yield fetchEntry(
      AircraftTimelineEntryType.AircraftContract,
      payload.ID,
      true,
    )) as AircraftContractTimelineEntry;

    const existedEntries = yield* selectAircraftContractTimelineEntriesSaga();
    const updatedEntries = [...existedEntries, entry];

    yield put(scheduleActions.setState({ aircraftContractTimelineEntries: updatedEntries }));
  } finally {
  }
}
