import actions from './actions';
import { AnyAction } from 'redux';
import { SaveError } from './types';
import { ModalMode } from '../../../../../../../../redux/pages/crew-scheduling-v3/modals/interfaces';
import MODAL_MODE from '../../../../../../common/constants';

export interface BulkScheduleModalState {
  isSaving: boolean;
  saveError: SaveError | null;
  contractBulkScheduleModalMode: ModalMode;
  aircraftBulkScheduleModalMode: ModalMode;
}

export const initialState: BulkScheduleModalState = {
  isSaving: false,
  saveError: null,
  contractBulkScheduleModalMode: {
    open: false,
    mode: MODAL_MODE.create,
  },
  aircraftBulkScheduleModalMode: {
    open: false,
    mode: MODAL_MODE.create,
  },
};

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_AIRCRAFT_MODAL_MODE:
    case actions.SET_CONTRACT_MODAL_MODE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
