import { DeleteAircraftGroupSchedule, reloadAirGroupSchedule } from '../actions';
import api from '../../../../../../../../services/api';
import { call, select } from 'redux-saga/effects';
import { getCompanyID } from '../../../../../../../../redux/selectors';
import { reloadCrewScheduleAfterActionWorker } from './reloadCrewScheduleAfterActionWorker';

export function* deleteGroupScheduleWorker(action: DeleteAircraftGroupSchedule) {
  const { aircraftGroupScheduleID } = action.payload;
  const companyID = yield select(getCompanyID);

  console.log(' on delete ', aircraftGroupScheduleID);

  try {
    yield call(api.delete, `/v1/companies/${companyID}/group-schedule/${aircraftGroupScheduleID}`);

    yield call(reloadCrewScheduleAfterActionWorker);
  } catch (err) {
    console.log(err);
  }
}
