import { ApiTypes } from 'models';

export enum Types {
  GET_DUTY_TIMES_REQUEST = 'pages/duty-times/GET_DUTY_TIMES_REQUEST',
  GET_DUTY_TIMES_SUCCESS = 'pages/duty-times/GET_DUTY_TIMES_SUCCESS',
  GET_DUTY_TIMES_ERROR = 'pages/duty-times/GET_DUTY_TIMES_ERROR',

  SET_CURRENT_PAGE_NUMBER = 'pages/duty-times/SET_CURRENT_PAGE_NUMBER',
  SET_CURRENT_PAGE_SIZE = 'pages/duty-times/SET_CURRENT_PAGE_SIZE',
  SET_TOTAL_COUNT = 'pages/duty-times/SET_TOTAL_COUNT',
}

const getDutyTimesRequest = (payload: ApiTypes.DutyTimes.TableDataRequest) => ({
  type: Types.GET_DUTY_TIMES_REQUEST,
  payload,
});

const getDutyTimesSuccess = (payload: ApiTypes.DutyTimes.Data[]) => ({
  type: Types.GET_DUTY_TIMES_SUCCESS,
  payload,
});

const setCurrentPageNumber = (payload: number) => ({
  type: Types.SET_CURRENT_PAGE_NUMBER,
  payload,
});

const setCurrentPageSize = (payload: number) => ({
  type: Types.SET_CURRENT_PAGE_SIZE,
  payload,
});

const setTotalCount = (payload: number) => ({
  type: Types.SET_TOTAL_COUNT,
  payload,
});

export default {
  getDutyTimesRequest,
  getDutyTimesSuccess,
  setCurrentPageNumber,
  setCurrentPageSize,
  setTotalCount,
};
