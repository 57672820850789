import moment from 'moment/moment';
import api from '../../../../../../../../services/api';
import { notification } from 'antd';
import { PublishVersionAction, versioningActions } from '../actions';
import { selectCompanyIDSaga, selectUserSaga } from '../../../../../../../../common/saga/selectors';
import { call, put } from 'redux-saga/effects';
import { fetchCompanyScheduleVersionWorker } from './fetchCompanyScheduleVersionWorker';
import {
  selectRecentlyPublishedEntriesSaga,
  selectUnpublishedEntriesSaga,
} from '../selectors-saga';
import { RecentlyPublishedEntry } from '../../../../../../../../common/types/timeline/RecentlyPublishedEntry';

export function* publishVersion(action: PublishVersionAction) {
  const companyID = yield* selectCompanyIDSaga();
  const user = yield* selectUserSaga();
  const unpublishedEntries = yield* selectUnpublishedEntriesSaga();
  const recentlyPublishedEntries = yield* selectRecentlyPublishedEntriesSaga();

  yield put(versioningActions.setState({ isSyncing: true }));

  const { version, notifyCrewMembers } = action.payload;

  const visibleStart = version?.VisibleStart
    ? moment(version.VisibleStart)
        .startOf('day')
        .tz('UTC')
        .format()
    : null;
  const visibleEnd = version?.VisibleEnd
    ? moment(version.VisibleEnd)
        .endOf('day')
        .tz('UTC')
        .format()
    : null;

  const payload = {
    PublishedBy: user?.id,
    PublishedAt: moment()
      .tz('UTC')
      .format(),
    Name: version.Name,
    Notes: version.Notes ? version.Notes : '',
    NotificationTo: notifyCrewMembers,
    VisibleStart: visibleStart,
    VisibleEnd: visibleEnd,
  };

  try {
    const {
      data: { Data },
    } = yield call(api.post, `/v1/companies/${companyID}/publish`, payload);

    // add new publishments to array
    yield put(versioningActions.addPublishedVersion(Data));

    // fetch schedule version for company
    yield call(fetchCompanyScheduleVersionWorker);

    const publishDateTime = moment()
      .tz('UTC')
      .format();

    const newRecentlyPublishedEntries: RecentlyPublishedEntry[] = [];
    for (const entryID of unpublishedEntries) {
      const existingEntry = recentlyPublishedEntries.find(entry => entry.ID === entryID);
      if (!!existingEntry) {
        newRecentlyPublishedEntries.push({
          ...existingEntry,
          publishedAt: publishDateTime,
        });
      } else {
        newRecentlyPublishedEntries.push({ ID: entryID, publishedAt: publishDateTime });
      }
    }
    yield put(versioningActions.setRecentlyPublishedEntries(newRecentlyPublishedEntries));

    // TODO: update entries
    yield put(versioningActions.setState({ unpublishedEntries: [] }));

    // notification popup
    notification.success({
      message: 'Version published successfully',
      description: `${payload.Name} is published successfully`,
    });

    window.location.reload();
  } catch (err) {
    console.log(err);

    if (err.response.status === 409) {
      notification.warning({
        message: 'Unable to publish version',
        description: 'This version is already published !',
      });
    } else {
      notification.warning({
        message: 'Unable to publish version',
        description: 'Sever errors while publishing',
      });
    }
  } finally {
    yield put(versioningActions.setState({ isSyncing: false }));
  }
}
