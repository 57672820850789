import { setEntry } from '../actions';
import { put, select } from 'redux-saga/effects';
import { getCSMobile } from '../selectors';
import { CombinedEntry } from '../../../../../../common/types/CombinedEntry';

export function* watchOnRestoreAircraftInformation() {
  const { entry, originalFlightInformation } = yield select(getCSMobile);
  const updatedEntry: CombinedEntry = { ...entry };

  updatedEntry.AircraftInformation.FlightInformation = [...originalFlightInformation];

  yield put(setEntry(updatedEntry));
}
