import { call, put, select } from 'redux-saga/effects';
import api from '../../../../../../../../services/api';
import { setGroupScheduleList, setState, setTimelineItems } from '../actions';
import { Config } from '../../../../legacy/utils/Config';
import { buildItems } from '../../../Timeline/GroupScheduleTimeline/helper';
import { getUserRole } from '../../../../../../../../redux/selectors';

export function* loadGroupScheduleWorker(config: Config) {
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  const userRoles = yield select(getUserRole) || [];
  if (!userRoles.includes('admin')) {
    return;
  }
  try {
    yield put(setState({ isLoading: true }));

    const {
      data: { Data },
    } = yield call(api.get, `/v1/companies/${companyID}/group-schedule`);

    yield put(setGroupScheduleList(Data));
    yield put(setTimelineItems(buildItems(Data)));
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setState({ isLoading: false }));
  }
}
