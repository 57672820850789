import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions, { OnEditTemplateAction, openTemplateModal, setEditingTemplate } from './actions';
import { saveTemplateWorker } from './workers/saveTemplateWorker';
import { loadTemplatesWorker } from './workers/loadTemplatesWorker';
import { getCustomForms } from './selectors';
import { CustomFormsState } from './reducers';
import { deleteTemplateWorker } from './workers/deleteTemplateWorker';
import { loadSubmissionsWorker } from './workers/loadSubmissionsWorker';
import { openSubmissionPreviewWorker } from './workers/openSubmissionPreviewWorker';
import { onSubmissionTableChangeWorker } from './workers/onSubmissionTableChangeWorker';
import { handleSubmissionUpdate } from './workers/pusher/handleSubmissionUpdate';

export function* handleEditTemplate(action: OnEditTemplateAction) {
  const { templateID } = action.payload;
  const { templateList } = (yield select(getCustomForms)) as CustomFormsState;
  const template = templateList.find(t => t.ID === templateID);
  yield put(setEditingTemplate(template));
  yield put(openTemplateModal());
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SAVE_TEMPLATE, saveTemplateWorker),
    takeEvery(actions.LOAD_TEMPLATES, loadTemplatesWorker),
    takeEvery(actions.ON_EDIT_TEMPLATE, handleEditTemplate),
    takeEvery(actions.ON_DELETE_TEMPLATE, deleteTemplateWorker),
    takeEvery(actions.LOAD_SUBMISSIONS, loadSubmissionsWorker),
    takeEvery(actions.OPEN_SUBMISSION_PREVIEW, openSubmissionPreviewWorker),
    takeEvery(actions.HANDLE_TABLE_CHANGE, onSubmissionTableChangeWorker),
    takeEvery(actions.PUSHER_SUBMISSION_CREATED_OR_UPDATED, handleSubmissionUpdate),
  ]);
}
