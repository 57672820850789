import { editFields, setEditValue } from '../../actions';
import moment from 'moment-timezone';
import { put } from 'redux-saga/effects';
import { CombinedEntry } from '../../../../../../../common/types/CombinedEntry';

export function* runIsSplitDutyCalc(editedField: string, editValues: any, entry: CombinedEntry) {
  if (editedField === editFields.IsSplitDuty && editValues[editFields.IsSplitDuty]) {
    const startTime =
      editValues[editFields.DutyFrom] !== undefined
        ? editValues[editFields.DutyFrom]
        : entry.StartTime && moment(entry.StartTime);
    yield put(setEditValue(editFields.RestStart, startTime));
    yield put(setEditValue(editFields.RestEnd, startTime));
  }
}
