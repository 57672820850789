import { DutyFiCommon } from '../../types/entries';
import { put } from 'redux-saga/effects';
import { editFields, setEditValue } from '../../actions';
import { composeKey } from '../../../helpers';
import { EntryType } from '../../../../../../../common/types/entryTypes';
import { apiEntryTypeMap } from '../../../../../../../common/types/apiEntryTypes';
import { round } from 'lodash';

export function* afterIFRChange(fi: DutyFiCommon, idx: number) {
  if (fi.IFRHours > fi.FlightTime) {
    yield put(
      setEditValue(composeKey(String(idx), editFields.totalFlightHours), round(fi.IFRHours, 1)),
    );
  } else {
    yield put(
      setEditValue(
        composeKey(String(idx), editFields.VFRHours),
        round(fi.FlightTime - fi.IFRHours, 1),
      ),
    );
  }
}
