import api from '../../../../../../../../services/api';
import { notification } from 'antd';
import { SwitchToPreferredVersionAction, versioningActions } from '../actions';
import { all, call, put } from 'redux-saga/effects';
import { selectCompanyIDSaga, selectUserSaga } from '../../../../../../../../common/saga/selectors';
import { fetchCompanyScheduleVersionWorker } from './fetchCompanyScheduleVersionWorker';

export function* switchToPreferredVersion(action: SwitchToPreferredVersionAction) {
  const { version } = action.payload;
  const companyID = yield* selectCompanyIDSaga();
  const user = yield* selectUserSaga();
  const ver = String(version);

  yield put(versioningActions.setState({ isVersionSwitching: true }));

  const payload = {
    PreferredVersion: ver,
  };

  try {
    const {
      data: { Data },
    } = yield call(api.patch, `/v1/companies/${companyID}/users/${user.id}`, payload);
    console.log(`Version updated to ${ver}`);

    if (ver.startsWith('sandbox')) {
      notification.success({
        message: `Version updated to sandbox`,
      });
    } else {
      notification.success({
        message: `Version updated to ${ver}`,
      });
    }

    yield put(
      versioningActions.setState({
        preferredVersion: ver,
        previousPreferredVersion: ver,
      }),
    );

    // TODO: Reload duty schedule and crew schedule entries
    // TODO: why       type: userActions.LOAD_CURRENT_ACCOUNT,?

    yield all([
      yield call(fetchCompanyScheduleVersionWorker),
      yield put(versioningActions.loadPublishedVersions({})),
    ]);

    // TODO: we just reload a page, but it's not a good practice
    window.location.reload();
  } catch (error) {
    console.log('Unable to Update version', error);
    notification.warning({
      message: 'Unable to Update version',
    });
  } finally {
    yield put(versioningActions.setState({ isVersionSwitching: false }));
  }
}
