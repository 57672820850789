import { CrewScheduleMobileState } from './reducers';
import { CommonInitState } from '../../../../../redux/common/reducers';
import { Actions } from '../../../../../common/types/permissions';

export const getCSMobile = ({ pages: { crewSchedulingMobile } }) =>
  crewSchedulingMobile as CrewScheduleMobileState;

export const getCommon = ({ common }) => common as CommonInitState;

export const getUserRole = ({ user: { CompanyUsers = [] } }) =>
  CompanyUsers?.[0]?.Roles as string[];
export const getAllowedActions = ({ pages: { crewSchedulingMobile } }) =>
  crewSchedulingMobile.permissionList.actions as Actions;

export const getRelatedPilotNames = ({ pages: { crewSchedulingMobile } }) =>
  crewSchedulingMobile.relatedPilotNames as string[];
