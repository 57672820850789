import { scheduleActions, SwitchToViewAction } from '../../actions';
import { TimelineView } from '../../../types/timeline';
import { put } from 'redux-saga/effects';
import { selectVisibleTimelinePeriodSaga } from '../../selectors-saga';
import { selectUserSaga } from '../../../../../../../common/saga/selectors';
import LocalCache from '../../../../../../local-storage/local-storage';
import { TimelinePeriod } from '../../../types/TimelinePeriod';

export function* switchToViewWorker(action: SwitchToViewAction) {
  const { currentView } = action.payload;
  const user = yield* selectUserSaga();
  const localCache = new LocalCache('crewSchedulingV2', user);
  localCache.setCached('currentView', currentView);

  const timePeriod = yield* selectVisibleTimelinePeriodSaga();
  let updatedTimePeriod = timePeriod;

  switch (currentView) {
    case TimelineView.Day:
      const startOfDay = timePeriod.getStart().startOf('day');
      const endOfDay = startOfDay.clone().endOf('day');
      updatedTimePeriod = new TimelinePeriod(startOfDay, endOfDay);
      break;
    case TimelineView.Week:
      const startOfWeek = timePeriod.getStart().startOf('week');
      const endOfWeek = startOfWeek.clone().endOf('week');
      updatedTimePeriod = new TimelinePeriod(startOfWeek, endOfWeek);
      break;
    case TimelineView.Month:
      const startOfMonth = timePeriod.getStart().startOf('month');
      const endOfMonth = startOfMonth.clone().endOf('month');
      updatedTimePeriod = new TimelinePeriod(startOfMonth, endOfMonth);
      break;
    case TimelineView.ThreeMonths:
      const startOfThreeMonths = timePeriod.getStart().startOf('month');
      const endOfThreeMonths = startOfThreeMonths.clone().add(3, 'month');
      updatedTimePeriod = new TimelinePeriod(startOfThreeMonths, endOfThreeMonths);
      break;
    case TimelineView.Year:
      const startOfYear = timePeriod.getStart().startOf('year');
      const endOfYear = startOfYear.clone().endOf('year');
      updatedTimePeriod = new TimelinePeriod(startOfYear, endOfYear);
      break;
    default:
      updatedTimePeriod = timePeriod;
  }

  yield put(scheduleActions.setSwitchedTimelinePeriod(updatedTimePeriod));
}
