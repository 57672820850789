import { all, call, put, select, takeEvery, debounce } from 'redux-saga/effects';
import api from '../../../../../services/api';
import {
  actions,
  setAcclimatizedTimezoneTagAction,
  setIsValidationRunningAction,
  setMaxDutyTimeAction,
  setMaxFDPAction,
  setRestAfterAction,
  setRestBeforeAction,
  setValidationStateAction,
  setValidationSuggestionsAction,
} from './actions';
import { getCompanyID, getCrewSchedulingV2Page } from '../../../../selectors';
import { serializeEntry } from './utils';
import getTimeString from '../../../../../pages/data/organization/crew-scheduling-v3/legacy/utils/getTimeString';
import { RunValidationPayload } from '../interfaces';
import { VALIDATION_DEB_MS } from '../../constants';
import { DutyScheduleEntry } from '../../scheduleEntries/types/DutyScheduleEntry';

/*
 todo: when we close the modal, setValidationSuggestions to {}
 */
function* runValidationSaga({
  payload: { dutyFields, flightFields },
  type: string,
}: {
  payload: RunValidationPayload;
  type: string;
}) {
  const companyID = yield select(getCompanyID);
  const {
    isFlyingDuty,
    onCallDuty,
    onReserveDuty,
    positioningAfterMaxFDP,
    managementOverride,
  } = yield select(({ pages: { dutyScheduleModal } }) => dutyScheduleModal);

  const { currentDutyScheduleEntry } = yield select(getCrewSchedulingV2Page);
  const dutyInfo: DutyScheduleEntry = currentDutyScheduleEntry;

  const [startDate, endDate] = dutyFields.DutyRange?.filter(m => m) ?? [null, null];
  const [rapStartDate, rapEndDate] = dutyFields.RAPRange?.filter(m => m) ?? [null, null];

  const isRangeValid = startDate && endDate && startDate.isValid() && endDate.isValid();
  const isDutyRangeEmpty = !startDate && !endDate;
  const isRAPRangeValid =
    rapStartDate && rapEndDate && rapStartDate.isValid() && rapEndDate.isValid();

  if (
    dutyFields.Pilot &&
    (onReserveDuty ? isRAPRangeValid && (isRangeValid || isDutyRangeEmpty) : isRangeValid) &&
    dutyFields?.SubParts?.length > 0
  ) {
    yield put(setIsValidationRunningAction(true));

    try {
      const response = yield call(
        api.post,
        `/v1/users/${dutyFields.Pilot}/duty-schedule/validate/v2`,
        serializeEntry(
          dutyInfo.ID,
          companyID,
          dutyFields,
          flightFields,
          isFlyingDuty,
          onCallDuty,
          onReserveDuty,
          positioningAfterMaxFDP,
          managementOverride,
          dutyInfo.Version,
        ),
      );

      const {
        data: {
          Data: {
            ComplianceSummary: { Issues, ValidationState },
          },
        },
      } = response;

      if (!!ValidationState) {
        const validationState = JSON.parse(ValidationState);
        yield put(setValidationStateAction(validationState));
        const restBeforeString =
          validationState.RestBeforeDutyTime < 24 * 365
            ? getTimeString(validationState.RestBeforeDutyTime * 60)
            : 'N/A';

        const restAfterString =
          validationState.RestAfterDutyTime < 24 * 365
            ? getTimeString(validationState.RestAfterDutyTime * 60)
            : 'N/A';

        const maxDutyTimeString = getTimeString(validationState.MaxDutyDuration * 60);
        if (validationState.ModalMaxFDP > 0) {
          const maxFDPString = getTimeString(validationState.ModalMaxFDP * 60);
          yield put(setMaxFDPAction(maxFDPString));
        } else {
          yield put(setMaxFDPAction('N/A'));
        }
        yield put(setMaxDutyTimeAction(maxDutyTimeString));
        yield put(setRestBeforeAction(restBeforeString));
        yield put(setRestAfterAction(restAfterString));
        yield put(setAcclimatizedTimezoneTagAction(validationState.AcclimatizedTimezone));
      }

      yield put(setValidationSuggestionsAction(Issues || []));
      yield put(setIsValidationRunningAction(false));
    } catch (err) {
      console.log('Request failed:', err);
      yield put(
        setValidationSuggestionsAction([
          {
            Severity: 'error',
            Message: 'Duty entry invalid. Please correct all errors and try again.',
          },
        ]),
      );
      yield put(setIsValidationRunningAction(false));
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.RUN_VALIDATION, runValidationSaga),
    debounce(VALIDATION_DEB_MS, actions.RUN_VALIDATION_DEB, runValidationSaga),
  ]);
}
