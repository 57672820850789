import { call, put } from 'redux-saga/effects';
import api from '../../../../../../services/api';
import { setAllRelations, setState } from '../actions';

export function* loadFilesRelationsWorker() {
  try {
    const {
      data: { Data },
    } = yield call(api.get, `/v1/company-library/relations`);

    if (Data !== null) {
      yield put(setAllRelations(Data));
    }
    yield put(setState({ allRelationsLoaded: true }));
  } catch (err) {
    console.log('Failed to load company library file list', err);
  }
}
