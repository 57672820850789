import { put } from 'redux-saga/effects';
import { editFields, setEditValue } from '../../actions';
import { CombinedEntry } from '../../../../../../../common/types/CombinedEntry';
import moment from 'moment-timezone';

export function* runFPDCalc(editedField: string, editValues: any, entry: CombinedEntry) {
  const startTime =
    editValues[editFields.DutyFrom] !== undefined
      ? editValues[editFields.DutyFrom]
      : entry.StartTime && moment(entry.StartTime);

  const endTime =
    editValues[editFields.DutyTo] !== undefined
      ? editValues[editFields.DutyTo]
      : entry.EndTime && moment(entry.EndTime);

  const FDPEndTime =
    editValues[editFields.FDPTo] !== undefined
      ? editValues[editFields.FDPTo]
      : entry.FlightDutyPeriodEndTime && moment(entry.FlightDutyPeriodEndTime);

  if (startTime && endTime && !FDPEndTime) {
    yield put(setEditValue(editFields.FDPTo, endTime));
  }
}
