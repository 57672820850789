import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { scheduleActions } from '../../../actions';
import { put, select } from 'redux-saga/effects';
import { selectPilotContractTimelineEntriesSaga } from '../../../selectors-saga';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';

export function* handlePilotContractEntryDelete(
  action: PusherReduxAction<PusherActionType.PILOT_CONTRACT_ENTRY_DELETED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  try {
    const { payload } = action;

    const existedEntries = yield* selectPilotContractTimelineEntriesSaga();
    const updatedEntries = existedEntries.filter(e => {
      return e.ID !== payload.ID;
    });

    yield put(scheduleActions.setState({ pilotContractTimelineEntries: updatedEntries }));
  } finally {
  }
}
