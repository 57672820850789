import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { put, select } from 'redux-saga/effects';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import {
  selectDutyScheduleTimelineEntriesSaga,
  selectPreferredVersionSaga,
} from '../../../selectors-saga';
import { isIgnoreUpdate } from '../helpers';
import { scheduleActions } from '../../../actions';
import { selectUserSaga } from '../../../../../../../../common/saga/selectors';

export function* handleDutyScheduleEntryDelete(
  action: PusherReduxAction<PusherActionType.DUTY_SCHEDULE_ENTRY_DELETED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }
  const { payload } = action;
  const { CrewScheduleSandboxID, UpdatedBy, ID } = payload;

  const user = yield* selectUserSaga();
  if (user.id === UpdatedBy) {
    return;
  }

  try {
    const preferredVersion = yield* selectPreferredVersionSaga();
    if (isIgnoreUpdate(preferredVersion, CrewScheduleSandboxID)) {
      return;
    }

    const exitedEntries = yield* selectDutyScheduleTimelineEntriesSaga();
    const updatedDutyScheduleTimelineEntries = exitedEntries.filter(dutyScheduleTimelineEntry => {
      if (dutyScheduleTimelineEntry.ID === ID) {
        return false;
      }
      return true;
    });

    yield put(
      scheduleActions.setState({ dutyScheduleTimelineEntries: updatedDutyScheduleTimelineEntries }),
    );
  } finally {
  }
}
