import { call, put } from 'redux-saga/effects';
import api from '../../../../../../../../services/api';
import { selectCompanyIDSaga } from '../../../../../../../../common/saga/selectors';
import { scheduleActions } from '../../../actions';

export function* deleteRelatedAircraftContracts(aircraftContractIDs: string[]) {
  const companyID = yield* selectCompanyIDSaga();
  yield put(scheduleActions.setTimelineLoading(true));

  for (const entryID of aircraftContractIDs) {
    try {
      yield call(api.delete, `/v1/companies/${companyID}/aircraftcontract/delete/${entryID}`);
    } catch (err) {
      console.error(err);
    }
  }

  yield put(scheduleActions.setTimelineLoading(false));
}
