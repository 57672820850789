import { all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { handleSaveCSGroupedEntry } from './workers/handleSaveCSGroupedEntry';
import { handleDeleteCSGroupedEntry } from './workers/handleDeleteCSGroupedEntry';

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SAVE_GROUPED_CS_ENTRY, handleSaveCSGroupedEntry),
    takeEvery(actions.DELETE_GROUPED_CS_ENTRY, handleDeleteCSGroupedEntry),
  ]);
}
