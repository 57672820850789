import { call } from 'redux-saga/effects';
import { UpdatePilotStatusNote } from '../actions';
import api from '../../../../../../services/api';

export function* updatePilotStatusNoteWorker(action: UpdatePilotStatusNote) {
  try {
    const { UserID, Note } = action.payload;
    const payload = {
      Note,
    };
    yield call(api.patch, `/v1/pilot-status/${UserID}/note`, payload);
  } catch (err) {
    console.log('Failed to update pilot status notes', err);
  }
}
