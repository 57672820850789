import { DutyFiCommon } from '../../types/entries';
import { composeKey } from '../../../helpers';
import { editFields, resetValidationMsg, setValidationMsg } from '../../actions';
import { put } from 'redux-saga/effects';

export function* checkVHRandIFR(fi: DutyFiCommon, idx: number, allowPrioritizeIFRHours: boolean) {
  const IFRHours = fi.IFRHours;
  const VFRHours = fi.VFRHours;

  if (allowPrioritizeIFRHours) {
    const msg = 'FR Hours cannot be greater than Total Hours';
    const key = composeKey(String(idx), editFields.VFRHours);
    if (VFRHours > fi.FlightTime) {
      yield put(setValidationMsg(key, msg));
    } else {
      resetValidationMsg(key);
    }
  } else {
    const msg = 'IFR Hours cannot be greater than Total Hours';
    const key = composeKey(String(idx), editFields.IFRHours);
    if (IFRHours > fi.FlightTime) {
      // todo: maybe TotalFlightHours
      yield put(setValidationMsg(key, msg));
    } else {
      resetValidationMsg(key);
    }
  }
}
