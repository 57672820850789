import { CombinedEntry } from '../../../../../../../common/types/CombinedEntry';
import { composeKey } from '../../../helpers';
import { editFields, resetValidationMsg, setValidationMsg } from '../../actions';
import moment from 'moment-timezone';
import { put, call } from 'redux-saga/effects';
import { Actions, AllowAction } from '../../../../../../../common/types/permissions';
import { isEntryOngoing } from '../../../components/EntryDetails/sections/helpers/ongoing';
import validateReserveOverlap from '../../../../../../../pages/data/common/helpers/validateReserveOverlap';

export function* checkDutyFromTo(
  entry: CombinedEntry,
  editValues: any,
  mostRecentDutyEntry: CombinedEntry | null,
  allowedActions: Actions,
  isNewEntry: boolean,
  entryType: string,
  reserveOnlyEntry: boolean,
) {
  const composedKey = composeKey(editFields.DutyFrom, editFields.DutyTo);
  const dutyFrom =
    editValues[editFields.DutyFrom] !== undefined
      ? moment(editValues[editFields.DutyFrom])
      : entry.StartTime && moment(entry.StartTime);
  const dutyTo =
    editValues[editFields.DutyTo] !== undefined
      ? moment(editValues[editFields.DutyTo])
      : entry.EndTime && moment(entry.EndTime);
  const isAllowCreateDutyTimeBeforeMostRecent =
    allowedActions[AllowAction.createDutyTimeBeforeMostRecent];

  const isOngoing = isEntryOngoing(entryType, entry, editValues, isNewEntry);

  const isOnReserve =
    editValues[editFields.IsOnReserve] !== undefined
      ? editValues[editFields.IsOnReserve]
      : !!(entry.RAPStartTime && entry.RAPStartTime);

  if (dutyFrom && !dutyTo) {
    if (isOngoing || isOnReserve) {
      yield put(resetValidationMsg(composeKey(composedKey)));
    } else if (isNewEntry) {
      yield put(setValidationMsg(composeKey(composedKey), 'Please enter a duty end time'));
    } else {
      yield put(setValidationMsg(composeKey(composedKey), ''));
    }
  }

  if (dutyFrom && dutyFrom.isValid() && dutyTo && dutyTo.isValid()) {
    let isDenyToCreateEntryBeforeMostRecent = false;

    if (mostRecentDutyEntry && !isAllowCreateDutyTimeBeforeMostRecent) {
      const minDate = mostRecentDutyEntry.EndTime || mostRecentDutyEntry.StartTime;

      if (dutyFrom.isBefore(minDate) || dutyTo.isBefore(minDate)) {
        isDenyToCreateEntryBeforeMostRecent = true;
      }
    }

    if (isDenyToCreateEntryBeforeMostRecent) {
      yield put(
        setValidationMsg(
          composeKey(composedKey),
          'Cannot create a duty time before an already existing duty time',
        ),
      );
    } else if (!isOnReserve && !dutyTo.isAfter(dutyFrom)) {
      yield put(setValidationMsg(composeKey(composedKey), 'Duty end must be after duty start'));
    } else {
      try {
        const rapDutyStartTime = entry.RAPStartTime ? moment(entry.RAPStartTime) : null;
        const rapDutyEndTime = entry.RAPEndTime ? moment(entry.RAPEndTime) : null;

        yield call(
          validateReserveOverlap,
          isOnReserve,
          rapDutyStartTime,
          rapDutyEndTime,
          dutyFrom,
          dutyTo,
          'Reserve times must overlap with duty times',
          'Duty period cannot contain entire reserve period',
        );

        yield put(resetValidationMsg(composeKey(composedKey)));
      } catch (error) {
        yield put(setValidationMsg(composeKey(composedKey), error.message));
      }
    }
  } else {
    if (!reserveOnlyEntry) {
      yield put(setValidationMsg(composeKey(composedKey), 'A duty start and end time is required'));
    }
  }
}
