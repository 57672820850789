import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd';
import ProfileMenu from './ProfileMenu';
import Breadcrumbs from './Breadcrumbs';
import './style.scss';

const mapStateToProps = ({ settings }) => ({
  isMenuCollapsed: settings.isMenuCollapsed,
});

@connect(mapStateToProps)
class TopBar extends React.Component {
  render() {
    const { isMenuCollapsed } = this.props;
    const changingClassName = `topbar${isMenuCollapsed ? ' collapsed-top-bar' : ''}`;
    return (
      <div className={changingClassName}>
        <Row justify="start">
          <Breadcrumbs />
        </Row>
        <Row justify="end">
          <ProfileMenu />
        </Row>
      </div>
    );
  }
}

export default TopBar;
