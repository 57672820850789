import { AircraftLogSelectorState } from './reducers';

const actions = {
  SET_STATE: 'components/aircraft_log_selector/SET_STATE',
  LOAD_AIRCRAFT_LOGS_FOR_AIRCRAFT:
    'components/aircraft_log_selector/LOAD_AIRCRAFT_LOGS_FOR_AIRCRAFT',
};

export default actions;

export type LoadAircraftLogsForAircraftAction = {
  type: typeof actions.LOAD_AIRCRAFT_LOGS_FOR_AIRCRAFT;
  payload: {
    aircraftID: string;
  };
};

const loadAircraftLogsForAircraft = (aircraftID: string): LoadAircraftLogsForAircraftAction => ({
  type: actions.LOAD_AIRCRAFT_LOGS_FOR_AIRCRAFT,
  payload: {
    aircraftID,
  },
});

const setState = (payload: Partial<AircraftLogSelectorState>) => ({
  type: actions.SET_STATE,
  payload,
});

export const aircraftLogSelectorAction = {
  setState,
  loadAircraftLogsForAircraft,
};
