import { call, put, select } from 'redux-saga/effects';
import api from '../../../../../../../../services/api';
import { setGroupList, setGroupScheduleList, setState } from '../actions';

export function* loadGroupWorker() {
  const companyID = yield select(({ user: { Company } }) => Company.ID);

  try {
    yield put(setState({ isAircraftGroupLoading: true }));

    const {
      data: { Data },
    } = yield call(api.get, `/v1/companies/${companyID}/aircraft-group`);

    yield put(setGroupList(Data));
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setState({ isAircraftGroupLoading: false }));
  }
}
