import { call, put, select } from 'redux-saga/effects';
import { setPilotStatusList, setState } from '../actions';
import api from '../../../../../../services/api';
import { TablePaginationConfig } from 'antd';
import { getPilotStatus } from '../selectors';

export function* loadPilotStatusListWorker() {
  try {
    yield put(setState({ isLoadingList: true }));
    const { pagination, sorter } = yield select(getPilotStatus);
    const queryParams = {
      limit: pagination.pageSize || -1,
      page: pagination.current || 1,
    };
    if (sorter.field && sorter.order) {
      queryParams['order'] = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }
    const {
      data: { Data, Total, Page },
    } = yield call(api.get, `/v1/pilot-status/list`, {
      params: queryParams,
    });
    yield put(setPilotStatusList(Data));
    const newPagination: TablePaginationConfig = {
      total: Total,
      current: Page,
      pageSize: pagination.pageSize,
    };
    yield put(setState({ pagination: newPagination, isLoadingList: false }));
  } catch (err) {
    console.log('Failed to load pilot status list', err);
  }
}
