import { setState, UpdateAircraftGroupScheduleAction } from '../actions';
import api from '../../../../../../../../services/api';
import { call, put, select } from 'redux-saga/effects';
import { getCompanyID } from '../../../../../../../../redux/selectors';
import { reloadCrewScheduleAfterActionWorker } from './reloadCrewScheduleAfterActionWorker';

export function* updateGroupScheduleWorker(action: UpdateAircraftGroupScheduleAction) {
  const { payload } = action;
  const companyID = yield select(getCompanyID);

  const data = payload;

  try {
    yield put(setState({ isCrewUpdateEntryRequest: true }));

    yield call(api.patch, `/v1/companies/${companyID}/group-schedule/${payload.ID}`, data);
    yield put(setState({ isModalOpen: false }));

    yield call(reloadCrewScheduleAfterActionWorker);
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setState({ isCrewUpdateEntryRequest: false }));
  }
}
