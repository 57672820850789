import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { fetchEntry } from '../../timelineEntries/fetchEntry';
import { scheduleActions } from '../../../actions';
import { put, select } from 'redux-saga/effects';
import { selectClientContractPeriodTimelineEntriesSaga } from '../../../selectors-saga';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import { ContractTimelineEntryType } from '../../../../../../../../common/types/timeline/ContractTimelineEntryType';
import { ClientContractPeriodTimeline } from '../../../../../../../../common/types/ClientContractPeriod';

export function* handleClientContractPeriodEntryUpdate(
  action: PusherReduxAction<PusherActionType.CLIENT_CONTRACT_PERIOD_CREATED_UPDATED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  const { payload } = action;

  try {
    const entry = (yield fetchEntry(
      ContractTimelineEntryType.ClientContractPeriod,
      payload.ID,
      true,
    )) as ClientContractPeriodTimeline;

    const existedEntries = yield* selectClientContractPeriodTimelineEntriesSaga();
    const thereIsExistedEntry = existedEntries.some(e => e.ID === payload.ID);
    let updatedEntries = existedEntries;
    if (!thereIsExistedEntry) {
      updatedEntries = [...existedEntries, entry];
    } else {
      updatedEntries = existedEntries.map(e => {
        if (e.ID === payload.ID) {
          return entry;
        }
        return e;
      });
    }

    yield put(scheduleActions.setState({ clientContractPeriodTimelineEntries: updatedEntries }));
  } finally {
  }
}
