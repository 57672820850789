import moment from 'moment-timezone';

export function numberOfDaysRemaining(
  dueAtDate: moment.Moment,
  referenceDate: moment.Moment = moment(),
): number {
  const dueAtEndOfDay = dueAtDate.clone().endOf('day');

  if (dueAtEndOfDay.isBefore(referenceDate)) {
    let overdueHrsDiff = referenceDate.diff(dueAtEndOfDay, 'hours');

    if (overdueHrsDiff < 24) {
      return -1;
    }

    const rest = overdueHrsDiff % 24;
    let overdueDayDiff = Math.floor(overdueHrsDiff / 24);
    if (rest > 0) {
      overdueDayDiff += 1;
    }

    return -overdueDayDiff;
  } else {
    return dueAtEndOfDay.diff(referenceDate, 'days');
  }
}

export function timeDiffCalc(
  dateFuture: string | Date,
  dateNow: string | Date,
  showHours = false,
): string {
  const futureMoment = moment(dateFuture);
  const nowMoment = moment(dateNow);

  if (!futureMoment.isValid() || !nowMoment.isValid()) {
    throw new Error('Invalid date input');
  }

  const sign = futureMoment.diff(nowMoment, 'hours') >= 0 ? '+ ' : '- ';

  // calculate days
  const days = Math.abs(futureMoment.diff(nowMoment, 'days'));

  // calculate hours
  const hours = Math.abs(futureMoment.diff(nowMoment, 'hours')) % 24;

  let difference = sign;
  if (days > 0) {
    difference += `${days} day${days === 1 ? '' : 's'}`;
    if (showHours && hours > 0) {
      difference += `, `;
    }
  }

  if (showHours && hours > 0) {
    difference += `${hours} hr${hours === 1 ? '' : 's'}`;
  }

  return difference.trim();
}

export function calculateTimeDifferenceInHoursOrDays(
  from: moment.Moment,
  to: moment.Moment,
): number {
  let timeDifference = Math.round(moment(to).diff(from, 'hour', true));
  if (timeDifference >= 24) {
    timeDifference = Math.round(timeDifference / 24);
  }
  return timeDifference;
}

export function normalizeDateSeconds(endTime: moment.Moment): moment.Moment {
  if (!endTime || !endTime.isValid()) {
    throw new Error('Invalid date input');
  }
  if (endTime.format('mm:ss') === '59:00') {
    endTime = endTime.add(59, 'seconds');
  }
  return endTime;
}
