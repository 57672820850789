import { delay, put, select } from 'redux-saga/effects';
import { getFlightWatch } from '../selectors';
import { setFlightWatchList } from '../actions';
import { getRouter } from '../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';

export function* processRemTimeWorker() {
  while (true) {
    const { location } = (yield select(getRouter)) as RouterState;
    if (location.pathname.includes('flight-watch')) {
      const { flightWatchList } = yield select(getFlightWatch);
      yield put(setFlightWatchList(flightWatchList));
    }
    yield delay(1000);
  }
}
