import { call, put, select } from 'redux-saga/effects';
import { getCompanyLibrary } from '../selectors';
import { CompanyLibraryState } from '../reducers';
import { LoadFileListAction, setState } from '../actions';
import { loadFileListWorker } from './loadFileListWorker';

export function* loadMoreWorker(action: LoadFileListAction) {
  const { pagination } = (yield select(getCompanyLibrary)) as CompanyLibraryState;

  const newPagination = {
    ...pagination,
    current: pagination.current + 1,
  };
  yield put(setState({ pagination: newPagination }));
  yield call(loadFileListWorker, action);
}
