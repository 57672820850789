const colors = {
  header: '#031f61',
  timeline: {
    cyan: '#10c8e9',
    blue: '#108ee9',
    black: '#000',
    green: '#3dbd7d',
    brightGreen: '#66ff00',
    red: '#f04134',
    orange: '#f56a00',
    darkBlue: '#0b5185',
    yellow: '#ffc722',
    selectedYellow: '#ffc721',
    darkYellow: '#b8901a',
    amber: '#ffbf00',
    grey: '#bfbfbf',
    white: '#fff',
    popover: '#000000cc',
    blueBackground: '#118ee9',
  },
};

export default colors;
