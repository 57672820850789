import { all, put, takeEvery, call, select, fork } from 'redux-saga/effects';
import actions, {
  OnTableChange,
  setState,
  StartEditFlightWatch,
  StartShowFlightWatchDetails,
} from './actions';
import { addFlightWatchWorker } from './workers/addFlightWatchWorker';
import { getFlightWatch } from './selectors';
import { updateFlightWatchWorker } from './workers/updateFlightWatchWorker';
import { loadFlightWatchListWorker } from './workers/loadFlightWatchListWorker';
import { onPusherFlightWatchCreatedWorker } from './workers/onPusherFlightWatchCreatedWorker';
import { onPusherFlightWatchUpdatedWorker } from './workers/onPusherFlightWatchUpdatedWorker';
import { processRemTimeWorker } from './workers/processRemTimeWorker';

function* startEditFlightWatchWorker(action: StartEditFlightWatch) {
  const { flightWatchID } = action.payload;
  const { flightWatchList } = yield select(getFlightWatch);
  const flightWatch = flightWatchList.find(
    (flightWatch: { ID: string }) => flightWatch.ID === flightWatchID,
  );
  yield put(
    setState({
      isAddUpdateModalOpen: true,
      editableFlightWatch: flightWatch,
    }),
  );
}

function* startShowFlightWatchDetailsWorker(action: StartShowFlightWatchDetails) {
  const { flightWatchID } = action.payload;
  const { flightWatchList } = yield select(getFlightWatch);
  const flightWatch = flightWatchList.find(
    (flightWatch: { ID: string }) => flightWatch.ID === flightWatchID,
  );
  yield put(
    setState({
      isDetailsModalOpen: true,
      flightWatchDetails: flightWatch,
    }),
  );
}

function* onTableChangeWorker(action: OnTableChange) {
  const { pagination, sorter } = action.payload;
  yield put(
    setState({
      pagination,
      sorter,
    }),
  );
  yield call(loadFlightWatchListWorker);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FLIGHT_WATCH_LIST, loadFlightWatchListWorker),
    takeEvery(actions.ADD_FLIGHT_WATCH, addFlightWatchWorker),
    takeEvery(actions.UPDATE_FLIGHT_WATCH, updateFlightWatchWorker),
    takeEvery(actions.START_EDIT_FLIGHT_WATCH, startEditFlightWatchWorker),
    takeEvery(actions.START_SHOW_FLIGHT_WATCH_DETAILS, startShowFlightWatchDetailsWorker),
    takeEvery(actions.HANDLE_TABLE_CHANGE, onTableChangeWorker),
    takeEvery(actions.PUSHER_FLIGHT_WATCH_UPDATED, onPusherFlightWatchUpdatedWorker),
    takeEvery(actions.PUSHER_FLIGHT_WATCH_CREATED, onPusherFlightWatchCreatedWorker),
    fork(processRemTimeWorker),
  ]);
}
