import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { fetchEntry } from '../../timelineEntries/fetchEntry';
import { CrewTimelineEntryType } from '../../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { scheduleActions } from '../../../actions';
import { put, select } from 'redux-saga/effects';
import { AircraftContractTimelineEntry } from '../../../../../../../../common/types/timeline/AircraftContractTimelineEntry';
import { selectPilotContractTimelineEntriesSaga } from '../../../selectors-saga';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';

export function* handlePilotContractEntryUpdate(
  action: PusherReduxAction<PusherActionType.PILOT_CONTRACT_ENTRY_CREATED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  const { payload } = action;

  try {
    const entry = (yield fetchEntry(
      CrewTimelineEntryType.PilotContract,
      payload.ID,
      true,
    )) as AircraftContractTimelineEntry;

    const existedEntries = yield* selectPilotContractTimelineEntriesSaga();
    const updatedEntries = existedEntries.map(e => {
      if (e.ID === payload.ID) {
        return entry;
      }
      return e;
    });

    yield put(scheduleActions.setState({ pilotContractTimelineEntries: updatedEntries }));
  } finally {
  }
}
