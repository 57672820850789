import { CheckOverlapsAction, setState } from '../actions';
import api from '../../../../services/api';
import { call, put, select } from 'redux-saga/effects';

export function* checkOverlapsWorker(action: CheckOverlapsAction) {
  const { entryType, entryID, from, to, userID } = action.payload;

  if (!entryType || !from || !to || !userID) {
    return;
  }

  const companyID = yield select(({ user: { Company } }) => Company.ID);
  try {
    yield put(setState({ isCheckingOverlap: true }));
    yield put(setState({ isEntryOverlapping: false }));
    yield put(setState({ isOverlapCheckActivated: true }));

    const {
      data: { Data },
    } = yield call(api.get, `/v1/companies/${companyID}/check-overlaps`, {
      params: {
        user_id: userID,
        start_time: from.format(),
        end_time: to.format(),
        entry_id: entryID,
        entry_type: entryType,
      },
    });
    yield put(setState({ isEntryOverlapping: Data }));
  } catch (err) {
    console.log('Failed to check overlaps', err);
  } finally {
    yield put(setState({ isCheckingOverlap: false }));
  }
}
