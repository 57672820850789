import { all, put, select, takeEvery } from 'redux-saga/effects';
import { PusherActionType } from '../../pusher/PusherActions';
import { getAircraftPage } from '../../selectors';
import aircraftPageActions from './actions';

function* HANDLE_AIRCRAFT_TABLE_CHANGE(action) {
  const { aircraftID, maintenanceDetails } = action.payload;
  const { tableData } = yield select(getAircraftPage);
  const filteredTableData = tableData.map(item =>
    item.ID === aircraftID
      ? {
          ...item,
          MaintenanceDetails: maintenanceDetails,
        }
      : item,
  );
  yield put({
    type: aircraftPageActions.SET_STATE,
    payload: {
      tableData: filteredTableData,
    },
  });
}

export default function* rootSaga() {
  yield all([takeEvery(PusherActionType.AIRCRAFT_TABLE_UPDATED, HANDLE_AIRCRAFT_TABLE_CHANGE)]);
}
