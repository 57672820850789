import { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { getIntegrationsUrl } from 'config';
import api from 'services/api';
import Jwt from 'services/Jwt';

class MicroserviceApi {
  private readonly baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async request(
    method: Method,
    url: string,
    body: any | undefined = undefined,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse> {
    return await api.request({
      baseURL: this.baseUrl,
      method,
      url,
      data: body,
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${Jwt.get()}`,
      },
      ...config,
    });
  }
}

export const integrationsApi = new MicroserviceApi(getIntegrationsUrl());
