import actions from './actions';

export interface NumInputState {
  showKeyboard: boolean;
  editingField: string | null;
  editingValue: string | null;
  editingInputProps: {
    decimalPlaces: number;
    isConfirmed: boolean;
  };
}

export const initialState: NumInputState = {
  showKeyboard: false,
  editingField: null,
  editingValue: null,
  editingInputProps: {
    decimalPlaces: 1,
    isConfirmed: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_EDITING_INPUT_PROPS:
    case actions.SET_EDITING_VALUE:
      return { ...state, ...action.payload };
    case actions.SET_EDITING_FIELD:
      return { ...state, ...action.payload, showKeyboard: true };
    case actions.CLOSE_NUM_KEYBOARD:
      return { ...state, showKeyboard: false, editingField: null, editingValue: null };
    default:
      return state;
  }
}
