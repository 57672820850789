import { Profile } from './user';
import { CustomCurrencyItem } from './customCurrencyItem';
import { GroupCustomSectionTemplate } from '../../pages/data/organization/crew-scheduling-v3/components/GroupSchedule/types';

export interface CombinedEntry {
  CreatedAt: string;
  UpdatedAt: string;
  UpdatedBy: string;
  DeletedAt: null;
  ID: string;
  UserID: string;
  CompanyID: string;
  StartTime: string;
  EndTime: string;
  StartAirportID: string;
  EndAirportID: string;
  RestAirportID: null;
  NonFlyingDuty: boolean;
  OnCallDuty: boolean;
  UnforeseenExtension: boolean;
  VerifiedAt: string;
  ComplianceSummary: ComplianceSummary;
  AircraftInformation: AircraftInformation;
  RAPStartTime: string;
  RAPEndTime: string;
  FDPWarningEmailSent: boolean;
  User: User;
  StartAirport: Airport;
  EndAirport: Airport;
  AssignedAircraft: string[];
  AssignedClients: string[];
  Subparts: string[];
  CompanyScheduleTypeID: string;
  CompanyScheduleType: CompanyScheduleType;
  OrignalEntryID: null;
  CustomSections: CustomSection[] | null;
  DutyType: string;
  StartTZ: string;
  EndTZ: string;
  RestStartTZ: string;
  RestEndTZ: string;
  FlightDutyPeriodEndTime: string;
  Notes: string;
  CrewScheduleSandboxID: null;
  AcclimatizedTimezone: string;
  Version: number;
  RestAirport: Airport;
  PositioningAfterMaxFDP: boolean;
  RestStart: string;
  RestEnd: string;
  RestStartTime: string;
  RestEndTime: string;
  ManagementOverride: boolean;
  ComplianceOverrideReason: string;

  // todo: for month divider
  type: string;
  month: string;
  Tag: string | null;
  IsPrivateEntry: boolean | null;
}

export interface AircraftEntry {
  StartTime: Date;
  EndTime: Date;
  StartAirportID: string;
  EndAirportID: string;
  CompanyScheduleTypeID: string;
  AssignedClients: null;
  AssignedAircraft: string[];
  UserID: string[];
  Subparts: string[];
  IsEditable: boolean;
  HasMultipleOverlaps: boolean;
  Notes: string;
  RelatedCrewScheduleEntries: CombinedEntry[];
  CompanyScheduleType: CompanyScheduleType;
  Shifts: { [key: string]: CombinedEntry[] };
  Pilots: User[];
  type?: string;
  month?: string;
}

export interface Airport {
  ID: string;
  IATACode: string;
  ICAOCode: string;
  Name: string;
  CountryName: string;
  CountryCode: string;
  FIRName: string;
  FIRCode: string;
  Region: string;
  Timezone: string;
  Latitude: number;
  Longitude: number;
  Elevation: number;
  ProcRunways: number;
  International: boolean;
  Inactive: boolean;
  Source: string;
  Public: boolean;
  CreatedAt: string;
  UpdatedAt: string;
}

export interface CompanyScheduleType {
  ID: string;
  CompanyID: string;
  ScheduleTypeID: string;
  Name: string;
  AbbreviatedName: string;
  FillColor: string;
  BorderColor: string;
  ScheduleDisplayConfiguration: ScheduleDisplayConfiguration;
  RequiredFields: RequiredFields;
  OrderRank: number;
  CustomSectionTemplate: CustomSection[] | null;
  ScheduleType: ScheduleType;
  IsDeprecated: boolean;
  Settings: {
    DefaultStartTime: Date;
    DefaultEndTime: Date;
    SpanOnDays: number;
    DefaultTotalTimeHours: number;
  };
  GroupCustomSectionTemplate: GroupCustomSectionTemplate | null;
  IsUsedInGroup: boolean;
}

export interface RequiredFields {
  [CrewScheduleRequiredFields.AssignedAircraft]: boolean;
  [CrewScheduleRequiredFields.AssignedClients]: boolean;
  [CrewScheduleRequiredFields.AssignedContract]: boolean;
  [CrewScheduleRequiredFields.Subpart]: boolean;
  [CrewScheduleRequiredFields.StartAirport]: boolean;
  [CrewScheduleRequiredFields.EndAirport]: boolean;
  [CrewScheduleRequiredFields.Tag]: boolean;
}

export enum CrewScheduleRequiredFields {
  AssignedAircraft = 'AssignedAircraft',
  AssignedClients = 'AssignedClients',
  AssignedContract = 'AssignedContract',
  Subpart = 'Subpart',
  StartAirport = 'StartAirport',
  EndAirport = 'EndAirport',
  Tag = 'Tag',
}

export interface ScheduleDisplayConfiguration {
  Aircraft: AircraftClass;
  Airport: AircraftClass;
  ScheduleType: AircraftClass;
  ScheduleTypeAbbreviated: AircraftClass;
  NumOfDays: AircraftClass;
  Customer: AircraftClass;
  Tag: AircraftClass;
  Date: AircraftClass;
  ShowPilotCertifications: boolean;
}

export interface AircraftClass {
  Display: boolean;
  Order: number;
}

export interface ScheduleType {
  ID: string;
  Action: string;
  Color: string;
  WorkingHours: boolean;
}

export interface AircraftInformation {
  FlightInformation: FlightInformation[];
}

export interface FlightInformation {
  AircraftID: string | null;
  Subparts: string[];
  AircraftType: string;
  NumberOfFlights: number;
  FlightTime: number;
  TotalFlightHours: number;
  IFRHours: number;
  VFRHours: number;
  IFRApproaches: number;
  NightTakeoffs: number;
  NightLandings: number;
  DayTakeoffs: number;
  DayLandings: number;
  FirstFlightStart?: Date;
  LastFlightEnd?: Date;
  SinglePilot?: boolean;
  AllDayVFR?: boolean;

  DetailedFlightTimes: DetailedFlightTime[];

  CustomCurrencyItems: CustomCurrencyItem[];
}

export interface DetailedFlightTime {
  Key: string;
  TimeOn: Date;
  TimeOff: Date;
  FlightTime: number;
  AirTime: number;
  IFR: boolean;
}

export interface ComplianceSummary {
  Issues: Issue[] | null;
  ValidationState: string;
  DutyTypeForIdentification: string;
}

export interface Issue {
  Severity: string;
  Code: string;
  Message: string;
  Timestamp: Date;
  FlightID: null;
  AircraftLogID: null;
}

export interface Airport {
  ID: string;
  IATACode: string;
  ICAOCode: string;
  Name: string;
  CountryName: string;
  CountryCode: string;
  FIRName: string;
  FIRCode: string;
  Region: string;
  Timezone: string;
  Latitude: number;
  Longitude: number;
  Elevation: number;
  ProcRunways: number;
  International: boolean;
  Inactive: boolean;
  Source: string;
  Public: boolean;
  CreatedAt: string;
  UpdatedAt: string;
}

export interface AircraftFlightInformation {
  NumberOfFlights?: number;
  IsSinglePilot: boolean;
  FlightTime?: number;
  IFRHours?: number;
  VFRHours?: number;
  AllDayVFR: boolean;
  SubParts: string[];
}

export interface User {
  CreatedAt: Date;
  UpdatedAt: Date;
  UpdatedBy: string;
  DeletedAt: null;
  ID: string;
  Profile: Profile;
}

export interface CustomSection {
  Title: string;
  Fields: CustomSectionField[];
}

export type CustomSectionFieldType = 'multipleOption' | 'singleOption' | 'longText' | 'shortText';

export interface CustomSectionField {
  Title: string;
  Type: CustomSectionFieldType;
  Value: string;
  Values: string[] | null;
  Options?: string[];
  Required?: boolean;
  DefaultValue?: string;
  DefaultValues?: string[] | null;
  Description?: string;
}
