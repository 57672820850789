import { all, put, select, take } from 'redux-saga/effects';
import userActions from '../../../../../../../../redux/user/actions';
import { versioningActions } from '../actions';
import {
  selectCompanySettingsSaga,
  selectUserRoleSaga,
  selectUserSaga,
} from '../../../../../../../../common/saga/selectors';
import { PreferredVersion } from '../../../../../../../../common/types/preferredVersoon';

export function* onSchedulePageLoadedWorker() {
  const { isCompanyLoaded, isUserLoaded } = yield select(({ user }) => user);

  const awaitActions = [];
  if (!isCompanyLoaded) {
    awaitActions.push(take(userActions.SET_COMPANY));
  }
  if (!isUserLoaded) {
    awaitActions.push(take(userActions.SET_USER));
  }
  // await for company or user to be loaded
  yield all(awaitActions);

  const companySettings = yield* selectCompanySettingsSaga();
  const userRoles = yield* selectUserRoleSaga();

  if (companySettings?.Versioning && !userRoles?.includes('admin')) {
    yield put(versioningActions.loadPublishedVersions({}));
  }

  const user = yield* selectUserSaga();
  yield put(
    versioningActions.setState({
      preferredVersion: user.CompanyUsers[0].PreferredVersion as PreferredVersion,
    }),
  );
}
