import { DutyFields } from '../../../../../pages/data/organization/crew-scheduling-v3/legacy/types/duty-fields';
import { ACFlightInformationRemote } from '../../../../../pages/data/organization/crew-scheduling-v3/legacy/types/flight-fields';
import { ApiFlightTimeEntry, RunValidationPayload, ValidationError } from '../interfaces';
import MODAL_MODE from '../../../../../pages/data/common/constants';

export const actions = {
  SET_STATE: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_STATE',
  SET_IS_VALIDATION_RUNNING: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_IS_VALIDATION_RUNNING',
  SET_VALIDATION_STATE: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_VALIDATION_STATE',
  SET_VALIDATION_SUGGESTIONS:
    'crew-scheduling-v3/DutyScheduleEntryModal/SET_VALIDATION_SUGGESTIONS',
  SET_MAX_FDP: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_MAX_FDP',
  SET_MAX_DUTY_TIME: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_MAX_DUTY_TIME',
  SET_REST_BEFORE: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_REST_BEFORE',
  SET_REST_AFTER: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_REST_AFTER',
  SET_ACCLIMATIZED_TIMEZONE_TAG:
    'crew-scheduling-v3/DutyScheduleEntryModal/SET_ACCLIMATIZED_TIMEZONE_TAG',
  SET_IS_FLYING_DUTY: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_IS_FLYING_DUTY',
  SET_ON_CALL_DUTY: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_ON_CALL_DUTY',
  SET_ON_RESERVE_DUTY: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_ON_RESERVE_DUTY',
  SET_POSITIONING_AFTER_MAX_FDP:
    'crew-scheduling-v3/DutyScheduleEntryModal/SET_POSITIONING_AFTER_MAX_FDP',
  SET_MANAGEMENT_OVERRIDE: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_MANAGEMENT_OVERRIDE',
  RUN_VALIDATION: 'crew-scheduling-v3/DutyScheduleEntryModal/RUN_VALIDATION',
  RUN_VALIDATION_DEB: 'crew-scheduling-v3/DutyScheduleEntryModal/RUN_VALIDATION_DEB',
  SET_IS_SPLIT_DUTY: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_IS_SPLIT_DUTY',
  SET_FLIGHT_TIME_ENTRIES: 'crew-scheduling-v3/DutyScheduleEntryModal/SET_FLIGHT_TIME_ENTRIES',
  RESET_FLIGHT_TIME_ENTRIES: 'crew-scheduling-v3/DutyScheduleEntryModal/RESET_FLIGHT_TIME_ENTRIES',
  SET_IS_DETAILED_FLIGHT_TIMES_FORM_VALID:
    'crew-scheduling-v3/DutyScheduleEntryModal/SET_IS_DETAILED_FLIGHT_TIMES_FORM_VALID',
  RESET_DETAILED_FLIGHT_VALIDATION_STATUS:
    'crew-scheduling-v3/DutyScheduleEntryModal/RESET_DETAILED_FLIGHT_VALIDATION_STATUS',
  OPEN_MODAL: 'crew-scheduling-v3/DutyScheduleEntryModal/OPEN_MODAL',
  CLOSE_MODAL: 'crew-scheduling-v3/DutyScheduleEntryModal/CLOSE_MODAL',
};

export const setIsValidationRunningAction = (isRunning: boolean) => ({
  type: actions.SET_IS_VALIDATION_RUNNING,
  payload: isRunning,
});

export const setValidationStateAction = (validationState: any) => ({
  type: actions.SET_VALIDATION_STATE,
  payload: validationState,
});

export const setValidationSuggestionsAction = (suggestions: ValidationError[]) => ({
  type: actions.SET_VALIDATION_SUGGESTIONS,
  payload: { validationSuggestions: suggestions },
});

export const setMaxFDPAction = (maxFDP: string) => ({
  type: actions.SET_MAX_FDP,
  payload: { maxFDP },
});

export const setMaxDutyTimeAction = (maxDutyTime: string) => ({
  type: actions.SET_MAX_DUTY_TIME,
  payload: { maxDutyTime },
});

export const setRestBeforeAction = (restBefore: string) => ({
  type: actions.SET_REST_BEFORE,
  payload: { restBefore },
});

export const setRestAfterAction = (restAfter: string) => ({
  type: actions.SET_REST_AFTER,
  payload: { restAfter },
});

export const setAcclimatizedTimezoneTagAction = (acclimatizedTimezoneTag: string) => ({
  type: actions.SET_ACCLIMATIZED_TIMEZONE_TAG,
  payload: { acclimatizedTimezoneTag },
});

export const setIsFlyingDutyAction = (isFlyingDuty: boolean) => ({
  type: actions.SET_IS_FLYING_DUTY,
  payload: { isFlyingDuty },
});

export const setOnCallDutyAction = (onCallDuty: boolean) => ({
  type: actions.SET_ON_CALL_DUTY,
  payload: { onCallDuty },
});

export const setManagementOverrideAction = (managementOverride: boolean) => ({
  type: actions.SET_MANAGEMENT_OVERRIDE,
  payload: { managementOverride },
});

export const setOnReserveDutyAction = (onReserveDuty: boolean) => ({
  type: actions.SET_ON_RESERVE_DUTY,
  payload: { onReserveDuty },
});

export const setPositioningAfterMaxFDPAction = (positioningAfterMaxFDP: boolean) => ({
  type: actions.SET_POSITIONING_AFTER_MAX_FDP,
  payload: { positioningAfterMaxFDP },
});

export const runValidationAction = (
  dutyFields: Partial<DutyFields>,
  flightFields: Partial<ACFlightInformationRemote>,
) => {
  return {
    type: actions.RUN_VALIDATION,
    payload: { dutyFields: dutyFields, flightFields: flightFields } as RunValidationPayload,
  };
};

export const runValidationDebounce = (
  dutyFields: Partial<DutyFields>,
  flightFields: Partial<ACFlightInformationRemote>,
) => {
  return {
    type: actions.RUN_VALIDATION_DEB,
    payload: { dutyFields: dutyFields, flightFields: flightFields } as RunValidationPayload,
  };
};

export const setIsSplitDutyAction = (isSplitDuty: boolean) => ({
  type: actions.SET_IS_SPLIT_DUTY,
  payload: { isSplitDuty },
});

export const setFlightTimeByAircraftType = (
  aircraftType: string,
  flightTimeEntries: ApiFlightTimeEntry[],
) => {
  return {
    type: actions.SET_FLIGHT_TIME_ENTRIES,
    payload: { aircraftType, flightTimeEntries },
  };
};

export const setIsDetailedFlightTimesFormValid = (aircraftType, isValid: boolean) => ({
  type: actions.SET_IS_DETAILED_FLIGHT_TIMES_FORM_VALID,
  payload: { aircraftType, isValid },
});

export const resetDetailedFlightValidationStatus = () => ({
  type: actions.RESET_DETAILED_FLIGHT_VALIDATION_STATUS,
});

export const resetFlightTimeEntries = () => ({
  type: actions.RESET_FLIGHT_TIME_ENTRIES,
});

export const openDutyScheduleModalAction = (mode: MODAL_MODE) => ({
  type: actions.OPEN_MODAL,
  payload: { mode },
});

export const closeDutyScheduleModalAction = () => ({
  type: actions.CLOSE_MODAL,
});
