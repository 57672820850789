import { openSubmissionModal, OpenSubmissionPreviewAction, setState } from '../actions';
import { call, put, select } from 'redux-saga/effects';
import { getCustomForms } from '../selectors';
import { CustomFormsState } from '../reducers';

export function* openSubmissionPreviewWorker(action: OpenSubmissionPreviewAction) {
  const { submissionID } = action.payload;
  const { submissionList } = (yield select(getCustomForms)) as CustomFormsState;

  const submission = submissionList.find(s => s.ID === submissionID);
  if (!submission) return;
  yield put(setState({ previewSubmission: submission }));
  yield put(openSubmissionModal());
}
