import { OnVisibleTimelinePeriodChangeAction, updateEntryPeriod } from '../actions';
import moment from 'moment-timezone';
import { put, select } from 'redux-saga/effects';
import { getNewEntryPeriodRange } from '../../../../pages/data/organization/crew-scheduling-v3/legacy/utils/utils';

export function* watchTimelinePeriodChange(action: OnVisibleTimelinePeriodChangeAction) {
  const { from, to } = action.payload;
  const { entryPeriod, currentView } = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2,
  );

  if (entryPeriod[0] && entryPeriod[1]) {
    const entryPeriodStartMoment = moment(entryPeriod[0]);
    const entryPeriodEndMoment = moment(entryPeriod[1]);
    const visibleStartMoment = moment(from);
    const visibleEndMoment = moment(to);

    if (
      entryPeriodEndMoment.diff(visibleEndMoment, 'days') < 1 ||
      visibleStartMoment.diff(entryPeriodStartMoment, 'days') < 1
    ) {
      const newEntryPeriod = getNewEntryPeriodRange(currentView, from, to, entryPeriod);
      yield put(updateEntryPeriod(newEntryPeriod));
    }
  }
}
