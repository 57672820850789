import { put, select } from 'redux-saga/effects';
import {
  afterEntryDeleted,
  AfterEntryDeletedType,
  setEntries,
  setEntryDetailsLoading,
  setIsEditing,
  setIsShowDetails,
} from '../actions';
import { getCSMobile } from '../selectors';
import { apiEntryTypeMap } from '../../../../../../common/types/apiEntryTypes';
import api from '../../../../../../services/api';
import { showMobileMenu } from '../../../../../../redux/settings/actions';

export function* deleteEntryWorker() {
  const { entry, entryType } = yield select(getCSMobile);

  yield put(setEntryDetailsLoading(true));
  try {
    let request;
    if (entryType === apiEntryTypeMap.DutyScheduleEntries) {
      request = api.delete(`/v1/users/${entry.UserID}/duty-schedule/${entry.ID}/v2`);
    } else if (entryType === apiEntryTypeMap.DutyTimeEntries) {
      request = api.delete(`v1/users/${entry.UserID}/duty/delete/${entry.ID}`);
    } else if (entryType === apiEntryTypeMap.CrewScheduleEntries) {
      request = api.delete(`v1/companies/${entry.CompanyID}/crewschedule/delete/${entry.ID}`);
    }

    yield request;

    yield put(afterEntryDeleted(entry.ID));
  } catch (err) {
    console.log('Failed to delete entry: ', err);
  } finally {
    // todo: it's not clear why we should show call 'showMobileMenu' here
    yield put(showMobileMenu());
    yield put(setIsEditing(false));
    yield put(setIsShowDetails(false));
    yield put(setEntryDetailsLoading(false));
  }
}

export function* watchDeleteEntry(action: AfterEntryDeletedType) {
  const { entryID } = action.payload;
  const { data } = yield select(getCSMobile);
  const newEntries = data.filter(entry => entry.ID !== entryID);
  yield put(setEntries(newEntries));
}
