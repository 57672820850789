export const snakeToCamel = str =>
  str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', ''),
  );

export const parseCookies = () => {
  return Object.fromEntries(
    document.cookie.split(/; */).map(c => {
      const [key, ...v] = c.split('=');
      return [key, decodeURIComponent(v.join('='))];
    }),
  );
};

export const clearCookies = () => {
  const cookies = document.cookie.split('; ');
  // eslint-disable-next-line no-plusplus
  for (let c = 0; c < cookies.length; c++) {
    const d = window.location.hostname.split('.');
    while (d.length > 0) {
      const cookieBase = `${encodeURIComponent(
        cookies[c].split(';')[0].split('=')[0],
      )}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${d.join('.')} ;path=`;
      // eslint-disable-next-line no-restricted-globals
      const p = location.pathname.split('/');
      document.cookie = `${cookieBase}/`;
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
};
