import { CrewSchedulingState } from './reducers';
import { CrewTimelineEntryType } from '../../../../../common/types/timeline/CrewTimelineEntryType';
import { entryTypeInStateMap } from '../types/state';
import { AircraftTimelineEntryType } from '../../../../../common/types/timeline/AircraftTimelineEntryType';
import { ContractTimelineEntryType } from '../../../../../common/types/timeline/ContractTimelineEntryType';

const getCrewScheduling = ({ pages: { crewScheduling } }) => crewScheduling as CrewSchedulingState;

export const getSelectedTags = state => getCrewScheduling(state).selectedTags;
export const getCompanyTags = state => getCrewScheduling(state).companyTags;
export const getSelectedClients = state => getCrewScheduling(state).selectedClients;
export const getSelectedClientContracts = state => getCrewScheduling(state).selectedClientContracts;
export const getCrewCertifications = state => getCrewScheduling(state).crewCertifications;
export const getSelectedCrewMemberIDs = state => getCrewScheduling(state).selectedCrewMemberIds;
export const getCrewMemberBases = state => getCrewScheduling(state).crewMemberBases;
export const getPilots = state => getCrewScheduling(state).pilots;
export const getPilotsFNOrder = state => getCrewScheduling(state).pilotsFNOrder;
export const getSelectedAircraftIdentifiers = state =>
  getCrewScheduling(state).selectedAircraftIdentifiers;
export const getSelectedTimelines = state => getCrewScheduling(state).selectedTimelines;
export const getIsTimelineLoaded = state => getCrewScheduling(state).isShowTimelineLoaded;
export const getSelectedTimelinePeriod = state => getCrewScheduling(state).timelinePeriod;
export const getVisibleTimelinePeriod = state => getCrewScheduling(state).visibleTimelinePeriod;
export const getCrewScheduleTimelineEntries = state =>
  getCrewScheduling(state).crewScheduleTimelineEntries;
export const getDutyScheduleTimelineEntries = state =>
  getCrewScheduling(state).dutyScheduleTimelineEntries;
export const getDutyTimeTimelineEntries = state => getCrewScheduling(state).dutyTimeTimelineEntries;
export const getPilotContractTimelineEntries = state =>
  getCrewScheduling(state).pilotContractTimelineEntries;

export const getAircraftScheduleTimelineEntries = state =>
  getCrewScheduling(state).aircraftScheduleTimelineEntries;
export const getAircraftContractTimelineEntries = state =>
  getCrewScheduling(state).aircraftContractTimelineEntries;
export const getClientContractPeriodTimelineEntries = state =>
  getCrewScheduling(state).clientContractPeriodTimelineEntries;

export const getCrewSortOption = state => getCrewScheduling(state).crewSortOption;
export const getJumpToDate = state => getCrewScheduling(state).jumpToDate;
export const getCurrentView = state => getCrewScheduling(state).currentView;
export const getVisibleTimelines = state => getCrewScheduling(state).visibleTimelines;
export const getAllEntryTypesLoaded = state => getCrewScheduling(state).allEntryTypesLoaded;

export const getPilotContractEntryForModal = state =>
  getCrewScheduling(state).pilotContractEntryForModal;
export const getAircraftContractEntryForModal = state =>
  getCrewScheduling(state).aircraftContractModalMode;

export const getPilotContractModalMode = state => getCrewScheduling(state).pilotContractModalMode;
export const getGroupedAircraftContractModalMode = state =>
  getCrewScheduling(state).groupedAircraftContractModalMode;
export const getRemotePrinterModalMode = state => getCrewScheduling(state).remotePrinterModalMode;

export const getEditAircraftScheduleEntry = state =>
  getCrewScheduling(state).editAircraftScheduleEntry;
export const getEditAircraftContractEntry = state =>
  getCrewScheduling(state).editAircraftContractEntry;

export const getEntryDisplayOptions = state => getCrewScheduling(state).entryDisplayOptions;
export const getIsLoadingMoreEntries = state => getCrewScheduling(state).isLoadingMoreEntries;
export const getIsTimelineLoading = state => getCrewScheduling(state).isTimelineLoading;

export const getCrewScheduleEntriesDisplayOptions = state =>
  getCrewScheduling(state).crewEntryDisplayOptions;
export const getAircraftScheduleEntriesDisplayOptions = state =>
  getCrewScheduling(state).aircraftEntryDisplayOptions;

export const getAllEntries = state => {
  return {
    [CrewTimelineEntryType.CrewSchedule]: getCrewScheduling(state)[
      entryTypeInStateMap[CrewTimelineEntryType.CrewSchedule]
    ],
    [CrewTimelineEntryType.DutySchedule]: getCrewScheduling(state)[
      entryTypeInStateMap[CrewTimelineEntryType.DutySchedule]
    ],
    [CrewTimelineEntryType.DutyTimes]: getCrewScheduling(state)[
      entryTypeInStateMap[CrewTimelineEntryType.DutyTimes]
    ],
    [CrewTimelineEntryType.PilotContract]: getCrewScheduling(state)[
      entryTypeInStateMap[CrewTimelineEntryType.PilotContract]
    ],
    [AircraftTimelineEntryType.AircraftSchedule]: getCrewScheduling(state)[
      entryTypeInStateMap[AircraftTimelineEntryType.AircraftSchedule]
    ],
    [AircraftTimelineEntryType.AircraftContract]: getCrewScheduling(state)[
      entryTypeInStateMap[AircraftTimelineEntryType.AircraftContract]
    ],
    [ContractTimelineEntryType.ClientContractPeriod]: getCrewScheduling(state)[
      entryTypeInStateMap[AircraftTimelineEntryType.AircraftContract]
    ],
  };
};

export const getSwitchedTimelinePeriod = state => getCrewScheduling(state).switchedTimelinePeriod;
export const getCutItem = state => getCrewScheduling(state).cutItem;
export const getCopyItem = state => getCrewScheduling(state).copyItem;
export const getContextMenuClickPosition = state =>
  getCrewScheduling(state).contextMenuClickPosition;

export const getCrewScheduleViolations = state => getCrewScheduling(state).crewScheduleViolations;
export const getPreferredVersion = state => getCrewScheduling(state).preferredVersion;
export const getClientContracts = state => getCrewScheduling(state).clientContracts;
export const getEditContractTimelineEntry = state =>
  getCrewScheduling(state).editContractTimelineEntry;
