import { SetPreferredVersionAction } from 'redux/pages/crew-scheduling-v3/actions';
import { PreferredVersion } from '../../../../../common/types/preferredVersoon';
import { EntryType } from '../../../../../common/types/entryTypes';
import { Pilot } from '../../../../../common/types/pilot';
import { CompanyRegulation } from '../../../../../common/types/companyRegulation';
import {
  CombinedEntry,
  FlightInformation,
  AircraftEntry,
} from '../../../../../common/types/CombinedEntry';
import { ApiFlightTimeEntry } from 'redux/pages/crew-scheduling-v3/modals/interfaces';
import { composeKey } from '../helpers';
import { CrewScheduleV2Permissions } from '../../../../../common/types/permissions';
import { CompanyTag } from './types/tag';
import { CompanyClient } from '../../../../../common/types/client';
import { AircraftMinimal } from '../../../../../common/types/aircraft';

const actions = {
  SET_STATE: 'pages/company/crew-scheduling-mobile/SET_STATE',
  COMPONENT_LOADED: 'pages/company/crew-scheduling-mobile/COMPONENT_LOADED',
  LOAD_ENTRIES: 'pages/company/crew-scheduling-mobile/LOAD_ENTRIES',
  SET_ENTRIES: 'pages/company/crew-scheduling-mobile/SET_ENTRIES',
  SET_AIRCRAFT_ENTRIES: 'pages/company/crew-scheduling-mobile/SET_AIRCRAFT_ENTRIES',
  SET_COMPANY_SCHEDULE_VERSION: 'pages/company/crew-scheduling-mobile/SET_COMPANY_SCHEDULE_VERSION',
  SET_PREFERRED_VERSION: 'pages/company/crew-scheduling-mobile/SET_PREFERRED_VERSION',
  SET_PUBLISHED_VERSIONS: 'pages/company/crew-scheduling-mobile/SET_PUBLISHED_VERSIONS',
  LOAD_MORE: 'pages/company/crew-scheduling-mobile/LOAD_MORE',
  SET_COMPANY_AIRCRAFTS: 'pages/company/crew-scheduling-mobile/SET_COMPANY_AIRCRAFTS',
  SET_ENTRY_TYPE: 'pages/company/crew-scheduling-mobile/SET_ENTRY_TYPE',
  SET_PILOTS: 'pages/company/crew-scheduling-mobile/SET_PILOTS',
  SET_SELECTED_PILOT: 'pages/company/crew-scheduling-mobile/SET_SELECTED_PILOT',
  SET_SELECTED_AIRCRAFT: 'pages/company/crew-scheduling-mobile/SET_SELECTED_AIRCRAFT',
  SET_SELECTED_PILOT_ID: 'pages/company/crew-scheduling-mobile/SET_SELECTED_PILOT_ID',
  SET_SELECTED_AIRCRAFT_ID: 'pages/company/crew-scheduling-mobile/SET_SELECTED_AIRCRAFT_ID',
  SET_IS_EDITING: 'pages/company/crew-scheduling-mobile/SET_IS_EDITING',
  SET_COMPANY_REGULATIONS: 'pages/company/crew-scheduling-mobile/SET_COMPANY_REGULATIONS',
  SET_EDIT_VALUE: 'pages/company/crew-scheduling-mobile/SET_EDIT_VALUE',
  SET_IS_FLIGHT_INFO_FORM_VALID: 'pages/company/crew-scheduling-mobile/SET_IS_FORM_VALID',
  CLEAR_EDIT_VALUE: 'pages/company/crew-scheduling-mobile/CLEAR_EDIT_VALUE',
  STORE_ORIGINAL_FLIGHT_INFORMATION:
    'pages/company/crew-scheduling-mobile/STORE_ORIGINAL_FLIGHT_INFORMATION',
  RESTORE_ORIGINAL_FLIGHT_INFORMATION:
    'pages/company/crew-scheduling-mobile/RESTORE_ORIGINAL_FLIGHT_INFORMATION',
  SET_EDIT_VALUES: 'pages/company/crew-scheduling-mobile/SET_EDIT_VALUES',
  SET_FLIGHT_INFO_EDIT_VALUE: 'pages/company/crew-scheduling-mobile/SET_FLIGHT_INFO_EDIT_VALUE',
  SAVE_ENTRY: 'pages/company/crew-scheduling-mobile/SAVE_ENTRY',
  RUN_VALIDATION: 'pages/company/crew-scheduling-mobile/RUN_VALIDATION',
  SET_ENTRY: 'pages/company/crew-scheduling-mobile/SET_ENTRY',
  SET_AIRCRAFT_ENTRY: 'pages/company/crew-scheduling-mobile/SET_AIRCRAFT_ENTRY ',
  CREATE_NEW_ENTRY: 'pages/company/crew-scheduling-mobile/CREATE_NEW_ENTRY',
  SET_IS_SHOW_DETAILS: 'pages/company/crew-scheduling-mobile/SET_IS_SHOW_DETAILS',
  SET_IS_SHOW_AIRCRAFT_DETAILS: 'pages/company/crew-scheduling-mobile/SET_IS_SHOW_AIRCRAFT_DETAILS',
  SET_VALIDATION_MSG: 'pages/company/crew-scheduling-mobile/SET_VALIDATION_MSG',
  SET_BANNER_MSGS: 'pages/company/crew-scheduling-mobile/SET_BANNER_MSGS',
  RESET_VALIDATION: 'pages/company/crew-scheduling-mobile/RESET_VALIDATION',
  RESET_VALIDATION_MSG: 'pages/company/crew-scheduling-mobile/RESET_VALIDATION_MSG',
  RESET_VALIDATION_MSGS: 'pages/company/crew-scheduling-mobile/RESET_VALIDATION_MSGS',
  SET_ADDITIONAL_INFO_VALUES: 'pages/company/crew-scheduling-mobile/SET_ADDITIONAL_INFO_VALUES',
  SET_PERMISSIONS: 'pages/company/crew-scheduling-mobile/SET_PERMISSIONS',
  ADD_NEW_AIRCRAFT_TYPE: 'pages/company/crew-scheduling-mobile/ADD_NEW_AIRCRAFT_TYPE',
  DELETE_AIRCRAFT_TYPE: 'pages/company/crew-scheduling-mobile/DELETE_AIRCRAFT_TYPE',
  DELETE_ENTRY: 'pages/company/crew-scheduling-mobile/DELETE_ENTRY',
  AFTER_ENTRY_DELETED: 'pages/company/crew-scheduling-mobile/AFTER_ENTRY_DELETED',
  AFTER_ENTRY_PATCHED: 'pages/company/crew-scheduling-mobile/AFTER_ENTRY_EDITED',
  AFTER_ENTRY_CREATED: 'pages/company/crew-scheduling-mobile/AFTER_ENTRY_CREATED',
  RESET_ENTRY_DETAILS_STATE: 'pages/company/crew-scheduling-mobile/RESET_ENTRY_DETAILS_STATE',
  RESET_AIRCRAFT_ENTRY_DETAILS_STATE:
    'pages/company/crew-scheduling-mobile/RESET_AIRCRAFT_ENTRY_DETAILS_STATE',
  SET_FRESH_ISSUES: 'pages/company/crew-scheduling-mobile/SET_FRESH_ISSUES',
  SET_MOST_RECENT_DUTY_ENTRY: 'pages/company/crew-scheduling-mobile/SET_MOST_RECENT_DUTY_ENTRY',
  SET_COMPANY_CLIENTS: 'pages/company/crew-scheduling-mobile/SET_COMPANY_CLIENTS',
  SET_COMPANY_TAGS: 'pages/company/crew-scheduling-mobile/SET_COMPANY_TAGS',
  SET_FI_NUM_FIELD: 'pages/company/crew-scheduling-mobile/SET_FI_NUM_FIELD',
  CLEAR_FLIGHT_INFO_EDIT_VALUES_BY_PREFIX:
    'pages/company/crew-scheduling-mobile/CLEAR_FLIGHT_INFO_EDIT_VALUES_BY_PREFIX',
  FETCH_RELATED_PILOTS: 'pages/company/crew-scheduling-mobile/FETCH_RELATED_PILOTS',
  SET_MORE_PAST_ENTRIES: 'pages/company/crew-scheduling-mobile/SET_MORE_PAST_ENTRIES',
  SET_MORE_FUTURE_ENTRIES: 'pages/company/crew-scheduling-mobile/SET_MORE_FUTURE_ENTRIES',
  FETCHED_ENTRIES_FOR_PERIOD: 'pages/company/crew-scheduling-mobile/FETCHED_ENTRIES_FOR_PERIOD',
};

export default actions;

export const editFields = {
  NonFlyingDuty: 'NonFlyingDuty',
  RestEnd: 'RestEnd',
  RAPEndTime: 'RAPEndTime',
  RestStart: 'RestStart',
  RAPStartTime: 'RAPStartTime',
  IsSplitDuty: 'IsSplitDuty',
  RestAirportID: 'RestAirportID',
  DutyFrom: 'DutyFrom',
  DutyTo: 'DutyTo',
  FDPTo: 'FDPTo',
  StartAirportID: 'StartAirportID',
  EndAirportID: 'EndAirportID',
  IsPositioningAfterMaxFDP: 'PositioningAfterMaxFDP',
  IsOnReserve: 'IsOnReserve',
  IsOngoing: 'IsOngoing',
  IsUnforeseenExtension: 'UnforeseenExtension',
  IsOnCallDuty: 'OnCallDuty',
  IsAllDayVFR: 'AllDayVFR',
  IsSinglePilot: 'SinglePilot',
  Notes: 'Notes',

  IFRApproaches: 'IFRApproaches',
  VFRHours: 'VFRHours',
  dayLandings: 'dayLandings',
  dayTakeoffs: 'dayTakeoffs',
  nightLandings: 'nightLandings',
  nightTakeoffs: 'nightTakeoffs',
  numberOfFlights: 'numberOfFlights',
  totalFlightHours: 'totalFlightHours',
  IFRHours: 'IFRHours',
  SubParts: 'SubParts',
  DetailedFlightTimes: 'DetailedFlightTimes',
  ManagementOverride: 'ManagementOverride',
  OverrideNotes: 'OverrideNotes',
  CompanyScheduleTypeID: 'CompanyScheduleTypeID',
  AssignedAircraft: 'AssignedAircraft',
  AssignedClient: 'AssignedClient',
  Tag: 'Tag',
  CustomSections: 'CustomSections',

  AircraftType: 'AircraftType',
  CustomCurrencyItems: 'CustomCurrencyItems',
  AircraftActiveShiftKey: 'AircraftActiveShiftKey',
  ShiftsByKey: 'ShiftsByKey',
};

export const additionalInfoFields = {
  restBefore: 'restBefore',
  restAfter: 'restAfter',
  maxDutyTime: 'maxDutyTime',
  maxFDP: 'maxFDP',
};

export type SaveEntryAction = {
  type: typeof actions.SAVE_ENTRY;
  payload: {
    entry: CombinedEntry;
    entryType: EntryType;
  };
};

export type FetchRelatedPilotsAction = {
  type: typeof actions.FETCH_RELATED_PILOTS;
  payload: {
    entry: CombinedEntry;
  };
};

export type SetValidationMsgAction = {
  type: typeof actions.SET_VALIDATION_MSG;
  payload: {
    key: string;
    msg: string;
  };
};

export type AddNewAircraftType = {
  type: typeof actions.ADD_NEW_AIRCRAFT_TYPE;
  payload: {
    aircraftType: string;
  };
};

export type DeleteAircraftType = {
  type: typeof actions.DELETE_AIRCRAFT_TYPE;
  payload: {
    aircraftType: string;
  };
};

export type AfterEntryPatchedType = {
  type: typeof actions.AFTER_ENTRY_PATCHED;
  payload: {
    entry: CombinedEntry;
  };
};

export type AfterEntryCreatedType = {
  type: typeof actions.AFTER_ENTRY_PATCHED;
  payload: {
    entry: CombinedEntry;
  };
};

export type AfterEntryDeletedType = {
  type: typeof actions.AFTER_ENTRY_PATCHED;
  payload: {
    entryID: string;
  };
};

export type RunValidationAction = SaveEntryAction;

export type SetEntryAction = {
  type: typeof actions.SET_ENTRY;
  payload: {
    entry: CombinedEntry;
  };
};

export type SetAircraftEntryAction = {
  type: typeof actions.SET_AIRCRAFT_ENTRY;
  payload: {
    aircraftEntry: AircraftEntry;
  };
};

export type EditValueAction = {
  type: typeof actions.SET_EDIT_VALUE;
  payload: {
    key: string;
    value: any;
  };
};

export type ClearValueAction = {
  type: typeof actions.CLEAR_EDIT_VALUE;
  payload: {
    key: string;
  };
};

export type FlightTimesEditValueAction = {
  type: typeof actions.SET_EDIT_VALUE;
  payload: {
    key: string;
    value: any;
    isFormValid: boolean;
  };
};

export type FlightTimesSetIsFormValid = {
  type: typeof actions.SET_IS_FLIGHT_INFO_FORM_VALID;
  payload: {
    key: string;
    isFormValid: boolean;
  };
};

export type EditValuesAction = {
  type: typeof actions.SET_EDIT_VALUES;
  payload: { [key: string]: any };
};

export type AdditionalInfoValuesAction = {
  type: typeof actions.SET_ADDITIONAL_INFO_VALUES;
  payload: { [key: string]: string };
};

export type SetIfShowDetailsAction = {
  type: typeof actions.SET_IS_SHOW_DETAILS;
  payload: { isShowDetails: boolean };
};

export type SetIsShowAircraftDetailsAction = {
  type: typeof actions.SET_IS_SHOW_AIRCRAFT_DETAILS;
  payload: { isShowAircraftDetails: boolean };
};

export type SetIsEditingAction = {
  type: typeof actions.SET_IS_EDITING;
  payload: { isEditing: boolean };
};

export type SetMostRecentDutyEntryAction = {
  type: typeof actions.SET_MOST_RECENT_DUTY_ENTRY;
  payload: { mostRecentDutyEntry: CombinedEntry };
};

export type SetSelectedPilotIdAction = {
  type: typeof actions.SET_SELECTED_PILOT_ID;
  payload: { selectedPilotId: string };
};
export type SetSelectedAircraftIdAction = {
  type: typeof actions.SET_SELECTED_AIRCRAFT_ID;
  payload: { selectedAircraftId: string };
};

export type SetFiNumField = {
  type: typeof actions.SET_FI_NUM_FIELD;
  payload: {
    fiNumField: string;
    fiNumFieldVal: string;
  };
};

export const fetchItemsRequest = () => {
  return {
    type: actions.LOAD_ENTRIES,
  };
};

export const componentLoaded = () => {
  return {
    type: actions.COMPONENT_LOADED,
  };
};

export const setEntries = entries => {
  return {
    type: actions.SET_ENTRIES,
    payload: entries,
  };
};

export const setMoreFutureEntries = (moreFutureEntries: boolean) => {
  return {
    type: actions.SET_MORE_FUTURE_ENTRIES,
    payload: moreFutureEntries,
  };
};
export const setMorePastEntries = (morePastEntries: boolean) => {
  return {
    type: actions.SET_MORE_PAST_ENTRIES,
    payload: morePastEntries,
  };
};
export const setAircraftEntries = entries => ({
  type: actions.SET_AIRCRAFT_ENTRIES,
  payload: entries,
});

export const setState = payload => ({
  type: actions.SET_STATE,
  payload,
});

export const setLoading = loading => ({
  type: actions.SET_STATE,
  payload: {
    loading,
  },
});

export const setEntryDetailsLoading = saving => ({
  type: actions.SET_STATE,
  payload: {
    saving,
  },
});

export const setValidating = validating => ({
  type: actions.SET_STATE,
  payload: {
    validating,
  },
});

export const setPreferredVersion = (version: PreferredVersion): SetPreferredVersionAction => ({
  type: actions.SET_PREFERRED_VERSION,
  payload: version,
});

export const loadMore = (isPast = true) => ({
  type: actions.LOAD_MORE,
  payload: isPast,
});

export const setEntryType = (entryType: string) => ({
  type: actions.SET_ENTRY_TYPE,
  payload: { entryType },
});

export const setPilots = pilots => ({
  type: actions.SET_PILOTS,
  payload: { pilots },
});

export const setCompanyAircraft = (aircraft: AircraftMinimal) => ({
  type: actions.SET_COMPANY_AIRCRAFTS,
  payload: { aircraft },
});

export const setSelectedPilot = (pilot: Pilot) => ({
  type: actions.SET_SELECTED_PILOT,
  payload: pilot
    ? {
        selectedPilotId: pilot.ID,
        selectedPilotName: `${pilot.Profile.FirstName} ${pilot.Profile.LastName}`,
      }
    : { selectedPilotId: null, selectedPilotName: null },
});

export const setSelectedAircraft = (aircraft: AircraftMinimal) => ({
  type: actions.SET_SELECTED_AIRCRAFT,
  payload: aircraft
    ? {
        selectedAircraftId: aircraft.ID,
        selectedAircraftTailNumber: aircraft.TailNumber,
        selectedAircraftCommonDesignator: aircraft.CommonDesignator,
      }
    : {
        selectedAircraftId: null,
        selectedAircraftTailNumber: null,
        selectedAircraftCommonDesignator: null,
      },
});

export const setIsEditing = (isEditing: boolean): SetIsEditingAction => ({
  type: actions.SET_IS_EDITING,
  payload: { isEditing },
});

export const setCompanyRegulations = (companyRegulations: CompanyRegulation[]) => ({
  type: actions.SET_COMPANY_REGULATIONS,
  payload: { companyRegulations },
});

export const setEditValue = (key: string, value: any): EditValueAction => ({
  type: actions.SET_EDIT_VALUE,
  payload: {
    key,
    value,
  },
});

export const storeOriginalFlightInformation = (originalFlightInformation: FlightInformation[]) => {
  return {
    type: actions.STORE_ORIGINAL_FLIGHT_INFORMATION,
    payload: originalFlightInformation,
  };
};

export const restoreOriginalFlightInformation = () => ({
  type: actions.RESTORE_ORIGINAL_FLIGHT_INFORMATION,
});

export const clearEditValue = (key: string): ClearValueAction => ({
  type: actions.CLEAR_EDIT_VALUE,
  payload: {
    key,
  },
});

export const setEditValues = (values: { [key: string]: any }): EditValuesAction => ({
  type: actions.SET_EDIT_VALUES,
  payload: values,
});

export const setAdditionalInfoValues = (values: {
  [key: string]: any;
}): AdditionalInfoValuesAction => ({
  type: actions.SET_ADDITIONAL_INFO_VALUES,
  payload: values,
});

export const setFIEditValue = (idx: number, key: string, value: string): EditValueAction => {
  return {
    type: actions.SET_FLIGHT_INFO_EDIT_VALUE,
    payload: {
      key: composeKey(String(idx), key),
      value: Number(value),
    },
  };
};

export const setFIEditCustomCurrencyItemValue = (
  idx: number,
  key: string,
  value: string,
): EditValueAction => {
  return {
    type: actions.SET_FLIGHT_INFO_EDIT_VALUE,
    payload: {
      key: composeKey(String(idx), key),
      value,
    },
  };
};

export const setDetailedFlightTimesEditValue = (
  idx: number,
  key: string,
  value: ApiFlightTimeEntry[],
  isFormValid: boolean,
): FlightTimesEditValueAction => {
  return {
    type: actions.SET_FLIGHT_INFO_EDIT_VALUE,
    payload: {
      key: composeKey(String(idx), key),
      value: value,
      isFormValid: isFormValid,
    },
  };
};

export const setDetailedFlightTimesFormValid = (
  idx,
  isFormValid: boolean,
): FlightTimesSetIsFormValid => {
  return {
    type: actions.SET_IS_FLIGHT_INFO_FORM_VALID,
    payload: {
      key: composeKey(String(idx), editFields.DetailedFlightTimes),
      isFormValid: isFormValid,
    },
  };
};

export const saveEntry = (entry: CombinedEntry, entryType: EntryType): SaveEntryAction => ({
  type: actions.SAVE_ENTRY,
  payload: { entry, entryType },
});

export const fetchRelatedPilots = (entry: CombinedEntry): FetchRelatedPilotsAction => ({
  type: actions.FETCH_RELATED_PILOTS,
  payload: { entry },
});

export const runValidation = (entry: CombinedEntry, entryType: EntryType): RunValidationAction => ({
  type: actions.RUN_VALIDATION,
  payload: { entry, entryType },
});

export const setEntry = (entry: CombinedEntry): SetEntryAction => {
  return {
    type: actions.SET_ENTRY,
    payload: { entry },
  };
};

export const setAircraftEntry = (aircraftEntry: AircraftEntry): SetAircraftEntryAction => {
  return {
    type: actions.SET_AIRCRAFT_ENTRY,
    payload: { aircraftEntry },
  };
};

export const createNewEntry = () => ({
  type: actions.CREATE_NEW_ENTRY,
});

export const setIsShowDetails = (isShowDetails: boolean): SetIfShowDetailsAction => ({
  type: actions.SET_IS_SHOW_DETAILS,
  payload: { isShowDetails },
});

export const setIsShowAircraftDetails = (
  isShowAircraftDetails: boolean,
): SetIsShowAircraftDetailsAction => ({
  type: actions.SET_IS_SHOW_AIRCRAFT_DETAILS,
  payload: { isShowAircraftDetails },
});

export const setValidationMsg = (key: string, msg: string): SetValidationMsgAction => ({
  type: actions.SET_VALIDATION_MSG,
  payload: { key, msg },
});

export const setBannerMsgs = (bannerMsgs: { message: string }[]) => ({
  type: actions.SET_BANNER_MSGS,
  payload: { bannerMsgs },
});

export const resetValidationMsg = (key: string) => ({
  type: actions.RESET_VALIDATION_MSG,
  payload: { key },
});

export const resetValidation = () => ({
  type: actions.RESET_VALIDATION,
});

export const resetValidationMsgs = (keys: string[]) => ({
  type: actions.RESET_VALIDATION_MSGS,
  payload: { keys },
});

export const setPermissions = (permissionList: CrewScheduleV2Permissions) => ({
  type: actions.SET_PERMISSIONS,
  payload: { permissionList },
});

export const addNewAircraftType = (aircraftType: string): AddNewAircraftType => ({
  type: actions.ADD_NEW_AIRCRAFT_TYPE,
  payload: { aircraftType },
});

export const deleteAircraftType = (aircraftType: string): DeleteAircraftType => ({
  type: actions.DELETE_AIRCRAFT_TYPE,
  payload: { aircraftType },
});

export const clearFIEditValuesByPrefix = (prefix: string) => ({
  type: actions.CLEAR_FLIGHT_INFO_EDIT_VALUES_BY_PREFIX,
  payload: {
    prefix,
  },
});

export const deleteEntry = () => ({
  type: actions.DELETE_ENTRY,
});

export const afterEntryDeleted = (entryID: string): AfterEntryDeletedType => ({
  type: actions.AFTER_ENTRY_DELETED,
  payload: { entryID },
});

export const afterEntryPatched = (entry: CombinedEntry): AfterEntryPatchedType => ({
  type: actions.AFTER_ENTRY_PATCHED,
  payload: { entry },
});

export const afterEntryCreated = (entry: CombinedEntry): AfterEntryCreatedType => ({
  type: actions.AFTER_ENTRY_CREATED,
  payload: { entry },
});

export const resetEntryDetailsState = () => ({
  type: actions.RESET_ENTRY_DETAILS_STATE,
});

export const resetAircraftEntryDetailsState = () => ({
  type: actions.RESET_AIRCRAFT_ENTRY_DETAILS_STATE,
});

export const setFreshIssues = (freshIssues: { Code: string; Message: string }[]) => ({
  type: actions.SET_FRESH_ISSUES,
  payload: { freshIssues },
});

export const setMostRecentDutyEntry = (
  mostRecentDutyEntry: CombinedEntry,
): SetMostRecentDutyEntryAction => ({
  type: actions.SET_MOST_RECENT_DUTY_ENTRY,
  payload: { mostRecentDutyEntry },
});

export const setSelectedPilotId = (selectedPilotId: string): SetSelectedPilotIdAction => ({
  type: actions.SET_SELECTED_PILOT_ID,
  payload: { selectedPilotId },
});

export const setSelectedAircraftId = (selectedAircraftId: string): SetSelectedAircraftIdAction => ({
  type: actions.SET_SELECTED_AIRCRAFT_ID,
  payload: { selectedAircraftId },
});

type SetCompanyClientsAction = {
  type: typeof actions.SET_COMPANY_CLIENTS;
  payload: {
    companyClients: CompanyClient[];
  };
};
type SetCompanyTagsAction = {
  type: typeof actions.SET_COMPANY_TAGS;
  payload: {
    companyTags: CompanyTag[];
  };
};

export const setCompanyClients = (companyClients: CompanyClient[]): SetCompanyClientsAction => ({
  type: actions.SET_COMPANY_CLIENTS,
  payload: { companyClients },
});

export const setCompanyTags = (companyTags: CompanyTag[]): SetCompanyTagsAction => ({
  type: actions.SET_COMPANY_TAGS,
  payload: { companyTags },
});
