import { loadSubmissions, PusherSubmissionCreatedOrUpdatedAction } from '../../actions';
import { put, select } from 'redux-saga/effects';

export function* handleSubmissionUpdate(action: PusherSubmissionCreatedOrUpdatedAction) {
  const { update } = action.payload;
  const user = yield select(({ user }) => user);
  if (user.id === update.ActionTriggeredByUserID) {
    return;
  }

  yield put(loadSubmissions());
}
