import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import actions from './actions';
import { PilotStatus } from '../types/pilotStatus';

export interface PilotStatusState {
  isLoadingList: boolean;
  pilotStatusList: PilotStatus[];
  pagination: TablePaginationConfig;
  sorter: SorterResult<any>;
  pilotStatusListLoaded: boolean;
}

export const initialState: PilotStatusState = {
  isLoadingList: false,
  pilotStatusList: [],
  pagination: {
    total: 0,
    current: 1,
    pageSize: 50,
  },
  sorter: {},
  pilotStatusListLoaded: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.LOAD_PILOT_STATUS_LIST:
      return { ...state, ...action.payload };
    case actions.SET_PILOT_STATUS_LIST:
      return { ...state, pilotStatusList: action.payload.list, pilotStatusListLoaded: true };
    default:
      return state;
  }
}
