import { DeleteAircraftGroup, DeleteAircraftGroupSchedule, loadAircraftGroup } from '../actions';
import api from '../../../../../../../../services/api';
import { call, select } from 'redux-saga/effects';
import { getCompanyID } from '../../../../../../../../redux/selectors';
import { loadGroupWorker } from './loadGroupWorker';

export function* deleteGroupWorker(action: DeleteAircraftGroup) {
  const { aircraftGroupID } = action.payload;
  const companyID = yield select(getCompanyID);

  console.log(' on delete ', aircraftGroupID);

  try {
    yield call(api.delete, `/v1/companies/${companyID}/aircraft-group/${aircraftGroupID}`);
    yield call(loadGroupWorker);
  } catch (err) {
    console.log(err);
  }
}
