import { EntryType } from './entryTypes';
import { CrewTimelineEntryType } from './timeline/CrewTimelineEntryType';
import { AircraftTimelineEntryType } from './timeline/AircraftTimelineEntryType';

export type ApiEntryType =
  | 'CrewScheduleEntries'
  | 'DutyScheduleEntries'
  | 'DutyTimeEntries'
  | 'AircraftScheduleEntries';

export const entryTypeMapping: Record<EntryType, ApiEntryType> = {
  'aircraft-schedule': 'AircraftScheduleEntries',
  crewschedule: 'CrewScheduleEntries',
  'duty-schedule': 'DutyScheduleEntries',
  'duty-times': 'DutyTimeEntries',
};

export const entryTypeLabelsMap: Record<EntryType, string> = {
  crewschedule: 'Crew Schedule',
  'duty-schedule': 'Planned Duty',
  'duty-times': 'Actual Duty',
  'aircraft-schedule': 'Aircraft Schedule',
};

export const apiEntryTypeMap: Record<ApiEntryType, EntryType> = {
  CrewScheduleEntries: CrewTimelineEntryType.CrewSchedule,
  DutyScheduleEntries: CrewTimelineEntryType.DutySchedule,
  DutyTimeEntries: CrewTimelineEntryType.DutyTimes,
  AircraftScheduleEntries: CrewTimelineEntryType.AircraftSchedule,
};

export const apiEntryGetPath: Record<CrewTimelineEntryType | AircraftTimelineEntryType, string> = {
  [CrewTimelineEntryType.CrewSchedule]: 'crew-schedule',
  [CrewTimelineEntryType.DutySchedule]: 'duty-schedule',
  [CrewTimelineEntryType.DutyTimes]: 'duty',
  [CrewTimelineEntryType.PilotContract]: 'pilot-contract',
  [AircraftTimelineEntryType.AircraftContract]: 'not-implemented',
  [AircraftTimelineEntryType.AircraftSchedule]: 'aircraft-schedule',
};
