export const TIMELINE_HEIGHT = 400;
export const TIMELINE_HEIGHT_CORRECTION = 200;

export const TIMELINE_KEYS = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start_time',
  itemTimeEndKey: 'end_time',
  groupLabelKey: 'title',
};

export const NEW_ITEM_ID = 'newItem';
