import { Action } from 'redux';
import CampConfig from 'types/CampConfig';

const integrationsActions = {
  INIT: 'integrations/INIT',
  SET_CAMP_CONFIG: 'integrations/SET_CAMP_CONFIG',
} as const;

export default integrationsActions;

export interface IntegrationsAction extends Action {
  type: typeof integrationsActions[keyof typeof integrationsActions];
}

export namespace IntegrationsAction {
  export interface Init {
    type: typeof integrationsActions.INIT;
    companyId: string;
  }

  export interface SetCampConfig {
    type: typeof integrationsActions.SET_CAMP_CONFIG;
    campConfig: CampConfig | null;
  }
}
