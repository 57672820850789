import { call, put } from 'redux-saga/effects';
import api from '../../../../../../../../services/api';
import { selectCompanyIDSaga } from '../../../../../../../../common/saga/selectors';
import { LoadPublishedVersionsAction, versioningActions } from '../actions';

export function* loadPublishedVersionWorker(action: LoadPublishedVersionsAction) {
  const companyID = yield* selectCompanyIDSaga();
  let range = [];
  let pagination = { limit: 10, page: 0 };
  if (action?.payload) {
    range = action.payload.range ?? range;
    pagination = action.payload.pagination ?? pagination;
  }
  const requestPayload = {};

  if (range?.length > 0) {
    requestPayload['published_at__gte'] = range[0];
    requestPayload['published_at__lte'] = range[1];
  }
  requestPayload['company_id'] = companyID;
  requestPayload['limit'] = pagination.limit;
  requestPayload['page'] = pagination.page;

  try {
    const {
      data: { Data, Count, Total, Page },
    } = yield call(api.get, `/v1/companies/${companyID}/publish/list`, { params: requestPayload });

    yield put(
      versioningActions.setState({
        publishedVersions: Data,
        publishedVersionsRaw: { Data, Count, Total, Page },
      }),
    );
  } finally {
    // console.log('loadPublishedVersionWorker');
  }
}
