import { CombinedMaintenanceState } from './reducers';
import { TablePaginationConfig } from 'antd';
import { CombinedMaintenanceItem } from '../types/combinedMaintenance';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

const actions = {
  SET_STATE: 'components/combined-maintenance/SET_STATE',
  LOAD_COMBINED_MAINTENANCE: 'pages/company/combined-maintenance/LOAD_FLIGHT_WATCH_LIST',
  SET_COMBINED_MAINTENANCE: 'pages/company/combined-maintenance/SET_FLIGHT_WATCH_LIST',
  HANDLE_TABLE_CHANGE: 'components/combined-maintenance/HANDLE_TABLE_CHANGE',
  START_SHOW_COMBINED_MAINTENANCE:
    'components/combined-maintenance/START_SHOW_COMBINED_MAINTENANCE_ITEMS',
  SET_VIEW_ONLY_WORK_ORDER_MODAL: 'components/combined-maintenance/SET_VIEW_ONLY_WORK_ORDER_MODAL',
  SET_LOADED_WORK_ORDER_ID: 'components/combined-maintenance/SET_LOADED_WORK_ORDER_ID',
  SET_LOADED_MAINTENANCE_ITEM_ID: 'components/combined-maintenance/SET_LOADED_MAINTENANCE_ITEM_ID',
  SET_WORK_ORDER_MODAL_IS_OPEN: 'components/combined-maintenance/SET_WORK_ORDER_MODAL_IS_OPEN',
  SET_VIEW_MAINTENANCE_ITEM_MODAL_STATUS:
    'components/combined-maintenance/SET_VIEW_MAINTENANCE_ITEM_MODAL_STATUS',
  SET_FILTER: 'components/combined-maintenance/SET_FILTER',
};

export default actions;

export type OnTableChange = {
  type: typeof actions.HANDLE_TABLE_CHANGE;
  payload: {
    pagination: TablePaginationConfig;
    sorter: SorterResult<any>;
  };
};

export type SetCombinedMaintenance = {
  type: typeof actions.SET_COMBINED_MAINTENANCE;
  payload: {
    list: CombinedMaintenanceItem[];
  };
};

export const setCombinedMaintenance = (list: CombinedMaintenanceItem[]): SetCombinedMaintenance => {
  return {
    type: actions.SET_COMBINED_MAINTENANCE,
    payload: {
      list,
    },
  };
};

export const setState = (payload: Partial<CombinedMaintenanceState>) => ({
  type: actions.SET_STATE,
  payload,
});

export const loadCombinedMaintenanceItems = () => {
  return {
    type: actions.LOAD_COMBINED_MAINTENANCE,
  };
};

export const onTableChange = (
  pagination: TablePaginationConfig,
  sorter: SorterResult<any>,
): OnTableChange => {
  return {
    type: actions.HANDLE_TABLE_CHANGE,
    payload: {
      pagination,
      sorter,
    },
  };
};

export const setViewOnlyWorkOrderModal = (viewOnlyWorkOrderModal: boolean) => {
  return {
    type: actions.SET_VIEW_ONLY_WORK_ORDER_MODAL,
    payload: {
      viewOnlyWorkOrderModal,
    },
  };
};

export const setLoadedWorkOrderId = (loadedWorkOrderId: string) => {
  return {
    type: actions.SET_LOADED_WORK_ORDER_ID,
    payload: {
      loadedWorkOrderId,
    },
  };
};

export const setLoadedMaintenanceItemId = (loadedMaintenanceItemId: string) => {
  return {
    type: actions.SET_LOADED_MAINTENANCE_ITEM_ID,
    payload: {
      loadedMaintenanceItemId,
    },
  };
};

export const setWorkOrderModalIsOpen = (workOrderModalIsOpen: boolean) => {
  return {
    type: actions.SET_WORK_ORDER_MODAL_IS_OPEN,
    payload: {
      workOrderModalIsOpen,
    },
  };
};

export const setViewMaintenanceItemModalStatus = (viewMaintenanceItemModalStatus: boolean) => {
  return {
    type: actions.SET_VIEW_MAINTENANCE_ITEM_MODAL_STATUS,
    payload: {
      viewMaintenanceItemModalStatus,
    },
  };
};

export const setFilter = (filter: Record<string, FilterValue | null>) => {
  return {
    type: actions.SET_FILTER,
    payload: {
      filter,
    },
  };
};
