import { SaveClientContractPeriodAction, clientContractModalActions } from '../actions';
import { v4 as uuidv4 } from 'uuid';
import {call, put} from 'redux-saga/effects';
import api from '../../../../../../../../../services/api';
import MODAL_MODE from '../../../../../../../common/constants';

type PutClientContractRequest = {
  ID: string;
  ClientContractID: string;
  StartTime: string;
  EndTime: string;
};

export function* saveClientContractPeriod(action: SaveClientContractPeriodAction) {
  const { clientContractID, form, existedEntryID } = action.payload;

  const [startTime, endTime] = form.SchedulePeriod;
  const request: PutClientContractRequest = {
    ID: existedEntryID || uuidv4(),
    ClientContractID: clientContractID,
    StartTime: startTime.startOf('day').format(),
    EndTime: endTime.endOf('day').format(),
  };

  try {
    yield call(api.put, `/v1/client-contracts/period`, request);
    yield put(
      clientContractModalActions.setClientContractPeriodModalMode({
        mode: MODAL_MODE.create,
        open: false,
      }),
    );
  } catch (e) {
    console.error('addClientContractPeriod', e);
  }
}
