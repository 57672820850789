import {
  CombinedEntry,
  CompanyScheduleType,
  FlightInformation,
} from '../../../../../../common/types/CombinedEntry';
import { Profile } from '../../../../../../common/types/user';

export const buildEntry = (
  userID: string,
  companyID: string,
  companySettings: any,
  profile: Profile,
  defaultAircraftType: string,
  defaultStartAirportID?: string,
  defaultEndAirportID?: string,
  defaultScheduleType?: CompanyScheduleType,
): CombinedEntry => {
  let subParts = [];
  if (profile && profile.Metadata?.PredefinedSubpart) {
    subParts = [profile.Metadata.PredefinedSubpart];
  } else if (profile && profile.Metadata?.MostRecentSubPart) {
    subParts = [profile.Metadata.MostRecentSubPart];
  } else if (companySettings?.DefaultSubPart) {
    subParts = [companySettings.DefaultSubPart];
  }
  // @ts-ignore
  const newEntry: CombinedEntry = {
    UserID: userID,
    NonFlyingDuty: companySettings?.IntegrateLoggingWithCrewScheduleV2 ? true : false,
    AircraftInformation: {
      FlightInformation: defaultAircraftType ? [buildFlightInformation(defaultAircraftType)] : [],
    },
    Notes: '',
    PositioningAfterMaxFDP: false,
    CompanyID: companyID,

    OnCallDuty: !!companySettings.DutyIsOnCallByDefault || false,
    StartAirportID: defaultStartAirportID,
    EndAirportID: defaultEndAirportID,
    CompanyScheduleTypeID: defaultScheduleType?.ID,
    CompanyScheduleType: defaultScheduleType,
  };
  return newEntry;
};

export const buildFlightInformation = (defaultAircraftType: string): FlightInformation => {
  return {
    TotalFlightHours: 0,
    AircraftID: null,
    AircraftType: defaultAircraftType,
    NumberOfFlights: 0,
    FlightTime: 0,
    IFRHours: 0,
    VFRHours: 0,
    IFRApproaches: 0,
    NightTakeoffs: 0,
    NightLandings: 0,
    DayTakeoffs: 0,
    DayLandings: 0,
    DetailedFlightTimes: [],
    Subparts: [],
    CustomCurrencyItems: [],
  };
};
