import { selectCompanySettingsSaga } from '../../../../../../../common/saga/selectors';
import { call, put } from 'redux-saga/effects';
import api from '../../../../../../../services/api';
import { ClientContract } from '../../../../../../../common/types/ClientContract';
import { scheduleActions } from '../../actions';

export function* loadClientContracts() {
  const companySettings = yield* selectCompanySettingsSaga();

  if (!companySettings.ContractSchedule) {
    return;
  }

  try {
    const {
      data: { Data },
    } = yield call(api.get, '/v1/client-contracts/list', {
      params: {},
    });
    const clientContracts: ClientContract[] = Data;
    yield put(scheduleActions.setState({ clientContracts }));
  } catch (err) {
  } finally {
  }
}
