import { VersioningState } from './reducers';

const getScheduleVersioning = ({ pages: { scheduleVersioning } }) =>
  scheduleVersioning as VersioningState;

export const getUnpublishedEntries = state => getScheduleVersioning(state).unpublishedEntries;
export const getRecentlyPublishedEntries = state =>
  getScheduleVersioning(state).recentlyPublishedEntries;
export const getPreferredVersion = state => getScheduleVersioning(state).preferredVersion;
export const getVersionSwitching = state => getScheduleVersioning(state).versionSwitching;
export const getReviewChangesModalVisibility = state =>
  getScheduleVersioning(state).reviewChangesModalVisibility;
export const getCurrentSandboxVersion = state => getScheduleVersioning(state).currentSandboxVersion;
export const getLatestSandboxVersion = state => getScheduleVersioning(state).latestSandboxVersion;
export const getCompanyScheduleVersion = state =>
  getScheduleVersioning(state).companyScheduleVersion;
export const getPublishedVersions = state => getScheduleVersioning(state).publishedVersions;
export const getPublishedVersionsRaw = state => getScheduleVersioning(state).publishedVersionsRaw;
