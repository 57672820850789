import moment from 'moment-timezone';

export enum TimelineType {
  Crew = 'crew',
  Aircraft = 'aircraft',
  AircraftGroup = 'aircraft_group',
  Contract = 'contract',
}

export enum TimelineView {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  ThreeMonths = '3-Month',
  Year = 'Year',
}
export enum TimelinePeriodSwitch {
  Previous = 'Previous',
  Next = 'Next',
}

export type TimelinePeriodExtension = {
  isRequired: boolean;
  left: boolean;
  right: boolean;
};

export type ContextMenuClickPosition = {
  groupID: string;
  time: number;
};
