import { put } from 'redux-saga/effects';
import LocalCache from '../../../../../../local-storage/local-storage';
import { scheduleActions } from '../../actions';
import { TimelineType } from '../../../types/timeline';
import { selectUserSaga } from '../../../../../../../common/saga/selectors';

export function* setSelectedTimelinesFromCache() {
  const user = yield* selectUserSaga();
  const localCache = new LocalCache('crewSchedulingV2', user);
  yield put(
    scheduleActions.setSelectedTimelines(
      localCache.getCached('selectedTimelines', [
        TimelineType.AircraftGroup,
        TimelineType.Aircraft,
        TimelineType.Crew,
      ]),
    ),
  );
}
