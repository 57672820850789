import { CombinedEntry } from '../../../../../../../common/types/CombinedEntry';
import { composeKey } from '../../../helpers';
import { editFields, resetValidationMsg, setValidationMsg } from '../../actions';
import moment from 'moment-timezone';
import { put } from 'redux-saga/effects';
import { apiEntryTypeMap } from '../../../../../../../common/types/apiEntryTypes';
import { EntryType } from '../../../../../../../common/types/entryTypes';

export function* checkRestStartEndTime(
  entry: CombinedEntry,
  editValues: any,
  entryType: EntryType,
) {
  const composedKey = composeKey(editFields.RestStart, editFields.RestEnd);

  let restStartTime = editValues[editFields.RestStart];
  let restEndTime = editValues[editFields.RestEnd];

  // Determine the entry keys based on entryType
  const restStartKey =
    entryType === apiEntryTypeMap.DutyScheduleEntries ? 'RestStartTime' : 'RestStart';
  const restEndKey = entryType === apiEntryTypeMap.DutyScheduleEntries ? 'RestEndTime' : 'RestEnd';

  // Set start and end times, if they were not set before
  restStartTime = restStartTime || (entry[restStartKey] && moment(entry[restStartKey]));
  restEndTime = restEndTime || (entry[restEndKey] && moment(entry[restEndKey]));

  const isSplitDuty =
    editValues[editFields.IsSplitDuty] !== undefined
      ? editValues[editFields.IsSplitDuty]
      : !!(entry[restStartKey] && entry[restEndKey]);

  if (isSplitDuty) {
    if (restStartTime && !restEndTime) {
      yield put(setValidationMsg(composeKey(composedKey), 'Enter a rest end'));
    } else if (!restStartTime && restEndTime) {
      yield put(setValidationMsg(composeKey(composedKey), 'Enter a rest start'));
    } else if (
      (!restEndTime && !restStartTime) ||
      restEndTime.diff(restStartTime, 'minutes') < 60
    ) {
      yield put(setValidationMsg(composeKey(composedKey), 'Rest must be at least 60 minutes'));
    } else {
      yield put(resetValidationMsg(composeKey(composedKey)));
    }
  } else {
    yield put(resetValidationMsg(composeKey(composedKey)));
  }
}
