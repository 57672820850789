import { DutyFiCommon } from '../../types/entries';
import { composeKey } from '../../../helpers';
import { editFields, resetValidationMsg, setValidationMsg } from '../../actions';
import { put } from 'redux-saga/effects';
import { EntryType } from '../../../../../../../common/types/entryTypes';
import { CombinedEntry } from '../../../../../../../common/types/CombinedEntry';

const generateValidationKey = (tabIndex: number, detailIndex: number, field: string) =>
  `${tabIndex}_${detailIndex}_${field}`;

export function* checkDetailedFlightsAmount(
  fi: DutyFiCommon,
  idx: number,
  entryType: EntryType,
  isDetailedFlightsTimesFeature: boolean,
  entry: CombinedEntry,
  editValues: any,
) {
  const key = composeKey(String(idx), editFields.DetailedFlightTimes);
  let totalFlights =
    isDetailedFlightsTimesFeature && fi.DetailedFlightTimes ? fi.DetailedFlightTimes.length : 0;

  const isNonFlyingDuty =
    editValues[editFields.NonFlyingDuty] !== undefined
      ? editValues[editFields.NonFlyingDuty]
      : entry.NonFlyingDuty;

  if (isDetailedFlightsTimesFeature) {
    if (!isNonFlyingDuty) {
      if (entry.AircraftInformation.FlightInformation.length !== 0) {
        if (totalFlights === 0) {
          yield put(
            setValidationMsg(
              key,
              `Please add at least one flight to ${entry.AircraftInformation.FlightInformation[idx]?.AircraftType}`,
            ),
          );
          yield put(resetValidationMsg(editFields.AircraftType));
        } else {
          for (const [detailIndex] of fi.DetailedFlightTimes.entries()) {
            for (const field of ['TimeOff', 'TimeOn', 'TimeOn_TimeOff']) {
              const validationKey = generateValidationKey(idx, detailIndex, field);
              yield put(resetValidationMsg(validationKey));
            }
          }
          yield put(resetValidationMsg(key));
        }
      }
    }
  }
}
