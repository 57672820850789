import { put } from 'redux-saga/effects';
import LocalCache from '../../../../../../local-storage/local-storage';
import { scheduleActions } from '../../actions';
import { selectUserSaga } from '../../../../../../../common/saga/selectors';

export function* setSelectedTagsFromCache() {
  const user = yield* selectUserSaga();
  const userState = yield* selectUserSaga();
  const allowedTags = userState.CompanyUsers[0].AllowedTags;

  if (allowedTags?.length > 0) {
    yield put(scheduleActions.setSelectedTags(allowedTags));
  } else {
    const localCache = new LocalCache('crewSchedulingV2', user);
    yield put(scheduleActions.setSelectedTags(localCache.getCached('selectedTags', [])));
  }
}
