import { all, takeEvery, put, call } from 'redux-saga/effects';
import api from '../../../services/api';
import actionTypes from './actions';
import commonActions from '../../common/actions';

export function* ADD_FILE_REQUEST({ payload }) {
  const formdata = new FormData();
  formdata.append('file', payload?.file);
  formdata.append(
    'json',
    JSON.stringify({
      Description: payload.description,
      Name: payload.name,
      FlightID: payload.aircraftLogId,
    }),
  );

  try {
    const response = yield call(
      api.post,
      `/v1/aircraft/${payload.aircraftId}/documents/${payload.documentId}`,
      formdata,
    );

    if (response.status === 200) {
      yield put({
        type: actionTypes.ADD_DOCUMENT_SUCCESS,
        payload: { newDocument: response.data, confirmSuccess: true },
      });
    }
  } catch (error) {
    const errorData = error?.response?.data;
    yield put({ type: actionTypes.ADD_DOCUMENT_ERROR });
    yield put(
      commonActions.setNotifire({
        type: 'error',
        message: `Error ${errorData?.Code}`,
        description: errorData?.Message,
      }),
    );
  }
}

export function* DELETE_DOCUMENT_REQUEST({ payload }) {
  try {
    const response = yield call(
      api.delete,
      `/v1/aircraft/${payload.aircraftId}/documents/${payload.documentId}`,
    );

    if (response.status === 204) {
      yield put({
        type: actionTypes.DELETE_DOCUMENT_SUCCESS,
        payload: { isDocumentDeleted: true, documentId: payload.documentId },
      });
    }
  } catch (error) {
    const errorData = error?.response?.data;
    yield put({ type: actionTypes.DELETE_DOCUMENT_ERROR });
    yield put(
      commonActions.setNotifire({
        type: 'error',
        message: `Error ${errorData?.Code}`,
        description: errorData?.Message,
      }),
    );
  }
}

export function* EDIT_DOCUMENT_REQUEST({ payload }) {
  const data = {
    Name: payload.name,
    Description: payload.description,
  };

  try {
    const response = yield call(
      api.patch,
      `/v1/aircraft/${payload.aircraftId}/documents/${payload.documentId}/meta`,
      data,
    );

    if (response.status === 200) {
      yield put({
        type: actionTypes.EDIT_DOCUMENT_SUCCESS,
        payload: { isDocumentEdited: true, updatedDocument: response.data.Data },
      });
    }
  } catch (error) {
    const errorData = error?.response?.data;
    yield put({ type: actionTypes.EDIT_DOCUMENT_ERROR });
    yield put(
      commonActions.setNotifire({
        type: 'error',
        message: `Error ${errorData?.Code}`,
        description: errorData?.Message,
      }),
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actionTypes.ADD_FILE_REQUEST, ADD_FILE_REQUEST),
    takeEvery(actionTypes.DELETE_DOCUMENT_REQUEST, DELETE_DOCUMENT_REQUEST),
    takeEvery(actionTypes.EDIT_DOCUMENT_REQUEST, EDIT_DOCUMENT_REQUEST),
  ]);
}
