import { put, select } from 'redux-saga/effects';
import LocalCache from '../../../../../../local-storage/local-storage';
import { scheduleActions } from '../../actions';

export function* setSelectedCrewMemberIdsFromCache() {
  const user = yield select(({ user }) => user);
  const localCache = new LocalCache('crewSchedulingV2', user);
  yield put(
    scheduleActions.setSelectedCrewMemberIds(localCache.getCached('selectedCrewMemberIds', [])),
  );
}
