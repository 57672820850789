import { getPusherKeys } from 'config';
import Pusher from 'pusher-js';
import * as Sentry from '@sentry/react';

function getKeys() {
  const keys = getPusherKeys();
  return {
    pusherKey: keys.pusher_key,
    pusherCluster: keys.pusher_cluster,
  };
}

class PusherClient {
  private static instance: PusherClient = null;
  private pusherKey: string;
  private pusherCluster: string;
  private pusher: Pusher;
  private retryCount: number = 0;
  private maxRetries: number = 5;
  private reconnectDelay: number = 3000;

  private constructor() {
    const keys = getKeys();
    this.pusherKey = keys.pusherKey;
    this.pusherCluster = keys.pusherCluster;

    this.pusher = new Pusher(this.pusherKey, {
      cluster: this.pusherCluster,
    });

    this.pusher.connection.bind('error', (error: string) => {
      console.error('Pusher connection error:', error);
      this.handleReconnection();
    });

    this.pusher.connection.bind('connected', () => {
      console.log('Pusher reconnected successfully.');
      this.retryCount = 0;
    });

    this.pusher.connection.bind('unavailable', () => {
      console.warn('Pusher connection unavailable, attempting reconnection...');
      this.handleReconnection();
    });
  }

  public static getInstance(): Pusher {
    if (!PusherClient.instance) {
      PusherClient.instance = new PusherClient();
    }
    return PusherClient.instance.pusher;
  }

  private handleReconnection() {
    if (this.retryCount < this.maxRetries) {
      this.retryCount++;
      console.log(`Pusher reconnection attempt ${this.retryCount}/${this.maxRetries}`);
      setTimeout(() => {
        this.pusher.disconnect();
        this.pusher.connect();
      }, this.reconnectDelay);
    } else {
      console.error('Pusher connection failed after max retries.');
      Sentry.captureException(new Error('Pusher connection failed after max retries.'));
    }
  }
}


export default PusherClient;
