const permissions = {
  auth: {
    user: null,
  },
  pages: {
    dashboard: true,
    logs: true,
    aircraft: true,
    'fleet-overview': true,
    'duty-times': true,
    users: true,
    'crew-compliance': true,
    'crew-scheduling': true,
    'crew-scheduling-v3': true,
    'crew-scheduling-mobile': true,
    regulations: true,
    reports: true,
    settings: true,
    'me/manage': true,
    'me/duty': true,
    aerochex: true,
    airports: true,
  },
  aircraftLogDetails: {
    actions: {
      editLogDetails: false,
    },
  },
  crewScheduleV2: {
    filters: {
      aircraftType: true,
      crewMember: true,
      crewCertification: true,
      crewMemberBase: true,
      aircraftBase: true,
      displayOption: true,
      companyClient: true,
      clientContract: true,
      companyTag: true,
    },
    timelines: {
      aircraftTimeline: true,
      viewAircraftContracts: true,
      viewAircraftEntries: true,

      crewTimeline: true,
    },
    actions: {
      // add
      addAircraftContract: true,
      addPilotContract: true,
      addScheduleEntry: true,
      addDutyEntry: true,
      addDutyTime: true,

      // update
      updateScheduleEntry: true,
      updateDutyEntry: true,
      updateDutyTime: true,

      // delete
      deleteScheduleEntry: true,
      deleteDutyEntry: true,
      deleteDutyTime: true,
      deletePilotContractEntry: true,

      // copy
      copyAndPasteEntry: false,

      // Management Override
      overrideEntryCompliance: false,
    },
    allowed: {
      tags: null,
    },
    cached: {},
  },
};

export default permissions;
