import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../redux/pusher/PusherActions';
import { call, put, select } from 'redux-saga/effects';
import { getRouter } from '../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import { scheduleActions } from '../../actions';
import { selectVisibleTimelinePeriodSaga } from '../../selectors-saga';
import { fetchTimelineEntries } from '../timelineEntries/fetchTimelineEntries';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';

export function* handleAircraftContractBulkUpdate(
  action: PusherReduxAction<PusherActionType.AIRCRAFT_CONTRACTS_BULK_UPDATE>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  const visiblePeriod = yield* selectVisibleTimelinePeriodSaga();

  yield put(scheduleActions.reFetchAllEntries());
  yield call(
    fetchTimelineEntries,
    visiblePeriod.getStart().format(),
    visiblePeriod.getEnd().format(),
    AircraftTimelineEntryType.AircraftContract,
    false,
  );
}
