export type EntryType = ContractTimelineEntryType;

export enum ContractTimelineEntryType {
  AircraftContract = 'contract-aircraft-contract',
  PilotSchedule = 'pilot-schedule',
  ClientContractPeriod = 'client-contract-period',
}

const sortOrder = [ContractTimelineEntryType.AircraftContract];

const compareTimelines = (a: ContractTimelineEntryType, b: ContractTimelineEntryType): number => {
  return sortOrder.indexOf(a) - sortOrder.indexOf(b);
};

export const sortContractTimelineEntryTypes = (
  timelines: ContractTimelineEntryType[],
): ContractTimelineEntryType[] => {
  return timelines.sort(compareTimelines);
};
