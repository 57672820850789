import { put, select } from 'redux-saga/effects';
import { reloadAirGroupSchedule } from '../actions';

export function* reloadCrewScheduleAfterActionWorker() {
  yield put(reloadAirGroupSchedule());
  const entryPeriod = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2.entryPeriod,
  );
  const [startTime, endTime] = entryPeriod;
  const config = { startTime, endTime, isShowLoading: true };
  // yield put(loadCrewScheduleEntries(config));
  // yield put(loadAircraftScheduleEntries(config, []));
}
