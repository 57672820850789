import { scheduleActions, SwitchTimelinePeriodAction } from '../../actions';
import { put } from 'redux-saga/effects';
import { selectCurrentViewSaga, selectSwitchedTimelinePeriodSaga } from '../../selectors-saga';

export function* switchTimelinePeriodWorker(action: SwitchTimelinePeriodAction) {
  const { periodSwitch } = action.payload;

  const timePeriod = yield* selectSwitchedTimelinePeriodSaga();
  const currentView = yield* selectCurrentViewSaga();

  const updatedTimePeriod = timePeriod.switchPeriod(currentView, periodSwitch);

  yield put(scheduleActions.setState({ switchedTimelinePeriod: updatedTimePeriod }));
}
