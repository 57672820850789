import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import actions from '../actions';

export function* loadCompanyDocumentsWorker() {
  try {
    const queryParams = {
      limit: 50,
      page: 1,
    };
    const {
      data: { Data },
    } = yield call(api.get, `/v1/company-library/list`, {
      params: queryParams,
    });

    yield put(actions.setCompanyDocuments(Data));
  }  catch (err) {
    console.log('Failed to load company documents list', err);
  }
}
