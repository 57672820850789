import { editFields, setEditValue } from '../../actions';
import moment from 'moment-timezone';
import { put } from 'redux-saga/effects';
import { CombinedEntry } from '../../../../../../../common/types/CombinedEntry';

export function* runOnReserveDutyCalc(editedField: string, editValues: any, entry: CombinedEntry) {
  if (editedField === editFields.IsOnReserve && editValues[editFields.IsOnReserve]) {
    const startTime =
      editValues[editFields.DutyFrom] !== undefined
        ? editValues[editFields.DutyFrom]
        : entry.StartTime && moment(entry.StartTime);

    const endTime =
      editValues[editFields.DutyTo] !== undefined
        ? editValues[editFields.DutyTo]
        : entry.EndTime && moment(entry.EndTime);

    const FDPEndTime =
      editValues[editFields.FDPTo] !== undefined
        ? editValues[editFields.FDPTo]
        : entry.FlightDutyPeriodEndTime && moment(entry.FlightDutyPeriodEndTime);

    if (startTime) {
      yield put(setEditValue(editFields.RestStart, startTime));
      yield put(setEditValue(editFields.RAPStartTime, startTime));
    }

    if (FDPEndTime) {
      yield put(setEditValue(editFields.RestEnd, endTime));
    }

    if (endTime) {
      yield put(setEditValue(editFields.RAPEndTime, endTime));
    }

    const isNonFlyingDuty =
      editValues[editFields.NonFlyingDuty] !== undefined
        ? editValues[editFields.NonFlyingDuty]
        : entry.NonFlyingDuty;
    if (!startTime && !endTime && !isNonFlyingDuty) {
      yield put(setEditValue(editFields.NonFlyingDuty, true));
    }
  }
}
