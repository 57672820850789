import { put, select } from 'redux-saga/effects';
import LocalCache from '../../../../pages/local-storage/local-storage';
import { getCommon } from '../../../selectors';
import { CommonInitState } from '../../../common/reducers';
import { setState } from '../actions';
import { AircraftSelectorType } from '../../../common/types';

export function* setSelectedAircraftIdentifiersFromCache() {
  const user = yield select(({ user }) => user);
  const localCache = new LocalCache('crewSchedulingV2', user);
  const { aircraftIdentifiers, aircraftList, aircraftSelectorType } = (yield select(
    getCommon,
  )) as CommonInitState;
  const allAllowedAircraft = aircraftList.filter(aircraft => {
    if (aircraftSelectorType === AircraftSelectorType.Registration) {
      return aircraftIdentifiers.includes(aircraft.Registration);
    }
    return aircraftIdentifiers.includes(aircraft.TailNumber);
  });

  const cachedIdentifiers = localCache.getCached('selectedAircraftIdentifiers', []);

  const selectedAircraftIdentifiers = cachedIdentifiers.filter(identifier => {
    if (aircraftSelectorType === AircraftSelectorType.Registration) {
      return allAllowedAircraft.some(aircraft => aircraft.Registration === identifier);
    }
    return allAllowedAircraft.some(aircraft => aircraft.TailNumber === identifier);
  });

  if (selectedAircraftIdentifiers.length === 0) {
    if (cachedIdentifiers.length > 0) {
      yield localCache.setCached('selectedAircraftIdentifiers', []);
      yield localCache.setCached('selectedAircraftTypes', []);
      yield localCache.setCached('selectedAircraftBases', []);
    }
    yield put(
      setState({
        selectedAircraftIdentifiers: [],
        selectedAircraft: allAllowedAircraft,
      }),
    );
  } else {
    yield put(
      setState({
        selectedAircraftIdentifiers: selectedAircraftIdentifiers,
        selectedAircraft: allAllowedAircraft.filter(aircraft => {
          if (aircraftSelectorType === AircraftSelectorType.Registration) {
            return selectedAircraftIdentifiers.includes(aircraft.Registration);
          }
          return selectedAircraftIdentifiers.includes(aircraft.TailNumber);
        }),
      }),
    );
  }
}
