import { CombinedEntry } from '../../../../../../common/types/CombinedEntry';
import { editFields } from '../actions';
import moment from 'moment-timezone';
import { EntryType } from '../../../../../../common/types/entryTypes';
import { apiEntryTypeMap } from '../../../../../../common/types/apiEntryTypes';
import {
  CommonDutyEntryAPI,
  DutyScheduleEntryAPI,
  DutyTimeEntryAPI,
  DutyFiCommon,
  CommonEntryAPI,
  CrewScheduleEntryAPI,
} from '../types/entries';
import { uniq } from 'lodash';
import { serializeCustomCurrencyItems } from '../../../../../../common/helpers/customCurrencyItems';
import { CustomCurrencyItemTemplate } from '../../../../../../common/types/customCurrencyItem';

function getFIEditValue(editValues: any, idx: number, key: string) {
  const val = editValues[`${idx}_${key}`];
  return val !== undefined ? val : null;
}

export function serializeEntry(
  input: CombinedEntry,
  editValues: any,
  entryType: EntryType,
  isDetailedFlightsTimesFeature: boolean,
  isNewEntry: boolean,
  customCurrencyItemTemplates: CustomCurrencyItemTemplate[] = [],
): DutyTimeEntryAPI | DutyScheduleEntryAPI | CrewScheduleEntryAPI {
  let startTime = null;
  let endTime = null;
  if (editValues[editFields.DutyFrom] !== null) {
    startTime =
      editValues[editFields.DutyFrom]?.format() ||
      (input.StartTime && moment(input.StartTime).format());
  }
  if (editValues[editFields.DutyTo] !== null) {
    endTime =
      editValues[editFields.IsOngoing] == true
        ? null
        : editValues[editFields.DutyTo]?.format() ||
          (input.EndTime && moment(input.EndTime).format());
  }
  const commonFields: CommonEntryAPI = {
    ID: input.ID,
    Notes: editValues[editFields.Notes] != undefined ? editValues[editFields.Notes] : input.Notes,
    CompanyID: input.CompanyID,
    StartAirportID: editValues[editFields.StartAirportID] || input.StartAirportID,
    EndAirportID: editValues[editFields.EndAirportID] || input.EndAirportID,
    StartTime: startTime,
    EndTime: endTime,
  };

  if (entryType === apiEntryTypeMap.CrewScheduleEntries) {
    const crewScheduleEntry: CrewScheduleEntryAPI = {
      ...commonFields,
      Tag: editValues[editFields.Tag] || input.Tag,
      UserID: input.UserID,
      CompanyScheduleTypeID:
        editValues[editFields.CompanyScheduleTypeID] || input.CompanyScheduleTypeID,
      AssignedAircraft: editValues[editFields.AssignedAircraft] || input.AssignedAircraft || [],
      AssignedClients: editValues[editFields.AssignedClient] || input.AssignedClients || [],
      Subparts: editValues[editFields.SubParts] || input.Subparts || [],
      CustomSections: editValues[editFields.CustomSections] || input.CustomSections || [],
      Version: input.Version,
    };

    return crewScheduleEntry;
  }
  const isOnReserve =
    editValues[editFields.IsOnReserve] !== undefined
      ? editValues[editFields.IsOnReserve]
      : !!(input.RAPStartTime && input.RAPStartTime);

  const isNonFlyingDuty =
    editValues[editFields.NonFlyingDuty] !== undefined
      ? editValues[editFields.NonFlyingDuty]
      : input.NonFlyingDuty;

  const dutyCommonFields: CommonDutyEntryAPI = {
    ...commonFields,
    RestAirportID:
      editValues[editFields.RestAirportID] || (input.RestAirportID ? input.RestAirportID : ''),
    FlightDutyPeriodEndTime: isNonFlyingDuty
      ? null
      : editValues[editFields.FDPTo]?.format() ||
        (input.FlightDutyPeriodEndTime && moment(input.FlightDutyPeriodEndTime).format()),
    NonFlyingDuty: isNonFlyingDuty,
    OnCallDuty:
      editValues[editFields.IsOnCallDuty] !== undefined
        ? editValues[editFields.IsOnCallDuty]
        : input.OnCallDuty,
    ManagementOverride:
      editValues[editFields.ManagementOverride] !== undefined
        ? editValues[editFields.ManagementOverride]
        : input.ManagementOverride,
    RAPStartTime: isOnReserve
      ? editValues[editFields.RAPStartTime] ||
        (input.RAPStartTime && moment(input.RAPStartTime).format())
      : null,
    RAPEndTime: isOnReserve
      ? editValues[editFields.RAPEndTime] || (input.RAPEndTime && moment(input.RAPEndTime).format())
      : null,
    PositioningAfterMaxFDP:
      editValues[editFields.IsPositioningAfterMaxFDP] !== undefined
        ? editValues[editFields.IsPositioningAfterMaxFDP]
        : !!input.PositioningAfterMaxFDP,
    ComplianceOverrideReason:
      editValues[editFields.OverrideNotes] || input.ComplianceOverrideReason,
  };

  if (entryType === apiEntryTypeMap.DutyTimeEntries) {
    const isSplitDuty =
      editValues[editFields.IsSplitDuty] !== undefined
        ? editValues[editFields.IsSplitDuty]
        : !!(input.RestStart && input.RestEnd);

    return {
      ...dutyCommonFields,
      UserID: input.UserID,
      RestStart: isSplitDuty
        ? editValues[editFields.RestStart] || (input.RestStart && moment(input.RestStart).format())
        : null,
      RestEnd: isSplitDuty
        ? editValues[editFields.RestEnd] || (input.RestEnd && moment(input.RestEnd).format())
        : null,
      Subparts: editValues[editFields.SubParts] || input.Subparts,
      UnforeseenExtension: input.UnforeseenExtension,
      // AcclimatizedTimezone: string;

      AircraftInformation: {
        SubParts: editValues[editFields.SubParts] || input.Subparts,
        FlightInformation: buildDutyFI(
          input,
          editValues,
          isDetailedFlightsTimesFeature,
          isNewEntry,
          entryType,
          customCurrencyItemTemplates,
        ),
      },
    };
  } else if (entryType === apiEntryTypeMap.DutyScheduleEntries) {
    const isSplitDuty =
      editValues[editFields.IsSplitDuty] !== undefined
        ? editValues[editFields.IsSplitDuty]
        : !!(input.RestStartTime && input.RestEndTime);
    const entry: DutyScheduleEntryAPI = {
      ...dutyCommonFields,
      Pilot: input.UserID,
      RestStartTime: isSplitDuty
        ? editValues[editFields.RestStart] ||
          (input.RestStartTime && moment(input.RestStartTime).format())
        : null,
      RestEndTime: isSplitDuty
        ? editValues[editFields.RestEnd] ||
          (input.RestEndTime && moment(input.RestEndTime).format())
        : null,
      AircraftInformation: {
        FlightInformation: buildDutyFI(
          input,
          editValues,
          isDetailedFlightsTimesFeature,
          isNewEntry,
          entryType,
          customCurrencyItemTemplates,
        ),
      },
      Version: input.Version,
    };
    return entry;
  } else {
    throw new Error(`Unsupported entryType: ${entryType}`);
  }
}

const buildEmptyDutyFi = (isDetailedFlightsTimesFeature: boolean): DutyFiCommon => {
  return {
    AircraftType: null,
    NumberOfFlights: isDetailedFlightsTimesFeature ? 0 : 1,
    FlightTime: 0,
    IFRHours: 0,
    VFRHours: 0,
    IFRApproaches: 0,
    NightTakeoffs: 0,
    NightLandings: 0,
    DayTakeoffs: 0,
    DayLandings: 0,
    DetailedFlightTimes: undefined,
    Subparts: [],
  };
};

export const buildDutyFI = (
  input: CombinedEntry,
  editValues: any,
  isDetailedFlightsTimesFeature: boolean,
  fillDefaultValues = false,
  entryType: EntryType,
  customCurrencyItemTemplates: CustomCurrencyItemTemplate[] = [],
): DutyFiCommon[] => {
  const isSinglePilot =
    editValues[editFields.IsSinglePilot] !== undefined
      ? editValues[editFields.IsSinglePilot]
      : (input.AircraftInformation?.FlightInformation || []).every(fi => fi.SinglePilot);
  const isAllDayVFR =
    editValues[editFields.IsAllDayVFR] !== undefined
      ? editValues[editFields.IsAllDayVFR]
      : (input.AircraftInformation?.FlightInformation || []).every(fi => fi.AllDayVFR);

  const uniqSubpartList = uniq(
    (input?.AircraftInformation?.FlightInformation?.map(el => el.Subparts) || []).flat(),
  );

  const subpartList =
    editValues[editFields.SubParts] ||
    input.Subparts ||
    (input.AircraftInformation?.FlightInformation?.length > 0 && uniqSubpartList) ||
    [];

  const parsedFlightInfo = Object.keys(editValues).reduce((acc, key) => {
    const idx = key.split('_')[0];
    if (!idx) return acc;
    const propName = key.replace(`${idx}_`, '');
    if (!acc[idx]) {
      acc[idx] = {};
    }
    acc[idx][propName] = editValues[key];
    return acc;
  }, []);

  let flightInformationArr = input.AircraftInformation.FlightInformation;
  if (entryType === apiEntryTypeMap.DutyScheduleEntries && flightInformationArr?.length > 0) {
    flightInformationArr = flightInformationArr.filter(fi => !!fi.AircraftType);
  }
  const fiByTypeLen = Math.max(parsedFlightInfo.length, flightInformationArr?.length || 0);

  const fiUpdates = parsedFlightInfo.map((updates, idx) => {
    // todo: remove this hack, set AircraftType in editValues
    const fi = flightInformationArr[idx];
    let aircraftType = fi?.AircraftType;

    if (fillDefaultValues) {
      return {
        ...buildEmptyDutyFi(isDetailedFlightsTimesFeature),
        ...updates,
        SinglePilot: isSinglePilot,
        AllDayVFR: isAllDayVFR,
        AircraftType: aircraftType,
        Subparts: subpartList,
      };
    }

    return {
      ...updates,
      SinglePilot: isSinglePilot,
      AllDayVFR: isAllDayVFR,
      AircraftType: aircraftType,
      Subparts: subpartList,
    };
  });

  const res: DutyFiCommon[] = [];
  for (let idx = 0; idx < fiByTypeLen; idx++) {
    const fiUpdate = fiUpdates[idx];
    const fi = flightInformationArr[idx];

    let detailedFlightTimes;
    if (fiUpdate?.DetailedFlightTimes) {
      detailedFlightTimes = fiUpdate.DetailedFlightTimes;
    } else {
      detailedFlightTimes = fi?.DetailedFlightTimes;
    }

    let flightHours = 0;
    flightHours = Math.max(fi?.FlightTime, fiUpdate?.totalFlightHours, 0);

    const entry = {
      ...{
        AircraftID: fi?.AircraftID,
        AircraftType: fi?.AircraftType,
        NumberOfFlights:
          getFIEditValue(editValues, idx, editFields.numberOfFlights) || fi?.NumberOfFlights,
        IFRHours: getFIEditValue(editValues, idx, editFields.IFRHours) || fi?.IFRHours,
        VFRHours: getFIEditValue(editValues, idx, editFields.VFRHours) || fi?.VFRHours,
        IFRApproaches:
          getFIEditValue(editValues, idx, editFields.IFRApproaches) || fi?.IFRApproaches,
        NightTakeoffs:
          getFIEditValue(editValues, idx, editFields.nightTakeoffs) || fi?.NightTakeoffs,
        NightLandings:
          getFIEditValue(editValues, idx, editFields.nightLandings) || fi?.NightLandings,
        DayTakeoffs: getFIEditValue(editValues, idx, editFields.dayTakeoffs) || fi?.DayTakeoffs,
        DayLandings: getFIEditValue(editValues, idx, editFields.dayLandings) || fi?.DayLandings,
        DetailedFlightTimes: fi?.DetailedFlightTimes || [],
        // todo: maybe wrong for multiple aircraft types
        TotalFlightHours:
          getFIEditValue(editValues, idx, editFields.totalFlightHours) || flightHours,
        FlightTime: getFIEditValue(editValues, idx, editFields.totalFlightHours) || flightHours,
        SinglePilot: isSinglePilot,
        AllDayVFR: isAllDayVFR,
        Subparts: subpartList,
      },
      ...fiUpdate,
      DetailedFlightTimes: detailedFlightTimes,
      CustomCurrencyItems: serializeCustomCurrencyItems(customCurrencyItemTemplates, fiUpdate),
    };

    if (entryType === apiEntryTypeMap.DutyScheduleEntries) {
      entry.NumberOfFlights = entry.numberOfFlights || entry.NumberOfFlights;
      entry.FlightTime = entry.totalFlightHours || entry.FlightTime;
      delete entry['TotalFlightHours'];
      delete entry['numberOfFlights'];
      delete entry['totalFlightHours'];
    }

    if (entryType === apiEntryTypeMap.DutyTimeEntries) {
      entry.FlightTime = entry.TotalFlightHours;
    }

    res.push(entry);
  }

  return res;
};
