import {
  additionalInfoFields,
  RunValidationAction,
  setAdditionalInfoValues,
  setFreshIssues,
  setValidating,
} from '../actions';
import { call, put, select } from 'redux-saga/effects';
import { serializeEntry } from './common';
import api from '../../../../../../services/api';
import getTimeString from '../../../crew-scheduling-v3/legacy/utils/getTimeString';
import { apiEntryTypeMap } from '../../../../../../common/types/apiEntryTypes';
import { CrewScheduleEntryAPI, DutyScheduleEntryAPI, DutyTimeEntryAPI } from '../types/entries';
import { FeatureFlag } from '../../../../../../utils/feature-flags/FeatureFlagsProvider';
import { getCommon, getCSMobile } from '../selectors';

export function* runValidationWorker(action: RunValidationAction) {
  const { entry, entryType } = action.payload;
  const { editValues, isNewEntry } = yield select(getCSMobile);
  const { featureFlags, customCurrencyItemTemplates } = yield select(getCommon);

  if (entry.IsPrivateEntry) {
    return;
  }

  try {
    yield put(setValidating(true));

    let request;
    if (entryType === apiEntryTypeMap.DutyScheduleEntries) {
      const data = serializeEntry(
        entry,
        editValues,
        entryType,
        featureFlags[FeatureFlag.DetailedFlightTimes],
        isNewEntry,
        customCurrencyItemTemplates,
      ) as DutyScheduleEntryAPI;
      request = call(api.post, `/v1/users/${data.Pilot}/duty-schedule/validate/v2`, data);
    } else if (entryType === apiEntryTypeMap.DutyTimeEntries) {
      const data = serializeEntry(
        entry,
        editValues,
        entryType,
        featureFlags[FeatureFlag.DetailedFlightTimes],
        isNewEntry,
        customCurrencyItemTemplates,
      ) as DutyTimeEntryAPI;
      request = call(api.post, `/v1/users/${data.UserID}/duty/validate`, data);
    } else if (entryType === apiEntryTypeMap.CrewScheduleEntries) {
      const data = serializeEntry(
        entry,
        editValues,
        entryType,
        featureFlags[FeatureFlag.DetailedFlightTimes],
        isNewEntry,
        customCurrencyItemTemplates,
      ) as CrewScheduleEntryAPI;

      if (!data.StartTime) {
        return;
      }
      request = call(api.post, `/v1/companies/${data.CompanyID}/crewschedule/validate`, data);
    }

    const response = yield request;

    const {
      data: {
        Data: {
          ComplianceSummary: { Issues, ValidationState },
        },
      },
    } = response;

    if (!!Issues) {
      yield put(setFreshIssues(Issues));
    }

    if (
      entryType !== apiEntryTypeMap.CrewScheduleEntries &&
      entryType !== apiEntryTypeMap.AircraftScheduleEntries &&
      !!ValidationState
    ) {
      const validationState = JSON.parse(ValidationState);
      const restBeforeString =
        validationState.RestBeforeDutyTime < 24 * 365
          ? getTimeString(validationState.RestBeforeDutyTime * 60)
          : 'N/A';

      const restAfterString =
        validationState.RestAfterDutyTime < 24 * 365
          ? getTimeString(validationState.RestAfterDutyTime * 60)
          : 'N/A';

      const maxDutyTimeString = getTimeString(validationState.MaxDutyDuration * 60);
      let maxFDP = 'N/A';
      if (validationState.ModalMaxFDP > 0) {
        const maxFDPString = getTimeString(validationState.ModalMaxFDP * 60);
        maxFDP = maxFDPString;
      }

      yield put(
        setAdditionalInfoValues({
          [additionalInfoFields.maxFDP]: maxFDP,
          [additionalInfoFields.maxDutyTime]: maxDutyTimeString,
          [additionalInfoFields.restAfter]: restAfterString,
          [additionalInfoFields.restBefore]: restBeforeString,
        }),
      );
    }
  } catch (err) {
    console.log('Failed to validate entry: ', err);
  } finally {
    yield put(setValidating(false));
  }
}
