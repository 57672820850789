import { call, put, select } from 'redux-saga/effects';
import api from '../../../../../../../services/api';
import LocalCache from '../../../../../../local-storage/local-storage';
import { Tag } from '../../../../../../../common/types/tag';
import { scheduleActions } from '../../actions';
import {
  selectCompanyIDSaga,
  selectUserRoleSaga,
  selectUserSaga,
} from '../../../../../../../common/saga/selectors';
import { selectSelectedTagsSaga } from '../../selectors-saga';

export function* fetchCompanyTagsWorker() {
  const companyID = yield* selectCompanyIDSaga();
  const {
    data: { Data },
  } = yield call(api.get, `/v1/companies/${companyID}/company-tag`);

  const companyTags: Tag[] = Data;
  const selectedTags = yield* selectSelectedTagsSaga();
  const userState = yield* selectUserSaga();
  const allowedTags = userState.CompanyUsers[0].AllowedTags;

  if (selectedTags.length > 0 && Data.length > 0) {
    const companyTagsIds = companyTags.map(t => t.ID);
    let filteredTags = selectedTags.filter(tag => companyTagsIds.includes(tag));

    if (allowedTags?.length > 0) {
      filteredTags = allowedTags;
    }

    yield put(scheduleActions.setSelectedTags(filteredTags));

    const user = yield select(({ user }) => user);
    const localCache = new LocalCache('crewSchedulingV2', user);
    localCache.setCached('selectedTags', filteredTags);
  }

  yield put(scheduleActions.setCompanyTags(companyTags));
}
