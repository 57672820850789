import {
  CombinedEntry,
  CompanyScheduleType,
} from '../../../../../../../common/types/CombinedEntry';
import { editFields } from '../../actions';
import { select } from 'redux-saga/effects';
import { getCommon } from '../../selectors';
import { runFieldsValidationWorker } from '../fieldsValidations';

export function* afterCrewScheduleEntyTypeChange(editValues: any, entry: CombinedEntry) {
  const scheduleEntryTypeID =
    editValues[editFields.CompanyScheduleTypeID] !== undefined
      ? editValues[editFields.CompanyScheduleTypeID]
      : entry.CompanyScheduleType?.ID;

  if (!scheduleEntryTypeID) {
    return;
  }

  const { companyScheduleTypes } = yield select(getCommon);

  const scheduleType: CompanyScheduleType = companyScheduleTypes.find(
    type => type.ID === scheduleEntryTypeID,
  );
  if (!scheduleType) {
    return;
  }

  yield runFieldsValidationWorker(editFields.CustomSections);
}
