import React from 'react';
import { Tag } from 'antd';
import moment from 'moment-timezone';

const TZTagUTC = ({ isMenuCollapsed }) => {
  const tz = moment().tz();
  const ts = Date.now();
  const formatOffset = () => {
    return `UTC${moment.tz(tz).format('Z')}`;
  };
  const tagStyle = {
    backgroundColor: '#012d56',
    border: '0px',
    color: 'white',
    fontSize: '10px',
  };
  return (
    <div>
      {!isMenuCollapsed && <Tag style={tagStyle}>{tz}</Tag>}
      <Tag style={tagStyle}>{formatOffset()}</Tag>
    </div>
  );
};

export default TZTagUTC;
