import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/selectors';

class LocalCache {
  user = {};

  nameEQ = '';

  ca = '';

  jwt = '';

  page = '';

  constructor(page, user) {
    this.page = page;
    if (user) {
      this.user = user;
    } else {
      this.user = useSelector(getUser);
    }
  }

  getCached(key, defaultReturnValue) {
    if (this.user?.id.length > 0) {
      this.filters = window.localStorage.getItem(`filters-${this.user?.id}`);
      this.filters = JSON.parse(this.filters?.length > 0 ? this.filters : '{}');

      return get(this.filters, `${this.page}.${key}`, defaultReturnValue);
    }

    return defaultReturnValue;
  }

  async setCached(key, value) {
    // this.jwt = this.getCookie('airtrail_jwt_payload')
    if (this.user?.id.length > 0) {
      this.filters = window.localStorage.getItem(`filters-${this.user?.id}`);
      this.filters = JSON.parse(this.filters?.length > 0 ? this.filters : '{}');
      if (!this.filters[this.page]) {
        this.filters[this.page] = {};
      }
      this.filters[this.page][key] = value;
      window.localStorage.setItem(`filters-${this.user?.id}`, JSON.stringify(this.filters));
    }
  }
}

export default LocalCache;
