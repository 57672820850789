import { all, takeEvery, put, call } from 'redux-saga/effects';
import api from '../../../services/api';
import { Types } from './actions';
import { AnyAction } from 'redux';
import { ApiTypes } from 'models/index';
import { removeEmptyParams } from 'utils/removeEmptyParams';
import moment from 'moment-timezone';

export function* GET_CURRENCY(action: AnyAction) {
  const params: Record<string, string> = {
    start_time__gte: moment(action.payload?.start_time__gte)
      .startOf('day')
      .toISOString(),
    end_time__lte: moment(action.payload?.end_time__lte)
      .endOf('day')
      .toISOString(),
    aircraft_type: action.payload?.aircraft_type,
    pilot_ids: action.payload?.pilot_ids,
    active: action.payload?.active,
  };
  const paramsCleared = removeEmptyParams(params);

  try {
    const response: ApiTypes.ResponseGenerator = yield call(
      api.get,
      `/v1/cco/${action.payload.companyID}/flight-currency`,
      { params: paramsCleared },
    );

    if (response.status === 200) {
      yield put({
        type: Types.GET_CURRENCY_SUCCESS,
        payload: response.data?.Data,
      });

      yield put({
        type: Types.SET_TOTAL_COUNT,
        payload: response.data?.Total,
      });
    }
  } catch (error) {
    yield put({ type: Types.GET_CURRENCY_ERROR });
  }
}

export function* GET_AIRCRAFT_TYPES(action: AnyAction) {
  try {
    const response: ApiTypes.ResponseGenerator = yield call(
      api.get,
      `/v1/cco/${action.payload}/aircraft-types`,
    );

    if (response.status === 200) {
      yield put({
        type: Types.GET_AIRCRAFT_TYPES_SUCCESS,
        payload: response.data?.Data,
      });
    }
  } catch (error) {
    yield put({ type: Types.GET_AIRCRAFT_TYPES_ERROR });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(Types.GET_CURRENCY_REQUEST, GET_CURRENCY),
    takeEvery(Types.GET_AIRCRAFT_TYPES_REQUEST, GET_AIRCRAFT_TYPES),
  ]);
}
