namespace Jwt {
  const key = 'airtrail_jwt';

  export const get = (): string | null => {
    return localStorage.getItem(key);
  };

  export const set = (value: string) => {
    localStorage.setItem(key, value);
  };

  export const clear = () => {
    localStorage.removeItem(key);
  };
}

export default Jwt;
