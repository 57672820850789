import { CombinedEntry, DetailedFlightTime } from '../../../../../../common/types/CombinedEntry';
import moment from 'moment-timezone';

export const adjustEntryDetailedFlightTimes = (entry: CombinedEntry) => {
  const ai = entry.AircraftInformation;
  const fiArr = ai.FlightInformation;
  if (fiArr && entry.StartTime) {
    const updatedFiArr = [];
    const startTime: moment.Moment = moment(entry.StartTime);
    for (const fi of fiArr) {
      const updatedFlightTimes = adjustDetailedFlightTimes(fi.DetailedFlightTimes, startTime);
      updatedFiArr.push({
        ...fi,
        DetailedFlightTimes: updatedFlightTimes,
      });
    }
    ai.FlightInformation = updatedFiArr;
  }

  return entry;
};

const adjustDetailedFlightTimes = (
  detailedFlightTimes: DetailedFlightTime[],
  daySource: moment.Moment,
): DetailedFlightTime[] => {
  if (!detailedFlightTimes) {
    return null;
  }
  const updatedFlights = [];

  for (const flight of detailedFlightTimes) {
    const timeOff = moment(flight.TimeOff);
    const timeOn = moment(flight.TimeOn);
    if (
      timeOff.get('date') !== daySource.get('date') ||
      timeOff.get('month') !== daySource.get('month')
    ) {
      const daysDiff = daySource.diff(timeOff, 'days', true);
      if (daysDiff !== 0) {
        updatedFlights.push({
          ...flight,
          TimeOff: timeOff
            .add(daysDiff, 'days')
            .utc()
            .format(),
          TimeOn: timeOn
            .add(daysDiff, 'days')
            .utc()
            .format(),
        });
      } else {
        updatedFlights.push(flight);
      }
    } else {
      updatedFlights.push(flight);
    }
  }

  return updatedFlights;
};

export const getNewEntryPeriodRange = (
  currentView: string,
  visibleTimeStart: number,
  visibleTimeEnd: number,
  defaultEntryPeriod: [moment.Moment, moment.Moment],
): [moment.Moment, moment.Moment] => {
  if (currentView?.length > 0) {
    if (currentView === '3-month') {
      return [
        moment(visibleTimeStart)
          .startOf('month')
          .subtract(3, 'month'),
        moment(visibleTimeEnd)
          .endOf('month')
          .add(6, 'month'),
      ];
    } else if (currentView === 'year') {
      return [
        moment(visibleTimeStart)
          .startOf('year')
          .subtract(2, 'month'),
        moment(visibleTimeStart)
          .endOf('year')
          .add(2, 'month'),
      ];
    } else if (currentView === 'day') {
      return [
        moment(visibleTimeStart)
          .startOf('day')
          .subtract(1, 'week'),
        moment(visibleTimeEnd)
          .endOf('day')
          .add(1, 'week'),
      ];
    } else {
      // todo: add correct types
      return [
        // @ts-ignore
        moment(visibleTimeStart)
          // @ts-ignore
          .startOf(currentView)
          .subtract(2, currentView),
        // @ts-ignore
        moment(visibleTimeEnd)
          // @ts-ignore
          .endOf(currentView)
          .add(2, currentView),
      ];
    }
  }
  return defaultEntryPeriod;
};
