import { CombinedEntry } from '../../../../../../../../common/types/CombinedEntry';
import { editFields } from '../../../../redux-saga/actions';
import { apiEntryTypeMap } from '../../../../../../../../common/types/apiEntryTypes';
import moment from 'moment-timezone';

export const isEntryOngoing = (
  entryType: string,
  entry: CombinedEntry,
  editValues: Record<string, any>,
  isNewEntry: boolean,
): boolean => {
  const dutyTo =
    editValues[editFields.DutyTo] !== undefined
      ? moment(editValues[editFields.DutyTo])
      : entry.EndTime && moment(entry.EndTime);

  const RAPStartTimeKey =
    entryType === apiEntryTypeMap.DutyScheduleEntries ? 'RAPStartTime' : 'RAPStartTime';
  const RAPEndTimeKey =
    entryType === apiEntryTypeMap.DutyScheduleEntries ? 'RAPEndTime' : 'RAPEndTime';
  const RAPEndTime =
    editValues[editFields.RAPEndTime] || (entry.RAPEndTime && moment(entry.RAPEndTime));

  const isOnReserve =
    editValues[editFields.IsOnReserve] !== undefined
      ? editValues[editFields.IsOnReserve]
      : !!(entry[RAPStartTimeKey] && entry[RAPEndTimeKey]);

  let endTime = dutyTo;
  if (isOnReserve) {
    endTime = RAPEndTime;
  }

  return editValues[editFields.IsOngoing] !== undefined
    ? editValues[editFields.IsOngoing]
    : isNewEntry
    ? false
    : !endTime;
};
