import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { scheduleActions } from '../../../actions';
import { put, select } from 'redux-saga/effects';
import {
  selectCrewScheduleTimelineEntriesSaga,
  selectPreferredVersionSaga,
} from '../../../selectors-saga';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import { isIgnoreUpdate } from '../helpers';

export function* handleCrewScheduleEntryDelete(
  action: PusherReduxAction<PusherActionType.CREW_SCHEDULE_ENTRY_DELETED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  try {
    const { payload } = action;

    const { CrewScheduleSandboxID } = payload;
    const preferredVersion = yield* selectPreferredVersionSaga();
    if (isIgnoreUpdate(preferredVersion, CrewScheduleSandboxID)) {
      return;
    }

    const existedEntries = yield* selectCrewScheduleTimelineEntriesSaga();
    const entryToDelete = existedEntries.find(
      crewScheduleEntry => crewScheduleEntry.ID === payload.ID,
    );
    const updatedEntries = existedEntries.filter(
      dutyScheduleTimelineEntry => dutyScheduleTimelineEntry.ID !== payload.ID,
    );

    yield put(scheduleActions.setState({ crewScheduleTimelineEntries: updatedEntries }));
    yield put(scheduleActions.reFetchAircraftScheduleEntries(entryToDelete?.AssignedAircraftIDs));
  } finally {
  }
}
