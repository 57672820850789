import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

const mapStateToProps = ({ breadcrumbs = [] }) => ({
  crumbList: breadcrumbs,
});

@connect(mapStateToProps)
class Breadcrumbs extends React.Component {
  state = {
    crumbs: [],
    crumbList: [], // eslint-disable-line react/no-unused-state
  };

  getAllBreadcrumbs = props => {
    return props.crumbList.map(val => this.getBreadcrumb(val));
  };

  getBreadcrumb = crumb => {
    let breadcrumbDisplay;
    if (crumb.link) {
      breadcrumbDisplay = <Link to={crumb.link}>{crumb.title}</Link>;
    } else {
      breadcrumbDisplay = crumb.title;
    }

    return <Breadcrumb.Item key={crumb.title}>{breadcrumbDisplay}</Breadcrumb.Item>;
  };

  render() {
    const { crumbs } = this.state;
    return (
      <Breadcrumb separator="·" style={{ fontSize: '18px', lineHeight: 'inherit' }}>
        {crumbs}
      </Breadcrumb>
    );
  }
}

export default Breadcrumbs;
