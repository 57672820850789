import { Reducer } from 'redux';
import integrationsActions, { IntegrationsAction } from './actions';

export interface IntegrationsState {
  campConfig?: {
    id: string;
    companyId: string;
  };
}

const initialState: IntegrationsState = {};

const integrationsReducer: Reducer<IntegrationsState, IntegrationsAction> =
  (state = initialState, action) => {
    switch (action.type) {
    case integrationsActions.SET_CAMP_CONFIG:
      const local = action as IntegrationsAction.SetCampConfig;
      return { ...state, campConfig: local.campConfig };
    default:
      return state;
    }
  };

export default integrationsReducer;
