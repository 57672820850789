import { firstBy } from 'thenby';
import moment from 'moment-timezone';
import { call, put } from 'redux-saga/effects';
import { orderBy } from 'lodash';
import LocalCache from '../../../../../../local-storage/local-storage';
import api from '../../../../../../../services/api';
import { Pilot } from '../../../../../../../common/types/pilot';
import { scheduleActions } from '../../actions';
import { Profile } from '../../../../../../../common/types/user';
import { CompanySettings } from '../../../../../../../common/types/Company';
import { setCrewSortOptionFromCache } from '../caching/setCrewSortOptionFromCache';
import { CascaderOptions } from '../../../components/types';
import {
  selectCompanyIDSaga,
  selectCompanySettingsSaga,
  selectUserRoleSaga,
  selectUserSaga,
} from '../../../../../../../common/saga/selectors';
import { selectSelectedTagsSaga } from '../../selectors-saga';
import { CrewSortOption } from '../../../types/sortOptions';

export function* loadPilotsWorker() {
  const companySettings = yield* selectCompanySettingsSaga();
  const companyID = yield* selectCompanyIDSaga();
  const user = yield* selectUserSaga();
  const selectedTags = yield* selectSelectedTagsSaga();
  const userRoles = yield* selectUserRoleSaga();

  const localCache = new LocalCache('crewSchedulingV2', user);

  const queryParams = { is_pilot: true, limit: -1, order: 'profile.last_name asc' };
  if (selectedTags.length > 0) {
    queryParams['crew_schedule_tags'] = selectedTags;
    queryParams['user_tags'] = selectedTags;
  }

  const {
    data: { Data },
  } = yield call(api.get, `/v1/companies/${companyID}/users`, {
    params: queryParams,
  });

  if (!Data) {
    return {
      sortedPilots: [],
      selectedPilotsData: [],
      orderedByFirstNamePilots: [],
      crewCertificationsData: [],
      crewMemberBasesData: [],
    };
  }

  // get all unique base airports
  const crewMemberBasesData = Array.from(
    new Set(Data.map((p: Pilot) => p.Profile.BaseAirportCode)),
  ).filter(b => b);

  const certificationsMap = {};
  Data.forEach(pilot => {
    if (pilot.Profile.PilotAircraftCertifications) {
      pilot.Profile.PilotAircraftCertifications.forEach(certification => {
        if (!certificationsMap[certification.AircraftType]) {
          certificationsMap[certification.AircraftType] = [];
        }

        if (
          certification.PilotPosition &&
          certificationsMap[certification.AircraftType].indexOf(certification.PilotPosition) < 0
        ) {
          certificationsMap[certification.AircraftType].push(certification.PilotPosition);
        }
      });
    }
  });
  const crewCertificationsData: CascaderOptions[] = Object.keys(certificationsMap)
    .map(key => {
      return {
        value: key,
        label: key,
        children: certificationsMap[key]
          .map(el => ({ value: el, label: el }))
          .sort((a, b) => a.value.localeCompare(b.value)),
      };
    })
    .sort((a, b) => a.value.localeCompare(b.value));

  let pilotList = Data.filter(p => user?.id === p.ID || p.Profile.ShowInCSV2);

  const onlyPilot = userRoles.includes('pilot') && !userRoles.includes('admin');
  if (
    onlyPilot &&
    !(
      userRoles.includes('crewScheduleViewer') ||
      userRoles.includes('taggedScheduleViewer') ||
      companySettings.PilotCanViewAllPilotsCrewSchedule
    )
  ) {
    pilotList = pilotList.filter(pilot => pilot?.Profile?.UserID === user.id);
  }

  let sortedPilots: Pilot[];

  if (
    localCache.getCached('crewSortOption') === 'custom' &&
    companySettings?.CustomUserDisplayOrder
  ) {
    // sortedPilots = sortByCustomUserDisplayOrder(pilotList, user.id, userRoles, companySettings);
    sortedPilots = pilotList; // TODO: we maybe need it
  } else {
    // sortedPilots = yield sortByCertifications(pilotList, user);
    sortedPilots = pilotList; // TODO: we maybe need it
  }

  let selectedPilotsData = sortedPilots.slice(0, 10);
  const cachedCrewMemberIds = localCache.getCached('selectedCrewMemberIds', []);
  // if any crew members are selected in the local cache, set those in selectedPilots
  if (cachedCrewMemberIds?.length > 0) {
    selectedPilotsData = sortedPilots.filter(p => cachedCrewMemberIds.includes(p?.ID));
  }

  const orderedByFirstNamePilots = orderBy(Data, ['Profile.FirstName'], ['asc']);
  return {
    sortedPilots,
    selectedPilotsData,
    orderedByFirstNamePilots,
    crewCertificationsData,
    crewMemberBasesData,
  };
}

export function* loadPilots() {
  try {
    const {
      sortedPilots,
      selectedPilotsData,
      orderedByFirstNamePilots,
      crewCertificationsData,
      crewMemberBasesData,
    } = yield loadPilotsWorker();

    yield put(scheduleActions.loadSelectedPilotsSuccess(selectedPilotsData));

    const companySettings = yield* selectCompanySettingsSaga();
    const user = yield* selectUserSaga();

    const localCache = new LocalCache('crewSchedulingV2', user);
    let crewSortOption: CrewSortOption | null;
    const crewSortCache = localCache.getCached('crewSortOption');
    if (!crewSortCache) {
      if (companySettings.CustomUserDisplayOrder) {
        crewSortOption = 'custom';
        yield call([localCache, 'setCached'], 'crewSortOption', crewSortOption);
        yield call(setCrewSortOptionFromCache);
      } else {
        crewSortOption = 'certTypeAsc';
      }
    } else {
      crewSortOption = crewSortCache;
    }

    yield put(
      scheduleActions.setState({
        pilots: sortedPilots,
        pilotsFNOrder: orderedByFirstNamePilots,
        isPilotsLoaded: true,
        crewSortOption: crewSortOption,
        crewCertifications: crewCertificationsData,
        crewMemberBases: crewMemberBasesData,
        lastRequestCalledTimestamp: moment(),
      }),
    );
  } catch (error) {
    console.log(error);
  }
}

export const sortByCustomUserDisplayOrder = (
  pilotList: Profile[],
  authUserID: string,
  userRoles: string[],
  companySettings: CompanySettings,
) => {
  const authPilot = pilotList.find(pilot => pilot.UserID === authUserID);
  const onlyPilot = userRoles.includes('pilot') && !userRoles.includes('admin');

  if (
    (userRoles.includes('crewScheduleViewer') ||
      userRoles.includes('taggedScheduleViewer') ||
      companySettings.PilotCanViewAllPilotsCrewSchedule) &&
    onlyPilot
  ) {
    pilotList = pilotList.filter(pilot => pilot.UserID !== authUserID);
  }

  const usersForSorting = pilotList.filter(pilot => pilot?.DisplayOrder > 0);
  const remainingUsers = pilotList.filter(
    pilot => pilot?.DisplayOrder === 0 || !pilot?.DisplayOrder,
  );

  let sortedPilotList = usersForSorting.sort((a, b) => a.DisplayOrder - b.DisplayOrder);

  if (
    onlyPilot &&
    (userRoles.includes('crewScheduleViewer') ||
      userRoles.includes('taggedScheduleViewer') ||
      companySettings.PilotCanViewAllPilotsCrewSchedule)
  ) {
    sortedPilotList = [authPilot, ...sortedPilotList].filter(p => p);
  } else if (!companySettings.PilotCanViewAllPilotsCrewSchedule && onlyPilot) {
    sortedPilotList = [authPilot];
  }

  // Concatenate the sorted and remaining users
  sortedPilotList = [...sortedPilotList, ...remainingUsers];

  return sortedPilotList;
};

const sortByCertifications = function*(pilotsArray: Profile[], authUser) {
  const companySettings = yield* selectCompanySettingsSaga();
  const user = yield* selectUserSaga();
  const userRoles = yield* selectUserRoleSaga();

  // sort pilots by
  // 1. if company.Settings.CustomUserDisplayOrder, sort by this first
  // 2. Pilot Certification asc
  // 3. Aircraft Type asc
  // 4. Date of Hire asc (nulls last)
  // 5. Last Name asc
  // 6. if role is (pilot and not admin && (PilotCanViewAllPilotsCrewSchedule)) show pilot first

  const authPilot = pilotsArray.find(p => p?.UserID === authUser?.id);
  const onlyPilot = userRoles?.includes('pilot') && !userRoles?.includes('admin');
  const localCache = new LocalCache('crewSchedulingV2', authUser);

  if (
    (userRoles?.includes('crewScheduleViewer') ||
      userRoles?.includes('taggedScheduleViewer') ||
      companySettings?.PilotCanViewAllPilotsCrewSchedule) &&
    onlyPilot
  ) {
    pilotsArray = pilotsArray.filter(p => p?.UserID !== authUser?.id);
  }
  const crewSortCache = localCache.getCached('crewSortOption');
  if (crewSortCache) {
    yield put(scheduleActions.setCrewSortOption(crewSortCache));
  }
  let sortedPilots = yield sortCrew(
    pilotsArray,
    crewSortCache,
    user.id,
    userRoles,
    companySettings,
  );
  if (
    (userRoles?.includes('crewScheduleViewer') ||
      userRoles?.includes('taggedScheduleViewer') ||
      companySettings?.PilotCanViewAllPilotsCrewSchedule) &&
    onlyPilot
  ) {
    sortedPilots = [authPilot, ...sortedPilots].filter(p => p);
  } else if (!companySettings?.PilotCanViewAllPilotsCrewSchedule && onlyPilot) {
    sortedPilots = [authPilot];
  }
  return sortedPilots;
};

export const sortCrew = (
  pilots: Profile[],
  crewSortOption: string,
  authUserID: string,
  userRoles: string[],
  companySettings: CompanySettings,
) => {
  let authUserIndex = null;
  let authUserPilot = null;
  if (userRoles.includes('pilot') && companySettings.PilotCanViewAllPilotsCrewSchedule) {
    authUserIndex = pilots.findIndex(pilot => pilot.UserID === authUserID);
    if (authUserIndex !== -1) {
      [authUserPilot] = pilots.splice(authUserIndex, 1); // Remove the pilot from the original array
    }
  }

  let sortedPilots: Profile[];
  if (crewSortOption === 'custom') {
    sortedPilots = sortByCustomUserDisplayOrder(pilots, authUserID, userRoles, companySettings);
    if (authUserPilot) {
      sortedPilots.unshift(authUserPilot)
    }
    return sortedPilots
  }
  if (crewSortOption === 'certTypeAsc') {
    sortedPilots = pilots.sort(
      firstBy((a: Profile, b: Profile) => {
        if (!a.PilotAircraftCertifications && !b.PilotAircraftCertifications) {
          return 0;
        }
        if (!a.PilotAircraftCertifications) {
          return 1;
        }
        if (!b.PilotAircraftCertifications) {
          return -1;
        }

        return sortCertifications(a.PilotAircraftCertifications)[0].AircraftType.localeCompare(
          sortCertifications(b.PilotAircraftCertifications)[0].AircraftType,
        );
      })
        .thenBy((a: Profile, b: Profile) => {
          if (!a.PilotAircraftCertifications && !b.PilotAircraftCertifications) {
            return 0;
          }
          if (!a.PilotAircraftCertifications) {
            return 1;
          }
          if (!b.PilotAircraftCertifications) {
            return -1;
          }

          return sortCertifications(a.PilotAircraftCertifications)[0].PilotPosition.localeCompare(
            sortCertifications(b.PilotAircraftCertifications)[0].PilotPosition,
          );
        })
        .thenBy((a: Profile, b: Profile) => {
          if (!a.DateOfHire && !b.DateOfHire) {
            return 0;
          }
          if (!a.DateOfHire) {
            return 1;
          }
          if (!b.DateOfHire) {
            return -1;
          }
          return moment(a.DateOfHire).diff(moment(b.DateOfHire));
        })
        .thenBy((a, b) => a.LastName.localeCompare(b.LastName)),
    );
  } else {
    sortedPilots = pilots.sort((a: Profile, b: Profile) => {
      if (!a.FirstName && !b.FirstName) {
        return 0; // Both names are null or undefined, consider them equal
      } else if (!a.FirstName) {
        return 1; // Null or undefined names are sorted to the end
      } else if (!b.FirstName) {
        return -1; // Null or undefined names are sorted to the end
      }
      if (crewSortOption === 'firstNameAlphabeticallyAsc') {
        return a.FirstName.localeCompare(b.FirstName);
      } else {
        return a.LastName.localeCompare(b.LastName);
      }
    });
  }
  if (authUserPilot) {
    sortedPilots.unshift(authUserPilot);
  }
  return [...sortedPilots];
};

const sortCertifications = (
  certifications: { AircraftType?: string; PilotPosition?: string }[],
) => {
  return certifications
    .sort((cert1: { AircraftType: string }, cert2: { AircraftType: string }) =>
      cert1?.AircraftType.localeCompare(cert2?.AircraftType),
    )
    .sort((cert1: { PilotPosition: string }, cert2: { PilotPosition: string }) =>
      cert1?.PilotPosition.localeCompare(cert2?.PilotPosition),
    );
};
