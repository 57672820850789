import { AnyAction } from 'redux';
import { actionTypes } from './actions';
import { ModalMode } from '../../../../../redux/pages/crew-scheduling-v3/modals/interfaces';
import MODAL_MODE from '../../../common/constants';
import { ClientContract } from '../../../../../common/types/ClientContract';

export interface SettingsInitState {
  clientContractModalMode: ModalMode;
  editedClientContract: ClientContract;
  editedClientID: string | null;
  triggerReFetchClients?: boolean;
}

const initialState: SettingsInitState = {
  clientContractModalMode: { mode: MODAL_MODE.create, open: false },
  editedClientContract: null,
  editedClientID: null,
  triggerReFetchClients: false,
};

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
