import { DeleteVirtualAircraftContractEntryAction, scheduleActions } from '../../actions';
import api from '../../../../../../../services/api';
import {call, put} from 'redux-saga/effects';
import MODAL_MODE from '../../../../../common/constants';

export function* deleteVirtualAircraftContract(action: DeleteVirtualAircraftContractEntryAction) {
  const { aircraftContractIDs } = action.payload;

  try {
    yield call(api.post, '/v1/contracts/virtual/aircraft', {
      CurrentAircraftContractIDs: aircraftContractIDs,
      NewValues: {},
    });
    yield put(
      scheduleActions.setGroupedAircraftContractModalMode({
        open: false,
        mode: MODAL_MODE.create,
      }),
    );
  } catch (err) {
    console.log('failed to post a new form item', err);
  } finally {
    console.log('finally');
  }
}
