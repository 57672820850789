import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { FlightWatch } from '../types/flightWatch';
import { FlightWatchFormValues } from '../types/flightWatchFormValues';
import { FlightWatchState } from './reducers';

const actions = {
  SET_STATE: 'pages/company/flight-watch/SET_STATE',
  LOAD_FLIGHT_WATCH_LIST: 'pages/company/flight-watch/LOAD_FLIGHT_WATCH_LIST',
  SET_FLIGHT_WATCH_LIST: 'pages/company/flight-watch/SET_FLIGHT_WATCH_LIST',
  ADD_FLIGHT_WATCH: 'pages/company/flight-watch/ADD_FLIGHT_WATCH',
  UPDATE_FLIGHT_WATCH: 'pages/company/flight-watch/UPDATE_FLIGHT_WATCH',
  START_EDIT_FLIGHT_WATCH: 'pages/company/flight-watch/START_EDIT_FLIGHT_WATCH',
  START_SHOW_FLIGHT_WATCH_DETAILS: 'pages/company/flight-watch/START_SHOW_FLIGHT_WATCH_DETAILS',
  HANDLE_TABLE_CHANGE: 'pages/company/flight-watch/HANDLE_TABLE_CHANGE',
  PUSHER_FLIGHT_WATCH_UPDATED: 'pages/company/flight-watch/PUSHER_FLIGHT_WATCH_UPDATED',
  PUSHER_FLIGHT_WATCH_CREATED: 'pages/company/flight-watch/PUSHER_FLIGHT_WATCH_CREATED',
};

export default actions;

export type PusherFlightWatchUpdate = {
  type: typeof actions.PUSHER_FLIGHT_WATCH_UPDATED;
  payload: {
    flightWatch: FlightWatch;
    actionTriggeredBuUsedID: string;
  };
};

export type AddFlightWatch = {
  type: typeof actions.ADD_FLIGHT_WATCH;
  payload: FlightWatchFormValues;
};

export type OnTableChange = {
  type: typeof actions.HANDLE_TABLE_CHANGE;
  payload: {
    pagination: TablePaginationConfig;
    sorter: SorterResult<any>;
  };
};

export type StartEditFlightWatch = {
  type: typeof actions.START_EDIT_FLIGHT_WATCH;
  payload: {
    flightWatchID: string;
  };
};

export type StartShowFlightWatchDetails = {
  type: typeof actions.START_SHOW_FLIGHT_WATCH_DETAILS;
  payload: {
    flightWatchID: string;
  };
};

export type UpdateFlightWatch = {
  type: typeof actions.UPDATE_FLIGHT_WATCH;
  payload: FlightWatchFormValues;
};

export type SetFlightWatchList = {
  type: typeof actions.SET_FLIGHT_WATCH_LIST;
  payload: {
    list: FlightWatch[];
  };
};

export const setFlightWatchList = (list: FlightWatch[]): SetFlightWatchList => {
  return {
    type: actions.SET_FLIGHT_WATCH_LIST,
    payload: {
      list,
    },
  };
};

export const loadFlightWatchList = () => {
  return {
    type: actions.LOAD_FLIGHT_WATCH_LIST,
  };
};

export const closeAddUpdateModal = () => {
  return {
    type: actions.SET_STATE,
    payload: {
      isAddUpdateModalOpen: false,
    },
  };
};

export const openAddUpdateModal = (isNewEntry: boolean) => {
  const payload: Partial<FlightWatchState> = {
    isAddUpdateModalOpen: true,
  };
  if (isNewEntry) {
    payload.editableFlightWatch = null;
  }
  return {
    type: actions.SET_STATE,
    payload,
  };
};
export const addFlightWatch = (payload: FlightWatchFormValues): AddFlightWatch => {
  return {
    type: actions.ADD_FLIGHT_WATCH,
    payload,
  };
};

export const updateFlightWatch = (payload: FlightWatchFormValues): UpdateFlightWatch => {
  return {
    type: actions.UPDATE_FLIGHT_WATCH,
    payload,
  };
};

export const startEditFlightWatch = (flightWatchID: string): StartEditFlightWatch => {
  return {
    type: actions.START_EDIT_FLIGHT_WATCH,
    payload: {
      flightWatchID,
    },
  };
};

export const startShowFlightWatchDetails = (flightWatchID: string): StartShowFlightWatchDetails => {
  return {
    type: actions.START_SHOW_FLIGHT_WATCH_DETAILS,
    payload: {
      flightWatchID,
    },
  };
};

export const closeFlightWatchDetailsModal = () => {
  return {
    type: actions.SET_STATE,
    payload: {
      isDetailsModalOpen: false,
    },
  };
};

export const onTableChange = (
  pagination: TablePaginationConfig,
  sorter: SorterResult<any>,
): OnTableChange => {
  return {
    type: actions.HANDLE_TABLE_CHANGE,
    payload: {
      pagination,
      sorter,
    },
  };
};

export const setState = (payload: Partial<FlightWatchState>) => {
  return {
    type: actions.SET_STATE,
    payload,
  };
};

export const pusherFlightWatchUpdated = (
  flightWatch: FlightWatch,
  actionTriggeredBuUsedID: string,
): PusherFlightWatchUpdate => {
  return {
    type: actions.PUSHER_FLIGHT_WATCH_UPDATED,
    payload: {
      flightWatch,
      actionTriggeredBuUsedID,
    },
  };
};

export const pusherFlightWatchCreated = (
  flightWatch: FlightWatch,
  actionTriggeredBuUsedID: string,
): PusherFlightWatchUpdate => {
  return {
    type: actions.PUSHER_FLIGHT_WATCH_CREATED,
    payload: {
      flightWatch,
      actionTriggeredBuUsedID,
    },
  };
};
