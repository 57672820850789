import { all, takeEvery, put, call } from 'redux-saga/effects';
import api from '../../../services/api';
import { Types } from './actions';
import { AnyAction } from 'redux';
import { ApiTypes } from 'models/index';
import { removeEmptyParams } from 'utils/removeEmptyParams';

export function* GET_FLIGHT_TIMES(action: AnyAction) {
  const params: Record<string, string> = {
    order: action.payload?.order,
    start_time__gte: action.payload?.start_time__gte,
    end_time__lte: action.payload?.end_time__lte,
    pilot_ids: action.payload?.pilot_ids,
    active: action.payload?.active,
  };
  const paramsCleared = removeEmptyParams(params);

  try {
    const response: ApiTypes.ResponseGenerator = yield call(
      api.get,
      `/v1/cco/${action.payload.companyID}/flight-time`,
      { params: paramsCleared },
    );

    if (response.status === 200) {
      yield put({
        type: Types.GET_FLIGHT_TIMES_SUCCESS,
        payload: response.data?.Data,
      });

      yield put({
        type: Types.SET_TOTAL_COUNT,
        payload: response.data?.Total,
      });
    }
  } catch (error) {
    yield put({ type: Types.GET_FLIGHT_TIMES_ERROR });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.GET_FLIGHT_TIMES_REQUEST, GET_FLIGHT_TIMES)]);
}
