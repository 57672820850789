import { editFields } from '../../actions';
import { call, put, select } from 'redux-saga/effects';
import { getAllowedActions, getCommon, getCSMobile } from '../../selectors';
import { FeatureFlag } from '../../../../../../../utils/feature-flags/FeatureFlagsProvider';
import { buildDutyFI } from '../common';
import { DutyFiCommon } from '../../types/entries';
import { AllowAction } from '../../../../../../../common/types/permissions';
import { checkVHRandIFR } from './checkVHRandIFR';
import { checkDutyFromTo } from './checkDutyFromTo';
import { checkTotalHours } from './checkTotalHours';
import { checkFDP } from './checkFDP';
import { checkRestStartEndTime } from './checkRestStartEndTime';
import { apiEntryTypeMap } from '../../../../../../../common/types/apiEntryTypes';
import { checkCrewScheduleRequiredFields } from './checkCrewScheduleRequiredFields';
import { checkCustomSectionRequiredFields } from './checkCustomSectionRequiredFields';
import { checkDutyRequiredFields } from './checkDutyRequiredFields';
import { checkDetailedFlightsAmount } from './checkDetailedFlightsAmount';
import { checkRAPFromTo } from './checkRAPFromTo';
import { checkFlightRequiredFields } from './checkFlightRequiredFields';

export function* runFieldsValidationWorker(editedField: string) {
  const { editValues, mostRecentDutyEntry, entry, entryType, isNewEntry } = yield select(
    getCSMobile,
  );
  const { featureFlags, customCurrencyItemTemplates } = yield select(getCommon);
  const allowedActions = yield select(getAllowedActions);

  if (
    entryType !== apiEntryTypeMap.CrewScheduleEntries &&
    entryType !== apiEntryTypeMap.AircraftScheduleEntries
  ) {
    if (
      !featureFlags[FeatureFlag.DetailedFlightTimes] &&
      (editedField == editFields.VFRHours || editedField == editFields.IFRHours)
    ) {
      const flightInformation = buildDutyFI(
        entry,
        editValues,
        !featureFlags[FeatureFlag.DetailedFlightTimes],
        false,
        entryType,
        customCurrencyItemTemplates,
      );

      for (let idx = 0; idx < flightInformation.length; idx++) {
        const fi: DutyFiCommon = flightInformation[idx];
        yield call(checkVHRandIFR, fi, idx, allowedActions[AllowAction.allowPrioritizeIFRHours]);
      }
    }

    const flightInformation = buildDutyFI(
      entry,
      editValues,
      !featureFlags[FeatureFlag.DetailedFlightTimes],
      false,
      entryType,
      customCurrencyItemTemplates,
    );
    for (let idx = 0; idx < flightInformation.length; idx++) {
      const fi: DutyFiCommon = flightInformation[idx];
      yield call(
        checkTotalHours,
        fi,
        idx,
        entryType,
        featureFlags[FeatureFlag.DetailedFlightTimes],
        entry,
        editValues,
      );
      yield call(
        checkDetailedFlightsAmount,
        fi,
        idx,
        entryType,
        featureFlags[FeatureFlag.DetailedFlightTimes],
        entry,
        editValues,
      );
      if (featureFlags[FeatureFlag.DetailedFlightTimes]) {
        yield call(checkFlightRequiredFields, idx, entry, editValues, allowedActions);
      }
    }

    if (editedField === editFields.RestStart || editedField === editFields.RestEnd) {
      yield call(checkRestStartEndTime, entry, editValues, entryType);
    }

    if (
      editedField === editFields.FDPTo ||
      editedField === editFields.DutyTo ||
      editedField === editFields.NonFlyingDuty
    ) {
      yield call(checkFDP, entry, editValues, mostRecentDutyEntry, allowedActions);
    }

    yield call(checkDutyRequiredFields, entry, editValues, allowedActions);
  }

  if (
    editedField === editFields.DutyFrom ||
    editedField === editFields.DutyTo ||
    editedField === editFields.IsOngoing ||
    editedField === editFields.RAPStartTime ||
    editedField === editFields.RAPEndTime
  ) {
    const reserveOnlyEntry = featureFlags[FeatureFlag.ReserveOnlyDutyEntry];
    yield call(
      checkDutyFromTo,
      entry,
      editValues,
      mostRecentDutyEntry,
      allowedActions,
      isNewEntry,
      entryType,
      reserveOnlyEntry,
    );
  }

  if (
    editedField === editFields.DutyTo ||
    editedField === editFields.RAPStartTime ||
    editedField === editFields.RAPEndTime ||
    editedField === editFields.IsOngoing ||
    editedField === editFields.IsOnReserve
  ) {
    yield call(
      checkRAPFromTo,
      entry,
      editValues,
      mostRecentDutyEntry,
      allowedActions,
      isNewEntry,
      entryType,
    );
  }

  if (entryType === apiEntryTypeMap.CrewScheduleEntries) {
    yield call(checkCrewScheduleRequiredFields);
    if (editedField === editFields.CustomSections) {
      yield call(checkCustomSectionRequiredFields);
    }
  }
}
