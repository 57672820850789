import { call, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import api from '../../../../../../services/api';
import * as Sentry from '@sentry/react';
import { notification } from 'antd';
import { CustomFormsState } from '../reducers';
import { getCustomForms } from '../selectors';
import { setState } from '../actions';
import { template } from 'lodash';
import { CustomFormTemplate } from '../../types/template';

export function* saveTemplateWorker() {
  const { editingTemplate, templateList } = (yield select(getCustomForms)) as CustomFormsState;

  yield put(setState({ isLoading: true }));

  try {
    let response: AxiosResponse;

    if (!editingTemplate.ID) {
      response = yield call(api.post, `/v1/custom-forms/template`, editingTemplate);
    } else {
      response = yield call(
        api.patch,
        `/v1/custom-forms/template/${editingTemplate.ID}`,
        editingTemplate,
      );
    }

    if (response.status === 200) {
      const newTemplate = response.data.Data as CustomFormTemplate;
      let updatedTemplateList: CustomFormTemplate[];
      if (!editingTemplate.ID) {
        updatedTemplateList = [...templateList, newTemplate];
      } else {
        updatedTemplateList = templateList.map(t => {
          if (t.ID === newTemplate.ID) {
            return newTemplate;
          }
          return t;
        });
      }

      yield put(setState({ templateList: updatedTemplateList }));

      notification.success({
        message: 'Success',
        description: 'Form template has been saved successfully.',
      });
    }
  } catch (error) {
    Sentry.captureMessage(`Error creating form template ${error}`);
    notification.error({
      message: 'Error',
      description:
        'There was an error creating/updating form template. Please try again or contact support.',
    });
  } finally {
    yield put(setState({ isLoading: false, isTemplateModalOpen: false }));
  }
}
