import { all, call, select, take, takeEvery, takeLeading } from 'redux-saga/effects';
import actions, { deleteAircraftGroup } from './actions';
import { createGroupScheduleWorker } from './workers/createGroupScheduleWorker';
import { loadGroupScheduleWorker } from './workers/loadGroupScheduleWorker';
import { loadGroupWorker } from './workers/loadGroupWorker';
import { createGroupWorker } from './workers/createGroupWorker';
import { updateGroupScheduleWorker } from './workers/updateGroupScheduleWorker';
import { selectAssociatedAircraftAndCrewWorker } from './workers/selectAssociatedAircraftAndCrewWorker';
import { deleteGroupScheduleWorker } from './workers/deleteGroupScheduleWorker';
import { deleteGroupWorker } from './workers/deleteGroupWorker';
import { updateGroupWorker } from './workers/updateGroupWorker';
import { scheduleActions } from '../../../redux-saga/actions';

function* watchComponentAndPilotsLoaded() {
  yield all([
    // take(scheduleActions.LOAD_SELECTED_PILOTS_SUCCESS),
    // take(scheduleActions.UPDATE_ENTRY_PERIOD),
    // take(scheduleActions.SET_COMPANY_SCHEDULE_VERSION),
  ]);

  const entryPeriod = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2.entryPeriod,
  );

  const [startTime, endTime] = entryPeriod;

  const config = { startTime, endTime, isShowLoading: true };
  yield call(loadGroupScheduleWorker, config);
  // todo: move to better place
  yield call(loadGroupWorker);
}

function* reloadAircraftGroupScheduleWorker() {
  const entryPeriod = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2.entryPeriod,
  );

  const [startTime, endTime] = entryPeriod;

  const config = { startTime, endTime, isShowLoading: true };
  yield call(loadGroupScheduleWorker, config);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_GROUP_SCHEDULE, createGroupScheduleWorker),
    takeEvery(actions.CREATE_GROUP, createGroupWorker),
    takeEvery(actions.UPDATE_GROUP, updateGroupWorker),
    takeEvery(actions.UPDATE_GROUP_SCHEDULE, updateGroupScheduleWorker),
    takeEvery(actions.SELECT_ASSOCIATED_AIRCRAFT_AND_CREW, selectAssociatedAircraftAndCrewWorker),
    takeEvery(actions.DELETE_GROUP_SCHEDULE, deleteGroupScheduleWorker),
    takeEvery(actions.DELETE_GROUP, deleteGroupWorker),
    takeEvery(actions.LOAD_GROUP, loadGroupWorker),
    takeEvery(actions.RELOAD_GROUP_SCHEDULE, reloadAircraftGroupScheduleWorker),
    // takeLeading(
    //   scheduleActions.LOAD_CREW_SCHEDULE_COMPONENT_DATA,
    //   watchComponentAndPilotsLoaded,
    // ),
  ]);
}
