import { LDUser } from 'launchdarkly-js-sdk-common';
import LDClientImpl from 'launchdarkly-js-client-sdk';
import { getLaunchDarklyKey } from '../../../config';
import { put, select } from 'redux-saga/effects';
import commonActions from '../actions';
import { getCommon } from '../../../pages/data/organization/crew-scheduling-mobile/redux-saga/selectors';
import { CommonInitState } from '../reducers';

export function* initLD(companyID: string, companyName: string) {
  if (!companyID || !companyName) {
    throw new Error('Failed to init LD. There are missed params.');
  }

  const { isFeatureFlagsLoaded, featureFlags } = (yield select(getCommon)) as CommonInitState;

  if (isFeatureFlagsLoaded) {
    return featureFlags;
  }

  const user: LDUser = { key: companyID, name: companyName };
  const client = LDClientImpl.initialize(getLaunchDarklyKey(), user);
  yield client.waitUntilReady();
  const flags = client.allFlags();
  yield put(commonActions.setFeatureFlags(flags));

  return flags;
}
