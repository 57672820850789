import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { fetchEntry } from '../../timelineEntries/fetchEntry';
import { scheduleActions } from '../../../actions';
import { put, select } from 'redux-saga/effects';
import {
  selectClientContractPeriodTimelineEntriesSaga,
  selectCrewScheduleTimelineEntriesSaga,
} from '../../../selectors-saga';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import { ContractTimelineEntryType } from '../../../../../../../../common/types/timeline/ContractTimelineEntryType';
import { ClientContractPeriodTimeline } from '../../../../../../../../common/types/ClientContractPeriod';

export function* handleClientContractPeriodEntryDelete(
  action: PusherReduxAction<PusherActionType.CLIENT_CONTRACT_PERIOD_DELETED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  const { payload } = action;

  try {
    const existedEntries = yield* selectClientContractPeriodTimelineEntriesSaga();
    const updatedEntries = existedEntries.filter(e => e.ID !== payload.ID);

    yield put(scheduleActions.setState({ clientContractPeriodTimelineEntries: updatedEntries }));
  } finally {
  }
}
