import { actions } from './actions';
import {
  DutyInfoFormFields,
  FlightInfoFormFields,
  FlightTimeEntry,
  ModalMode,
} from '../interfaces';
import MODAL_MODE from '../../../../../pages/data/common/constants';
import { DutyTimeEntry } from '../../scheduleEntries/types/DutyTimeEntry';

export type DutyTimeState = {
  isSplitDuty: boolean;
  isOnCallDuty: boolean;
  isFlyingDuty: boolean;
  managementOverride: boolean;
  isOnReserveDuty: boolean;
  isPositioningAfterMaxFDP: boolean;
  isUnforeseenExtension: boolean;
  acclimatizedTimezoneTag: string;
  maxDutyTime: string;
  maxFDP: string;
  restAfter: string;
  restBefore: string;
  validationSuggestions: any[]; // You may want to specify a more detailed type here
  isValidationRunning: boolean;
  validationState: any | null; // You may want to specify a more detailed type here
  modalMode: ModalMode;
  dutyInfoForm: DutyInfoFormFields;
  flightInfoForm: FlightInfoFormFields;
  currentDutyTimeEntry: DutyTimeEntry;
  flightTimeByAircraftType: { [aircraftType: string]: FlightTimeEntry[] };
  isDetailedFlightTimesFormValid: boolean;
  detailedFlightFlightsValidStateByType: { [aircraftType: string]: boolean };
};

export const initialState: DutyTimeState = {
  isSplitDuty: false,
  isOnCallDuty: false, // todo:  currentEntry?.OnCallDuty || false
  isFlyingDuty: false,
  managementOverride: false,
  isOnReserveDuty: false, // todo:  !!dutyInfo?.RAPStartTime
  isPositioningAfterMaxFDP: false, // todo:  set from duty info !!dutyInfo?.PositioningAfterMaxFDP || false
  isUnforeseenExtension: false, // todo:  !!dutyInfo?.UnforeseenExtension || false
  acclimatizedTimezoneTag: '',
  maxDutyTime: 'N/A',
  maxFDP: 'N/A',
  restAfter: 'N/A',
  restBefore: 'N/A',
  validationSuggestions: [],
  isValidationRunning: false,
  validationState: null,
  modalMode: { open: false, mode: MODAL_MODE.create } as ModalMode,
  dutyInfoForm: {} as DutyInfoFormFields,
  flightInfoForm: {} as FlightInfoFormFields,
  currentDutyTimeEntry: {} as DutyTimeEntry,
  flightTimeByAircraftType: {} as { [aircraftType: string]: FlightTimeEntry[] },
  isDetailedFlightTimesFormValid: true,
  detailedFlightFlightsValidStateByType: {} as { [aircraftType: string]: boolean },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_IS_SPLIT_DUTY:
    case actions.SET_IS_ON_CALL_DUTY:
    case actions.SET_MANAGEMENT_OVERRIDE:
    case actions.SET_IS_FLYING_DUTY:
    case actions.SET_IS_ON_RESERVE_DUTY:
    case actions.SET_IS_POSITIONING_AFTER_MAX_FDP:
    case actions.SET_IS_UNFORESEEN_EXTENSION:
    case actions.SET_MAX_DUTY_TIME:
    case actions.SET_REST_BEFORE:
    case actions.SET_REST_AFTER:
    case actions.SET_VALIDATION_SUGGESTIONS:
    case actions.SET_ACCLIMATIZED_TIMEZONE_TAG:
    case actions.SET_FORM_FIELDS_DEFAULT_VALUES:
    case actions.SET_MAX_FDP:
      return { ...state, ...action.payload };
    case actions.OPEN_MODAL:
      return { ...state, modalMode: { open: true, mode: action.payload.mode } };
    case actions.CLOSE_MODAL:
      return { ...state, modalMode: { open: false, mode: MODAL_MODE.create } };
    case actions.UPDATE_DUTY_INFO_FORM_FIELDS:
      return { ...state, dutyInfoForm: { ...state.dutyInfoForm, ...action.payload } };
    case actions.SET_DUTY_INFO_FORM_FIELDS:
      return { ...state, dutyInfoForm: action.payload };
    case actions.UPDATE_FLIGHT_INFO_FORM_FIELDS:
      return { ...state, flightInfoForm: { ...state.flightInfoForm, ...action.payload } };
    case actions.SET_FLIGHT_INFO_FORM_FIELDS:
      return { ...state, flightInfoForm: action.payload };
    case actions.SET_CURRENT_DUTY_TIME_ENTRY:
      return { ...state, currentDutyTimeEntry: action.payload };
    case actions.SET_IS_DETAILED_FLIGHT_TIMES_FORM_VALID:
      const updatedDetailedFlightValidation = { ...state.detailedFlightFlightsValidStateByType };
      if (action.payload.isValid) {
        delete updatedDetailedFlightValidation[action.payload.aircraftType];
      } else {
        updatedDetailedFlightValidation[action.payload.aircraftType] = false;
      }

      return {
        ...state,
        isDetailedFlightTimesFormValid: Object.keys(updatedDetailedFlightValidation).length === 0,
        detailedFlightFlightsValidStateByType: updatedDetailedFlightValidation,
      };
    case actions.SET_FLIGHT_TIME_ENTRIES:
      return {
        ...state,
        flightTimeByAircraftType: {
          ...state.flightTimeByAircraftType,
          [action.payload.aircraftType]: action.payload.flightTimeEntries,
        },
      };
    case actions.SET_IS_VALIDATION_RUNNING:
      return { ...state, isValidationRunning: action.payload };
    case actions.SET_VALIDATION_STATE:
      return { ...state, validationState: action.payload };
    case actions.RESET_DETAILED_FLIGHT_VALIDATION_STATUS:
      return {
        ...state,
        isDetailedFlightTimesFormValid: true,
        detailedFlightFlightsValidStateByType: {},
      };
    case actions.RESET_FLIGHT_TIME_ENTRIES:
      return { ...state, flightTimeByAircraftType: {} };
    default:
      return state;
  }
}
