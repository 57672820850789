import { EntryType } from '../../../../../../common/types/entryTypes';
import moment, { Moment } from 'moment-timezone';
import { call, put, select } from 'redux-saga/effects';
import actions, { FetchRelatedPilotsAction, setEntries, setLoading, setState } from '../actions';
import { getCSMobile } from '../selectors';
import { getCompanyID } from '../../../../../../redux/selectors';
import api from '../../../../../../services/api';
import { populateMonthDividers } from '../../helpers';
import { PreferredVersion } from '../../../../../../common/types/preferredVersoon';
import { fetchEntry } from '../../../crew-scheduling-v3/redux-saga/workers/timelineEntries/fetchEntry';
import { CrewScheduleEntry } from '../../../../../../redux/pages/crew-scheduling-v3/scheduleEntries/types/CrewScheduleEntry';
import { CrewTimelineEntryType } from '../../../../../../common/types/timeline/CrewTimelineEntryType';
import { getProfileName } from '../../../../../../common/helpers/profile';

function* checkRangeLimits(from: Moment, to: Moment) {
  const { CompanyUsers } = yield select(({ user }) => user);

  const companySettings = yield select(({ user: { Company } }) => Company.Settings);
  const { publishedVersions, companyScheduleVersion, entryType } = yield select(getCSMobile);

  const preferredVersion: PreferredVersion = CompanyUsers[0]?.PreferredVersion || 'current';

  const isNotDutyTimes = entryType !== 'duty-times';
  if (
    isNotDutyTimes &&
    companySettings?.Versioning &&
    publishedVersions?.length > 0 &&
    preferredVersion &&
    preferredVersion !== 'latest' &&
    companyScheduleVersion
  ) {
    let selectedVersion = preferredVersion;
    if (preferredVersion === 'current') {
      selectedVersion = companyScheduleVersion.CurrentVersion;
    }

    const selectedPublishedVersion = publishedVersions?.find(
      pv => pv.Version === parseInt(selectedVersion as string, 10),
    );

    if (
      selectedPublishedVersion?.VisibleStart &&
      moment(selectedPublishedVersion?.VisibleStart).valueOf() > moment(from).valueOf()
    ) {
      from = moment(selectedPublishedVersion?.VisibleStart);
    }

    if (
      selectedPublishedVersion?.VisibleEnd &&
      moment(selectedPublishedVersion?.VisibleEnd).valueOf() < moment(to).valueOf()
    ) {
      to = moment(selectedPublishedVersion?.VisibleEnd);
    }
  }

  return [from, to];
}

export function* fetchEntries(
  entryType: EntryType,
  fromDate: Moment,
  toDate: Moment,
  showLoading: boolean,
) {
  try {
    if (showLoading) {
      yield put(setLoading(true));
    }

    const [from, to] = yield checkRangeLimits(fromDate, toDate);

    yield put({
      type: actions.SET_STATE,
      payload: {
        isFutureVisibleLimitExceeded: to.isBefore(toDate),
      },
    });

    const { selectedPilotId } = yield select(getCSMobile);
    const companyID = yield select(getCompanyID);

    const pilotIds = selectedPilotId ? [selectedPilotId] : [];

    const baseApiParams = {
      end_time__gt: from.format(),
      start_time__lt: to.format(),
      ongoing: true,
      limit: -1,
      user_ids: pilotIds,
    };
    const {
      data: { Data },
    } = yield call(api.get, `/v1/companies/${companyID}/${entryType}`, { params: baseApiParams });

    if (!Data) {
      yield put(setLoading(false));
      return;
    }
    const sorted = Data.sort((a, b) => moment(a.StartTime).diff(moment(b.StartTime)));

    for (let i = sorted.length - 1; i >= 0; i--) {
      const entry = sorted[i];
      if (entry.EndAirportID) {
        yield put(
          setState({
            defaultStartAirportID: entry.EndAirportID,
            defaultEndAirportID: entry.EndAirportID,
          }),
        );
        break;
      }
    }

    const withMonthDividers = populateMonthDividers(sorted);
    yield put(setEntries(withMonthDividers));
    yield put({
      type: actions.SET_STATE,
      payload: {
        hasMore: false,
      },
    });
  } finally {
    if (showLoading) {
      yield put(setLoading(false));
    }
  }
}

export function* fetchEntriesForPeriodWorker(
  fromDate: Moment,
  toDate: Moment,
  entryType: EntryType,
  showLoading: boolean,
) {
  let fetchStart = '';
  let fetchEnd = '';
  let entriesBefore = false;
  let entriesAfter = false;
  try {
    if (showLoading) {
      yield put(setLoading(true));
    }

    const [from, to] = yield checkRangeLimits(moment(fromDate), moment(toDate));

    const { selectedPilotId, selectedAircraftId, entryPeriod } = yield select(getCSMobile);
    const companyID = yield select(getCompanyID);

    const pilotIds = selectedPilotId ? [selectedPilotId] : [];
    const aircraftId = selectedAircraftId ? selectedAircraftId : null;

    if (pilotIds.length === 0) {
      return {};
    }

    const baseApiParams = {
      end_time__gt: from.format(),
      start_time__lt: to.format(),
      ongoing: true,
      limit: -1,
      user_ids: pilotIds,
      aircraft_id: aircraftId,
    };
    try {
      const {
        data: {
          fetchStart: fetchedStart,
          fetchEnd: fetchedEnd,
          entriesBefore: fetchedEntriesBefore,
          entriesAfter: fetchedEntriesAfter,
        },
      } = yield call(api.get, `/v1/companies/${companyID}/${entryType}/closest-entry-period`, {
        params: baseApiParams,
      });

      fetchStart = fetchedStart;
      fetchEnd = fetchedEnd;
      entriesBefore = fetchedEntriesBefore;
      entriesAfter = fetchedEntriesAfter;

      if (entriesBefore && entriesAfter) {
        yield put(
          setState({
            entryPeriod: [moment(fetchStart), moment(fetchEnd)],
          }),
        );
      } else if (entriesBefore && !entriesAfter) {
        yield put(
          setState({
            entryPeriod: [moment(fetchStart), to],
          }),
        );
        fetchEnd = to.format();
      } else if (!entriesBefore && entriesAfter) {
        yield put(
          setState({
            entryPeriod: [from, moment(fetchEnd)],
          }),
        );
        fetchStart = from.format();
      }

      return {
        fetchedStart: fetchStart,
        fetchedEnd: fetchEnd,
        fetchedEntriesBefore: entriesBefore,
        fetchedEntriesAfter: entriesAfter,
      };
    } catch (err) {
      console.error('Failed to fetch closest entry period:', err);
    }

    return {};
  } finally {
    if (showLoading) {
      yield put(setLoading(false));
    }
  }
}

export function* fetchRelatedPilotsWorker(action: FetchRelatedPilotsAction) {
  const { entry } = action.payload;
  const { Company } = yield select(({ user }) => user);
  if (Company.Settings.ShowCrewScheduleRelatedPilots) {
    const crewScheduleEntry = (yield fetchEntry(
      CrewTimelineEntryType.CrewSchedule,
      entry.ID,
      false,
    )) as CrewScheduleEntry;

    const names = crewScheduleEntry?.RelatedPilots?.map(pilot => getProfileName(pilot)) || [];
    yield put(setState({ relatedPilotNames: names }));
  }
}
