import { actions } from './actions';
import { ApiFlightTimeEntry, ModalMode, ValidationError } from '../interfaces';
import MODAL_MODE from '../../../../../pages/data/common/constants';

export interface DutyScheduleModalInitialState {
  validationSuggestions: ValidationError[];
  isValidationRunning: boolean;
  validationState: any | null;
  maxFDP: string;
  maxDutyTime: string;
  restBefore: string;
  restAfter: string;
  acclimatizedTimezoneTag: string;
  isFlyingDuty: boolean;
  onCallDuty: boolean;
  onReserveDuty: boolean;
  positioningAfterMaxFDP: boolean;
  isSplitDuty: boolean;
  flightTimeByAircraftType: { [aircraftType: string]: ApiFlightTimeEntry[] };
  isDetailedFlightTimesFormValid: boolean;
  detailedFlightFlightsValidStateByType: { [aircraftType: string]: boolean };
  managementOverride: boolean;
  modalMode: ModalMode;
}

export const initialState: DutyScheduleModalInitialState = {
  validationSuggestions: [],
  isValidationRunning: false,
  validationState: null,
  maxFDP: 'N/A',
  maxDutyTime: 'N/A',
  restBefore: 'N/A',
  restAfter: 'N/A',
  acclimatizedTimezoneTag: '',
  isFlyingDuty: false,
  onCallDuty: false,
  onReserveDuty: false, // todo: the dfault value in the component was set from !!dutyInfo?.RAPStartTime
  positioningAfterMaxFDP: false, // todo: the dfault value in the component was set from !!dutyInfo?.PositioningAfterMaxFDP || false
  isSplitDuty: false,
  flightTimeByAircraftType: {},
  isDetailedFlightTimesFormValid: true,
  detailedFlightFlightsValidStateByType: {},
  managementOverride: false,
  modalMode: { open: false, mode: MODAL_MODE.create },
};

export default function reducer(state: DutyScheduleModalInitialState = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_VALIDATION_SUGGESTIONS:
    case actions.SET_MAX_FDP:
    case actions.SET_MAX_DUTY_TIME:
    case actions.SET_REST_BEFORE:
    case actions.SET_REST_AFTER:
    case actions.SET_ACCLIMATIZED_TIMEZONE_TAG:
    case actions.SET_IS_FLYING_DUTY:
    case actions.SET_ON_CALL_DUTY:
    case actions.SET_MANAGEMENT_OVERRIDE:
    case actions.SET_ON_RESERVE_DUTY:
    case actions.SET_POSITIONING_AFTER_MAX_FDP:
    case actions.SET_IS_SPLIT_DUTY:
      return { ...state, ...action.payload };
    case actions.SET_IS_DETAILED_FLIGHT_TIMES_FORM_VALID:
      const updatedDetailedFlightValidation = { ...state.detailedFlightFlightsValidStateByType };
      if (action.payload.isValid) {
        delete updatedDetailedFlightValidation[action.payload.aircraftType];
      } else {
        updatedDetailedFlightValidation[action.payload.aircraftType] = false;
      }

      return {
        ...state,
        isDetailedFlightTimesFormValid: Object.keys(updatedDetailedFlightValidation).length === 0,
        detailedFlightFlightsValidStateByType: updatedDetailedFlightValidation,
      };
    case actions.SET_FLIGHT_TIME_ENTRIES:
      return {
        ...state,
        flightTimeByAircraftType: {
          ...state.flightTimeByAircraftType,
          [action.payload.aircraftType]: action.payload.flightTimeEntries,
        },
      };
    case actions.SET_IS_VALIDATION_RUNNING:
      return { ...state, isValidationRunning: action.payload };
    case actions.SET_VALIDATION_STATE:
      return { ...state, validationState: action.payload };
    case actions.RESET_DETAILED_FLIGHT_VALIDATION_STATUS:
      return {
        ...state,
        isDetailedFlightTimesFormValid: true,
        detailedFlightFlightsValidStateByType: {},
      };
    case actions.RESET_FLIGHT_TIME_ENTRIES:
      return { ...state, flightTimeByAircraftType: {} };
    case actions.OPEN_MODAL:
      return { ...state, modalMode: { open: true, mode: action.payload.mode } };
    case actions.CLOSE_MODAL:
      return { ...state, modalMode: { open: false, mode: MODAL_MODE.create } };
    default:
      return state;
  }
}
