import { all, call, debounce, put, select, take, takeEvery } from 'redux-saga/effects';
import {
  actions,
  setAcclimatizedTimezoneTagAction,
  setDutyInfoFormFieldsAction,
  setFlightInfoFormFieldsAction,
  setIsValidationRunningAction,
  setMaxDutyTimeAction,
  setMaxFDPAction,
  setRestAfterAction,
  setRestBeforeAction,
  setValidationStateAction,
  setValidationSuggestionsAction,
} from './actions';
import { getCompany, getCompanyID, getCrewSchedulingV2Page } from '../../../../selectors';
import api from '../../../../../services/api';
import { deserializeAircraftInfo, deserializeDutyInfo, serializeEntry } from './utils';
import getTimeString from '../../../../../pages/data/organization/crew-scheduling-v3/legacy/utils/getTimeString';
import { modalChangeModeWorker } from './modalChangeModeWorker';
import { omit } from 'lodash';
import { VALIDATION_DEB_MS } from '../../constants';
import { getDutyTimeModalMode } from './selectors';

function* runValidationSaga({ type: string }: { type: string }) {
  const companyID = yield select(getCompanyID);
  const { Settings: companySettings } = yield select(getCompany);

  const {
    isSplitDuty,
    isOnCallDuty,
    isFlyingDuty,
    isOnReserveDuty,
    managementOverride,
    isPositioningAfterMaxFDP,
    isUnforeseenExtension,
    dutyInfoForm,
    flightInfoForm,
    acclimatizedTimezoneTag,
  } = yield select(({ pages: { dutyTimeModal } }) => dutyTimeModal);

  const [startTime, endTime] = dutyInfoForm.DutyRange?.filter(m => m) ?? [null, null];
  const [rapStartTime, rapEndTime] = dutyInfoForm.RAPRange?.filter(m => m) ?? [null, null];

  const isRangeValid = startTime && endTime && startTime.isValid() && endTime.isValid();
  const isDutyRangeEmpty = !startTime && !endTime;
  const isRAPRangeValid =
    rapStartTime && rapEndTime && rapStartTime.isValid() && rapEndTime.isValid();

  if (
    dutyInfoForm.UserID &&
    (isOnReserveDuty ? isRAPRangeValid && (isRangeValid || isDutyRangeEmpty) : isRangeValid) &&
    dutyInfoForm.SubParts?.length > 0
  ) {
    yield put(setIsValidationRunningAction(true));
    try {
      const response = yield call(
        api.post,
        `v1/users/${dutyInfoForm.UserID}/duty/validate`,
        serializeEntry(
          {
            ...omit(dutyInfoForm, [
              'ComplianceSummary',
              'FlightInformationDT',
              'AircraftInformation',
              'DurationSeconds',
              'onGoing',
              'VerifiedAt',
              'CreatedAt',
              'UpdatedAt',
              'UpdatedBy',
              'DeletedAt',
              'RemindersSent',
              'User',
              'FDPWarningEmailSent',
              'ViolationEmailSent',
              'WarningEmailSent',
              '',
            ]),
            ...{
              AcclimatizedTimezone: dutyInfoForm.acclimatizedTimezone || acclimatizedTimezoneTag,
            },
          },
          flightInfoForm,
          isSplitDuty,
          isOnReserveDuty,
          isPositioningAfterMaxFDP,
          isUnforeseenExtension,
          companyID,
          companySettings.IntegrateLoggingWithCrewScheduleV2,
          companySettings.AllowLoggingCustomerEditFlightInfo,
          isOnCallDuty,
          isFlyingDuty,
          managementOverride,
        ),
      );

      const {
        data: {
          Data: {
            ComplianceSummary: { Issues, ValidationState },
          },
        },
      } = response;

      if (!!ValidationState) {
        const validationState = JSON.parse(ValidationState);
        yield put(setValidationStateAction(validationState));
        const restBeforeString =
          validationState.RestBeforeDutyTime < 24 * 365
            ? getTimeString(validationState.RestBeforeDutyTime * 60)
            : 'N/A';

        let restAfterString = 'N/A';
        const totalDays = validationState.RestAfterDutyTime / 24;
        if (totalDays > 7) {
          restAfterString = '≥7 days';
        } else {
          restAfterString =
            validationState.RestAfterDutyTime < 24 * 365
              ? getTimeString(validationState.RestAfterDutyTime * 60)
              : 'N/A';
        }

        const maxDutyTimeString = getTimeString(validationState.MaxDutyDuration * 60);

        if (validationState.ModalMaxFDP > 0) {
          const maxFDPString = getTimeString(validationState.ModalMaxFDP * 60);
          yield put(setMaxFDPAction(maxFDPString));
        } else {
          yield put(setMaxFDPAction('N/A'));
        }

        yield put(setMaxDutyTimeAction(maxDutyTimeString));
        yield put(setRestBeforeAction(restBeforeString));
        yield put(setRestAfterAction(restAfterString));
        yield put(setAcclimatizedTimezoneTagAction(validationState.AcclimatizedTimezone));
      }

      yield put(setValidationSuggestionsAction(Issues || []));
    } catch (err) {
      yield put(
        setValidationSuggestionsAction([
          {
            Severity: 'error',
            Message: 'Duty entry invalid. Please correct all errors and try again.',
          },
        ]),
      );
    } finally {
      yield put(setIsValidationRunningAction(false));
    }
  }
}

function* watchFormUpdates() {
  const actionTypes = [
    actions.SET_DUTY_INFO_FORM_FIELDS,
    actions.SET_IS_ON_CALL_DUTY,
    actions.SET_IS_FLYING_DUTY,
    actions.SET_IS_ON_RESERVE_DUTY,
    actions.SET_IS_POSITIONING_AFTER_MAX_FDP,
    actions.SET_IS_UNFORESEEN_EXTENSION,
  ];

  while (true) {
    yield take(actionTypes);
    const modalMode = yield select(getDutyTimeModalMode);

    if (modalMode.open) {
      yield call(runValidationSaga, { type: actions.RUN_VALIDATION });
    }
  }
}

function* watchCurrentDutyTimeEntryUpdate() {
  const { Settings: companySettings } = yield select(getCompany);
  const { currentDutyTimeEntry } = yield select(({ pages: { dutyTimeModal } }) => dutyTimeModal);

  const dutyInfo = deserializeDutyInfo(currentDutyTimeEntry, companySettings);
  // todo: we should pick on the fields that are actually used in the form
  yield put(setDutyInfoFormFieldsAction(dutyInfo));

  const aircraftInfo = deserializeAircraftInfo(currentDutyTimeEntry);

  yield put(setFlightInfoFormFieldsAction(aircraftInfo));
}

function* watchModalModeChange() {
  while (true) {
    yield take([actions.OPEN_MODAL, actions.CLOSE_MODAL]);
    const { modalMode } = yield select(({ pages: { dutyTimeModal } }) => dutyTimeModal);

    yield call(modalChangeModeWorker, modalMode);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.RUN_VALIDATION, runValidationSaga),
    debounce(VALIDATION_DEB_MS, actions.RUN_VALIDATION_DEB, runValidationSaga),
    watchFormUpdates(),
    watchModalModeChange(),
    takeEvery(actions.SET_CURRENT_DUTY_TIME_ENTRY, watchCurrentDutyTimeEntryUpdate),
  ]);
}
