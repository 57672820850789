import { notification } from 'antd';
import { history } from 'index';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getCompany, getMyAccount, logout, requestToken } from '../../services/user';
import { getRedirectPath } from '../../utils/authorization';
import commonActions, { resetCommonState } from '../common/actions';
import { initLD } from '../common/workers/initLDWorker';
import integrationsActions from "../integrations/actions";
import menuActions from '../menu/actions';
import actions from './actions';
import { resetCompanyLibraryState } from '../../pages/data/organization/company-library/redux-saga/actions';

export function* LOGIN({ payload }) {
  const { email, password } = payload;

  const isSuccess = yield call(requestToken, email, password);

  if (!isSuccess) {
    yield put(
      commonActions.setNotifire({
        type: 'error',
        message: 'Login failed',
      }),
    );
    return;
  }

  const response = yield call(getMyAccount);
  const { ID, Email, Profile = {}, CompanyUsers = [] } = response;

  sessionStorage.setItem('ATcompanyUsers', JSON.stringify(CompanyUsers));
  sessionStorage.setItem('ATauthenticated', JSON.stringify(true));

  // CompanyUsers should always be there based on db relationships
  let companyResponse = null;
  if (CompanyUsers?.[0]?.CompanyID) {
    companyResponse = yield call(getCompany, CompanyUsers[0].CompanyID);
  } else {
    yield put(
      commonActions.setNotifire({
        type: 'error',
        message: 'Login failed',
      }),
    );
    return;
  }

  if (companyResponse) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        Company: companyResponse,
      },
    });
  }
  const featureFlags = yield call(initLD, companyResponse.ID, companyResponse.Name);

  yield put({
    type: actions.SET_STATE,
    payload: {
      id: ID,
      name: Profile ? `${Profile.FirstName} ${Profile.LastName}` : '',
      email: Email,
      role: 'admin',
      authenticated: true,
      Profile,
      CompanyUsers,
    },
  });

  notification.success({
    message: 'Logged In',
    description: 'You have successfully logged in to Air Trail',
  });

  yield put({
    type: menuActions.GET_DATA,
  });

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });

  const redirectionPath = getRedirectPath(
    true,
    true,
    featureFlags,
    companyResponse.Settings,
    CompanyUsers[0].Roles,
  );
  history.push(redirectionPath);
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(getMyAccount);
  if (response) {
    const { ID, Email, Profile = {}, CompanyUsers = [] } = response;
    yield put({
      type: actions.SET_STATE,
      payload: {
        id: ID,
        name: Profile ? `${Profile.FirstName} ${Profile.LastName}` : '',
        email: Email,
        role: 'admin',
        authenticated: true,
        Profile,
        CompanyUsers,
      },
    });
    if (CompanyUsers && CompanyUsers.length) {
      yield put({
        type: actions.LOAD_CURRENT_COMPANY,
        payload: {
          id: CompanyUsers[0].CompanyID,
        },
      });
      yield put({
        type: integrationsActions.INIT,
        companyId: CompanyUsers[0].CompanyID,
      });
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}

export function* LOAD_CURRENT_COMPANY({ payload }) {
  const { id } = payload;
  const response = yield call(getCompany, id);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        Company: response,
      },
    });
  }
}

export function* LOGOUT() {
  yield call(logout);
  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authenticated: false,
      loading: false,
    },
  });

  yield put(resetCommonState());
  yield put(resetCompanyLibraryState());

  sessionStorage.clear();
  history.push('/user/login');

  // we set isMobileMenuOpen to false, it will update local store and after next login
  // it will be false by default
  yield put({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'isMobileMenuOpen',
      value: false,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOAD_CURRENT_COMPANY, LOAD_CURRENT_COMPANY),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user authwatchCompanyLoaded
  ]);
}
