import actions from './actions';
import { RcFile } from 'antd/lib/upload/interface';
import { FlightsWithoutDuty } from './sagas';
import moment from 'moment-timezone';

export enum FileImportStatus {
  NONE = 'none',
  VALIDATION = 'validation',
  ERROR = 'error',
  DONE = 'done',
}

export interface FileImportResult {
  status: FileImportStatus;
  notImportedPilotsNames?: string[];
  importedActualDutiesAmount?: number;
  importedPlannedDutiesAmount?: number;
  flightsWithoutDuty?: FlightsWithoutDuty[];
  successUpdateActualDutyAmount?: number;
  successUpdatePlannedDutyAmount?: number;
}

export enum FileImportSourceVersion {
  v1 = 'v1',
  v2 = 'v2',
}

export const defaultVersion: FileImportSourceVersion | null = FileImportSourceVersion.v1;

export interface FileImportState {
  version: FileImportSourceVersion | null;
  availableVersions: FileImportSourceVersion[];
  isShowDrawer: boolean;
  isLoading: boolean;
  dutyTimeFile: RcFile;
  flightInfoFile: RcFile;
  uploadResult: FileImportResult;
  plannedDutiesRefMoment: moment.Moment;
}

export const initialState: FileImportState = {
  version: defaultVersion,
  availableVersions: [FileImportSourceVersion.v1],
  isShowDrawer: false,
  isLoading: false,
  dutyTimeFile: null,
  flightInfoFile: null,
  uploadResult: {
    status: FileImportStatus.NONE,
    notImportedPilotsNames: [],
    importedActualDutiesAmount: 0,
    flightsWithoutDuty: [],
    successUpdateActualDutyAmount: 0,
    successUpdatePlannedDutyAmount: 0,
  },
  plannedDutiesRefMoment: moment()
    .add(1, 'day')
    .startOf('day'),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_PLANNED_DUTIES_REF_MOMENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
