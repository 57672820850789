import { call, put, select } from 'redux-saga/effects';
import api from '../../../../../../../../services/api';
import { versioningActions } from '../actions';

export function* fetchCompanyScheduleVersionWorker() {
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  try {
    const {
      data: { Data },
    } = yield call(api.get, `/v1/companies/${companyID}/schedule-version`);
    yield put(versioningActions.setCompanyScheduleVersion(Data));
  } catch (error) {
    console.error(error);
  }
}
