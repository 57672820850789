import {call, put} from 'redux-saga/effects';
import { DeleteClientContractAction, clientContractModalActions } from '../actions';
import api from '../../../../../../../../../services/api';
import MODAL_MODE from '../../../../../../../common/constants';

export function* deleteClientContractPeriod(action: DeleteClientContractAction) {
  const { clientContractPeriodID } = action.payload;

  if (!clientContractPeriodID) {
    return;
  }

  try {
    yield call(api.delete, `/v1/client-contracts/period/${clientContractPeriodID}`);
    yield put(
      clientContractModalActions.setClientContractPeriodModalMode({
        mode: MODAL_MODE.create,
        open: false,
      }),
    );
  } catch (e) {
    console.error('Delete client contract error:', e);
  }
}
