import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, Divider } from 'antd';
import styles from './style.module.scss';
import { FeatureFlag } from '../../../../utils/feature-flags/FeatureFlagsProvider';
import Guard from '../../../../pages/permissions/crew-schedule-v2-guard';
import { getCommon, getMenu, getUser } from '../../../../redux/selectors';
import Icon from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars';

interface MobileDropdownMenuProps {
  closeOnSelect: () => void;
}
const MobileDropdownMenu: React.FC<MobileDropdownMenuProps> = ({ closeOnSelect }) => {
  const { menuLeftData: menuData } = useSelector(getMenu);
  const guard = new Guard('pages');
  const { featureFlags } = useSelector(getCommon);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (menuData.some(menuItem => menuItem.key === latestOpenKey)) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  };

  const generateItem = (item: any) => {
    const { key, title, url, disabled } = item;
    const icon = item ? getIcon(item) : null;

    if (item.divider) {
      return <Divider key={Math.random()} />;
    }
    return (
      <Menu.Item key={key} disabled={disabled} className={styles.menuItem}>
        {item.target ? (
          <a onClick={closeOnSelect} href={url} target={item.target} rel="noopener noreferrer">
            <span className={styles.title}>{title}</span>
          </a>
        ) : (
          <Link onClick={closeOnSelect} to={url} className={styles.menuChildren}>
            {icon}
            <span className={styles.title}>{title}</span>
          </Link>
        )}
      </Menu.Item>
    );
  };
  const getIcon = menuItem => {
    if (!menuItem) return null;
    const { iconName, iconComponent } = menuItem;
    if (iconComponent) {
      return <Icon component={iconComponent} className={`${styles.icon} icon-collapsed-hidden`} />;
    }
    if (iconName) {
      return (
        <Icon
          style={{
            marginRight: '8px',
            lineHeight: 'inherit',
            float: 'right',
            color: 'white',
          }}
          type={iconName}
        />
      );
    }
    return null;
  };

  const generateMenuItems = (items: any[]) => {
    return items
      .filter(menuItem => {
        if (
          menuItem.key === 'crew-scheduling-mobile' &&
          !featureFlags[FeatureFlag.CrewScheduleMobile]
        ) {
          return false;
        }
        if (menuItem.key === 'flightWatch' && !featureFlags[FeatureFlag.FlightWatch]) {
          return false;
        }
        return guard.canAccess(`${menuItem.key}`);
      })
      .map(menuItem => {
        if (menuItem.children) {
          return menuItem.children.map(child => generateItem(child));
        }
        return generateItem(menuItem);
      })
      .flat();
  };

  return (
    <div style={{ backgroundColor: '#001529' }}>
      <Scrollbars
        className={styles.scrollbarMobile}
        renderThumbVertical={({ style, ...styleProps }) => (
          <div
            {...styleProps}
            style={{
              ...style,
              width: '4px',
              borderRadius: 'inherit',
              backgroundColor: '#c5cdd2',
              left: '1px',
            }}
          />
        )}
        autoHide
      >
        <Menu
          theme={'dark'}
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          style={{ background: 'rgba(0, 0, 0, .85)' }}
        >
          {menuData.flatMap(menuItem => generateMenuItems(menuItem.children ?? [menuItem]))}
        </Menu>
      </Scrollbars>
    </div>
  );
};

export default MobileDropdownMenu;
