import { PusherFileDeleted, setFileList } from '../actions';
import { put, select } from 'redux-saga/effects';
import { getCompanyLibrary } from '../selectors';
import { CompanyLibraryState } from '../reducers';

export function* onPusherFileDeletedWorker(action: PusherFileDeleted) {
  const { fileList, loadMoreAvailable } = (yield select(getCompanyLibrary)) as CompanyLibraryState;

  const updatedFileList = fileList.filter(file => file.ID !== action.payload.fileID);
  yield put(setFileList(updatedFileList, loadMoreAvailable));
}
