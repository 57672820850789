import { put } from 'redux-saga/effects';
import LocalCache from '../../../../../../local-storage/local-storage';
import { scheduleActions } from '../../actions';
import { selectUserSaga } from '../../../../../../../common/saga/selectors';

export function* setCrewSortOptionFromCache() {
  const user = yield* selectUserSaga();
  const localCache = new LocalCache('crewSchedulingV2', user);
  const crewSortCache = localCache.getCached('crewSortOption');
  if (crewSortCache) {
    yield put(scheduleActions.setCrewSortOption(crewSortCache));
  } else {
    yield put(scheduleActions.setCrewSortOption('certTypeAsc'));
  }
}
