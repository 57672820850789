import { put, select } from 'redux-saga/effects';
import { getCommon, getCSMobile } from '../../selectors';
import { editFields, resetValidationMsg, setValidationMsg } from '../../actions';
import {
  CompanyScheduleType,
  CustomSection,
} from '../../../../../../../common/types/CombinedEntry';
import { composeKey } from '../../../helpers';

export function* checkCustomSectionRequiredFields() {
  const { editValues, entry } = yield select(getCSMobile);
  const { companyScheduleTypes } = yield select(getCommon);

  const scheduleEntryTypeID =
    editValues[editFields.CompanyScheduleTypeID] !== undefined
      ? editValues[editFields.CompanyScheduleTypeID]
      : entry.CompanyScheduleType?.ID;

  if (!scheduleEntryTypeID) {
    return;
  }

  const scheduleType: CompanyScheduleType = companyScheduleTypes.find(
    type => type.ID === scheduleEntryTypeID,
  );
  if (!scheduleType) {
    return;
  }

  let customSections: CustomSection[] =
    editValues[editFields.CustomSections] !== undefined
      ? editValues[editFields.CustomSections]
      : entry.CustomSections || scheduleType.CustomSectionTemplate || [];

  const validationMsgs: { [key: string]: string } = {};
  const allKeys = [];
  for (const section of customSections) {
    for (const field of section.Fields) {
      const isRequired = field.Required;
      let key = composeKey(editFields.CustomSections, section.Title, field.Title);
      allKeys.push(key);

      if (isRequired && (field.Type === 'multipleOption' ? !field.Values : !field.Value)) {
        validationMsgs[key] = `Required`;
      }
    }
  }

  for (const key of allKeys) {
    if (!validationMsgs[key]) {
      yield put(resetValidationMsg(key));
    } else {
      yield put(setValidationMsg(key, validationMsgs[key]));
    }
  }
}
