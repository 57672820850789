import { all, call } from 'redux-saga/effects';
import { TimelinePeriod } from '../../../types/TimelinePeriod';
import { loadAll } from './fetchAllEntries';
import moment from 'moment-timezone';

export type PeriodExtension = {
  period: TimelinePeriod;
  isLeft: boolean;
};

export function* extendAllEntries(extensions: PeriodExtension[]) {
  const actions = [];
  for (const extension of extensions) {
    const { period, isLeft } = extension;
    let from: moment.Moment;
    let to: moment.Moment;

    if (isLeft) {
      from = period.getStart().subtract(1, 'month');
      to = period.getEnd();
    } else {
      from = period.getStart();
      to = period.getEnd().add(1, 'month');
    }

    actions.push(call(loadAll, from.format(), to.format(), true));
  }

  yield all(actions);
}
