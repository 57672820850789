import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NProgress from 'nprogress';
import { Helmet } from 'react-helmet';
import { getUser } from '../redux/selectors';

const IndexLayout = props => {
  const [previousPath, setPreviousPath] = useState('');
  const user = useSelector(getUser);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props, previousPath, user]);

  const {
    children,
    location: { pathname, search },
  } = props;

  // NProgress Management
  NProgress.configure({ showSpinner: false });
  const currentPath = pathname + search;
  if (currentPath !== previousPath) {
    NProgress.start();
  }

  setTimeout(() => {
    NProgress.done();
    setPreviousPath(currentPath);
  }, 300);

  return (
    <>
      <Helmet titleTemplate="Air Trail | %s" title="Air Trail Dashboard" />
      {children}
    </>
  );
};

export default withRouter(IndexLayout);
