import { put } from 'redux-saga/effects';
import LocalCache from '../../../../../../local-storage/local-storage';
import { scheduleActions } from '../../actions';
import { selectUserSaga } from '../../../../../../../common/saga/selectors';
import { selectCurrentViewSaga, selectEntryDisplayOptionsSaga } from '../../selectors-saga';

export function* setCurrentViewFromCache() {
  // const user = yield* selectUserSaga();
  // const localCache = new LocalCache('crewSchedulingV2', user);
  // const defaultCurrentView = yield* selectCurrentViewSaga();
  // yield put(scheduleActions.switchToView(localCache.getCached('currentView', defaultCurrentView)));
}
