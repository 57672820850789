import { call, put } from 'redux-saga/effects';
import { OnTableChange, setState } from '../actions';
import { loadSubmissionsWorker } from './loadSubmissionsWorker';

export function* onSubmissionTableChangeWorker(action: OnTableChange) {
  const { pagination, sorter } = action.payload;
  yield put(
    setState({
      submissionPagination: pagination,
      submissionSorter: sorter,
    }),
  );
  yield call(loadSubmissionsWorker);
}
