import { HandleMoveTimelineItemAction, scheduleActions } from '../../actions';
import { TimelineItemResizeProps } from '../../../components/Timeline/types';
import { put } from 'redux-saga/effects';

export function* handleMoveTimelineItem(action: HandleMoveTimelineItemAction) {
  const { item, timelineType, newStartTime } = action.payload;

  const resizeProps: TimelineItemResizeProps = {
    timelineType,
    item,
    time: newStartTime,
    edge: 'both',
  };

  yield put(scheduleActions.handleResizeTimelineItem(resizeProps));
}
