import { scheduleActions, SetVisibleTimelinePeriodAction } from '../../actions';
import {
  selectAllEntryTypesLoadedSaga,
  selectIsLoadingMoreEntriesSaga,
  selectSelectedTimelinePeriodSaga,
} from '../../selectors-saga';
import { TimelinePeriodExtension } from '../../../types/timeline';
import { loadMore } from '../timelineEntries/loadMore';
import { call, put } from 'redux-saga/effects';
import { TimelinePeriod } from '../../../types/TimelinePeriod';

export function* watchSetVisibleTimelinePeriod(action: SetVisibleTimelinePeriodAction) {
  const { visibleTimelinePeriod } = action.payload;
  const isLoadingMore = yield* selectIsLoadingMoreEntriesSaga();
  const isFistLoadHappened = yield* selectAllEntryTypesLoadedSaga();

  if (!isFistLoadHappened) {
    return;
  }
  if (isLoadingMore) {
    return;
  }

  yield put(scheduleActions.setState({ isLoadingMoreEntries: true, isTimelineLoading: true }));

  try {
    const timePeriod = yield* selectSelectedTimelinePeriodSaga();
    const extension = checkOnExtension(timePeriod, visibleTimelinePeriod);

    if (extension.isRequired) {
      yield call(loadMore, extension);
    }
  } finally {
    yield put(scheduleActions.setState({ isLoadingMoreEntries: false, isTimelineLoading: false }));
  }
}

const checkOnExtension = (
  current: TimelinePeriod,
  visible: TimelinePeriod,
): TimelinePeriodExtension => {
  const isLeft = visible.getStart().isBefore(current.getStart());
  const isRight = visible.getEnd().isAfter(current.getEnd());
  return {
    isRequired: isLeft || isRight,
    left: isLeft,
    right: isRight,
  };
};
