import { all, takeEvery, fork, debounce } from 'redux-saga/effects';
import actions from './actions';
import { loadFileListWorker } from './workers/loadFileListWorker';
import { onPusherFileCreatedWorker } from './workers/onPusherFileCreatedWorker';
import { onPusherFileUpdatedWorker } from './workers/onPusherFileUpdatedWorker';
import { loadFilesRelationsWorker } from './workers/loadFilesRelations';
import { loadAircraftLogs } from './workers/loadAircraftLogs';
import { handleFiltersChange } from './workers/handleFiltersChange';
import { uploadFileWorker } from './workers/uploadFileWorker';
import { deleteFileWorker } from './workers/deleteFileWorker';
import { onPusherFileDeletedWorker } from './workers/onPusherFileDeletedWorker';
import { loadMoreWorker } from './workers/loadMoreWorker';
import { resetCompanyLibraryState } from './workers/resetCompanyLibraryState';

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FILE_LIST, loadFileListWorker),
    takeEvery(actions.LOAD_AIRCRAFT_LOGS_FOR_SELECTOR, loadAircraftLogs),
    takeEvery(actions.LOAD_FILE_LIST, loadFilesRelationsWorker),
    takeEvery(actions.PUSHER_FILE_CREATED, onPusherFileCreatedWorker),
    takeEvery(actions.PUSHER_FILE_UPDATED, onPusherFileUpdatedWorker),
    takeEvery(actions.PUSHER_FILE_DELETED, onPusherFileDeletedWorker),
    takeEvery(actions.RELATED_ENTITIES_CHANGE_FILTER, handleFiltersChange),
    debounce(700, actions.FILE_NAME_CHANGE_FILTER, handleFiltersChange),
    takeEvery(actions.RELATED_ENTITY_TYPES_CHANGE_FILTER, handleFiltersChange),
    takeEvery(actions.UPLOAD_FILE, uploadFileWorker),
    takeEvery(actions.DELETE_FILE, deleteFileWorker),
    takeEvery(actions.LOAD_MORE, loadMoreWorker),
    takeEvery(actions.RESET_STATE, resetCompanyLibraryState),
  ]);
}
