import { DutyFiCommon } from '../../types/entries';
import { composeKey } from '../../../helpers';
import { editFields, resetValidationMsg, setValidationMsg } from '../../actions';
import { put } from 'redux-saga/effects';
import { apiEntryTypeMap } from '../../../../../../../common/types/apiEntryTypes';
import { EntryType } from '../../../../../../../common/types/entryTypes';
import { CombinedEntry } from '../../../../../../../common/types/CombinedEntry';

export function* checkTotalHours(
  fi: DutyFiCommon,
  idx: number,
  entryType: EntryType,
  isDetailedFlightsTimesFeature: boolean,
  entry: CombinedEntry,
  editValues: any,
) {
  const key = composeKey(String(idx), editFields.totalFlightHours);

  let totalHours = 0;
  if (isDetailedFlightsTimesFeature && fi.DetailedFlightTimes) {
    let totalFlights =
      isDetailedFlightsTimesFeature && fi.DetailedFlightTimes ? fi.DetailedFlightTimes.length : 0;
    if (totalFlights === 0) {
      return yield put(resetValidationMsg(key));
    }
    totalHours = fi.DetailedFlightTimes.reduce((acc, curr) => acc + curr.FlightTime, 0);
  } else {
    if (
      entryType === apiEntryTypeMap.DutyTimeEntries ||
      entryType === apiEntryTypeMap.DutyScheduleEntries
    ) {
      totalHours = fi.FlightTime;
    }
  }

  const isNonFlyingDuty =
    editValues[editFields.NonFlyingDuty] !== undefined
      ? editValues[editFields.NonFlyingDuty]
      : entry.NonFlyingDuty;

  if (totalHours === 0 && !isNonFlyingDuty) {
    yield put(setValidationMsg(key, 'Total Hours must be more than 0'));
  } else {
    yield put(resetValidationMsg(key));
  }
}
