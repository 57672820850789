import React, { useEffect, useState } from 'react';
import { Col, Row, Select, Spin, Button, message } from 'antd';
import { getCompany, getSettings, getUser } from 'redux/selectors';
import { useSelector } from 'react-redux';
import moment from 'moment';
import api from 'services/api';
import TZTagUTC from './TZTagUtc';

const { Option } = Select;

const TimezoneSetting = ({ config }) => {
  const company = useSelector(getCompany);
  const settings = useSelector(getSettings);
  const user = useSelector(getUser);
  const [isLoading, setIsloading] = useState(true);
  const [companySettings, setCompanySettings] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [currentTimezone, setCurrentTimezone] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState('');

  // const config = props;
  const { editable, labelOnly } = config;

  useEffect(() => {
    if (company && user) {
      if (company?.Settings) {
        setCompanySettings({
          CanMemberChangeTimezone: company.Settings.CanMemberChangeTimezone,
          CompanyDefaultTimezone: company.Settings.CompanyDefaultTimezone,
        });
      }
      if (user?.Profile.Metadata) {
        setUserSettings({
          SelectedTimeZone: user.Profile.Metadata.SelectedTimeZone
            ? user.Profile.Metadata.SelectedTimeZone
            : '',
        });
      }
    }
  }, [company, user]);

  useEffect(() => {
    if (companySettings && userSettings) {
      if (companySettings.CanMemberChangeTimezone) {
        if (userSettings.SelectedTimeZone.length > 0) {
          setCurrentTimezone(userSettings.SelectedTimeZone);
        } else {
          setCurrentTimezone(companySettings.CompanyDefaultTimezone);
        }
      } else {
        setCurrentTimezone(companySettings.CompanyDefaultTimezone);
      }
      setIsloading(false);
    } else {
      setCurrentTimezone(moment().tz());
    }
  }, [userSettings, companySettings]);

  useEffect(() => {
    setSelectedTimezone(currentTimezone);
  }, [currentTimezone]);

  const updateProfileTimezone = async value => {
    setIsloading(true);
    const {
      data: { Data },
    } = await api.patch(
      `/v1/users/${user.id}/timezone/update`,
      {},
      {
        params: { timezone: value },
      },
    );
    if (Data) {
      message.success('Timezone updated successfully');
      window.location.reload();
      setIsloading(false);
    }
  };

  const handleTimezoneChange = value => {
    setSelectedTimezone(value);
    updateProfileTimezone(value);
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          {companySettings?.CanMemberChangeTimezone ? (
            <Spin spinning={isLoading} style={{ width: '100%' }}>
              {editable && (
                <Row
                  justify="center"
                  style={{ border: '1px solid lightgray', borderRadius: '5px', padding: '10px' }}
                >
                  <Col span={24}>
                    <p
                      style={{
                        fontSize: '14px',
                        color: settings.isMobileView ? '#FFFFFFA6' : 'black',
                        textAlign: 'center',
                        marginTop: '2px',
                      }}
                    >
                      Timezone
                    </p>
                    <Select
                      dropdownStyle={{ zIndex: 10000 }}
                      style={{ width: '100%' }}
                      showSearch
                      placeholder="Please Select Timezone"
                      // defaultValue="Asia/Kolkata"
                      value={selectedTimezone}
                      onChange={handleTimezoneChange}
                    >
                      {moment.tz.names().map(tz => (
                        <Option key={tz} value={tz}>
                          {tz}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              )}

              {labelOnly && (
                <div style={{ textAlign: 'right', marginRight: '20px' }}>
                  {/* {currentTimezone.length > 0 ? currentTimezone : moment.tz.guess()} */}
                  <TZTagUTC isMenuCollapsed={settings.isMobileView} />
                </div>
              )}
            </Spin>
          ) : (
            <Row style={{ display: 'block' }}>
              {editable && (
                <p
                  style={{
                    textAlign: 'center',
                    color: settings.isMobileView ? '#FFFFFFA6' : 'black',
                  }}
                >
                  Timezone settings are disabled
                </p>
              )}
            </Row>
          )}

          {labelOnly && !companySettings?.CanMemberChangeTimezone && (
            <div style={{ textAlign: 'right', marginRight: '20px' }}>
              <TZTagUTC isMenuCollapsed={settings.isMobileView} />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TimezoneSetting;
