import { ApiTypes } from 'models';

export enum Types {
  GET_CURRENCY_REQUEST = 'pages/currency/GET_CURRENCY_REQUEST',
  GET_CURRENCY_SUCCESS = 'pages/currency/GET_CURRENCY_SUCCESS',
  GET_CURRENCY_ERROR = 'pages/currency/GET_CURRENCY_ERROR',

  SET_CURRENT_PAGE_NUMBER = 'pages/currency/SET_CURRENT_PAGE_NUMBER',
  SET_CURRENT_PAGE_SIZE = 'pages/currency/SET_CURRENT_PAGE_SIZE',
  SET_TOTAL_COUNT = 'pages/currency/SET_TOTAL_COUNT',

  GET_AIRCRAFT_TYPES_REQUEST = 'pages/currency/GET_AIRCRAFT_TYPES_REQUEST',
  GET_AIRCRAFT_TYPES_SUCCESS = 'pages/currency/GET_AIRCRAFT_TYPES_SUCCESS',
  GET_AIRCRAFT_TYPES_ERROR = 'pages/currency/GET_AIRCRAFT_TYPES_ERROR',

  SET_CURRENT_AIRCRAFT_TYPE = 'pages/currency/SET_CURRENT_AIRCRAFT_TYPE',
  SET_CURRENCY_DATA = 'pages/currency/SET_CURRENCY_DATA',
}

const getCurrencyRequest = (payload: ApiTypes.Currency.TableDataRequest) => ({
  type: Types.GET_CURRENCY_REQUEST,
  payload,
});

const getCurrencySuccess = (payload: ApiTypes.Currency.Data[]) => ({
  type: Types.GET_CURRENCY_SUCCESS,
  payload,
});

const setCurrentPageNumber = (payload: number) => ({
  type: Types.SET_CURRENT_PAGE_NUMBER,
  payload,
});

const setCurrentPageSize = (payload: number) => ({
  type: Types.SET_CURRENT_PAGE_SIZE,
  payload,
});

const setTotalCount = (payload: number) => ({
  type: Types.SET_TOTAL_COUNT,
  payload,
});

const getAircraftTypesRequest = (payload: string) => ({
  type: Types.GET_AIRCRAFT_TYPES_REQUEST,
  payload,
});

const getAircraftTypesSuccess = payload => ({
  type: Types.GET_AIRCRAFT_TYPES_SUCCESS,
  payload,
});

const setCurrentAircraftType = (payload: string) => ({
  type: Types.SET_CURRENT_AIRCRAFT_TYPE,
  payload,
});

const setCurrencyData = data => ({
  type: Types.SET_CURRENCY_DATA,
  payload: {
    data,
  },
});

export default {
  getCurrencyRequest,
  getCurrencySuccess,
  setCurrentPageNumber,
  setCurrentPageSize,
  setTotalCount,
  getAircraftTypesRequest,
  getAircraftTypesSuccess,
  setCurrentAircraftType,
  setCurrencyData,
};
