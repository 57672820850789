import { call, put } from 'redux-saga/effects';
import api from '../../../../../../services/api';
import { setState } from '../actions';
import { CustomFormTemplate } from '../../types/template';

export function* loadTemplatesWorker() {
  try {
    yield put(setState({ isLoadingTemplateList: true }));
    const {
      data: { Data },
    } = yield call(api.get, `/v1/custom-forms/list-templates`);

    const list = Data as CustomFormTemplate[];

    yield put(setState({ templateList: list }));
  } catch (err) {
    console.log('Failed to load templates', err);
  } finally {
    yield put(setState({ isLoadingTemplateList: false }));
  }
}
