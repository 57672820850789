import { AircraftLogsFromFlightsAction, setState } from '../actions';
import api from '../../../../services/api';
import { call, put, select } from 'redux-saga/effects';
import moment from 'moment-timezone';

export function* aircraftLogsFromFlightsWorker(action: AircraftLogsFromFlightsAction) {
  const { startTime, endTime, userID } = action.payload;
  if (!startTime || !endTime || !userID) {
    return;
  }
  if (moment(endTime).diff(moment(startTime), 'hours') > 30) {
    yield put(
      setState({
        logsFromFlightsMessage:
          'Difference between start and end time exceeds 30 hours. Logs cannot be fetched.',
        aircraftLogsByFlight: [],
      }),
    );
    return;
  }
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  try {
    yield put(setState({ isLoadingAircraftLogsByFlight: true, aircraftLogsByFlight: [] }));

    const {
      data: { Data },
    } = yield call(api.get, `/v1/companies/${companyID}/find-logs`, {
      params: {
        start_time: startTime.format(),
        end_time: endTime.format(),
        user_id: userID,
      },
    });
    if (Data?.length) {
      yield put(
        setState({
          logsFromFlightsMessage: '',
          aircraftLogsByFlight: Data,
        }),
      );
    } else {
      yield put(
        setState({
          logsFromFlightsMessage: 'There are no flights associated with this duty.',
        }),
      );
    }
  } catch (err) {
    console.log('Failed to find logs', err);
  } finally {
    yield put(setState({ isLoadingAircraftLogsByFlight: false }));
  }
}
