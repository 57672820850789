import { CommonInitState } from './reducers';

const getCommon = ({ common }) => common as CommonInitState;

export const getAllProfiles = state => getCommon(state).allProfiles;
export const getPilotProfiles = state => getCommon(state).pilotProfiles;
export const getAircraftSelectorType = state => getCommon(state).aircraftSelectorType;

export const getFeatureFlags = state => getCommon(state).featureFlags;
export const getClientContracts = state => getCommon(state).clientContracts;
export const getCompanyScheduleTypes = state => getCommon(state).companyScheduleTypes;
export const getCompanyAircraftList = state => getCommon(state).aircraftList;
export const getDefaultSubPartList = state => getCommon(state).defaultSubParts;
