const actions = {
  SET_STATE: 'pages/aircraft-details/SET_STATE',
  CREATE_WORK_ORDER: 'pages/aircraft-details/CREATE_WORK_ORDER',

  ADD_FILE_REQUEST: 'pages/aircraft-details/ADD_FILE_REQUEST',
  ADD_DOCUMENT_SUCCESS: 'pages/aircraft-details/ADD_DOCUMENT_SUCCESS',
  ADD_DOCUMENT_ERROR: 'pages/aircraft-details/ADD_DOCUMENT_ERROR',

  EDIT_DOCUMENT_REQUEST: 'pages/aircraft-details/EDIT_DOCUMENT_REQUEST',
  EDIT_DOCUMENT_SUCCESS: 'pages/aircraft-details/EDIT_DOCUMENT_SUCCESS',
  EDIT_DOCUMENT_ERROR: 'pages/aircraft-details/EDIT_DOCUMENT_ERROR',

  DELETE_DOCUMENT_REQUEST: 'pages/aircraft-details/DELETE_DOCUMENT_REQUEST',
  DELETE_DOCUMENT_SUCCESS: 'pages/aircraft-details/DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_ERROR: 'pages/aircraft-details/DELETE_DOCUMENT_ERROR',
};

export default actions;
