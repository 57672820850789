import { GenericAction } from '../../../../../../../../common/types/redux/actions';
import { BulkScheduleModalValuesForm } from '../../../types';
import MODAL_MODE from '../../../../../../common/constants';
import { SaveError } from './types';
import { ModalMode } from '../../../../../../../../redux/pages/crew-scheduling-v3/modals/interfaces';
import { BulkScheduleGroupedByField } from '../Base/types';

const actions = {
  SET_STATE: 'BulkScheduleModal/SET_STATE',
  SET_SAVE_ERROR: 'BulkScheduleModal/SET_SAVE_ERROR',
  SAVE_GROUPED_CS_ENTRY: 'BulkScheduleModal/SAVE_GROUPED_CS_ENTRY',
  DELETE_GROUPED_CS_ENTRY: 'BulkScheduleModal/DELETE_GROUPED_CS_ENTRY',
  SET_AIRCRAFT_MODAL_MODE: 'BulkScheduleModal/SET_AIRCRAFT_MODAL_MODE',
  SET_CONTRACT_MODAL_MODE: 'BulkScheduleModal/SET_CONTRACT_MODAL_MODE',
};

export interface SaveCSGroupedEntryAction extends GenericAction {
  payload: {
    formValues: BulkScheduleModalValuesForm;
    mode: MODAL_MODE;
    groupedBy: BulkScheduleGroupedByField;
  };
}

const saveVirtualCrewScheduleContractEntry = (
  formValues: BulkScheduleModalValuesForm,
  mode: MODAL_MODE,
  groupedBy: BulkScheduleGroupedByField,
): SaveCSGroupedEntryAction => {
  return {
    type: actions.SAVE_GROUPED_CS_ENTRY,
    payload: { formValues, mode, groupedBy },
  };
};

const setIsSaving = (isSaving: boolean) => ({
  type: actions.SET_STATE,
  payload: { isSaving },
});

export interface SaveErrorAction extends GenericAction {
  payload: {
    saveError: SaveError | null;
  };
}

const setSaveError = (saveError: SaveError | null): SaveErrorAction => ({
  type: actions.SET_SAVE_ERROR,
  payload: { saveError },
});

export interface DeleteCSGroupedEntryAction extends GenericAction {
  payload: {
    crewScheduleIDs: string[];
    relatedAircraftIDs: string[];
  };
}

const deleteVirtualCrewScheduleContractEntry = (
  crewScheduleIDs: string[],
  relatedAircraftIDs: string[],
): DeleteCSGroupedEntryAction => ({
  type: actions.DELETE_GROUPED_CS_ENTRY,
  payload: { crewScheduleIDs, relatedAircraftIDs },
});

const setAircraftModalMode = (aircraftBulkScheduleModalMode: ModalMode) => ({
  type: actions.SET_AIRCRAFT_MODAL_MODE,
  payload: { aircraftBulkScheduleModalMode },
});

const setContractModalMode = (contractBulkScheduleModalMode: ModalMode) => ({
  type: actions.SET_CONTRACT_MODAL_MODE,
  payload: { contractBulkScheduleModalMode },
});

export const bulkModalEntryActions = {
  saveVirtualCrewScheduleContractEntry,
  setIsSaving,
  setSaveError,
  deleteVirtualCrewScheduleContractEntry,
  setAircraftModalMode,
  setContractModalMode,
};

export default actions;
