import actions from './actions';
import { CustomFormTemplate } from '../types/template';
import { Submissions } from '../types/submissions';
import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';

export interface CustomFormsState {
  isLoading: boolean;
  isLoadingTemplateList: boolean;
  isLoadingSubmissionsList: boolean;
  isTemplateModalOpen: boolean;
  isSubmissionModalOpen: boolean;
  editingTemplate: CustomFormTemplate;
  templateList: CustomFormTemplate[];
  submissionList: Submissions[];
  previewSubmission: Submissions | null;
  submissionPagination: TablePaginationConfig;
  submissionSorter: SorterResult<any>;
}

export const initialState: CustomFormsState = {
  isLoading: false,
  isLoadingTemplateList: false,
  isLoadingSubmissionsList: false,
  isTemplateModalOpen: false,
  isSubmissionModalOpen: false,
  editingTemplate: null,
  templateList: [],
  submissionList: [],
  previewSubmission: null,
  submissionPagination: {
    total: 0,
    current: 1,
    pageSize: 20,
  },
  submissionSorter: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.OPEN_TEMPLATE_MODAL:
    case actions.CLOSE_TEMPLATE_MODAL:
    case actions.SET_EDITING_TEMPLATE:
    case actions.OPEN_SUBMISSION_MODAL:
    case actions.CLOSE_SUBMISSION_MODAL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
