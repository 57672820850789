import { call, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import api from '../../../../../../services/api';
import * as Sentry from '@sentry/react';
import { notification } from 'antd';
import { CustomFormsState } from '../reducers';
import { getCustomForms } from '../selectors';
import { OnDeleteTemplateAction, setState } from '../actions';
import { template } from 'lodash';
import { CustomFormTemplate } from '../../types/template';

export function* deleteTemplateWorker(action: OnDeleteTemplateAction) {
  const { templateID } = action.payload;
  yield put(setState({ isLoading: true }));
  const { templateList } = (yield select(getCustomForms)) as CustomFormsState;

  try {
    let response: AxiosResponse = yield call(api.delete, `/v1/custom-forms/template/${templateID}`);

    if (response.status === 200) {
      let updatedTemplateList: CustomFormTemplate[] = templateList
        .map(t => {
          if (t.ID === templateID) {
            return null;
          }
          return t;
        })
        .filter(t => t !== null) as CustomFormTemplate[];

      yield put(setState({ templateList: updatedTemplateList }));

      notification.success({
        message: 'Success',
        description: 'Form template has been deleted.',
      });
    }
  } catch (error) {
    Sentry.captureMessage(`Error creating form template ${error}`);
    notification.error({
      message: 'Error',
      description:
        'There was an error deleting form template. Please try again or contact support.',
    });
  } finally {
    yield put(setState({ isLoading: false }));
  }
}
