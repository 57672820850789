import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { fetchEntry } from '../../timelineEntries/fetchEntry';
import { CrewTimelineEntryType } from '../../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { DutyTimelineEntry } from '../../../../../../../../common/types/timeline/DutyTimelineEntry';
import { put, select } from 'redux-saga/effects';
import { scheduleActions } from '../../../actions';
import {
  selectDutyScheduleTimelineEntriesSaga,
  selectPreferredVersionSaga,
} from '../../../selectors-saga';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import { isIgnoreUpdate } from '../helpers';

export function* handleDutyScheduleEntryUpdate(
  action: PusherReduxAction<PusherActionType.DUTY_SCHEDULE_ENTRY_UPDATED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  try {
    const { payload } = action;

    const { CrewScheduleSandboxID } = payload;
    const preferredVersion = yield* selectPreferredVersionSaga();
    if (isIgnoreUpdate(preferredVersion, CrewScheduleSandboxID)) {
      return;
    }

    const dutyScheduleEntry = (yield fetchEntry(
      CrewTimelineEntryType.DutySchedule,
      payload.ID,
      true,
    )) as DutyTimelineEntry;

    const exitedEntries = yield* selectDutyScheduleTimelineEntriesSaga();

    const updatedDutyScheduleTimelineEntries = exitedEntries.map(dutyScheduleTimelineEntry => {
      if (dutyScheduleTimelineEntry.ID === dutyScheduleEntry.ID) {
        return dutyScheduleEntry;
      }
      return dutyScheduleTimelineEntry;
    });

    yield put(
      scheduleActions.setState({ dutyScheduleTimelineEntries: updatedDutyScheduleTimelineEntries }),
    );
  } finally {
  }
}
