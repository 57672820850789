import { CompanySettings } from '../../../../../../common/types/Company';

export function isRegulationFieldRequired(settings?: CompanySettings): boolean {
  return !settings?.IntegrateLoggingWithCrewScheduleV2 || settings?.AllowAdminEditRegulations;
}

export function isRegulationEditDisabled(
  isFlyingDuty: boolean,
  settings?: CompanySettings,
  userRoles: string[] = [],
): boolean {
  const isPilot = userRoles.includes('pilot');
  const isAdmin = userRoles.includes('admin');
  if (isPilot && !isAdmin && !settings?.AllowPilotDutyManagement) {
    return true;
  }
  return (
    settings?.IntegrateLoggingWithCrewScheduleV2 &&
    isFlyingDuty &&
    !settings?.AllowAdminEditRegulations
  );
}
