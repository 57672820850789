import { GenericAction } from '../../../../../common/types/redux/actions';
import { SettingsInitState } from './reducers';
import { ClientContractForm } from './types';
import { ModalMode } from '../../../../../redux/pages/crew-scheduling-v3/modals/interfaces';
import { ClientContract } from '../../../../../common/types/ClientContract';

export const actionTypes = {
  SET_STATE: 'settings-page/SET_STATE',
  SAVE_CLIENT_CONTRACT: 'settings-page/SAVE_CLIENT_CONTRACT',
  DELETE_CLIENT_CONTRACT: 'settings-page/DELETE_CLIENT_CONTRACT',
};

export interface SetStateAction extends GenericAction {
  payload: Partial<SettingsInitState>;
}

export interface AddClientContractAction extends GenericAction {
  payload: { clientID: string; form: ClientContractForm; clientContractID: string | null };
}

export interface DeleteClientContractAction extends GenericAction {
  payload: { clientContractID: string };
}

export interface SetModalModeAction extends GenericAction {
  payload: { clientContractModalMode: ModalMode };
}

export interface SetEditedClientContractAction extends GenericAction {
  payload: { editedClientContract: ClientContract };
}

export interface SetEditedClientIDAction extends GenericAction {
  payload: { editedClientID: string | null };
}

export const settingPageActions = {
  setState: (payload: Partial<SettingsInitState>): SetStateAction => ({
    type: actionTypes.SET_STATE,
    payload,
  }),
  saveClientContract: (
    clientID: string,
    form: ClientContractForm,
    clientContractID: string | null,
  ): AddClientContractAction => ({
    type: actionTypes.SAVE_CLIENT_CONTRACT,
    payload: { clientID, form, clientContractID },
  }),
  setClientContractModalMode: (clientContractModalMode: ModalMode): SetModalModeAction => ({
    type: actionTypes.SET_STATE,
    payload: { clientContractModalMode },
  }),
  setEditedClientContract: (
    editedClientContract: ClientContract,
  ): SetEditedClientContractAction => ({
    type: actionTypes.SET_STATE,
    payload: { editedClientContract },
  }),
  setEditedClientID: (editedClientID: string | null): SetEditedClientIDAction => ({
    type: actionTypes.SET_STATE,
    payload: { editedClientID },
  }),
  deleteClientContract: (clientContractID: string): DeleteClientContractAction => ({
    type: actionTypes.DELETE_CLIENT_CONTRACT,
    payload: { clientContractID },
  }),
};
