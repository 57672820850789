import { call, put, select } from 'redux-saga/effects';
import { getCompany } from '../../selectors';
import { Company } from '../../../common/types/Company';
import api from '../../../services/api';
import actions from '../actions';
import { CompanyCustomCurrencyItem } from '../../../common/types/customCurrencyItem';

export function* loadCustomCurrencyItemTemplates() {
  const company = (yield select(getCompany)) as Company;

  const customCurrencyItemConfig = company.Settings;
  if (customCurrencyItemConfig.CustomCurrencyItemsEnabled) {
    try {
      const {
        data: { Data },
      } = yield call(api.get, `/v1/companies/${company.ID}/custom-currency-item-templates`, {
        params: {},
      });
      const items = Data as CompanyCustomCurrencyItem[];
      const templates = items?.map(item => {
        return {
          ...item.CustomCurrencyItemTemplate,
          CompanyConfig: {
            Enabled: item.Enabled,
            Scope: item.Scope,
          },
        };
      });
      yield put(actions.setCustomCurrencyItemTemplates(templates || []));
    } catch (err) {
      console.log('err', err);
    }
  }
}
