import { select } from 'redux-saga/effects';
import {
  getCompanyScheduleVersion,
  getPreferredVersion,
  getPublishedVersions,
  getRecentlyPublishedEntries,
  getUnpublishedEntries,
} from './selectors';

export function* selectUnpublishedEntriesSaga(): Generator<
  any,
  ReturnType<typeof getUnpublishedEntries>,
  ReturnType<typeof getUnpublishedEntries>
> {
  return yield select(getUnpublishedEntries);
}

export function* selectRecentlyPublishedEntriesSaga(): Generator<
  any,
  ReturnType<typeof getRecentlyPublishedEntries>,
  ReturnType<typeof getRecentlyPublishedEntries>
> {
  return yield select(getRecentlyPublishedEntries);
}

export function* selectPublishedVersionsSaga(): Generator<
  any,
  ReturnType<typeof getPublishedVersions>,
  ReturnType<typeof getPublishedVersions>
> {
  return yield select(getPublishedVersions);
}

export function* selectPreferredVersionSaga(): Generator<
  any,
  ReturnType<typeof getPreferredVersion>,
  ReturnType<typeof getPreferredVersion>
> {
  return yield select(getPreferredVersion);
}

export function* selectCompanyScheduleVersionSaga(): Generator<
  any,
  ReturnType<typeof getCompanyScheduleVersion>,
  ReturnType<typeof getCompanyScheduleVersion>
> {
  return yield select(getCompanyScheduleVersion);
}
