import { put, select } from 'redux-saga/effects';
import { setState } from '../actions';
import LocalCache from '../../../../pages/local-storage/local-storage';
import { sortCrew } from '../loadPilots';
import { getUserRole } from '../../../selectors';

export function* watchSelectedPilots() {
  const { selectedCrewMemberIds, pilots, crewSortOption } = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2,
  );
  const user = yield select(({ user }) => user);
  const userRoles = yield select(getUserRole) || [];
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);
  const localCache = new LocalCache('crewSchedulingV2', user);
  if (selectedCrewMemberIds.length === 0) {
    let sortedCrew = sortCrew(pilots, crewSortOption, user, userRoles, companySettings);
    yield put(setState({ selectedPilots: sortedCrew }));
    localCache.setCached('selectedCrewMemberIds', []);
    return;
  }

  const selectedPilots = pilots.filter(({ ID }) => selectedCrewMemberIds.includes(ID));
  let sortedCrew = sortCrew(selectedPilots, crewSortOption, user, userRoles, companySettings);
  yield put(setState({ selectedPilots: sortedCrew }));
  localCache.setCached('selectedCrewMemberIds', selectedCrewMemberIds);
}
