import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { CrewScheduleTimelineEntry } from '../../../../../../../../common/types/timeline/CrewScheduleTimelineEntry';
import { fetchEntry } from '../../timelineEntries/fetchEntry';
import { CrewTimelineEntryType } from '../../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { scheduleActions } from '../../../actions';
import { put, select } from 'redux-saga/effects';
import {
  selectCrewScheduleTimelineEntriesSaga,
  selectPreferredVersionSaga,
} from '../../../selectors-saga';
import { NEW_ITEM_ID } from '../../../../components/Timeline/constants';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import { isIgnoreUpdate } from '../helpers';

export function* handleCrewScheduleEntryCreate(
  action: PusherReduxAction<PusherActionType.CREW_SCHEDULE_ENTRY_CREATED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  const { payload } = action;
  const { ID, CrewScheduleSandboxID } = payload;
  const preferredVersion = yield* selectPreferredVersionSaga();
  if (isIgnoreUpdate(preferredVersion, CrewScheduleSandboxID)) {
    return;
  }

  try {
    const entry = (yield fetchEntry(
      CrewTimelineEntryType.CrewSchedule,
      ID,
      true,
    )) as CrewScheduleTimelineEntry;

    const existedEntries = yield* selectCrewScheduleTimelineEntriesSaga();
    const updatedEntries = [...existedEntries.filter(e => e.ID !== NEW_ITEM_ID), entry];

    yield put(scheduleActions.setState({ crewScheduleTimelineEntries: updatedEntries }));
    yield put(scheduleActions.reFetchAircraftScheduleEntries(entry.AssignedAircraftIDs));
  } finally {
  }
}
