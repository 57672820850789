import { CommonTypes } from 'models';
import { Profile } from '../../common/types/user';
import { AircraftMinimal } from '../../common/types/aircraft';
import { CommonInitState } from './reducers';
import { CustomCurrencyItemTemplate } from '../../common/types/customCurrencyItem';
import { AircraftSelectorType } from './types';
import { CompanyScheduleType } from '../../common/types/CombinedEntry';
import { CompanyClient } from '../../common/types/client';
import { Pilot } from '../../common/types/pilot';
import { CompanyDocument } from '../../common/types/companyDocument';
import { ClientContract } from '../../common/types/ClientContract';
import { GenericAction } from '../../common/types/redux/actions';

export const actionTypes = {
  SET_NOTIFIRE: 'common/SET_NOTIFIRE',
  CLEAR_NOTIFIRE: 'common/CLEAR_NOTIFIRE',
  SET_STATE: 'common/SET_STATE',
  SET_LOAD_DEFAULT_AIRPORTS_TO_STATE: 'common/SET_LOAD_DEFAULT_AIRPORTS_TO_STATE',
  LOAD_DEFAULT_AIRPORTS: 'common/LOAD_DEFAULT_AIRPORTS',
  SET_FEATURE_FLAGS: 'common/SET_FEATURE_FLAGS',
  LOAD_COMPANY_CLIENTS: 'common/LOAD_COMPANY_CLIENTS',
  SET_CLIENT_CONTRACTS: 'common/SET_CLIENT_CONTRACTS',
  COMPANY_CLIENTS_LOADED: 'common/COMPANY_CLIENTS_LOADED',
  DEFAULT_COMPANY_CLIENTS_LOADED: 'common/DEFAULT_COMPANY_CLIENTS_LOADED',
  SET_PILOT_PROFILES: 'common/SET_PILOT_PROFILES',
  SET_ALL_PROFILES: 'common/SET_ALL_PROFILES',
  SET_COMPANY_AIRCRAFT_LIST: 'common/SET_COMPANY_AIRCRAFT_LIST',
  SET_AIRCRAFT_TAIL_NUMBERS: 'common/SET_AIRCRAFT_TAIL_NUMBERS',
  SET_AIRCRAFT_BASES: 'common/SET_AIRCRAFT_BASES',
  SET_CUSTOM_CURRENCY_ITEM_TEMPLATES: 'common/SET_CUSTOM_CURRENCY_ITEM_TEMPLATES',
  SET_AIRCRAFT_SELECTOR_TYPE: 'common/SET_AIRCRAFT_SELECTOR_TYPE',
  SET_COMPANY_SCHEDULE_TYPES: 'common/SET_COMPANY_SCHEDULE_TYPES',
  SET_DEFAULT_SCHEDULE_TYPE: 'common/SET_DEFAULT_SCHEDULE_TYPE',
  RESET_STATE: 'common/RESET_STATE',
  SET_COMPANY_DOCUMENTS: 'common/SET_COMPANY_DOCUMENTS',
  SET_COMPANY_USERS: 'common/SET_COMPANY_PILOTS'
};

export type SetCompanyAircraftList = {
  type: typeof actionTypes.SET_COMPANY_AIRCRAFT_LIST;
  payload: { aircraftList: AircraftMinimal[] };
};

const setNotifire = (payload: CommonTypes.Notifire) => ({
  type: actionTypes.SET_NOTIFIRE,
  payload,
});

const clearNotifire = () => ({
  type: actionTypes.CLEAR_NOTIFIRE,
});

const setFeatureFlags = (payload: { [key: string]: boolean }) => ({
  type: actionTypes.SET_FEATURE_FLAGS,
  payload,
});

const loadCompanyClients = (companyClients: CompanyClient[]) => ({
  type: actionTypes.LOAD_COMPANY_CLIENTS,
  payload: { companyClients },
});

export interface SetClientContractsAction extends GenericAction {
  type: typeof actionTypes.SET_CLIENT_CONTRACTS;
  payload: {
    clientContracts: CommonInitState['clientContracts'];
  };
}

const setClientContracts = (clientContracts: ClientContract[]): SetClientContractsAction => ({
  type: actionTypes.SET_CLIENT_CONTRACTS,
  payload: { clientContracts },
});

const loadCompanyClientsFinished = () => ({
  type: actionTypes.COMPANY_CLIENTS_LOADED,
});

const defaultCompanyClientsLoaded = () => ({
  type: actionTypes.DEFAULT_COMPANY_CLIENTS_LOADED,
});

const setPilotProfiles = (profiles: Profile[]) => ({
  type: actionTypes.SET_PILOT_PROFILES,
  payload: { pilotProfiles: profiles },
});

const setAllProfiles = (profiles: Profile[]) => ({
  type: actionTypes.SET_ALL_PROFILES,
  payload: { allProfiles: profiles },
});

const setCompanyAircraftList = (aircraftList: AircraftMinimal[]): SetCompanyAircraftList => ({
  type: actionTypes.SET_COMPANY_AIRCRAFT_LIST,
  payload: { aircraftList },
});

const setState = (payload: Partial<CommonInitState>) => ({
  type: actionTypes.SET_STATE,
  payload,
});

const setCompanyAircraftIdentifiers = (payload: string[]) => ({
  type: actionTypes.SET_AIRCRAFT_TAIL_NUMBERS,
  payload: { aircraftIdentifiers: payload },
});

const setAircraftBases = (payload: string[]) => ({
  type: actionTypes.SET_AIRCRAFT_BASES,
  payload: { aircraftBases: payload },
});

const setAircraftSelectorType = (type: AircraftSelectorType) => ({
  type: actionTypes.SET_AIRCRAFT_SELECTOR_TYPE,
  payload: { aircraftSelectorType: type },
});

const setCustomCurrencyItemTemplates = (list: CustomCurrencyItemTemplate[]) => ({
  type: actionTypes.SET_CUSTOM_CURRENCY_ITEM_TEMPLATES,
  payload: { customCurrencyItemTemplates: list },
});

const setCompanyDocuments = (documents: SetCompanyDocuments[]) => ({
  type: actionTypes.SET_COMPANY_DOCUMENTS,
  payload: { companyDocuments: documents },
});

const setCompanyUsers = (users: SetCompanyUsers[]) => ({
  type: actionTypes.SET_COMPANY_USERS,
  payload: { companyUsers: users },
});

type SetCompanyDocuments = {
  type: typeof actionTypes.SET_COMPANY_DOCUMENTS;
  payload: {
    companyDocuments: CompanyDocument[];
  };
};

type SetCompanyUsers = {
  type: typeof actionTypes.SET_COMPANY_USERS;
  payload: {
    companyDocuments: Pilot[];
  };
};

type SetCompanyScheduleTypesAction = {
  type: typeof actionTypes.SET_COMPANY_SCHEDULE_TYPES;
  payload: {
    companyScheduleTypes: CompanyScheduleType[];
  };
};

type SetDefaultScheduleTypeAction = {
  type: typeof actionTypes.SET_DEFAULT_SCHEDULE_TYPE;
  payload: {
    defaultScheduleType: CompanyScheduleType;
  };
};

export const setCompanyScheduleTypes = (
  companyScheduleTypes: CompanyScheduleType[],
): SetCompanyScheduleTypesAction => ({
  type: actionTypes.SET_COMPANY_SCHEDULE_TYPES,
  payload: { companyScheduleTypes },
});

export const setDefaultScheduleType = (
  defaultScheduleType: CompanyScheduleType,
): SetDefaultScheduleTypeAction => ({
  type: actionTypes.SET_DEFAULT_SCHEDULE_TYPE,
  payload: { defaultScheduleType },
});

export const resetCommonState = () => ({
  type: actionTypes.RESET_STATE,
});

export default {
  setNotifire,
  clearNotifire,
  setFeatureFlags,
  loadCompanyClients,
  loadCompanyClientsFinished,
  defaultCompanyClientsLoaded,
  setPilotProfiles,
  setAllProfiles,
  setCompanyAircraftList,
  setState,
  setCompanyAircraftIdentifiers: setCompanyAircraftIdentifiers,
  setAircraftBases,
  setCustomCurrencyItemTemplates,
  setAircraftSelectorType,
  resetCommonState,
  setCompanyDocuments,
  setCompanyUsers,
  setClientContracts,
};
