import {
  AircraftGroup,
  AircraftGroupCreateRequest,
  GroupSchedule,
  AircraftGroupScheduleCreateRequest,
  AircraftGroupScheduleUpdateRequest,
  AircraftGroupUpdateRequest,
} from '../types';
import { AircraftGroupTimelineItem } from '../../Timeline/GroupScheduleTimeline/types';

const actions = {
  SET_STATE: 'components/group-schedule/SET_STATE',
  SET_MODAL_OPEN: 'components/group-schedule/SET_MODAL_OPEN',
  OPEN_MODAL_FOR_NEW_ENTRY: 'components/group-schedule/OPEN_MODAL_FOR_NEW_ENTRY',
  CREATE_GROUP_SCHEDULE: 'components/group-schedule/CREATE_GROUP_SCHEDULE',
  UPDATE_GROUP_SCHEDULE: 'components/group-schedule/UPDATE_GROUP_SCHEDULE',
  SET_GROUP_SCHEDULE_LIST: 'components/group-schedule/SET_GROUP_SCHEDULE_LIST',
  SET_EDITED_ENTRY: 'components/group-schedule/SET_EDITED_ENTRY',
  SET_GROUP: 'components/group-schedule/SET_GROUP',
  CREATE_GROUP: 'components/group-schedule/CREATE_GROUP',
  UPDATE_GROUP: 'components/group-schedule/UPDATE_GROUP',
  SELECT_ASSOCIATED_AIRCRAFT_AND_CREW:
    'components/group-schedule/SELECT_ASSOCIATED_AIRCRAFT_AND_CREW',
  DELETE_GROUP_SCHEDULE: 'components/group-schedule/DELETE_GROUP_SCHEDULE',
  DELETE_GROUP: 'components/group-schedule/DELETE_GROUP',
  LOAD_GROUP: 'components/group-schedule/LOAD_GROUP',
  RELOAD_GROUP_SCHEDULE: 'components/group-schedule/RELOAD_GROUP_SCHEDULE',
  SET_TIMELINE_ITEMS: 'components/group-schedule/SET_TIMELINE_ITEMS',
};

export default actions;

export const setState = payload => ({
  type: actions.SET_STATE,
  payload,
});

export type CreateAircraftGroupScheduleAction = {
  type: string;
  payload: AircraftGroupScheduleCreateRequest;
};

export type UpdateAircraftGroupScheduleAction = {
  type: string;
  payload: AircraftGroupScheduleUpdateRequest;
};

export type CreateAircraftGroupAction = {
  type: string;
  payload: AircraftGroupCreateRequest;
};

export type UpdateAircraftGroupAction = {
  type: string;
  payload: AircraftGroupUpdateRequest;
};

export type SetAircraftGroupScheduleListAction = {
  type: string;
  payload: { aircraftGroupScheduleList: GroupSchedule[] };
};

export type SetAircraftGroupListAction = {
  type: string;
  payload: { aircraftGroupList: AircraftGroup[] };
};

export type SelectAssociatedAircraftAndCrewAction = {
  type: string;
  payload: { aircraftGroupScheduleID: string };
};

export type DeleteAircraftGroupSchedule = {
  type: string;
  payload: { aircraftGroupScheduleID: string };
};

export type DeleteAircraftGroup = {
  type: string;
  payload: { aircraftGroupID: string };
};

export type SetTimelineItems = {
  type: string;
  payload: { timelineItems: AircraftGroupTimelineItem[] };
};
export const setModalOpen = (isModalOpen: boolean) => ({
  type: actions.SET_MODAL_OPEN,
  payload: {
    isModalOpen: isModalOpen,
    editedEntry: null,
  },
});

export const setEditedEntry = (itemID: string) => ({
  type: actions.SET_EDITED_ENTRY,
  payload: {
    editedEntryID: itemID,
  },
});

export const createGroupSchedule = (
  payload: AircraftGroupScheduleCreateRequest,
): CreateAircraftGroupScheduleAction => ({
  type: actions.CREATE_GROUP_SCHEDULE,
  payload,
});

export const updateGroupSchedule = (
  payload: AircraftGroupScheduleUpdateRequest,
): UpdateAircraftGroupScheduleAction => ({
  type: actions.UPDATE_GROUP_SCHEDULE,
  payload,
});

export const setGroupScheduleList = (
  list: GroupSchedule[],
): SetAircraftGroupScheduleListAction => ({
  type: actions.SET_GROUP_SCHEDULE_LIST,
  payload: { aircraftGroupScheduleList: list },
});

export const setGroupList = (list: AircraftGroup[]): SetAircraftGroupListAction => ({
  type: actions.SET_GROUP,
  payload: { aircraftGroupList: list },
});

export const createGroup = (payload: AircraftGroupCreateRequest): CreateAircraftGroupAction => ({
  type: actions.CREATE_GROUP,
  payload,
});

export const updateGroup = (payload: AircraftGroupUpdateRequest): UpdateAircraftGroupAction => ({
  type: actions.UPDATE_GROUP,
  payload,
});

export const selectAssociatedAircraftAndCrew = (
  aircraftGroupID: string,
): SelectAssociatedAircraftAndCrewAction => ({
  type: actions.SELECT_ASSOCIATED_AIRCRAFT_AND_CREW,
  payload: { aircraftGroupScheduleID: aircraftGroupID },
});

export const deleteAircraftGroupSchedule = (ID: string): DeleteAircraftGroupSchedule => ({
  type: actions.DELETE_GROUP_SCHEDULE,
  payload: { aircraftGroupScheduleID: ID },
});

export const deleteAircraftGroup = (ID: string): DeleteAircraftGroup => ({
  type: actions.DELETE_GROUP,
  payload: { aircraftGroupID: ID },
});

export const loadAircraftGroup = () => ({
  type: actions.LOAD_GROUP,
});

export const openModalForNewEntry = (start: string, end: string, groupID: string) => ({
  type: actions.OPEN_MODAL_FOR_NEW_ENTRY,
  payload: {
    start,
    end,
    groupID,
  },
});

export const reloadAirGroupSchedule = () => ({
  type: actions.RELOAD_GROUP_SCHEDULE,
});

export const setTimelineItems = (items: AircraftGroupTimelineItem[]): SetTimelineItems => ({
  type: actions.SET_TIMELINE_ITEMS,
  payload: {
    timelineItems: items,
  },
});
