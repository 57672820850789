import { SelectAssociatedAircraftAndCrewAction } from '../actions';
import { put, select } from 'redux-saga/effects';
import { getAircraftGroupSchedule } from '../selectors';
import { AircraftGroupScheduleState } from '../reducers';
import { GroupFieldType, GroupSchedule } from '../../types';

import { getCommon } from '../../../../../../../../redux/selectors';
import { CommonInitState } from '../../../../../../../../redux/common/reducers';
import { AircraftSelectorType } from '../../../../../../../../redux/common/types';
import { scheduleActions } from '../../../../redux-saga/actions';

export function getUniqueIds(
  schedule: GroupSchedule,
): { aircraftIds: string[]; crewMemberIDs: string[] } {
  const aircraftIds = new Set<string>();
  const crewMemberIDs = new Set<string>();

  for (const [aircraftID, fieldValue] of Object.entries(schedule.GroupCustomSectionValues.Values)) {
    aircraftIds.add(aircraftID);
    for (const val of fieldValue) {
      if (val.Type === GroupFieldType.CrewSelector) {
        crewMemberIDs.add(val.Value);
      }
    }
  }

  return {
    aircraftIds: Array.from(aircraftIds),
    crewMemberIDs: Array.from(crewMemberIDs),
  };
}

export function* selectAssociatedAircraftAndCrewWorker(
  action: SelectAssociatedAircraftAndCrewAction,
) {
  const { aircraftGroupScheduleID } = action.payload;
  const aircraftGroupScheduleState: AircraftGroupScheduleState = yield select(
    getAircraftGroupSchedule,
  );
  const aircraftGroupSchedule = aircraftGroupScheduleState.aircraftGroupScheduleList.find(
    ({ ID }) => ID === aircraftGroupScheduleID,
  );

  if (aircraftGroupSchedule) {
    const { aircraftIds, crewMemberIDs } = getUniqueIds(aircraftGroupSchedule);
    if (aircraftIds.length > 0) {
      const commonState: CommonInitState = yield select(getCommon);
      const aircraftTails = commonState.aircraftList
        .filter(({ ID }) => aircraftIds.includes(ID))
        .map(aircraft => {
          if (commonState.aircraftSelectorType === AircraftSelectorType.Registration) {
            return aircraft.Registration;
          }
          return aircraft.TailNumber;
        });
      yield put(scheduleActions.setSelectedAircraftIdentifiers(aircraftTails));
    }
    if (crewMemberIDs.length > 0) {
      yield put(scheduleActions.setSelectedCrewMemberIds(crewMemberIDs));
    }
  }
}
