import actions from './actions';

const initialState = {
  crumbList: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CRUMBS:
      return { ...state, crumbList: action.payload || [] };
    case actions.GET_CRUMBS:
      return state.crumbList;
    default:
      return state;
  }
}
