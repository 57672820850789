import { CombinedEntry } from '../../../../../../../common/types/CombinedEntry';
import { composeKey } from '../../../helpers';
import { editFields, resetValidationMsg, setValidationMsg } from '../../actions';
import moment from 'moment-timezone';
import { put, call } from 'redux-saga/effects';
import { Actions, AllowAction } from '../../../../../../../common/types/permissions';
import { isEntryOngoing } from '../../../components/EntryDetails/sections/helpers/ongoing';
import validateReserveOverlap from '../../../../../../../pages/data/common/helpers/validateReserveOverlap';

export function* checkRAPFromTo(
  entry: CombinedEntry,
  editValues: any,
  mostRecentDutyEntry: CombinedEntry | null,
  allowedActions: Actions,
  isNewEntry: boolean,
  entryType: string,
) {
  const composedKey = composeKey(editFields.RAPStartTime, editFields.RAPEndTime);
  const RAPFrom =
    editValues[editFields.RAPStartTime] !== undefined
      ? moment(editValues[editFields.RAPStartTime])
      : entry.RAPStartTime && moment(entry.RAPStartTime);
  const RAPTo =
    editValues[editFields.RAPEndTime] !== undefined
      ? moment(editValues[editFields.RAPEndTime])
      : entry.RAPEndTime && moment(entry.RAPEndTime);

  const dutyFrom = editValues[editFields.DutyFrom]
    ? moment(editValues[editFields.DutyFrom])
    : entry.StartTime
    ? moment(entry.StartTime)
    : undefined;
  const dutyTo = editValues[editFields.DutyTo]
    ? moment(editValues[editFields.DutyTo])
    : entry.EndTime
    ? moment(entry.EndTime)
    : undefined;

  const isOngoing = isEntryOngoing(entryType, entry, editValues, isNewEntry);

  const isOnReserve =
    editValues[editFields.IsOnReserve] !== undefined
      ? editValues[editFields.IsOnReserve]
      : !!(entry.RAPStartTime && entry.RAPStartTime);

  if (!isOnReserve) {
    yield put(resetValidationMsg(composeKey(composedKey)));
    return;
  }
  if (!RAPFrom || !RAPTo || !RAPFrom.isValid() || !RAPTo.isValid()) {
    if (isOngoing) {
      yield put(resetValidationMsg(composeKey(composedKey)));
    } else {
      yield put(setValidationMsg(composeKey(composedKey), ''));
    }
  }
  if ((!RAPFrom || !RAPFrom.isValid()) && (!RAPTo || !RAPTo.isValid())) {
    yield put(
      setValidationMsg(
        composeKey(composedKey),
        'Reserve availability must have start and end time',
      ),
    );
  } else {
    if (RAPFrom && RAPTo) {
      if (!RAPTo.isAfter(RAPFrom)) {
        yield put(
          setValidationMsg(composeKey(composedKey), 'Reserve end must be after reserve start'),
        );
      } else {
        const overlapErrorMessage = 'Reserve times must overlap with duty times';
        const coveringErrorMessage = 'Duty period cannot contain entire reserve period';

        try {
          yield call(
            validateReserveOverlap,
            isOnReserve,
            dutyFrom,
            dutyTo,
            RAPFrom,
            RAPTo,
            overlapErrorMessage,
            coveringErrorMessage,
          );

          yield put(resetValidationMsg(composeKey(composedKey)));
        } catch (error) {
          yield put(setValidationMsg(composeKey(composedKey), error.message));
        }
      }
    }
  }
}
