import { FileImportResult, FileImportState, FileImportStatus } from './reducers';
import { RcFile } from 'antd/lib/upload/interface';
import moment from 'moment-timezone';

const actions = {
  SET_STATE: 'components/FOSImport/SET_STATE',
  SET_DRAWER_VISIBILITY: 'components/FOSImport/SET_DRAWER_VISIBILITY',
  UPLOAD: 'components/FOSImport/UPLOAD',
  SET_PLANNED_DUTIES_REF_MOMENT: 'components/FOSImport/SET_PLANNED_DUTIES_REF_MOMENT',
};

export default actions;

export const setState = (payload: Partial<FileImportState>) => ({
  type: actions.SET_STATE,
  payload,
});

export const setDrawerVisible = (payload: boolean) => ({
  type: actions.SET_STATE,
  payload: {
    isShowDrawer: payload,
  },
});

export const setIsLoading = (payload: boolean) => ({
  type: actions.SET_STATE,
  payload: {
    isLoading: payload,
  },
});

export const upload = () => ({
  type: actions.UPLOAD,
});

export const setDutyTimeFile = (file: RcFile) => ({
  type: actions.SET_STATE,
  payload: {
    dutyTimeFile: file,
  },
});

export const setFlightInfoFile = (file: RcFile) => ({
  type: actions.SET_STATE,
  payload: {
    flightInfoFile: file,
  },
});

export const setUploadResult = (uploadResult: FileImportResult) => ({
  type: actions.SET_STATE,
  payload: {
    uploadResult: uploadResult,
  },
});

export const setUploadStatus = (uploadStatus: FileImportStatus) => ({
  type: actions.SET_STATE,
  payload: {
    uploadStatus,
  },
});

export const setPlannedDutiesRefMoment = (plannedDutiesRefMoment: moment.Moment) => ({
  type: actions.SET_STATE,
  payload: {
    plannedDutiesRefMoment: plannedDutiesRefMoment,
  },
});

export const fileImportActions = {
  setState,
  setDrawerVisible,
  setIsLoading,
  upload,
  setDutyTimeFile,
  setFlightInfoFile,
  setUploadResult,
};
