import axios from 'axios';
import { store as reduxStore } from 'index';
import { getAPIURL } from '../config';
import Jwt from './Jwt';

let isRequestingRefreshToken = false;
let requestsToRefresh = [];

const UNAUTHORIZED = 401;
const api = axios.create({
  baseURL: getAPIURL(),
  withCredentials: true,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});

const unauthorizedExceptionRouts = ['/user/reset-password', '/user/forgot', '/user/login'];

api.interceptors.response.use(
  response => response,
  error => {
    const { response, config } = error;
    if (response.status === UNAUTHORIZED) {
      if (!isRequestingRefreshToken) {
        const state = reduxStore.getState();
        const pathname = state?.router?.location?.pathname;

        if (unauthorizedExceptionRouts.some(rout => rout === pathname)) return false;

        // refresh token
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          isRequestingRefreshToken = true;
          api
            .post(
              '/auth/refresh',
              {
                grant_type: 'refresh_token',
              },
              {
                headers: {
                  Authorization: `Bearer ${refreshToken}`,
                },
              },
            )
            .then(({ data }) => {
              console.log('token refreshed');
              Jwt.set(data.AccessToken);
              localStorage.setItem('refreshToken', data.RefreshToken);
              requestsToRefresh.forEach(cb => cb(data.AccessToken));
            })
            .catch(err => {
              console.log('unable to refresh token');
              console.log(err);
              requestsToRefresh.forEach(cb => cb(null));
              reduxStore.dispatch({ type: 'user/LOGOUT' });
            })
            .finally(() => {
              requestsToRefresh = [];
              isRequestingRefreshToken = false;
            });
        } else {
          reduxStore.dispatch({ type: 'user/LOGOUT' });
        }
      }

      // console.log(`The request is waiting for a new token.. [${response.config.url}]`)
      return new Promise((resolve, reject) => {
        // In our variable (requests that expect a new token
        // from the first request), we add a callback to resend the request
        requestsToRefresh.push(token => {
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            resolve(axios(config));
          }
          reject(error);
        });
      });
    }
    return Promise.reject(error);
  },
);

export default api;
