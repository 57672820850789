import { Types } from './actions';
import { ApiTypes } from 'models';
import { AnyAction } from 'redux';

export interface InitialState {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  isDataRequested: boolean;
  data: ApiTypes.FlightTimes.Data[] | null;
}

const initialState: InitialState = {
  pageNumber: 1,
  pageSize: 20,
  totalCount: 0,
  data: null,
  isDataRequested: false,
};

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case Types.GET_FLIGHT_TIMES_REQUEST:
      return { ...state, ...{ isDataRequested: true } };
    case Types.GET_FLIGHT_TIMES_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload,
          isDataRequested: false,
        },
      };
    case Types.GET_FLIGHT_TIMES_ERROR:
      return {
        ...state,
        ...{
          isDataRequested: false,
        },
      };
    case Types.SET_CURRENT_PAGE_NUMBER:
      return { ...state, ...{ pageNumber: action.payload } };
    case Types.SET_CURRENT_PAGE_SIZE:
      return { ...state, ...{ pageSize: action.payload } };
    case Types.SET_TOTAL_COUNT:
      return { ...state, ...{ totalCount: action.payload } };
    default:
      return state;
  }
}
