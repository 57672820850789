import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { put, select } from 'redux-saga/effects';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import { selectDutyTimeTimelineEntriesSaga } from '../../../selectors-saga';
import { scheduleActions } from '../../../actions';
import { selectUserSaga } from '../../../../../../../../common/saga/selectors';

export function* handleDutyTimeEntryDelete(
  action: PusherReduxAction<PusherActionType.DUTY_TIME_ENTRY_DELETED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  const { payload } = action;
  const { UpdatedBy, ID } = payload;

  const user = yield* selectUserSaga();
  if (user.id === UpdatedBy) {
    return;
  }

  try {
    const existedEntries = yield* selectDutyTimeTimelineEntriesSaga();
    const updatedEntries = existedEntries.filter(dt => {
      if (dt.ID === ID) {
        return false;
      }
      return true;
    });
    yield put(scheduleActions.setState({ dutyTimeTimelineEntries: updatedEntries }));
  } finally {
  }
}
