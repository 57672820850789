import { call, put, select } from 'redux-saga/effects';
import { setCombinedMaintenance, setState } from '../actions';
import { getCombinedMaintenance } from '../selectors';
import api from '../../../../../../services/api';
import { TablePaginationConfig } from 'antd';
import { getAircraftDetailsPage, getCompany } from '../../../../../../redux/selectors';
import { Aircraft } from '../../../../../../common/types/aircraft';

export function* loadCombinedMaintenanceItemsWorker() {
  try {
    yield put(setState({ isLoading: true }));
    const { Settings } = yield select(getCompany);

    const { pagination, sorter, filter, aircraftID } = yield select(getCombinedMaintenance);
    const { aircraft } = (yield select(getAircraftDetailsPage)) as { aircraft: Aircraft };
    if (!aircraft?.ID) {
      return;
    }
    const queryParams = {
      limit: pagination.pageSize || 10,
      page: pagination.current || 1,
      include_maintenance_items: Settings.ShowMaintenanceInCombinedList,
    };

    if (sorter.field && sorter.order) {
      queryParams['order'] = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    } else {
      queryParams['order'] = 'default asc';
    }

    if (filter.status) {
      queryParams['status'] = filter.status;
    } else {
      if (Settings.ShowMaintenanceInCombinedList) {
        queryParams['status'] = 'openAndDeferred';
      }
    }

    const {
      data: { Data, Total, Page },
    } = yield call(api.get, `/v1/aircraft/${aircraft.ID}/maintenance-items-combined`, {
      params: queryParams,
    });
    yield put(setCombinedMaintenance(Data));
    const newPagination: TablePaginationConfig = {
      total: Total,
      current: Page,
      pageSize: pagination.pageSize,
    };
    yield put(setState({ pagination: newPagination, isLoading: false }));
  } catch (err) {
    console.log('Failed to load combined maintenance items list', err);
  }
}
