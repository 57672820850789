import { AddNewAircraftType, setEntry } from '../actions';
import { put, select } from 'redux-saga/effects';
import { getCSMobile } from '../selectors';
import { CombinedEntry } from '../../../../../../common/types/CombinedEntry';
import { buildFlightInformation } from '../helpers/entryHelper';

export function* watchOnAddNewAircraftType(action: AddNewAircraftType) {
  const { entry } = yield select(getCSMobile);
  const { aircraftType } = action.payload;

  // todo: use editValues, but not entry
  const updatedEntry: CombinedEntry = { ...entry };
  if (!updatedEntry.AircraftInformation.FlightInformation) {
    updatedEntry.AircraftInformation.FlightInformation = [];
  }
  updatedEntry.AircraftInformation.FlightInformation.push(buildFlightInformation(aircraftType));

  yield put(setEntry(updatedEntry));
}
