const actions = {
  SET_STATE: 'settings/SET_STATE',
  CHANGE_SETTING: 'settings/CHANGE_SETTING',
  SHOW_MOBILE_MENU: 'settings/SHOW_MOBILE_MENU',
  HIDE_MOBILE_MENU: 'settings/HIDE_MOBILE_MENU',
};

export default actions;

export const showMobileMenu = () => ({
  type: actions.SHOW_MOBILE_MENU,
});

export const hideMobileMenu = () => ({
  type: actions.HIDE_MOBILE_MENU,
});
