import { PusherFlightWatchUpdate } from '../actions';
import { call, select } from 'redux-saga/effects';
import { loadFlightWatchListWorker } from './loadFlightWatchListWorker';

export function* onPusherFlightWatchUpdatedWorker(action: PusherFlightWatchUpdate) {
  const user = yield select(({ user }) => user);
  if (user.id === action.payload.actionTriggeredBuUsedID) {
    return;
  }
  yield call(loadFlightWatchListWorker);
}
