import { SetCrewSortOption, setState } from '../actions';
import { sortCrew } from '../loadPilots';
import { put, select } from 'redux-saga/effects';
import { getUserRole } from '../../../selectors';

export function* watchCrewSortOptionChanged(action: SetCrewSortOption) {
  const { crewSortOption } = action.payload;
  const { selectedPilots, pilots, selectedCrewMemberIds } = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2,
  );
  const userRoles = yield select(getUserRole) || [];
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);
  const user = yield select(({ user }) => user);

  yield put(setState({ crewSortOption: crewSortOption }));
  if (!selectedCrewMemberIds.length) {
    let sortedPilots = sortCrew(pilots, crewSortOption, user, userRoles, companySettings);
    yield put(setState({ selectedPilots: sortedPilots.slice(0, 10) }));
  } else {
    let sortedSelected = sortCrew(selectedPilots, crewSortOption, user, userRoles, companySettings);
    yield put(setState({ selectedPilots: sortedSelected.slice(0, 10) }));
  }
}
