import { call, put, select } from 'redux-saga/effects';
import api from '../../../../../../services/api';
import { setState } from '../actions';
import { Submissions } from '../../types/submissions';
import { getCustomForms } from '../selectors';
import { CustomFormsState } from '../reducers';
import { TablePaginationConfig } from 'antd';

export function* loadSubmissionsWorker() {
  try {
    yield put(setState({ isLoadingSubmissionsList: true }));

    const { submissionPagination, submissionSorter } = (yield select(
      getCustomForms,
    )) as CustomFormsState;

    const queryParams = {
      limit: submissionPagination.pageSize || 20,
      page: submissionPagination.current || 1,
      order: 'submitted_at desc',
    };
    if (submissionSorter.field && submissionSorter.order) {
      queryParams['order'] = `${submissionSorter.columnKey} ${
        submissionSorter.order === 'ascend' ? 'asc' : 'desc'
      }`;
    }

    const {
      data: { Data, Total, Page },
    } = yield call(api.get, `/v1/custom-forms/list-submissions`, {
      params: queryParams,
    });

    const list = Data as Submissions[];

    const newPagination: TablePaginationConfig = {
      total: Total,
      current: Page,
      pageSize: submissionPagination.pageSize,
    };

    yield put(setState({ submissionList: list, submissionPagination: newPagination }));
  } catch (err) {
    console.log('Failed to load submissions', err);
  } finally {
    yield put(setState({ isLoadingSubmissionsList: false }));
  }
}
