import moment from 'moment-timezone';

export const serializeEntry = (ID, CompanyID, values, version, customSectionValues) => {
  const {
    SchedulePeriod,
    AssignedAircraft,
    AssignedClients,
    Subpart,
    StartAirportID,
    EndAirportID,
    ...rest
  } = values;

  let assignedAircraft = null;
  if (AssignedAircraft) {
    assignedAircraft = Array.isArray(AssignedAircraft) ? AssignedAircraft : [AssignedAircraft];
  }

  const customSection = customSectionValues;
  return {
    ...rest,
    ID,
    CompanyID,
    StartTime: moment(SchedulePeriod[0])
      .seconds(0)
      .format(),
    EndTime: moment(SchedulePeriod[1])
      .seconds(0)
      .format(),
    AssignedAircraft: assignedAircraft,
    AssignedClients: AssignedClients?.length > 0 ? AssignedClients : [],
    Subparts: Subpart ? [Subpart] : null,
    CustomSections: customSection,
    StartAirportID: StartAirportID?.length > 0 ? StartAirportID : null,
    EndAirportID: EndAirportID?.length > 0 ? EndAirportID : null,
    Version: version,
  };
};
