import {
  SaveEntryAction,
  setIsEditing,
  setEntryDetailsLoading,
  AfterEntryCreatedType,
  afterEntryCreated,
  afterEntryPatched,
  AfterEntryPatchedType,
  setEntries,
  setEntry,
  setState,
} from '../actions';
import { call, put, select } from 'redux-saga/effects';
import api from '../../../../../../services/api';
import { CrewScheduleEntryAPI, DutyScheduleEntryAPI, DutyTimeEntryAPI } from '../types/entries';
import { serializeEntry } from './common';
import { apiEntryTypeMap } from '../../../../../../common/types/apiEntryTypes';
import { FeatureFlag } from '../../../../../../utils/feature-flags/FeatureFlagsProvider';
import { getCommon, getCSMobile } from '../selectors';
import { DIVIDER_TYPE, populateMonthDividers } from '../../helpers';
import moment from 'moment-timezone';
import { fetchEntries } from './fetchEntries';
import { loadMostRecentDutyEntry } from '../sagas';

export function* saveEntryWorker(action: SaveEntryAction) {
  const { payload } = action;
  const { entry, entryType } = payload;
  const { editValues, isEditing, isNewEntry } = yield select(getCSMobile);
  const { featureFlags, customCurrencyItemTemplates } = yield select(getCommon);

  if (isEditing && Object.keys(editValues).length === 0) {
    yield put(setState({ isEditing: false }));
  }

  try {
    yield put(setEntryDetailsLoading(true));

    let request;
    if (entryType === apiEntryTypeMap.DutyScheduleEntries) {
      const data = serializeEntry(
        entry,
        editValues,
        entryType,
        featureFlags[FeatureFlag.DetailedFlightTimes],
        isNewEntry,
        customCurrencyItemTemplates,
      ) as DutyScheduleEntryAPI;
      if (isNewEntry) {
        request = api.post(`/v1/users/${data.Pilot}/duty-schedule/v2`, data);
      } else {
        request = api.patch(`/v1/users/${data.Pilot}/duty-schedule/${data.ID}/v2`, data);
      }
    } else if (entryType === apiEntryTypeMap.DutyTimeEntries) {
      const data = serializeEntry(
        entry,
        editValues,
        entryType,
        featureFlags[FeatureFlag.DetailedFlightTimes],
        isNewEntry,
        customCurrencyItemTemplates,
      ) as DutyTimeEntryAPI;
      if (isNewEntry) {
        request = api.post(`/v1/users/${data.UserID}/duty`, data);
      } else {
        request = api.patch(`/v1/users/${data.UserID}/duty/${data.ID}`, data);
      }
    } else if (entryType === apiEntryTypeMap.CrewScheduleEntries) {
      const data = serializeEntry(
        entry,
        editValues,
        entryType,
        featureFlags[FeatureFlag.DetailedFlightTimes],
        isNewEntry,
        customCurrencyItemTemplates,
      ) as CrewScheduleEntryAPI;
      if (isNewEntry) {
        request = api.post(`/v1/companies/${data.CompanyID}/crewschedule/create`, data);
      } else {
        request = api.patch(`/v1/companies/${data.CompanyID}/crewschedule/update/${data.ID}`, data);
      }
    }

    const {
      data: { Data: updatedEntry },
    } = yield request;

    if (isNewEntry) {
      yield put(afterEntryCreated(updatedEntry));
    } else {
      yield put(afterEntryPatched(updatedEntry));
    }

    // todo: used setState instead of setIsEditing because of the bug with the watchEditingModeChange worker
    yield put(setState({ isEditing: false }));

    if (entryType === apiEntryTypeMap.CrewScheduleEntries) {
      // TODO: we reload all the entries because after entry creation we don't have CompanyScheduleType,
      //  Start EndAirport fields, only ids
      const { entryPeriod, entryType } = yield select(getCSMobile);
      let [from, to] = entryPeriod;
      yield call(fetchEntries, entryType, from, to, false);
    }
  } catch (err) {
    console.log('Failed to save entry: ', err);
  } finally {
    yield put(setEntryDetailsLoading(false));
  }
}

export function* watchAddEntry(action: AfterEntryCreatedType) {
  const { data, entryPeriod, entry: entryToUpdate } = yield select(getCSMobile);
  const { entry } = action.payload;

  const startTime = entry.StartTime;
  const [from, to] = entryPeriod;
  if (moment(startTime).isBefore(from) || moment(startTime).isAfter(to)) {
    return;
  }

  const newEntries = [...data.filter(item => item.type !== DIVIDER_TYPE), entry].sort((a, b) =>
    moment(a.StartTime).diff(moment(b.StartTime)),
  );
  const withMonthDividers = populateMonthDividers(newEntries);
  const updatedEntry = { ...entryToUpdate, ID: entry.ID };
  yield put(setEntries(withMonthDividers));
  yield put(setEntry(updatedEntry));
  yield call(loadMostRecentDutyEntry, updatedEntry.UserID);
  yield put(setState({ isNewEntry: false }));
}

export function* watchPatchEntry(action: AfterEntryPatchedType) {
  const { entry } = action.payload;
  const { data } = yield select(getCSMobile);

  const newEntries = data.map(item => {
    if (item.ID === entry.ID) {
      return entry;
    }
    return item;
  });

  yield put(setEntries(newEntries));
  yield put(setEntry(entry));
}
