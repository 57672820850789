import { Types } from './actions';
import { ApiTypes } from 'models';
import { AnyAction } from 'redux';

export interface InitialState {
  pilotFilter: ApiTypes.Pilot[];
  pilots: ApiTypes.Pilot[];
  isPilotsRequested: boolean | null;
}

const initialState: InitialState = {
  pilotFilter: [],
  pilots: [],
  isPilotsRequested: null,
};

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case Types.SET_PILOT_FILTER:
      return { ...state, ...{ pilotFilter: action.payload } };
    case Types.GET_PILOTS_REQUEST:
      return { ...state, ...{ isPilotsRequested: true } };
    case Types.GET_PILOTS_ERROR:
      return { ...state, ...{ isPilotsRequested: false } };
    case Types.GET_PILOTS_SUCCESS:
      return {
        ...state,
        ...{
          pilots: action.payload,
          isPilotsRequested: false,
        },
      };
    default:
      return state;
  }
}
