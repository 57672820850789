export namespace ApiTypes {
  export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
  }

  export namespace DutyTimes {
    export interface TableDataRequest {
      companyID: string;
      limit: number;
      page: number;
      order?: string;
      user_id?: string;
      user_ids?: string[];
      pilot_ids?: string[];
      active?: string;
    }

    export interface User {
      ID: string;
      Profile: {
        FirstName: string;
        LastName: string;
        PilotLicenseNumber: string;
        AMELicenseNumber: string;
      };
    }

    export interface Data {
      CreatedAt: string;
      UpdatedAt: string;
      DeletedAt: string | null;
      ID: string;
      UserID: string;
      CompanyID: string;
      StartTime: string;
      EndTime: string;
      NonFlyingDuty: boolean;
      UnforeseenExtension: boolean;
      VerifiedAt: string;
      User: User;
      DurationSeconds: number;
    }
  }

  export namespace FlightTimes {
    export interface TableDataRequest {
      companyID: string;
      pilot_name?: string;
      pilot_id?: string;
      pilot_ids?: string[];
      start_time__gte?: string;
      end_time__lte?: string;
      order?: string;
    }

    export interface Data {
      PilotName: string;
      TotalFlightHours: number;
      FirstFlight: string | null;
      LastFlight: string | null;
    }
  }

  export namespace Currency {
    export interface TableDataRequest {
      companyID: string;
      pilot_name?: string;
      pilot_ids?: string[];
      interval?: string;
      aircraft_type?: string;
      start_time__gte?: string;
      end_time__lte?: string;
    }

    export interface Data {
      PilotName: string;
      NightFlightCurrency: FlightCurrency;
      StandardFlightCurrency: FlightCurrency;
      StandardFlightCurrency90Days: FlightCurrency;
      NightFlightCurrency6Months: FlightCurrency;
    }

    interface FlightCurrency {
      Expiry: null;
      IsCurrent: boolean;
      Landings: number;
      Takeoffs: number;
    }
  }

  export interface Pilot {
    CompanyUsers: [];
    Email: string;
    ID: string;
    LastLogin: string;
    Profile: Profile;
  }

  export interface Profile {
    BaseAirportCode: string;
    FirstName: string;
    LastName: string;
    PilotAircraftCertifications: [];
    PilotLicenseNumber: string;
    UpdatedAt: string;
    UserID: string;
    Weight: number;
    WeightClass: string;
    DateOfHire: string;
  }

  export namespace CrewCompliance {
    export interface PilotFilter {
      companyID: string;
      search?: string;
      active?: string;
    }
  }
}
