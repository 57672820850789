export type EntryType = CrewTimelineEntryType;

export enum CrewTimelineEntryType {
  CrewSchedule = 'crewschedule',
  DutySchedule = 'duty-schedule',
  DutyTimes = 'duty-times',
  AircraftSchedule = 'aircraft-schedule',
  PilotContract = 'pilot-contract',
}

const sortOrder = [
  CrewTimelineEntryType.CrewSchedule,
  CrewTimelineEntryType.PilotContract,
  CrewTimelineEntryType.DutySchedule,
  CrewTimelineEntryType.DutyTimes,
  CrewTimelineEntryType.AircraftSchedule,
];

const compareTimelines = (a: CrewTimelineEntryType, b: CrewTimelineEntryType): number => {
  return sortOrder.indexOf(a) - sortOrder.indexOf(b);
};

export const sortCrewTimelineEntryTypes = (
  timelines: CrewTimelineEntryType[],
): CrewTimelineEntryType[] => {
  return timelines.sort(compareTimelines);
};
