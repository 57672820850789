import { CompanyScheduleType } from '../../../../../../common/types/CombinedEntry';

export interface AircraftGroupScheduleCreateRequest {
  CompanyScheduleTypeID: string;
  GroupID: string;
  GroupCustomSectionValues: GroupCustomSectionValues;
  StartTime: string;
  EndTime: string;
  Notes: string;
}

export interface AircraftGroupScheduleUpdateRequest extends AircraftGroupScheduleCreateRequest {
  ID: string;
}

export interface AircraftGroupCreateRequest {
  Name: string;
}

export interface AircraftGroupUpdateRequest {
  ID: string;
  Name: string;
}

export interface AircraftGroup {
  CreatedAt: Date;
  UpdatedAt: Date;
  UpdatedBy: string;
  DeletedAt: Date;
  ID: string;
  CompanyID: string;
  Name: string;
}

export interface GroupSchedule {
  CreatedAt: Date;
  UpdatedAt: Date;
  UpdatedBy: string;
  DeletedAt: Date;
  ID: string;
  GroupID: string;
  CompanyID: string;
  StartTime: string;
  EndTime: string;
  CompanyScheduleTypeID: string;
  CompanyScheduleType: CompanyScheduleType;
  GroupCustomSectionValues: GroupCustomSectionValues;
  Notes: string;
}

export interface AircraftGroupSelectorForm {
  aircraftGroup: AircraftGroupSelectorFields[];
}

export interface AircraftGroupSelectorFields {
  Aircraft: string;
  Pilot: string;
  Instructor: string;
  Federated: boolean;
  StandAlone: boolean;
  FireBoss?: boolean;
  Wheeled?: boolean;
}

export enum GroupCustomSectionType {
  AircraftGroup = 'aircraftGroup',
}

export interface GroupCustomSectionValues {
  Type: GroupCustomSectionType;
  GroupFieldID: string;
  Values: { [groupFieldIDValue: string]: GroupCustomFieldValue[] };
}

export interface GroupCustomSectionTemplate {
  Type: GroupCustomSectionType;
  GroupFieldID: string;
  Fields: GroupCustomSectionField[];
}

export enum GroupFieldType {
  SingleOption = 'singleOption',
  MultipleOption = 'multipleOption',
  LongText = 'longText',
  ShortText = 'shortText',
  CrewSelector = 'crewSelector',
  AircraftSelector = 'aircraftSelector',
}

export enum GroupCustomSectionMappingField {
  Pilot = 'pilot',
}

export interface GroupCustomSectionField {
  Title: string;
  Value: string;
  Values: string[] | null;
  Options?: string[];
  Required?: boolean;
  DefaultValue?: string;
  DefaultValues?: string[] | null;
  Description?: string;

  FieldID: string;
  Type: GroupFieldType;
  MappingField: GroupCustomSectionMappingField;
}

export interface GroupCustomFieldValue {
  Title: string;
  Value?: string;
  Values?: string[];

  Options?: string[];
  Required?: boolean;
  DefaultValue?: string;
  DefaultValues?: string[] | null;
  Description?: string;

  FieldID: string;
  Type: GroupFieldType;
  MappingField: string;
}
