import { CrewTimelineEntryType } from '../../../../../../common/types/timeline/CrewTimelineEntryType';
import { AircraftTimelineEntryType } from '../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { ItemContext } from './types';
import { CrewTimelineItem } from './CrewTimeline/generator';
import { AircraftTimelineItem } from './AircraftTimeline/generator';
import colors from '../colors';
import { useLayoutEffect, useState } from 'react';
import { TIMELINE_HEIGHT, TIMELINE_HEIGHT_CORRECTION } from './constants';
import { ContractTimelineItem } from './ContractTimeline/generator';
import { ContractTimelineEntryType } from '../../../../../../common/types/timeline/ContractTimelineEntryType';
import { CompanySettings } from '../../../../../../common/types/Company';

export const buildGroupID = (
  entryID: string,
  entryType: CrewTimelineEntryType | AircraftTimelineEntryType | ContractTimelineEntryType,
): string => {
  return `${entryID}__${entryType}`;
};

export const parseGroupID = (
  groupID: string,
): {
  entryID: string;
  entryType: CrewTimelineEntryType | AircraftTimelineEntryType | ContractTimelineEntryType;
} => {
  const parts = groupID.split('__');
  if (parts.length !== 2) {
    throw new Error('Invalid group ID format');
  }

  const entryID = parts[0];
  const entryType = parts[1] as
    | CrewTimelineEntryType
    | AircraftTimelineEntryType
    | ContractTimelineEntryType;

  return {
    entryID,
    entryType,
  };
};

export const getBackgroundColor = (
  item: CrewTimelineItem | AircraftTimelineItem | ContractTimelineItem,
  itemContext: ItemContext,
) => {
  if (item.isNewEntry) {
    return colors.timeline.blueBackground;
  } else if (itemContext.selected) {
    if (itemContext.dragging) {
      return colors.timeline.red;
    } else {
      return colors.timeline.selectedYellow;
    }
  } else {
    return item.bgColor;
  }
};

export const getBorder = (
  item: CrewTimelineItem | ContractTimelineItem,
  itemContext: ItemContext,
) => {
  const crewEntry = (item as CrewTimelineItem).crewEntry;
  const dutyEntry = (item as CrewTimelineItem).dutyEntry;
  const isUnpublished = crewEntry?.IsUnpublished || dutyEntry?.IsUnpublished;
  let border = '';
  if (item.isNewEntry) {
    border = '1px solid black';
  } else if (itemContext.selected) {
    border = '1px solid red';
  } else if (isUnpublished) {
    border = `3px dashed ${
      item.bgColor !== colors.timeline.yellow ? colors.timeline.yellow : colors.timeline.black
    }`;
  } else {
    if (item.entryType === CrewTimelineEntryType.CrewSchedule) {
      border = `1px solid ${item.crewEntry.BorderColor || colors.timeline.darkBlue}`;
    } else {
      border = `1px solid ${colors.timeline.darkBlue}`;
    }
  }
  return border;
};

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export function calcTimelineHeight(
  windowHeight: number,
  isShortHeader: boolean,
  visibleTimelinesAmount: number,
  rowsLen: number,
  rowHeight: number,
): number {
  const topHeight = (windowHeight - TIMELINE_HEIGHT_CORRECTION) / visibleTimelinesAmount;
  const headerHeight = isShortHeader ? 23 : 62;
  let calculatedHeight = rowHeight * rowsLen;
  let resultedHeight = TIMELINE_HEIGHT;
  if (calculatedHeight < TIMELINE_HEIGHT) {
    resultedHeight = calculatedHeight + headerHeight;
  } else if (topHeight > resultedHeight) {
    resultedHeight = topHeight - headerHeight;
  }
  return resultedHeight;
}

export const getTextColor = (
  item: CrewTimelineItem | AircraftTimelineItem | ContractTimelineItem,
  itemContext: ItemContext,
) => {
  if (item.entryType === CrewTimelineEntryType.CrewSchedule) {
    return item?.crewEntry?.TextColor || colors.timeline.white;
  } else {
    return colors.timeline.white;
  }
};

export const getPositionLabel = (companySettings: CompanySettings, position: string) => {
  if (companySettings.UserCertifications?.length > 0) {
    const certification = companySettings.UserCertifications.find(c =>
      c.Assignment.some(a => a.Name === position),
    );

    if (certification) {
      const assignment = certification.Assignment.find(a => a.Name === position);
      if (assignment) {
        return assignment.Label;
      }
    }
  }

  return position;
};
