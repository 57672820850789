import { DutyFiCommon } from '../../types/entries';
import { put } from 'redux-saga/effects';
import { editFields, resetValidationMsg, setValidationMsg, setEditValues } from '../../actions';
import { composeKey } from '../../../helpers';
import { round } from 'lodash';

export function* runDetailedFlightTimesCalc(flightInformationArr: DutyFiCommon[], idx: number) {
  const flightInformation = flightInformationArr[idx];
  if (!!flightInformation) {
    const { DetailedFlightTimes } = flightInformation;

    let flightTimeTotal: number;
    let numberOfFlights: number;
    let IFRHours: number;
    let VFRHours: number;

    numberOfFlights = DetailedFlightTimes.length;
    flightTimeTotal = DetailedFlightTimes.reduce((acc, dft) => {
      acc = acc + dft.FlightTime;
      return acc;
    }, 0);
    IFRHours = DetailedFlightTimes.reduce((acc, dft) => {
      acc = acc + (dft.IFR ? dft.FlightTime : 0);
      return acc;
    }, 0);
    VFRHours = flightTimeTotal - (IFRHours || 0);

    yield put(
      setEditValues({
        [composeKey(String(idx), editFields.IFRHours)]: round(IFRHours, 1),
        [composeKey(String(idx), editFields.totalFlightHours)]: round(flightTimeTotal, 1),
        [composeKey(String(idx), editFields.numberOfFlights)]: numberOfFlights,
        [composeKey(String(idx), editFields.VFRHours)]: round(VFRHours, 1),
      }),
    );
    const key = composeKey(String(idx), editFields.DetailedFlightTimes);
    if (numberOfFlights === 0 && flightInformation?.AircraftType) {
      yield put(
        setValidationMsg(
          key,
          `Please add at least one flight to ${flightInformation.AircraftType}`,
        ),
      );
    } else {
      yield put(resetValidationMsg(key));
    }
  }
}
